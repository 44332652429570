import Box, { BoxProps } from '@mui/material/Box';
import { useEffect } from 'react';

import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import useInfiniteScroll from '~utils/hooks/useInfiniteScroll';

interface InfiniteScrollLoadingIndicatorProps extends BoxProps {
  intersectionObserverOptions?: IntersectionObserverInit;
  isLoading?: boolean;
  loadingIndicatorSize?: number;
  onFetchMore?: () => void;
}

export default function InfiniteScrollLoadingIndicator({
  intersectionObserverOptions = { threshold: 0 },
  isLoading,
  loadingIndicatorSize = 16,
  onFetchMore,
  ...rest
}: InfiniteScrollLoadingIndicatorProps) {
  const infiniteScroll = useInfiniteScroll(intersectionObserverOptions);

  useEffect(() => {
    if (infiniteScroll.endReached) {
      infiniteScroll.notify();

      onFetchMore?.();
    }
  }, [onFetchMore, infiniteScroll.endReached]);

  return (
    <Box ref={infiniteScroll.nodeRef} {...rest}>
      <LoadingSpinner isVisible={isLoading} loadingIndicatorSize={loadingIndicatorSize} />
    </Box>
  );
}
