import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import { forwardRef } from 'react';

import { SearchBar, SearchBarHandler } from '~components/Search/SearchBar';
import { JobAssignmentType } from '~constants/enums';

interface SearchProps {
  assigneeType: JobAssignmentType;
  onChange: (searchValue: string) => void;
  sx?: SxProps;
}

const Search = forwardRef<SearchBarHandler, SearchProps>(function Search(
  { assigneeType, onChange, sx },
  ref,
) {
  return (
    <Box sx={sx}>
      <SearchBar
        ref={ref}
        alwaysExpanded
        setFilter={onChange}
        placeHolder={
          assigneeType === JobAssignmentType.VENDOR
            ? t('dispatch.job.assignment_popover.search_for_vendor') || ''
            : t('dispatch.job.assignment_popover.search_for_driver') || ''
        }
      />
    </Box>
  );
});

export default Search;
