import { cloneDeep } from 'lodash';
import { makeAutoObservable, observable, runInAction } from 'mobx';

import { AdminRoleItem, AdminUser } from '~hooks/admin/useUsersAdmin/models';
import { User } from '~hooks/useUsers/models';
import { Pagination, PaginationLink } from '~services/pagination';
import { RootStore } from '~store/RootStore';
import { getPageNumber } from '~utils/pagination';

class UserAdminStore {
  user: AdminUser = {} as AdminUser;
  users: AdminUser[] = [];
  userRoles: AdminRoleItem[] = [];
  // Reference to the original user doing the impersonation
  // Useful only for rendering the impersonation experience, and for triggering hydration
  // Jwt is handled in connectionModule
  impersonatorUser: User | null = null;

  pagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this, {
      user: observable,
      users: observable,
      userRoles: observable,
      impersonatorUser: observable,
      pagination: observable,
    });
    this.rootStore = rootStore;
  }

  public get isImpersonating(): boolean {
    return this.impersonatorUser !== null;
  }

  setUserRoles(userRoles: AdminRoleItem[]) {
    runInAction(() => {
      this.userRoles = [...userRoles];
    });
  }

  setPagination(pagination: Pagination) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        before: pagination.before || '',
        after: pagination.after || '',
      };
    });
  }

  setImpersonatorUser(user: User | null) {
    runInAction(() => {
      this.impersonatorUser = user;
    });
  }

  updatePageNumber(link: PaginationLink) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        page: getPageNumber(this.pagination.page, link),
      };
    });
  }

  setUsers(users: AdminUser[]) {
    runInAction(() => {
      this.users = [...users];
    });
  }

  setUser(user: AdminUser) {
    runInAction(() => {
      this.user = cloneDeep(user);
    });
  }

  addUser(user: AdminUser) {
    runInAction(() => {
      this.users = [...this.users, user];
    });
  }

  updateUser(user: AdminUser) {
    runInAction(() => {
      this.users = this.users.map((u) => (u.id === user.id ? user : u));
    });
  }

  deleteUser(id: string) {
    runInAction(() => {
      this.users = this.users.filter((u) => u.id !== id);
    });
  }
}

export default UserAdminStore;
