import { SiteState } from '@treadinc/horizon-api-spec';
import axios, { AxiosError } from 'axios';
import _ from 'lodash';

import { geoFenceCircleOption, geofenceNoneOption } from '~constants/enums';
import { CompanyBasic } from '~hooks/useCompany';
import { WayPoint } from '~hooks/useSites';
import { Nullable } from '~types/Nullable';

import { ProjectFormSchemaInterface } from '../Projects/projectFormSchema';
import { NewOrderFormSchemaInterface } from './newOrderFormSchema';

export const createSitesInline = async (
  data: NewOrderFormSchemaInterface | ProjectFormSchemaInterface,
  createNewSite: any,
  defaultRadius?: Nullable<number>,
) => {
  if (data.pickUpWayPoint && !data.pickUpWayPoint?.site?.id) {
    // Site.id is empty when an address from the SiteLocation is selected
    // This site is not in the list of sites, and needs to be added.
    const company = data.company as CompanyBasic;
    const geofence = createCircleGeofence(defaultRadius, data.pickUpWayPoint as WayPoint);

    try {
      const pickUpSite = await createNewSite({
        name: data.pickUpWayPoint?.site?.name || '',
        company: { id: company?.id, legal_name: company?.legalName },
        address: data.pickUpWayPoint?.site?.address,
        lat: String(data.pickUpWayPoint?.site?.lat),
        lon: String(data.pickUpWayPoint?.site?.lon),
        state: SiteState.ACTIVE,
        geoFence: geofence,
        geofenceType: geofence ? geoFenceCircleOption : geofenceNoneOption,
        latLon: `${String(data.pickUpWayPoint?.site?.lat)}, ${String(data.pickUpWayPoint?.site?.lon)}`,
        ...geofence,
      });
      data.pickUpWayPoint.site.id = pickUpSite.id;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const is422 = String((error as AxiosError).status) === '422';
        const message = _.get(error, 'response.data.error.errors[0].message');

        if (is422 && message) {
          return { error: true, field: 'pickUpWayPoint', message };
        }
      }

      return { error: true, field: 'pickUpWayPoint' };
    }
  }

  if (data.dropOffWayPoint && !data.dropOffWayPoint?.site?.id) {
    // Site.id is empty when an address from the SiteLocation is selected
    // This site is not in the list of sites, and needs to be added.
    const company = data.company as CompanyBasic;
    const geofence = createCircleGeofence(
      defaultRadius,
      data.dropOffWayPoint as WayPoint,
    );

    try {
      const dropOffSite = await createNewSite({
        name: data.dropOffWayPoint?.site?.name || '',
        company: { id: company?.id, legal_name: company?.legalName },
        address: data.dropOffWayPoint?.site?.address,
        lat: String(data.dropOffWayPoint?.site?.lat),
        lon: String(data.dropOffWayPoint?.site?.lon),
        state: SiteState.ACTIVE,
        geoFence: geofence,
        latLon: `${String(data.dropOffWayPoint?.site?.lat)},${String(data.dropOffWayPoint?.site?.lon)}`,
        ...geofence,
      });
      data.dropOffWayPoint.site.id = dropOffSite.id;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const is422 = String((error as AxiosError).status) === '422';
        const message = _.get(error, 'response.data.error.errors[0].message');

        if (is422 && message) {
          return { error: true, field: 'dropOffWayPoint', message };
        }
      }

      return { error: true, field: 'dropOffWayPoint' };
    }
  }

  return data;
};

const createCircleGeofence = (radius: Nullable<number>, waypoint: WayPoint) => {
  if (!waypoint || !radius) {
    return {};
  }

  return {
    name: waypoint.site?.name,
    radius: Number(radius),
    circleCenter: {
      lat: waypoint.site?.lat,
      lon: waypoint.site?.lon,
    },
    geofenceType: geoFenceCircleOption,
  };
};
