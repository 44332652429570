import { useState } from 'react';

import { PaginationQuery } from '~services/pagination';
enum SubFilterKey {
  Job = 'job',
}

// legacy interface
interface AddSearchHeaderParamsProps {
  params: PaginationQuery;
  searchValue: string | undefined;
  filterParams?: Record<string, any>;
  // Sub filter allows for the filter params to be in to form of filter[_subFilter_][_key_]
  subFilterKey?: SubFilterKey;
}

// interface when using codegen services
interface AddSearchHeaderQueryProps {
  query: PaginationQuery;
  searchValue: string | undefined;
  filterQuery?: Record<string, any>;
  // Sub filter allows for the filter params to be in to form of filter[_subFilter_][_key_]
  subFilterKey?: SubFilterKey;
}

export const useDataGridSearch = () => {
  const [searchValue, setSearchValue] = useState('');

  // legacy method
  const addSearchHeaderParam = ({
    params,
    searchValue,
    filterParams,
    subFilterKey,
  }: AddSearchHeaderParamsProps) => {
    const subFilterText = subFilterKey ? `[${subFilterKey}]` : '';

    if (searchValue?.length) {
      params = { [`search${subFilterText}[datagrid]`]: searchValue || '', ...params };
    }

    // Handle filter params
    if (filterParams) {
      const filters: Record<string, string> = {};

      Object.keys(filterParams).forEach((key) => {
        filters[`filter${subFilterText}[${key}]`] = filterParams[key];
      });

      params = { ...params, ...filters };
    }

    return params;
  };

  // use with codegen services
  const addSearchHeaderQuery = ({
    query,
    searchValue,
    filterQuery,
    subFilterKey,
  }: AddSearchHeaderQueryProps) => {
    const subFilterText = subFilterKey ? `[${subFilterKey}]` : '';

    if (searchValue?.length) {
      query = { [`search${subFilterText}[datagrid]`]: searchValue || '', ...query };
    }

    // Handle filter query
    if (filterQuery) {
      const filters: Record<string, string> = {};

      Object.keys(filterQuery).forEach((key) => {
        filters[`filter${subFilterText}[${key}]`] = filterQuery[key];
      });

      query = { ...query, ...filters };
    }

    return query;
  };

  const setSearchQueryValue = (searchValue?: string) => {
    setSearchValue(searchValue || '');
  };

  return {
    addSearchHeaderParam,
    addSearchHeaderQuery,
    setSearchQueryValue,
    searchValue,
    SubFilterKey,
  } as const;
};
