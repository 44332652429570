import { Subscription } from '@rails/actioncable';
import {
  deleteV1TicketsId,
  getV1CompaniesCompanyIdTickets,
  GetV1CompaniesCompanyIdTicketsData,
  Ticket_Read,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useCallback, useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import connection from '~services/connectionModule';
import { extractPagination, PaginationLink, PaginationQuery } from '~services/pagination';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store';
import { subscribeToChannel } from '~utils/rtu/rtu-utils';
import { getEffectiveUserCompanyId } from '~utils/user/user-utils';

import { Ticket } from './models';

export type TicketEventType = 'approve' | 'void' | 'flag' | 'unflag';

interface updateTicketByIdProps {
  id: string;
  data: any;
  callBack?: (data: Ticket) => void;
}
interface DeleteIdCallBackProps {
  id: string;
  callBack?: () => void;
}
interface UploadTicketImageCallBackProps {
  id: string;
  file: File;
  callBack?: () => void;
}
interface GetTicketByIdProps {
  id: string;
  callBack?: (ticket: Ticket) => void;
}
interface CreateTicketProp {
  data: any;
  callBack?: (ticket: Ticket) => void;
}

type GetTicketsByJobQueryParams = { 'page[after]'?: string };

export const useTickets = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isUpdatingTicket, setIsUpdatingTicket] = useState<boolean>(false);
  const { ticketsStore, userStore } = useStores();
  const [ticketSubscription, setTicketSubscription] = useState<Subscription>();

  const { addSearchHeaderParam } = useDataGridSearch();

  const updateTicketById = ({ id, data, callBack }: updateTicketByIdProps) => {
    setIsUpdatingTicket(true);
    return connection
      .patch<Ticket_Read>(
        `${API_VERSION}/tickets/${id}`,
        Ticket.deparseUpdate(data),
        {},
        $t('error_messages.tickets.failed_to_update') as string,
      )
      .then((resp) => {
        const formatted = Ticket.parse(resp);
        callBack?.(formatted);
        ticketsStore.updateTicket(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdatingTicket(false);
      });
  };
  const deleteTicketImageById = ({ id, callBack }: DeleteIdCallBackProps) => {
    setIsUpdatingTicket(true);
    return connection
      .delete<Ticket_Read>(
        `${API_VERSION}/tickets/${id}/image`,
        {},
        $t('error_messages.tickets.failed_to_delete_ticket_image') as string,
      )
      .then((resp) => {
        callBack?.();
        return resp;
      })
      .finally(() => {
        setIsUpdatingTicket(false);
      });
  };
  const uploadTicketImageById = ({
    id,
    file,
    callBack,
  }: UploadTicketImageCallBackProps) => {
    setIsUpdatingTicket(true);
    const formData = new FormData();
    formData.append('image', file);
    return connection
      .post<Ticket_Read>(
        `${API_VERSION}/tickets/${id}/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        $t('error_messages.tickets.failed_to_upload_ticket_image') as string,
      )
      .then((resp) => {
        const formatted = Ticket.parse(resp);
        callBack?.();
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const getTicketById = async ({ id, callBack }: GetTicketByIdProps) => {
    setIsLoading(true);
    try {
      const resp = await connection.get<Ticket_Read>(
        `${API_VERSION}/tickets/${id}`,
        {},
        $t('error_messages.tickets.failed_to_fetch_ticket') as string,
      );
      const formatted = Ticket.parse(resp);
      callBack?.(formatted);
      return formatted;
    } finally {
      setIsLoading(false);
    }
  };
  const createTicket = async ({ data, callBack }: CreateTicketProp) => {
    setIsLoading(true);
    try {
      const resp = await connection.post<Ticket_Read>(
        `${API_VERSION}/tickets`,
        data,
        {},
        $t('error_messages.tickets.failed_to_create') as string,
      );
      const formatted = Ticket.parse(resp);
      callBack?.(formatted);
      return formatted;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTicketById = ({ id, callBack }: DeleteIdCallBackProps) => {
    setIsUpdatingTicket(true);

    return deleteV1TicketsId({
      path: {
        id,
      },
    })
      .then((resp) => {
        callBack?.();
        return resp;
      })
      .finally(() => {
        setIsUpdatingTicket(false);
      });
  };

  /**
   * Retrieves tickets based on the provided parameters.
   *
   * @param link - The pagination link to fetch the next or previous page of tickets.
   * @param searchQuery - The search query to filter the tickets.
   * @param filterParams - Additional filter parameters to narrow down the tickets.
   */
  const getTickets = async (
    link?: PaginationLink,
    searchQuery?: string,
    filterParams?: Record<string, any>,
  ) => {
    setIsLoading(true);

    // Set pagination params
    let params: PaginationQuery = {
      'page[limit]': ticketsStore.ticketsReviewPagination.limit,
    };
    if (link && ticketsStore.ticketsReviewPagination[link]) {
      params[`page[${link}]`] = ticketsStore.ticketsReviewPagination[link];
    }

    // Add search and filter params
    params = addSearchHeaderParam({
      searchValue: searchQuery,
      filterParams,
      params,
    });

    return await connection
      .getPaginated<Ticket_Read>(
        `${API_VERSION}/tickets`,
        { params },
        $t('error_messages.tickets.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted = data.map(Ticket.parse);
        ticketsStore.setTickets(formatted);
        ticketsStore.setTicketsReviewPagination(pagination);
        ticketsStore.updateTicketsReviewPageNumber(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getTicketsByCompanyId = async (
    companyId: string,
    link?: PaginationLink,
    filterParams?: { from_date?: string; to_date?: string },
  ) => {
    setIsLoading(true);

    const query: GetV1CompaniesCompanyIdTicketsData['query'] = {
      'page[limit]': ticketsStore.ticketsReviewPagination.limit,
    };
    if (link && ticketsStore.ticketsReviewPagination[link]) {
      query[`page[${link}]`] = ticketsStore.ticketsReviewPagination[link];
    }
    if (filterParams?.from_date) {
      query['filter[from_date]'] = filterParams.from_date;
    }
    if (filterParams?.to_date) {
      query['filter[to_date]'] = filterParams.to_date;
    }

    try {
      const response = await getV1CompaniesCompanyIdTickets({
        path: { 'company-id': companyId },
        query,
      });
      const formatted = response.data.data.map(Ticket.parse);
      ticketsStore.setTickets(formatted);
      const pagination = extractPagination(response);
      ticketsStore.setTicketsReviewPagination(pagination);
      ticketsStore.updateTicketsReviewPageNumber(link);
    } catch (e) {
      console.error(e);
      connection.handleRequestError(
        e,
        $t('error_messages.tickets.failed_to_fetch') as string,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const updateState = async (id: string, event: TicketEventType) => {
    setIsUpdating(true);
    try {
      const resp = await connection.put<Ticket_Read>(
        `${API_VERSION}/tickets/${id}/${event}`,
        {},
        {},
        $t('error_messages.tickets.failed_to_do_ticket_event', { event }) as string,
      );
      const formatted = Ticket.parse(resp);
      ticketsStore.updateTicket(formatted);
      return formatted;
    } finally {
      setIsUpdating(false);
    }
  };

  const getAllTicketsByJob = async (jobId: string) => {
    setIsLoading(true);

    const getPage = (params: GetTicketsByJobQueryParams) => {
      return connection.getPaginated<Ticket_Read>(
        `${API_VERSION}/jobs/${jobId}/tickets`,
        { params: { ...params, 'page[limit]': 25 } },
        $t('error_messages.tickets.failed_to_fetch') as string,
      );
    };

    let tickets: Ticket[] = [];
    let shouldFetchNextPage = true;
    const params: GetTicketsByJobQueryParams = {};

    try {
      while (shouldFetchNextPage) {
        const response = await getPage(params);

        const parsedTickets = response.data.map((ticket) => Ticket.parse(ticket));
        tickets = tickets.concat(parsedTickets);

        shouldFetchNextPage = Boolean(response.pagination.after);

        if (shouldFetchNextPage) {
          params['page[after]'] = response.pagination.after;
        }
      }

      return tickets;
    } finally {
      setIsLoading(false);
    }
  };

  const subscribeToTicketUpdates = useCallback(() => {
    const companyId = getEffectiveUserCompanyId(userStore);
    subscribeToChannel(
      realTimeChannels.TicketUpdateChannel,
      companyId,
      setTicketSubscription,
      (response: { data: Ticket_Read }) => {
        const ticket = Ticket.parse(response?.data);
        const existingTicket = ticketsStore.tickets.find((t) => t.id === ticket.id);
        if (existingTicket) {
          ticketsStore.updateTicket(ticket);
        } else {
          ticketsStore.setTickets([...ticketsStore.tickets, ticket]);
        }
      },
    );
  }, [getEffectiveUserCompanyId(userStore), setTicketSubscription]);

  return {
    createTicket,
    deleteTicketImageById,
    deleteTicketById,
    getAllTicketsByJob,
    getTicketById,
    getTickets,
    getTicketsByCompanyId,
    isLoading,
    isUpdating,
    isUpdatingTicket,
    updateState,
    updateTicketById,
    uploadTicketImageById,
    subscribeToTicketUpdates,
    ticketSubscription,
  } as const;
};
