import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import {
  BulkAssignVendor_Create,
  DriverAssignment_Create,
  putV1JobsBulkAssignDrivers,
  putV1JobsBulkAssignVendors,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

import useAssignDriverMenu from '~components/Job/AssignJobToView/hooks/useAssignDriverMenu';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { JobAssignmentType } from '~constants/enums';
import { Job } from '~hooks/useJob';
import { Order } from '~hooks/useOrders';
import { useStores } from '~store';
import { SelectedAssignee } from '~store/BulkAssignMultipleStore';
import theme from '~theme/AppTheme';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isJobAssignable } from '~utils/jobs/job-utils';
import { getEffectiveUserCompanyId } from '~utils/user/user-utils';
import { isActionClicked } from '~utils/utilFunctions';

import AssigneesList from './bulk-assign-multiple-jobs-dialog/AssigneesList';
import AssigneeTabs from './bulk-assign-multiple-jobs-dialog/AssigneeTabs';
import DialogHeader from './bulk-assign-multiple-jobs-dialog/DialogHeader';
import Search from './bulk-assign-multiple-jobs-dialog/Search';
import SelectionCounters from './bulk-assign-multiple-jobs-dialog/SelectionCounters';

const DIALOG_CONTENT_INNER_GUTTER_SIZE = 1.5;
const DIALOG_CONTENT_FLEX_GAP_SIZE = 1.5;

const distributeJobs = (assignees: SelectedAssignee[], jobs: Job[]) => {
  let driversAssignments: DriverAssignment_Create[] = [];
  const vendorsAssignments: BulkAssignVendor_Create[] = [];
  let currentJobIndex = 0;
  const sortedJobs = [...jobs].sort((a, b) => a.jobStartAt.unix() - b.jobStartAt.unix());

  assignees.forEach((assignee) => {
    const jobsAssignedCount = assignee.count;
    const hasJobsAssigned = jobsAssignedCount > 0;
    const isJobForVendor = assignee.type === JobAssignmentType.VENDOR;

    if (hasJobsAssigned) {
      if (isJobForVendor) {
        const assignment: BulkAssignVendor_Create = {
          vendor_account_id: assignee.id,
          job_ids: Array.from(
            { length: jobsAssignedCount },
            (_, i) => sortedJobs[currentJobIndex + i].id,
          ),
        };

        vendorsAssignments.push(assignment);
      } else {
        const assignments = Array.from(
          { length: jobsAssignedCount },
          (_, i): DriverAssignment_Create => ({
            driver_id: assignee.id,
            job_id: sortedJobs[currentJobIndex + i].id,
          }),
        );

        driversAssignments = driversAssignments.concat(assignments);
      }

      currentJobIndex = currentJobIndex + jobsAssignedCount;
    }
  });

  return { driversAssignments, vendorsAssignments };
};

interface BulkAssignMultipleJobsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  order?: Order;
}

const BulkAssignMultipleJobsDialog = observer(
  ({ isOpen, onClose, onConfirm, order }: BulkAssignMultipleJobsDialogProps) => {
    const { bulkAssignMultipleStore, ordersDispatchStore } = useStores();

    const orderJobs = order?.id ? ordersDispatchStore.orderJobs.get(order.id) ?? [] : [];
    const orderAssignableJobs = orderJobs.filter((job) => isJobAssignable(job));

    const noAssigneesSelected = bulkAssignMultipleStore.selectedAssigneesCount === 0;
    const selectedAssigneesCountExceedsMaxSelectionsCount =
      bulkAssignMultipleStore.selectedAssigneesCount > orderAssignableJobs.length;
    const shouldDisableConfirmButton = order
      ? noAssigneesSelected || selectedAssigneesCountExceedsMaxSelectionsCount
      : false;

    const handleClearAllClick = useCallback(() => {
      bulkAssignMultipleStore.clearSelectedAssignees();
    }, []);

    const handleConfirm = useCallback(() => {
      const { driversAssignments, vendorsAssignments } = distributeJobs(
        bulkAssignMultipleStore.selectedAssignees,
        orderAssignableJobs,
      );

      if (driversAssignments.length) {
        putV1JobsBulkAssignDrivers({ body: { driver_assignments: driversAssignments } });
      }

      if (vendorsAssignments.length) {
        putV1JobsBulkAssignVendors({ body: { vendor_assignments: vendorsAssignments } });
      }

      onConfirm();
    }, [
      JSON.stringify(bulkAssignMultipleStore.selectedAssignees),
      orderAssignableJobs,
      onConfirm,
    ]);

    return (
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            height: '720px',
            maxWidth: '649px',
            '.MuiDialogContent-root': { p: 0, overflow: 'hidden' },
          },
        }}
        open={isOpen}
        onClose={(__: never, reason: DialogCloseReasonType) => {
          if (isActionClicked(reason)) {
            onClose();
          }
        }}
      >
        <MuiDialogContent>
          {order && <DialogContent order={order} assignableJobs={orderAssignableJobs} />}
        </MuiDialogContent>

        <DialogActions
          sx={{
            alignItems: 'center',
            borderTop: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            justifyContent: 'space-between',
            m: 0,
            p: 1.5,
          }}
        >
          <SmallButton
            color="brandV2OrangeDark"
            onClick={handleClearAllClick}
            variant="text"
            sx={{
              visibility: bulkAssignMultipleStore.selectedAssigneesCount
                ? 'visible'
                : 'hidden',
            }}
          >
            {t('common.clear_all')}
          </SmallButton>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SmallButton color="secondary" variant="outlined" onClick={onClose}>
              {t('actions.cancel')}
            </SmallButton>

            <SmallButton
              color="brandV2Yellow"
              disabled={shouldDisableConfirmButton}
              onClick={handleConfirm}
            >
              {t('dispatch.order.assign_all')}
            </SmallButton>
          </Box>
        </DialogActions>
      </Dialog>
    );
  },
);

interface DialogContentProps {
  assignableJobs: Job[];
  order: Order;
}

const DialogContent = observer(({ assignableJobs, order }: DialogContentProps) => {
  const { userStore, bulkAssignMultipleStore } = useStores();

  const companyId = getEffectiveUserCompanyId(userStore);
  const countsByType = bulkAssignMultipleStore.selectedAssigneesCountByAssigneeType;

  const {
    data,
    fetchMore,
    hasMore,
    loadingReason,
    reset,
    searchBarRef,
    searchFieldValue,
    selectedTab,
    setOpen,
    setSearchFieldValue,
    setSelectedTab,
  } = useAssignDriverMenu(companyId);

  const hasEmptyResults = searchFieldValue.length > 0 && !loadingReason && !data?.length;

  useEffect(() => {
    setOpen(true);
    bulkAssignMultipleStore.clearSelectedAssignees();

    return () => {
      reset();
    };
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: DIALOG_CONTENT_FLEX_GAP_SIZE,
        py: 1.5,
      }}
    >
      <DialogHeader
        maxSelectionsCount={assignableJobs.length}
        order={order}
        sx={{ px: DIALOG_CONTENT_INNER_GUTTER_SIZE }}
      />

      <AssigneeTabs
        onChange={setSelectedTab}
        selectedTab={selectedTab}
        internalDriversCount={countsByType[JobAssignmentType.INTERNAL_DRIVER]}
        externalDriversCount={countsByType[JobAssignmentType.EXTERNAL_DRIVER]}
        vendorsCount={countsByType[JobAssignmentType.VENDOR]}
        sx={{ px: DIALOG_CONTENT_INNER_GUTTER_SIZE }}
      />

      <Search
        ref={searchBarRef}
        assigneeType={selectedTab}
        onChange={setSearchFieldValue}
        sx={{ px: DIALOG_CONTENT_INNER_GUTTER_SIZE }}
      />

      <SelectionCounters
        currentSelectionsCount={bulkAssignMultipleStore.selectedAssigneesCount}
        maxSelectionsCount={assignableJobs.length}
        sx={{ px: DIALOG_CONTENT_INNER_GUTTER_SIZE }}
      />

      <Divider />

      <Box
        sx={{
          flex: 1,
          my: DIALOG_CONTENT_FLEX_GAP_SIZE * -1,
          py: DIALOG_CONTENT_FLEX_GAP_SIZE,
          px: DIALOG_CONTENT_INNER_GUTTER_SIZE,
          overflow: 'auto',
        }}
      >
        <AssigneesList
          key={selectedTab}
          assigneeType={selectedTab}
          assignees={data ?? []}
          fetchMore={fetchMore}
          hasMore={hasMore}
          loadingReason={loadingReason ?? undefined}
          showNoResultsMessage={hasEmptyResults}
        />
      </Box>
    </Box>
  );
});

export default BulkAssignMultipleJobsDialog;
