import {
  Equipment_Read,
  Equipment_Read_Typeahead,
  EquipmentType_Read,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import _ from 'lodash';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { Equipment, EquipmentTypeItem } from '~hooks/useEquipment';
import connection from '~services/connectionModule';
import { Paginated, PaginationLink, PaginationQuery } from '~services/pagination';
import { useStores } from '~store';
interface CreateEquipmentProps {
  equipment: Equipment;
  callBack?: (equipment: Equipment) => void;
}

interface CreateEquipmentTypeByCompanyProps {
  equipmentType: EquipmentTypeItem;
  companyId: string;
  callBack?: (equipmentType: EquipmentTypeItem) => void;
}
interface GetEquipmentTypesByCompanyIdProps {
  companyId: string;
  callBack?: (equipmentTypes: EquipmentTypeItem[]) => void;
  link?: PaginationLink;
}
interface GetEquipmentTypesByCompanyIdTypeaheadProps {
  companyId: string;
  callBack?: (equipmentTypes: EquipmentTypeItem[]) => void;
  limit?: number;
  query?: string;
  cursor?: string;
}

interface GetEquipmentTypesByCompanyIdTypeaheadQueryProps {
  'page[limit]': number;
  'search[query]': string;
  'page[after]': string;
}

interface DeleteEquipmentProps {
  equipmentId: string;
  callBack?: () => void;
}
interface DeleteEquipmentTypeProps {
  equipmentTypeId: string;
  callBack?: () => void;
}
interface UpdateEquipmentTypeProps {
  equipmentType: EquipmentTypeItem;
  callBack?: (equipmentType: EquipmentTypeItem) => void;
}

interface GetEquipmentByCompanyIdProps {
  companyId: string;
  link?: PaginationLink;
  limit?: number;
  dispatchable?: boolean;
  shared?: boolean;
  query?: string;
}

interface GetEquipmentByCompanyIdParams {
  'page[limit]'?: number;
  'page[after]'?: string;
  'page[before]'?: string;
  'filter[dispatchable]'?: boolean;
  'filter[shared]'?: boolean;
  'search[datagrid]'?: string;
}

interface GetEquipmentByCompanyIdTypeaheadProps {
  companyId: string;
  limit?: number;
  query?: string;
  cursor?: string;
  dispatchable?: boolean;
  shared?: boolean;
}

interface GetEquipmentByCompanyIdTypeaheadQueryProps {
  'page[limit]'?: number;
  'search[query]'?: string;
  'page[after]'?: string;
  'filter[dispatchable]'?: boolean;
  'filter[shared]'?: boolean;
}

export const useEquipment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { companyAssetsStore } = useStores();
  const { addSearchHeaderParam } = useDataGridSearch();

  const getEquipment = (link?: PaginationLink, searchQuery?: string) => {
    setIsLoading(true);
    let params: PaginationQuery = {
      'page[limit]': companyAssetsStore.equipmentPagination.limit,
    };
    params = addSearchHeaderParam({ searchValue: searchQuery, params });
    if (link && companyAssetsStore.equipmentPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.equipmentPagination[link];
    }
    return connection
      .getPaginated<Equipment_Read>(
        `${API_VERSION}/equipment`,
        { params },
        $t('error_messages.equipment.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        companyAssetsStore.setEquipment(data.map(Equipment.parse));
        companyAssetsStore.setEquipmentPagination(pagination);
        companyAssetsStore.updateEquipmentPageNumber(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getEquipmentByCompanyId = async ({
    companyId,
    link,
    shared,
    limit,
    dispatchable,
    query,
  }: GetEquipmentByCompanyIdProps) => {
    const params: GetEquipmentByCompanyIdParams = {
      'page[limit]': limit || companyAssetsStore.equipmentPagination.limit,
    };
    if (link && companyAssetsStore.equipmentPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.equipmentPagination[link];
    }
    if (!_.isNil(shared)) {
      params['filter[shared]'] = shared;
    }
    if (!_.isNil(dispatchable)) {
      params['filter[dispatchable]'] = dispatchable;
    }
    if (query) {
      params['search[datagrid]'] = query;
    }

    try {
      setIsLoading(true);
      const response = await connection.getPaginated<Equipment_Read>(
        `${API_VERSION}/companies/${companyId}/equipment`,
        {
          params,
        },
        $t('error_messages.equipment.failed_to_fetch') as string,
      );
      const { data, pagination } = response;
      companyAssetsStore.setEquipment(data.map(Equipment.parse));
      companyAssetsStore.setEquipmentPagination(pagination);
      companyAssetsStore.updateEquipmentPageNumber(link);
      return data.map(Equipment.parse);
    } catch (e) {
      console.error(e);
      throw new Error('Failed to fetch equipment');
    } finally {
      setIsLoading(false);
    }
  };

  const getEquipmentByCompanyIdTypeahead = async ({
    companyId,
    limit,
    query,
    cursor,
    dispatchable,
    shared,
  }: GetEquipmentByCompanyIdTypeaheadProps) => {
    const params: GetEquipmentByCompanyIdTypeaheadQueryProps = {
      'page[limit]': limit || companyAssetsStore.equipmentPagination.limit,
    };

    if (query) {
      params['search[query]'] = query;
    }

    if (cursor) {
      params['page[after]'] = cursor;
    }

    if (!_.isNil(shared)) {
      params['filter[shared]'] = shared;
    }
    if (!_.isNil(dispatchable)) {
      params['filter[dispatchable]'] = dispatchable;
    }

    try {
      setIsLoading(true);
      const response = await connection.getPaginated<
        Paginated<Equipment_Read_Typeahead[]>
      >(
        `${API_VERSION}/companies/${companyId}/equipment/typeahead`,
        {
          params,
        },
        $t('error_messages.equipment.failed_to_fetch') as string,
      );
      const { data, pagination } = response;
      return { data, pagination };
    } catch (e) {
      console.error(e);
      throw new Error($t('error_messages.equipment.failed_to_fetch') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const createEquipment = ({ equipment, callBack }: CreateEquipmentProps) => {
    setIsLoading(true);
    return connection
      .post<Equipment_Read>(
        `${API_VERSION}/equipment`,
        Equipment.deparse(equipment),
        {},
        $t('error_messages.equipment.failed_to_create') as string,
        [422],
      )
      .then((resp) => {
        const equipment = Equipment.parse(resp);
        companyAssetsStore.addEquipment(equipment);
        callBack?.(equipment);
        return equipment;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createEquipmentTypeByCompanyId = ({
    companyId,
    equipmentType,
    callBack,
  }: CreateEquipmentTypeByCompanyProps) => {
    setIsLoading(true);
    return connection
      .post<EquipmentType_Read>(
        `${API_VERSION}/companies/${companyId}/equipment_types`,
        EquipmentTypeItem.deparse(equipmentType),
        {},
        $t('error_messages.equipment_types.failed_to_create') as string,
      )
      .then((resp) => {
        const equipmentType = EquipmentTypeItem.parse(resp);

        companyAssetsStore.addEquipmentType(equipmentType);
        callBack?.(equipmentType);
        return equipmentType;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateEquipment = ({ equipment, callBack }: CreateEquipmentProps) => {
    setIsUpdating(true);
    return connection
      .patch<Equipment_Read>(
        `${API_VERSION}/equipment/${equipment.id}`,
        Equipment.deparseUpdate(equipment),
        {},
        $t('error_messages.equipment.failed_to_update') as string,
        [422],
      )
      .then((resp) => {
        const equipment = Equipment.parse(resp);
        companyAssetsStore.updateEquipment(equipment);
        callBack?.(equipment);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const deleteEquipment = ({ equipmentId, callBack }: DeleteEquipmentProps) => {
    setIsLoading(true);
    return connection
      .delete<Equipment_Read>(
        `${API_VERSION}/equipment/${equipmentId}`,
        {},
        $t('error_messages.equipment.failed_to_delete') as string,
      )
      .then(() => {
        companyAssetsStore.deleteEquipment(equipmentId);
        callBack?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getEquipmentTypeById = (equipmentTypeId: string) => {
    try {
      setIsLoading(true);
      return connection
        .get<EquipmentType_Read>(
          `${API_VERSION}/equipment_types/${equipmentTypeId}`,
          {},
          $t('error_messages.equipment_types.failed_to_fetch') as string,
        )
        .then((resp) => {
          const equipment = EquipmentTypeItem.parse(resp);
          return equipment;
        });
    } catch (e) {
      console.error(e);
      throw new Error($t('error_messages.equipment_types.failed_to_fetch') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const getEquipmentTypesByCompanyId = ({
    companyId,
    callBack,
    link,
  }: GetEquipmentTypesByCompanyIdProps) => {
    const params: PaginationQuery = {
      'page[limit]': companyAssetsStore.equipmentTypePagination.limit,
    };
    if (link && companyAssetsStore.equipmentTypePagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.equipmentTypePagination[link];
    }
    setIsLoading(true);
    return connection
      .getPaginated<EquipmentType_Read>(
        `${API_VERSION}/companies/${companyId}/equipment_types`,
        { params },
        $t('error_messages.equipment_types.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted = data.map(EquipmentTypeItem.parse);
        companyAssetsStore.setEquipmentTypes(formatted);
        companyAssetsStore.setEquipmentTypePagination(pagination);
        companyAssetsStore.updateEquipmentTypePageNumber(link);
        callBack?.(formatted);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getEquipmentTypesByCompanyIdTypeahead = async (
    options: GetEquipmentTypesByCompanyIdTypeaheadProps,
  ) => {
    const params: Partial<GetEquipmentTypesByCompanyIdTypeaheadQueryProps> = {
      'page[limit]': options.limit || companyAssetsStore.equipmentTypePagination.limit,
    };
    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoading(true);
      const { data, pagination } =
        await connection.getPaginated<Equipment_Read_Typeahead>(
          `${API_VERSION}/companies/${options.companyId}/equipment_types/typeahead`,
          {
            params,
          },
          $t('error_messages.equipment_types.failed_to_fetch') as string,
        );
      const formatted = (data as EquipmentType_Read[]).map(EquipmentTypeItem.parse);
      options.callBack?.(formatted);
      return { data: formatted, pagination };
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getEquipmentById = (equipmentId: string) => {
    try {
      setIsLoading(true);
      return connection
        .get<Equipment_Read>(
          `${API_VERSION}/equipment/${equipmentId}`,
          {},
          $t('error_messages.equipment.failed_to_fetch') as string,
        )
        .then((resp) => {
          const equipment = Equipment.parse(resp);
          return equipment;
        });
    } catch (e) {
      console.error(e);
      throw new Error($t('error_messages.equipment.failed_to_fetch') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const updateEquipmentType = ({ equipmentType, callBack }: UpdateEquipmentTypeProps) => {
    setIsUpdating(true);
    return connection
      .patch<EquipmentType_Read>(
        `${API_VERSION}/equipment_types/${equipmentType.id}`,
        EquipmentTypeItem.deparseUpdate(equipmentType),
        {},
        $t('error_messages.equipment_types.failed_to_update') as string,
      )
      .then((resp) => {
        const formatted = EquipmentTypeItem.parse(resp);
        companyAssetsStore.updateEquipmentType(equipmentType);
        callBack?.(formatted);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const deleteEquipmentType = ({
    equipmentTypeId,
    callBack,
  }: DeleteEquipmentTypeProps) => {
    setIsLoading(true);
    return connection
      .delete<EquipmentTypeItem>(
        `${API_VERSION}/equipment_types/${equipmentTypeId}`,
        {},
        $t('error_messages.equipment_types.failed_to_delete') as string,
      )
      .then(() => {
        companyAssetsStore.deleteEquipmentType(equipmentTypeId);
        callBack?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const activateEquipment = (equipmentId: string) => {
    setIsLoading(true);
    return connection
      .put<Equipment_Read>(
        `${API_VERSION}/equipment/${equipmentId}/activate`,
        {},
        {},
        $t('error_messages.equipment.failed_to_activate') as string,
      )
      .then((resp) => {
        const equipment = Equipment.parse(resp);
        companyAssetsStore.updateEquipment(equipment);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const deactivateEquipment = (equipmentId: string) => {
    setIsUpdating(true);
    return connection
      .put<Equipment_Read>(
        `${API_VERSION}/equipment/${equipmentId}/deactivate`,
        {},
        {},
        $t('error_messages.equipment.failed_to_deactivate') as string,
      )
      .then((resp) => {
        const equipment = Equipment.parse(resp);
        companyAssetsStore.updateEquipment(equipment);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return {
    isLoading,
    isUpdating,
    createEquipment,
    deleteEquipment,
    updateEquipment,
    getEquipmentTypeById,
    getEquipmentTypesByCompanyId,
    getEquipmentTypesByCompanyIdTypeahead,
    createEquipmentTypeByCompanyId,
    deleteEquipmentType,
    updateEquipmentType,
    activateEquipment,
    deactivateEquipment,
    getEquipment,
    getEquipmentById,
    getEquipmentByCompanyId,
    getEquipmentByCompanyIdTypeahead,
  };
};
