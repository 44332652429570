import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

import { DialogHeader } from '~components/Dialog/DialogHeader';
import { AccountTypeahead } from '~hooks/useAccount';
import theme from '~theme/AppTheme';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

import { VendorAccountListWithTypeahead } from './AssignJobToView/AssignJobForm';

interface BulkAssignJobsDialogProps {
  accountId: string | null;
  companyId: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onSelectedAccountChange: (account: AccountTypeahead) => void;
}

export function BulkAssignJobsDialog({
  accountId,
  companyId,
  isOpen,
  onClose,
  onConfirm,
  onSelectedAccountChange,
}: BulkAssignJobsDialogProps) {
  return (
    <Dialog
      sx={{ '& .MuiPaper-root': { maxWidth: '700px', height: '700px' } }}
      open={isOpen}
      onClose={(__: never, reason: DialogCloseReasonType) => {
        if (isActionClicked(reason)) {
          onClose();
        }
      }}
    >
      <DialogHeader
        closeCallBack={onClose}
        title={
          <>
            <Typography component="span" variant="h5">
              {t('dispatch.job.bulk_assign_jobs_dialog_title')}
            </Typography>
            <Typography component="p" variant="body1">
              {t('dispatch.job.bulk_assign_jobs_dialog_text')}
            </Typography>
          </>
        }
      />

      <DialogContent sx={{ backgroundColor: theme.palette.grey[100] }}>
        <Card sx={{ p: 2, mb: 2, mt: 2.5 }}>
          <VendorAccountListWithTypeahead
            accountId={accountId}
            companyId={companyId}
            onAccountSelect={onSelectedAccountChange}
          />
        </Card>
      </DialogContent>

      <DialogActions
        sx={{
          m: 0,
          p: 2,
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row-reverse',
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button onClick={onConfirm} sx={{ mr: 2, px: 2 }} color="primary">
          {t('actions.assign')}
        </Button>

        <Button
          onClick={onClose}
          sx={{ mr: 2, px: 2 }}
          color="secondary"
          variant="outlined"
        >
          {t('actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface BulkSendJobsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function BulkSendJobsDialog({
  isOpen,
  onClose,
  onConfirm,
}: BulkSendJobsDialogProps) {
  return (
    <Dialog
      sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
      open={isOpen}
      onClose={(__: never, reason: DialogCloseReasonType) => {
        if (isActionClicked(reason)) {
          onClose();
        }
      }}
    >
      <DialogHeader
        closeCallBack={onClose}
        title={
          <Typography component="span" variant="h5">
            {t('dispatch.job.bulk_send_jobs_dialog_title')}
          </Typography>
        }
      />

      <DialogContent>
        <Typography sx={{ mt: 2.5 }}>
          {t('dispatch.job.bulk_send_jobs_dialog_text')}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          m: 0,
          p: 2,
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row-reverse',
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button onClick={onConfirm} sx={{ mr: 2, px: 2 }} color="primary">
          {t('actions.confirm')}
        </Button>

        <Button
          onClick={onClose}
          sx={{ mr: 2, px: 2 }}
          color="secondary"
          variant="outlined"
        >
          {t('actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
