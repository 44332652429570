import { AccountType } from '@treadinc/horizon-api-spec';
import _ from 'lodash';
import { toJS } from 'mobx';
import { useCallback, useEffect, useRef, useState } from 'react';

import { SearchBarHandler } from '~components/Search/SearchBar';
import { JobAssignmentType } from '~constants/enums';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { useAccount } from '~hooks/useAccount';
import { useDrivers } from '~hooks/useDrivers';
import { useStores } from '~store';
import { usePrevious } from '~utils/hooks/usePrevious';

const PAGINATION_LIMIT = 20;

interface AppliedFilter {
  query: string;
  type: JobAssignmentType;
}

export default function useAssignDriverMenu(companyId: string) {
  const [open, setOpen] = useState<boolean>(false);
  const searchBarRef = useRef<SearchBarHandler>(null);
  const [appliedFilter, setAppliedFilter] = useState<AppliedFilter>({
    type: JobAssignmentType.INTERNAL_DRIVER,
    query: '',
  });
  const { getAccountsByCompanyIdTypeahead } = useAccount();
  const { getDriversByCompanyIdTypeahead } = useDrivers();
  const applyDebouncedFilter = _.debounce((callback: () => void) => {
    callback();
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  const { assignDriverMenuStore } = useStores();

  const queryData = assignDriverMenuStore.getQueryDataByJobAssignmentType(
    appliedFilter.type,
  );
  const queryResults = { data: toJS(queryData.data), hasMore: queryData.hasMore };

  const isInitialFetch = useRef(true);
  const previousQuery = usePrevious(appliedFilter.query);
  const previousType = usePrevious(appliedFilter.type);

  const fetchInternalDrivers = useCallback(
    async (query: string, cursor?: string) => {
      assignDriverMenuStore.fetchStart(JobAssignmentType.INTERNAL_DRIVER, query, cursor);

      const response = await getDriversByCompanyIdTypeahead({
        companyId,
        shared: false,
        searchParams: {
          limit: PAGINATION_LIMIT,
          query,
          link: { type: 'after', cursor: cursor ?? '' },
        },
      });

      assignDriverMenuStore.fetchEnd(response, JobAssignmentType.INTERNAL_DRIVER);
    },
    [companyId],
  );

  const fetchExternalDrivers = useCallback(
    async (query: string, cursor?: string) => {
      assignDriverMenuStore.fetchStart(JobAssignmentType.EXTERNAL_DRIVER, query, cursor);

      const response = await getDriversByCompanyIdTypeahead({
        companyId,
        shared: true,
        searchParams: {
          limit: PAGINATION_LIMIT,
          query,
          link: { type: 'after', cursor: cursor ?? '' },
        },
      });

      assignDriverMenuStore.fetchEnd(response, JobAssignmentType.EXTERNAL_DRIVER);
    },
    [companyId],
  );

  const fetchVendors = useCallback(
    async (query: string, cursor?: string) => {
      assignDriverMenuStore.fetchStart(JobAssignmentType.VENDOR, query, cursor);

      const response = await getAccountsByCompanyIdTypeahead({
        companyId,
        searchParams: {
          accountTypes: [AccountType.VENDOR],
          limit: PAGINATION_LIMIT,
          query,
          link: { type: 'after', cursor: cursor ?? '' },
        },
      });

      if (!response) {
        throw new Error('Failed to fetch vendors.');
      }

      assignDriverMenuStore.fetchEnd(response, JobAssignmentType.VENDOR);
    },
    [companyId],
  );

  const fetch = useCallback(
    (isInitialFetch?: boolean) => {
      const queryDidChange = previousQuery !== appliedFilter.query;
      const isClearingQuery = previousQuery && !appliedFilter.query.length;
      const typeDidChange = previousType !== appliedFilter.type;

      // if there is data already fetched for the selected type then next results should be
      // fetched on scroll
      const hasPreviousData = Boolean(
        assignDriverMenuStore.getQueryDataByJobAssignmentType(appliedFilter.type).data,
      );

      let shouldFetch: boolean = false;

      if (isInitialFetch) {
        shouldFetch = true;
      } else if (isClearingQuery) {
        // clear the already fetched data for the previous type if it was being filtered by
        // query, so next time this type is picked up all data is refetched but
        // this time out the search query filter.
        if (previousType) {
          assignDriverMenuStore.resetQueryDataByJobAssignmentType(previousType);
        }

        if (typeDidChange) {
          shouldFetch = !hasPreviousData;
        } else {
          shouldFetch = true;
        }
      } else if (typeDidChange) {
        shouldFetch = !hasPreviousData;
      } else if (queryDidChange) {
        shouldFetch = true;
      }

      if (!shouldFetch) {
        return;
      }

      if (appliedFilter.type === JobAssignmentType.INTERNAL_DRIVER) {
        fetchInternalDrivers(appliedFilter.query);
      } else if (appliedFilter.type === JobAssignmentType.EXTERNAL_DRIVER) {
        fetchExternalDrivers(appliedFilter.query);
      } else {
        fetchVendors(appliedFilter.query);
      }
    },
    [
      appliedFilter.query,
      appliedFilter.type,
      fetchExternalDrivers,
      fetchInternalDrivers,
      fetchVendors,
      previousQuery,
      previousType,
    ],
  );

  const fetchMore = useCallback(() => {
    if (assignDriverMenuStore.loadingReason) {
      return;
    }

    const { hasMore } = assignDriverMenuStore.getQueryDataByJobAssignmentType(
      appliedFilter.type,
    );

    if (!hasMore) {
      return;
    }

    if (appliedFilter.type === JobAssignmentType.INTERNAL_DRIVER) {
      fetchInternalDrivers(
        appliedFilter.query,
        assignDriverMenuStore.internalDrivers.cursor,
      );
    } else if (appliedFilter.type === JobAssignmentType.EXTERNAL_DRIVER) {
      fetchExternalDrivers(
        appliedFilter.query,
        assignDriverMenuStore.externalDrivers.cursor,
      );
    } else {
      fetchVendors(appliedFilter.query, assignDriverMenuStore.vendors.cursor);
    }
  }, [
    appliedFilter.type,
    appliedFilter.query,
    assignDriverMenuStore.externalDrivers.cursor,
    assignDriverMenuStore.internalDrivers.cursor,
    assignDriverMenuStore.vendors.cursor,
    assignDriverMenuStore.loadingReason,
    fetchExternalDrivers,
    fetchInternalDrivers,
    fetchVendors,
  ]);

  const setSelectedTab = useCallback((tab: JobAssignmentType) => {
    searchBarRef.current?.clear();
    setAppliedFilter({ query: '', type: tab });
  }, []);

  const setSearchFieldValue = useCallback((value: string) => {
    setAppliedFilter((state) => ({ ...state, query: value }));
  }, []);

  useEffect(() => {
    if (open && searchBarRef.current) {
      searchBarRef.current.focus();
    }
  }, [open, appliedFilter.type]);

  const reset = useCallback(() => {
    isInitialFetch.current = true;
    setAppliedFilter({ type: JobAssignmentType.INTERNAL_DRIVER, query: '' });
    assignDriverMenuStore.reset();
  }, []);

  useEffect(() => {
    if (open) {
      fetch(isInitialFetch.current);
      isInitialFetch.current = false;
    }
  }, [open, fetch]);

  return {
    applyDebouncedFilter,
    data: queryResults.data,
    fetchMore,
    hasMore: queryResults.hasMore,
    loadingReason: assignDriverMenuStore.loadingReason,
    open,
    reset,
    searchBarRef,
    searchFieldValue: appliedFilter.query,
    selectedTab: appliedFilter.type,
    setOpen,
    setSearchFieldValue,
    setSelectedTab,
  };
}
