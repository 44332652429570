import axios, { CancelTokenSource } from 'axios';

export default class AxiosRequestCancelationHandler {
  #token: CancelTokenSource;
  #isRequestInFlight: boolean;

  constructor() {
    this.#token = axios.CancelToken.source();
    this.#isRequestInFlight = false;
  }

  cancelCurrentRequest() {
    this.#isRequestInFlight = false;
    this.#token.cancel();
    this.#token = axios.CancelToken.source();
  }

  get cancelationToken() {
    return this.#token;
  }

  get isRequestInFlight() {
    return this.#isRequestInFlight;
  }
  set isRequestInFlight(value: boolean) {
    this.#isRequestInFlight = value;
  }
}
