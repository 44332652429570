import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import ButtonBase from '@mui/material/ButtonBase';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';

import { usePrevious } from '~utils/hooks/usePrevious';

const StyledButton = styled(ButtonBase)`
  align-items: center;
  background-color: transparent;
  border: none;
  box-shadow: none;
  display: inline-flex;
  justify-content: space-between;
  padding: 0;
  position: relative;
  width: 100%;

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }

  .MuiPaper-root {
    align-items: center;
    display: flex;
    flex: 0;
    gap: 6px;
  }
`;

const StyledAlert = styled(Alert)`
  background-color: transparent;
  padding: 0;

  .MuiAlert-icon {
    font-size: 14px;
    margin-right: 0;
    padding: 0;
  }

  .MuiAlert-message {
    font-size: 12px;
    padding: 0;
  }
`;

interface CopyToClipboardProps {
  children: React.ReactNode;
  textToCopy: string;
  alertMessageDuration?: number;
}

const CopyToClipboard = ({
  textToCopy,
  children,
  alertMessageDuration = 2000,
}: CopyToClipboardProps) => {
  const [error, setError] = useState<string>();
  const [showMessage, setShowMessageState] = useState(false);
  const [, copyToClipboard] = useCopyToClipboard();

  const prevShowMessageState = usePrevious(showMessage);

  const onLinkClick = useCallback(async () => {
    setError(undefined);

    try {
      await copyToClipboard(textToCopy);
      setShowMessageState(true);
    } catch (error) {
      setError(`${t('approvals.copy_error')}: ${error}`);
    }
  }, [textToCopy]);

  useEffect(() => {
    let fadeMessage: ReturnType<typeof setTimeout>;

    if (!prevShowMessageState && showMessage) {
      fadeMessage = setTimeout(() => {
        setShowMessageState(false);
      }, alertMessageDuration);
    }

    return () => {
      clearTimeout(fadeMessage);
    };
  }, [prevShowMessageState, showMessage]);

  return (
    <StyledButton onClick={onLinkClick} disableRipple>
      {children}

      {error && <StyledAlert severity="error">{error}</StyledAlert>}

      {!error && showMessage && (
        <StyledAlert severity="success">{t('approvals.copy_successful')}</StyledAlert>
      )}
    </StyledButton>
  );
};

export { CopyToClipboard };
