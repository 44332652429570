import { AxiosResponse } from 'axios';
// this is the pagination code to use with codegen services. if you're using legacy-style
// code then you should use the interface from src/interfaces/pagination.tsx
export interface Pagination {
  // The cursor to start paginating after
  after?: string;
  // The cursor to stop paginating at
  before?: string;
  // The maximum number of paginated results to return
  limit: number;
  // The current page number
  // TODO: where does this come from?
  page: number;
}

export interface Paginated<T> {
  data: Array<T>;
  pagination: Pagination;
}

export type PaginationLink = ('after' | 'before') | undefined;

export interface PaginationQuery {
  // The maximum number of paginated results to return
  'page[limit]': number;
  // The cursor to start paginating after
  'page[after]'?: string;
  // The cursor to stop paginating at
  'page[before]'?: string;
  // catch anything else that might be in the query (eg search, filter, etc)
  [key: string]: number | string | undefined | boolean | string[];
}

export function extractPagination(response: AxiosResponse<unknown, unknown>): Pagination {
  const { headers } = response;
  const links = headers.link || '';
  const refs = links.match(/\[(after|before)\]=\S+/gi);
  const pagination = {} as Pagination;

  // Parse pagination links to store them in pagination object
  refs?.forEach((ref: string) => {
    // Split by first '=', because the link can contain '=' in the url
    const [key, link] = ref.replace(/=/, '****').split('****');

    // @ts-ignore
    pagination[key.replace(/\[|\]/g, '')] = link.replace(/>\S+/g, '');
  });

  return pagination;
}
