import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AgaveLedgerAccountType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { AgaveLedgerAccount } from '~hooks/useAgave/models';
import useAgave from '~hooks/useAgave/useAgave';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

export const ledgerAccountTypeLabelMap = {
  [AgaveLedgerAccountType.ACCOUNTS_PAYABLE]: t(
    'administration.integration.agave.quick_books_online.grid.accounts_payable',
  ),
  [AgaveLedgerAccountType.ACCOUNTS_RECEIVABLE]: t(
    'administration.integration.agave.quick_books_online.grid.accounts_receivable',
  ),
};

const ledgerAccountOptions = [
  {
    value: AgaveLedgerAccountType.ACCOUNTS_PAYABLE,
    label: ledgerAccountTypeLabelMap[AgaveLedgerAccountType.ACCOUNTS_PAYABLE],
  },
  {
    value: AgaveLedgerAccountType.ACCOUNTS_RECEIVABLE,
    label: ledgerAccountTypeLabelMap[AgaveLedgerAccountType.ACCOUNTS_RECEIVABLE],
  },
];

interface LedgerAccountSelectorProps {
  ledgerAccount: AgaveLedgerAccount;
}

const LedgerAccountSelector = ({ ledgerAccount }: LedgerAccountSelectorProps) => {
  const { updateLedgerAccount } = useAgave();
  const { toasterStore, agaveIntegrationQuickBooksStore } = useStores();

  const {
    control,
    formState: { errors },
  } = useForm<{ type: { value: string; label: string } | null }>({
    reValidateMode: 'onChange',
    defaultValues: {
      type: ledgerAccount.type
        ? {
            value: ledgerAccount.type,
            label: ledgerAccountTypeLabelMap[ledgerAccount.type],
          }
        : null,
    },
  });

  const hasReceivableAndPayable = agaveIntegrationQuickBooksStore.ledgerAccounts.filter(
    (account) =>
      account.type === AgaveLedgerAccountType.ACCOUNTS_RECEIVABLE ||
      account.type === AgaveLedgerAccountType.ACCOUNTS_PAYABLE,
  );

  const [error, setError] = useState<string | null>(null);

  return (
    <Box sx={{ display: 'flex', width: '300px', alignItems: 'center' }}>
      <AutocompleteFormField
        control={control}
        errors={errors}
        disabled={hasReceivableAndPayable.length === 2 && !ledgerAccount.type}
        sx={{
          minWidth: 240,
          maxWidth: 240,
        }}
        placeholder={t('select') as string}
        name={'type'}
        getValue={(item) => item.value || ''}
        getLabel={(item) => item.label || ''}
        list={ledgerAccountOptions}
        onSelect={async (value) => {
          try {
            const account = await updateLedgerAccount(
              ledgerAccount.id,
              value?.value ?? null,
            );
            if (!account) {
              setError(
                t(
                  'administration.integration.agave.quick_books_online.grid.update_error',
                ),
              );
              return;
            }

            setError(null);

            toasterStore.push(
              alert(
                t(
                  'administration.integration.agave.quick_books_online.grid.tread_name_updated',
                  {
                    name: ledgerAccount.agaveApiLedgerAccount.name,
                  },
                ),
                AlertTypes.success,
              ),
            );
          } catch (err: any) {
            if (
              err.response?.data?.error.errors[0]?.message ===
              'Type has already been taken'
            ) {
              setError(
                t(
                  'administration.integration.agave.quick_books_online.grid.ledger_account_update_error',
                  {
                    type: ledgerAccountTypeLabelMap[
                      value?.value as keyof typeof ledgerAccountTypeLabelMap
                    ],
                  },
                ),
              );
            } else {
              setError(t('error_messages.agave.failed_to_update_ledger_account'));
            }
          }
        }}
        clearable
      />
      <Box
        sx={{
          pl: '8px',
        }}
      >
        {error && (
          <Typography color="error" variant="body1">
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LedgerAccountSelector;
