import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { t as $t, t } from 'i18next';
import { useRef, useState } from 'react';

import { DialogActionsWrapper } from '~components/Dialog/DialogActionsWrapper';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { FormStateChangeProps } from '~formsShared';
import { Account, ConnectAccountByTreadIdParams, useAccount } from '~hooks/useAccount';
import { useCompany } from '~hooks/useCompany';
import {
  AddConnectionFormRef,
  ConnectAccountByTreadIdForm,
} from '~pages/Settings/Accounts/ConnectAccountByTreadIdForm';
import { useAccountFilters } from '~pages/Settings/Accounts/useAccountFilters';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

interface ConnectAccountDialogProps {
  isDialogOpen: boolean;
  onClose: () => void;
}

export const ConnectAccountDialog = ({
  isDialogOpen,
  onClose,
}: ConnectAccountDialogProps) => {
  const { userStore, toasterStore } = useStores();
  const connectAccountFormRef = useRef<AddConnectionFormRef>(null);
  const theme = useTheme();
  const {
    isLoading: isCreatingConnection,
    createConnectedAccountByTreadId,
    getAllCompanyAccounts,
  } = useAccount();
  const { accountTypeParam } = useAccountFilters();
  const { isLoadingCompanies } = useCompany();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const companyId = userStore.currentCompanies[0]?.id || userStore.userCompany?.id;

  const onValidateCompanyForm = ({ isDirty, isValid = false }: FormStateChangeProps) => {
    setIsDirty(isDirty);
    setIsValid(isValid);
  };

  const onSuccess = async (account: Account) => {
    // Close Modal
    onClose();

    // Upon successful connection, re-fetch updated
    // Accounts table on main page
    if (account) {
      // TODO: Pass in selected company id
      await getAllCompanyAccounts({
        accountTypes: accountTypeParam,
        ...{ companyId: !userStore.isCurrentCompanyActive ? companyId : undefined },
      });
      connectAccountFormRef.current?.resetForm();
      toasterStore.push(alert($t('account.connection_successful'), AlertTypes.success));
    }
  };
  const onSubmitAction = (data: ConnectAccountByTreadIdParams) => {
    createConnectedAccountByTreadId({
      ...data,
      callBack: onSuccess,
    });
  };

  const onConnectionSubmit = () => {
    connectAccountFormRef.current?.submit(onSubmitAction);
  };

  return (
    <Dialog
      maxWidth={'sm'}
      open={isDialogOpen}
      onClose={(_: never, reason: DialogCloseReasonType) => {
        isActionClicked(reason) && onClose();
      }}
    >
      <DialogHeader
        closeCallBack={onClose}
        title={t('account.add_connection_with_tread_id_heading')}
      />
      <DialogContent sx={{ backgroundColor: theme.palette.grey[100] }}>
        <ConnectAccountByTreadIdForm
          ref={connectAccountFormRef}
          onFormStateChange={onValidateCompanyForm}
        />
      </DialogContent>
      <DialogActionsWrapper>
        <Button
          onClick={onClose}
          sx={{ px: 2 }}
          disabled={isLoadingCompanies}
          color="secondary"
          variant="outlined"
        >
          {$t('actions.cancel')}
        </Button>
        <LoadingButton
          disabled={isLoadingCompanies || isCreatingConnection || !isDirty || !isValid}
          loading={isLoadingCompanies}
          loadingPosition="start"
          startIcon={<></>}
          onClick={onConnectionSubmit}
          data-test-id={'connect-account-btn'}
          type="button"
          variant="contained"
          color="primary"
          sx={isLoadingCompanies ? { pl: 5, pr: 2 } : { pr: 2 }}
        >
          {$t('actions.connect')}
        </LoadingButton>
      </DialogActionsWrapper>
    </Dialog>
  );
};
