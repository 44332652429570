import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Equipment_Read_Nested, getV1SitesId } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { flatten } from 'lodash';
import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import { TORONTO_OFFICE_COORDINATES } from '~constants/mapConsts';
import { Job } from '~hooks/useJob';
import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';
import { useNextBillionAssetLocationHistories } from '~hooks/useNextBillionAssetLocationHistories/useNextBillionAssetLocationHistories';
import { Order } from '~hooks/useOrders';
import { Site, WayPoint } from '~hooks/useSites/models';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

import { GeofenceType, MarkerType } from './constants';
import { MapV2 } from './MapV2';

const emDash = '—';

const getEquipmentLabel = (equipment: Nullable<Equipment_Read_Nested>) => {
  return equipment
    ? `${equipment.company_share?.external_id ?? equipment.external_id ?? ''}${equipment.company_share?.external_id || equipment.external_id ? ' - ' : ''}${equipment.name ?? ''}`
    : '';
};

const getSiteById = async (id: string) => {
  try {
    const resp = await getV1SitesId({ path: { id } });
    return Site.parse(resp.data.data);
  } catch (error) {
    // Do nothing. This data is not critical to the map.
  }
};

/*
 * Consider this the "opinionated" part of our mapping solution. This component transforms jobs,
 * orders, and local truck data into a more presentation-heavy BaseMap.
 */
const TreadLiveMapV2 = ({ order, job }: { order?: Order; job?: Job }) => {
  const [truckLocations, setTruckLocations] = useState<NextBillionAssetLocation[]>([]);
  const [siteDetailsBySiteId, setSiteDetailsBySiteId] = useState<Record<
    string,
    Site
  > | null>(null);
  const { userStore } = useStores();

  const { getLatestLocations } = useNextBillionAssetLocationHistories();

  const getLatestTruckLocations = useCallback(async () => {
    const truckLocations = [];
    let afterLink = null;
    let hasMore = true;

    while (hasMore) {
      const { data, pagination } = await getLatestLocations({
        job_id: job?.id,
        order_id: order?.id,
        linkType: 'after',
        link: afterLink,
      });

      truckLocations.push(data);
      if (pagination?.after && data.length !== 0) {
        afterLink = pagination.after;
      } else {
        hasMore = false;
      }
    }

    return flatten(truckLocations).flatMap((item) => item);
  }, []);

  const SitePopover = useCallback(
    ({ wayPoint }: { wayPoint: WayPoint }) => {
      return (
        <Box
          sx={{
            p: 2,
            borderRadius: 1,
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gap: 1,
            maxWidth: '360px',
          }}
        >
          <Typography variant="h6" sx={{ gridColumn: 'span 2' }}>
            {wayPoint.site?.name}
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.address')}
          </Typography>
          <Typography>{wayPoint.siteNested?.address?.thoroughfare ?? emDash}</Typography>
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.type')}
          </Typography>
          <Typography>
            {siteDetailsBySiteId?.[wayPoint.site?.id ?? ''].siteType ?? emDash}
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.geofence_type')}
          </Typography>
          <Typography>
            {wayPoint.site?.nextBillionGeofence?.geofenceType
              ? wayPoint.site?.nextBillionGeofence?.geofenceType.replace(
                  /^./,
                  wayPoint.site?.nextBillionGeofence?.geofenceType[0].toUpperCase(),
                )
              : emDash}
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.external_id')}
          </Typography>
          <Typography>
            {siteDetailsBySiteId?.[wayPoint.site?.id ?? ''].externalId?.length
              ? siteDetailsBySiteId?.[wayPoint.site?.id ?? ''].externalId
              : emDash}
          </Typography>
        </Box>
      );
    },
    [siteDetailsBySiteId],
  );

  useEffect(() => {
    const loadTruckLocations = async () => {
      const data = await getLatestTruckLocations();
      setTruckLocations(data);
    };
    loadTruckLocations();
  }, [order?.id, job?.id, getLatestTruckLocations]);

  useEffect(() => {
    const loadSiteDetails = async (siteId1: string, siteId2: string) => {
      const [site1, site2] = await Promise.all([
        getSiteById(siteId1),
        getSiteById(siteId2),
      ]);
      if (site1 && site2) {
        setSiteDetailsBySiteId((prev) => ({
          ...(prev ?? {}),
          [site1.id]: site1,
          [site2.id]: site2,
        }));
      }
    };

    if (order?.id && order.waypoints?.[0].site?.id && order.waypoints?.[1].site?.id) {
      loadSiteDetails(order.waypoints[0].site.id, order.waypoints[1].site.id);
    } else if (job?.id && job.waypoints?.[0].site?.id && job.waypoints?.[1].site?.id) {
      loadSiteDetails(job.waypoints[0].site.id, job.waypoints[1].site.id);
    }
  }, [order?.id, job?.id]);

  const mapCenter = useMemo(() => {
    return {
      lng: userStore.userCompany?.defaultLon || TORONTO_OFFICE_COORDINATES.lng,
      lat: userStore.userCompany?.defaultLat || TORONTO_OFFICE_COORDINATES.lat,
    };
  }, []);

  const truckAndSiteMarkers = truckLocations.map((truckLocation) => {
    if (truckLocation.radii?.length) {
      return {
        id: truckLocation.id,
        type: MarkerType.moving_site,
        lat: Number.parseFloat(truckLocation.lat),
        lng: Number.parseFloat(truckLocation.lon),
        radii: truckLocation.radii.map((r) => r.radiusMeters),
      };
    }
    return {
      id: truckLocation.id,
      type: MarkerType.truck,
      label: getEquipmentLabel(truckLocation.equipment),
      lat: Number.parseFloat(truckLocation.lat),
      lng: Number.parseFloat(truckLocation.lon),
    };
  });

  const orderMarkers = order?.waypoints
    ?.filter((wayPoint) => !!wayPoint && !!wayPoint?.site?.lat && !!wayPoint?.site?.lon)
    .map((wayPoint) => ({
      // Sorry for all the casting. Filter above protects us. Many nullable fields in the origin shape.
      id: wayPoint.id as string,
      type: MarkerType.site,
      lat: wayPoint.site?.lat as number,
      lng: wayPoint.site?.lon as number,
      // Conditionally add radius if a circle
      ...(wayPoint.site?.nextBillionGeofence?.geofenceType === 'circle'
        ? {
            geofenceType: GeofenceType.circle,
            radii: [wayPoint?.site?.nextBillionGeofence?.circleRadius],
          }
        : {}),
      // Conditionally add coordinates if a polygon
      ...(wayPoint.site?.nextBillionGeofence?.geofenceType === 'polygon'
        ? {
            geofenceType: GeofenceType.polygon,
            coordinates: wayPoint?.site?.nextBillionGeofence?.geojson?.coordinates,
          }
        : {}),
      popoverContent: <SitePopover wayPoint={wayPoint} />,
    }));

  const jobMarkers = job?.waypoints
    ?.filter((wayPoint) => !!wayPoint && !!wayPoint?.site?.lat && !!wayPoint?.site?.lon)
    .map((wayPoint) => ({
      // Sorry for all the casting. Filter above protects us. Many nullable fields in the origin shape.
      id: wayPoint?.id as string,
      type: MarkerType.site,
      lat: wayPoint?.site?.lat as number,
      lng: wayPoint?.site?.lon as number,
      // Conditionally add radius if a circle
      ...(wayPoint?.site?.nextBillionGeofence?.geofenceType === 'circle'
        ? {
            geofenceType: GeofenceType.circle,
            radii: [wayPoint?.site?.nextBillionGeofence?.circleRadius],
          }
        : {}),
      // Conditionally add coordinates if a polygon
      ...(wayPoint?.site?.nextBillionGeofence?.geofenceType === 'polygon'
        ? {
            geofenceType: GeofenceType.polygon,
            coordinates: wayPoint?.site?.nextBillionGeofence?.geojson?.coordinates,
          }
        : {}),
      popoverContent: <SitePopover wayPoint={wayPoint} />,
    }));

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
    >
      <MapV2
        center={mapCenter}
        markers={
          (order
            ? orderMarkers
            : job
              ? jobMarkers
              : truckAndSiteMarkers) as ComponentProps<typeof MapV2>['markers']
        }
      />
    </Box>
  );
};

export default TreadLiveMapV2;
