import { Approval_Status } from '@treadinc/horizon-api-spec';

export class ApprovalStatus {
  constructor(
    private _id: string,
    private biller_approved: boolean,
    private company_admin_approved: boolean,
    private foreman_approved: boolean,
    private driver_approved: boolean,
  ) {}

  public get id(): string {
    return this._id;
  }

  public get billerApproved(): boolean {
    return this.biller_approved;
  }

  public get companyAdminApproved(): boolean {
    return this.company_admin_approved;
  }

  public get foremanApproved(): boolean {
    return this.foreman_approved;
  }

  public get driverApproved(): boolean {
    return this.driver_approved;
  }
  public static parse(proto: Approval_Status): ApprovalStatus {
    return new ApprovalStatus(
      proto.id ?? '',
      proto.biller_approved ?? false,
      proto.company_admin_approved ?? false,
      proto.foreman_approved ?? false,
      proto.driver_approved ?? false,
    );
  }
}
