import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import {
  CompanyShareableType,
  ModelError_Item,
  // this is a @typescript-eslint bug - we're using this type in a specialization and the
  // linter doesn't catch it :/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ModelError_Response,
} from '@treadinc/horizon-api-spec';
import type { AxiosError } from 'axios';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { StatusTabFilters } from '~components/StatusTabFilters/StatusTabFilters';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { PageTitle } from '~components/typographyTitles/PageTitle';
import { AccountTypesFilter, data as enums } from '~constants/enums';
import { companyConnectAccountByTreadId, FeatureFlags } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { useAccount } from '~hooks/useAccount';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { ConnectAccountDialog } from '~pages/Settings/Accounts/ConnectAccountDialog';
import { DeleteAccountErrorComponent } from '~pages/Settings/Accounts/DeleteAccountErrorComponent';
import { useAccountFilters } from '~pages/Settings/Accounts/useAccountFilters';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';
import { dateFormat } from '~utils/dateTime';

import { AccountsDialog } from './AccountsDialog';

export interface AccountFormStateChangeProps extends FormStateChangeProps {
  hasValidEmail: boolean;
  hasValidPhone: boolean;
  inConnectedView: boolean;
}

const AccountTypes = enums.account_type.values.reduce(
  (obj: Record<string, unknown>, value: string) => {
    obj[value] = value;
    return obj;
  },
  {},
);

const ActionsWrapper = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1.5),
}));

const ConnectAccountDialogCTA = () => {
  const { userStore } = useStores();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  const { treatments, isReady } = useSplitTreatments({
    names: [companyConnectAccountByTreadId],
  });
  const featureFlag = treatments[companyConnectAccountByTreadId];
  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);

  return (
    <>
      {isReady && featureFlag.treatment === 'on' && userPermissions.canConnectCompany && (
        <>
          <Button
            data-test-id={'create-company-btn'}
            color={'primary'}
            onClick={openDialog}
            startIcon={<Add />}
          >
            {t('actions.connect')}
          </Button>

          <ConnectAccountDialog isDialogOpen={isDialogOpen} onClose={closeDialog} />
        </>
      )}
    </>
  );
};

interface GetCompanyAccountsParams {
  accountTypes: (AccountTypesFilter.CUSTOMER | AccountTypesFilter.VENDOR)[] | undefined;
  searchQuery: string;
  companyId?: string;
}

const AccountsDataGrid = observer(() => {
  const theme = useTheme();
  const { treatments, isReady } = useSplitTreatments({
    names: [FeatureFlags.simplifiedSettingsTabs, FeatureFlags.vendorAccountActionMenu],
  });
  const simplifiedSettingsTabsFeatureFlag =
    treatments[FeatureFlags.simplifiedSettingsTabs];
  const vendorAccountActionMenuFeatureFlag =
    treatments[FeatureFlags.vendorAccountActionMenu];
  const location = useLocation();
  const isCustomerPage = location.pathname.includes('customers');

  const queriedAccountType = isCustomerPage
    ? AccountTypesFilter.CUSTOMER
    : AccountTypesFilter.VENDOR;

  const { accountTypeParam, accountTypeFilter, setAccountTypeFilter } =
    useAccountFilters();

  const getAccountType = useCallback(() => {
    return simplifiedSettingsTabsFeatureFlag.treatment === 'on'
      ? [queriedAccountType as AccountTypesFilter.CUSTOMER | AccountTypesFilter.VENDOR]
      : accountTypeParam;
  }, [simplifiedSettingsTabsFeatureFlag.treatment, queriedAccountType, accountTypeParam]);

  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState<boolean>(false);
  const [selectedAccountId, setSelectedAccountId] = useState<Nullable<string>>(null);

  const modalDialogRef = useRef<ModalDialogHandler>(null);
  const {
    getAllCompanyAccounts,
    deleteAccount,
    isLoading: isAccountLoading,
  } = useAccount();
  const { setSearchQueryValue, searchValue } = useDataGridSearch();
  const { toasterStore, userStore, companyAssetsStore } = useStores();
  const isLoading = isAccountLoading;
  const companyId = userStore.currentCompanies[0]?.id || userStore.userCompany?.id || '';

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);

  const rows = useMemo(() => {
    return companyAssetsStore.userCompanyAccounts || [];
  }, [companyAssetsStore.userCompanyAccounts]);
  const selectedAccount = useMemo(() => {
    return (
      companyAssetsStore.userCompanyAccounts.find(
        (account) => account.id === selectedAccountId,
      ) || undefined
    );
  }, [selectedAccountId, rows]);
  const createNew = () => {
    setSelectedAccountId(null);
    setIsAccountDialogOpen(true);
  };

  const deleteRow = (id: string) => {
    setSelectedAccountId(id);
    modalDialogRef.current?.open();
  };
  const editRow = (id: string) => {
    setSelectedAccountId(id);
    setIsAccountDialogOpen(true);
  };
  const handleCloseAccountDialog = () => {
    setIsAccountDialogOpen(false);
    setSelectedAccountId(null);
  };
  const onChangeQuickFilter = (searchQuery: string) => {
    setSearchQueryValue(searchQuery);
  };
  const deleteAccountCallBack = async () => {
    if (selectedAccountId) {
      await deleteAccount({
        id: selectedAccountId,
        callBack: () => {
          modalDialogRef.current?.close();
          toasterStore.push(alert($t('account.account_removed'), AlertTypes.success));
        },
        errorCallback: (error: AxiosError) => {
          modalDialogRef.current?.setError({
            code: error.response?.status || null,
            message: error.message || 'An unexpected error occurred',
          });
        },
      });
    }
  };

  useEffect(() => {
    const params: GetCompanyAccountsParams = {
      accountTypes: getAccountType(),
      searchQuery: searchValue,
    };

    if (!userStore.isCurrentCompanyActive) {
      params.companyId = companyId;
    }

    getAllCompanyAccounts(params);
  }, [getAccountType, searchValue, companyId, userStore.isCurrentCompanyActive]);

  const accountsTypesFilters = [
    {
      value: AccountTypesFilter.CUSTOMER,
      label: 'common.customers',
    },
    { value: AccountTypesFilter.VENDOR, label: 'common.vendors' },
    { value: AccountTypesFilter.ALL, label: 'common.all' },
  ];

  const addDriver = (accountId: string) => {
    // Implement the logic to add a driver here once TRE-6002 is done
    console.log(`Add Driver for account ID: ${accountId}`);
  };

  const accountsColumns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: $t('form_fields.account_name'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return params.value;
          },
        },
        {
          field: 'company',
          headerName: $t('form_fields.company'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.company?.legalName))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.legalName;
          },
          renderCell: (params: GridValueGetterParams) => {
            return params.value;
          },
        },
        {
          field: 'company.connected',
          headerName: $t('form_fields.connected'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            const { connectedCompany, id } = params.row;
            const isConnectedLabel = connectedCompany ? 'Yes' : 'No';

            return (
              <BasicTooltip
                title={isConnectedLabel}
                key={`tooltip-account-type-key-${id}`}
              >
                <Chip
                  key={id}
                  label={isConnectedLabel}
                  color={connectedCompany ? 'success' : 'info'}
                  variant={'outlined'}
                />
              </BasicTooltip>
            );
          },
        },
        {
          field: 'accountTypes',
          headerName: $t('form_fields.type'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                {params.value?.sort().map((type: string, index: number) => (
                  <BasicTooltip title={type} key={`tooltip-account-type-key-${type}`}>
                    <Chip
                      sx={{ ml: index !== 0 ? 1 : 0 }}
                      key={type}
                      label={type}
                      color={type === AccountTypes.vendor ? 'info' : 'success'}
                      variant={'outlined'}
                    />
                  </BasicTooltip>
                ))}
              </>
            );
          },
        },

        {
          field: 'address.fullAddress',
          headerName: $t('form_fields.address'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.address) return '';
            const {
              address: { streetAddress, premise, locality, country, administrativeArea },
            } = params.row;

            return `${streetAddress}${premise ? ` ${premise},` : ','} ${locality}, ${administrativeArea}, ${country.name}`;
          },
        },

        // {
        //   Field: 'address.administrativeArea',
        //   HeaderName: 'State',
        //   Flex: 1,
        //   ValueGetter: (params: GridValueGetterParams) => {
        //     Return get(params.row, 'address.administrativeArea');
        //   },
        // },
        //
        // {
        //   Field: 'address.country',
        //   HeaderName: 'Country',
        //   Flex: 1,
        //   ValueGetter: (params: GridValueGetterParams) => {
        //     Return get(params.row, 'address.country.name');
        //   },
        // },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          type: 'date',
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <Typography component="span">
                  {dateFormat(params.row.createdAt)}
                </Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'notes',
          headerName: $t('form_fields.notes'),
          flex: 1,
        },

        // {
        //   Field: 'email',
        //   HeaderName: $t('form_fields.email'),
        //   Flex: 1,
        //   ValueGetter: (params: GridValueGetterParams) => {
        //     Const { primaryContact, billingContact } = params.row;
        //     Return [primaryContact, billingContact].map((item) => item.email).join(', ');
        //   },
        // },
        {
          field: 'actions',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={companyAssetsStore.userCompanyAccounts?.length || 0}
              loading={loadingRef.current}
              pagination={companyAssetsStore.accountsPagination}
              callback={(link, searchQuery) =>
                getAllCompanyAccounts({
                  link,
                  searchQuery,
                  accountTypes: getAccountType(),
                  ...{
                    companyId: !userStore.isCurrentCompanyActive ? companyId : undefined,
                  },
                })
              }
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params) => {
            const options = [];
            const { connectedCompany, accountTypes } = params.row;
            const showVendorActions =
              isReady &&
              vendorAccountActionMenuFeatureFlag.treatment === 'on' &&
              accountTypes.includes(AccountTypesFilter.VENDOR) &&
              connectedCompany;
            if (showVendorActions) {
              options.push({
                title: t('actions.add_driver'),
                icon: <Add />,
                callBack: () => addDriver(params.row.id),
              });
            }

            if (userPermissions.canDeleteAccount) {
              options.push({
                title: t('actions.delete'),
                icon: <Delete />,
                callBack: () => deleteRow(params.row.id),
              });
            }

            return (
              <>
                {userPermissions.canEditAccount && (
                  <IconButton size="small" onClick={() => editRow(params.row.id)}>
                    <Edit />
                  </IconButton>
                )}
                <SimpleMenu
                  options={options}
                  menuTitle={
                    showVendorActions ? (t('actions.manage_vendor') as string) : undefined
                  }
                >
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows, userPermissions],
  );

  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          // Hide columns status and traderName, the other columns will remain visible
          notes: false,
        },
      },
    }),
    [],
  );

  return (
    <>
      <Box sx={{ mr: -3 }}>
        {isReady && simplifiedSettingsTabsFeatureFlag.treatment !== 'on' ? (
          <Box sx={{ mb: 2 }}>
            <StatusTabFilters
              value={accountTypeFilter as AccountTypesFilter}
              onChange={(tab) => setAccountTypeFilter(tab as AccountTypesFilter)}
              filters={accountsTypesFilters}
            />
          </Box>
        ) : (
          <PageTitle
            text={
              isCustomerPage
                ? `${t('administration.customers.title')}`
                : `${t('administration.vendors.title')}`
            }
            sx={{ mb: 2 }}
          />
        )}
        <DataGrid
          id="admin-accounts-grid"
          sx={{ maxWidth: `calc(100% - ${theme.spacing(3)})` }}
          columns={accountsColumns}
          // HiddenColumns={['id', 'address.country', 'address.zip']}
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoading}
          initialState={initialState}
          onChangeFilter={onChangeQuickFilter}
          headerActionsComponent={
            <>
              {userPermissions.canCreateAccount && (
                <ActionsWrapper display={'flex'}>
                  <ConnectAccountDialogCTA />

                  <Button
                    data-test-id={'create-company-btn'}
                    color={'primary'}
                    onClick={createNew}
                    startIcon={<Add />}
                  >
                    {t('account.create_account')}
                  </Button>
                </ActionsWrapper>
              )}
            </>
          }
        />
      </Box>
      <AccountsDialog
        open={isAccountDialogOpen}
        defaultAccount={selectedAccount}
        onClose={handleCloseAccountDialog}
      />

      <ModalDialog
        ref={modalDialogRef}
        loading={isLoading}
        title={$t('account.delete_account_title')}
        content={$t('account.delete_account_text')}
        confirmButtonText={`${$t('actions.delete')}`}
        callBack={deleteAccountCallBack}
        handleErrorsInline={true}
        errorComponent={DeleteAccountErrorComponent}
      />
    </>
  );
});
export { AccountsDataGrid };
