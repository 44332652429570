import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { FetchReason } from '~constants/enums';
import { Job } from '~hooks/useJob';
import { useLiveMapNew } from '~hooks/useLiveMap/useLiveMapNew';
import { Order } from '~hooks/useOrders';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import useInfiniteScroll from '~utils/hooks/useInfiniteScroll';
import { getEffectiveUserCompanyId } from '~utils/user/user-utils';

import OrderCard from './OrderListComponents/OrderCard';

const infiniteScrollOptions: IntersectionObserverInit = {
  threshold: 0.1,
  rootMargin: '500px',
} as const;

const OrderList = observer(
  ({
    onSelectJob,
    onSelectOrder,
    selectedJob,
    selectedOrder,
    onViewJobRoute,
    isSidebarCollapsed,
  }: {
    onSelectJob: (job: Job) => void;
    onSelectOrder: (order?: Order) => void;
    selectedJob?: Job;
    selectedOrder?: Order;
    sx?: SxProps;
    onViewJobRoute: (job: Job) => void;
    isSidebarCollapsed?: boolean;
  }) => {
    const { liveMapStoreNew, userStore } = useStores();
    const {
      getCompanyOrders,
      getCompanyOrdersByCompanyId,
      subscribeToJobsRTU,
      subscribeToOrdersRTU,
    } = useLiveMapNew();

    const [ordersFetchState, setOrdersFetchState] = useState({
      shouldFetch: false,
      reason: FetchReason.INITIAL_FETCH,
    });

    const infiniteScroll = useInfiniteScroll(infiniteScrollOptions);
    const orders = liveMapStoreNew.orders;
    const companyId = getEffectiveUserCompanyId(userStore);
    const areRequiredFiltersSet = Boolean(liveMapStoreNew.filters.orderStates);
    const shouldFetchOrders = ordersFetchState.shouldFetch;

    useEffect(() => {
      let ordersRTUSubscription: ReturnType<typeof subscribeToOrdersRTU> | null = null;
      let jobsRTUSubscription: ReturnType<typeof subscribeToJobsRTU> | null = null;

      if (companyId) {
        ordersRTUSubscription = subscribeToOrdersRTU(companyId);
        jobsRTUSubscription = subscribeToJobsRTU(companyId);
      }

      return () => {
        ordersRTUSubscription?.unsubscribe?.();
        jobsRTUSubscription?.unsubscribe?.();
      };
    }, [companyId]);

    useEffect(() => {
      if (areRequiredFiltersSet) {
        setOrdersFetchState({
          shouldFetch: true,
          reason: FetchReason.FILTERS_CHANGED,
        });
      }
    }, [areRequiredFiltersSet, JSON.stringify(liveMapStoreNew.filters), companyId]);

    useEffect(() => {
      if (shouldFetchOrders) {
        if (userStore.isCurrentCompanyActive) {
          getCompanyOrders().then(() => infiniteScroll.notify());
        } else {
          getCompanyOrdersByCompanyId({ companyId }).then(() => infiniteScroll.notify());
        }
      }
    }, [shouldFetchOrders]);

    useEffect(() => {
      setOrdersFetchState((state) => {
        if (liveMapStoreNew.isLoadingOrders || !areRequiredFiltersSet) {
          return { ...state, shouldFetch: false };
        }

        if (infiniteScroll.endReached && liveMapStoreNew.hasMore) {
          return { shouldFetch: true, reason: FetchReason.INFINITE_SCROLL };
        }

        return state;
      });
    }, [
      liveMapStoreNew.isLoadingOrders,
      areRequiredFiltersSet,
      infiniteScroll.endReached,
      liveMapStoreNew.hasMore,
    ]);

    const handleSelectOrder = (order: Order, isSelected: boolean) => {
      if (isSelected) {
        onSelectOrder(undefined);
      } else {
        onSelectOrder(order);
      }
    };

    return (
      <Box
        p={1}
        pb={12}
        gap={1}
        display="flex"
        flexDirection={'column'}
        height={'100%'}
        overflow="auto"
        bgcolor={theme.brandV2.colors.treadGray10}
        visibility={isSidebarCollapsed ? 'hidden' : 'visible'}
      >
        <LoadingSpinner
          isVisible={orders.length === 0 && liveMapStoreNew.isLoadingOrders}
        />
        {orders.map((order) => (
          <OrderCard
            key={order.orderId}
            order={order}
            onSelectJob={onSelectJob}
            onSelectOrder={handleSelectOrder}
            onViewJobRoute={onViewJobRoute}
            selectedJob={selectedJob}
            selectedOrder={selectedOrder}
          />
        ))}
        {liveMapStoreNew.hasMore ? (
          <Box ref={infiniteScroll.nodeRef}>
            <LoadingSpinner isVisible={liveMapStoreNew.isLoadingOrders} />
          </Box>
        ) : liveMapStoreNew.isLoadingOrders ? null : (
          <Box
            display="flex"
            justifyContent="center"
            position="relative"
            sx={{
              '&::before': {
                backgroundColor: theme.brandV2.colors.treadGray6,
                content: "''",
                display: 'block',
                height: '1px',
                left: 0,
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
              },
            }}
          >
            <Typography
              bgcolor={theme.brandV2.colors.treadGray8}
              color={theme.brandV2.colors.treadGray6}
              fontSize="12px"
              fontWeight="700"
              position="relative"
              px={1}
              textAlign="center"
              textTransform="uppercase"
            >
              {liveMapStoreNew.orders.length === 0
                ? t('common.no_data')
                : t('dispatch.dispatch_v2.all_orders_loaded')}
            </Typography>
          </Box>
        )}
      </Box>
    );
  },
);

export default OrderList;
