import { useDroppable } from '@dnd-kit/core';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useCallback, useMemo } from 'react';

import { JobCard, JobPlacement } from '~pages/Dispatch/components/drivers/JobCard';
import { Nullable } from '~types/Nullable';
import { hexToRgba } from '~utils/utilFunctions';

import { useDriverScheduler } from '../../hooks/useDriverScheduler';
import { INCREMENTS_PER_HOUR } from './constants';

interface DroppableCellProps {
  assigneeId: string;
  columnIndex: number;
  jobId: Nullable<string>;
  style: CSSProperties;
}

export const DroppableCell = observer(
  ({ assigneeId, columnIndex, style, jobId }: DroppableCellProps) => {
    const theme = useTheme();
    const offsetColumnIndex = useMemo(() => columnIndex, [columnIndex]);
    const id = `${assigneeId}:${offsetColumnIndex}`;

    const { handleCellDrop } = useDriverScheduler();
    const onDrop = useCallback(
      () => handleCellDrop(assigneeId, offsetColumnIndex),
      [assigneeId, offsetColumnIndex],
    );
    const { setNodeRef, isOver } = useDroppable({ id });

    const isWholeHourColumn = offsetColumnIndex % INCREMENTS_PER_HOUR === 0;
    const verticalBorderColor = isWholeHourColumn
      ? theme.brandV2.colors.treadGray7
      : 'transparent';

    return (
      <Box
        ref={setNodeRef}
        style={{
          ...style,
          borderTop: 'none',
          borderBottom: `1px solid ${theme.brandV2.colors.treadGray7}`,
          borderLeft: `1px solid ${verticalBorderColor}`,
          borderRight: 'none',
          backgroundColor: isOver
            ? hexToRgba(theme.brandV2.colors.treadYellow, 0.25)
            : '#fff',
          boxShadow: isOver
            ? `inset 0 0 0 2px ${theme.brandV2.colors.treadYellow}`
            : 'none',
          transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
        }}
        onDrop={onDrop}
      >
        {jobId && (
          <>
            <JobCard
              jobId={jobId}
              style={{ position: 'absolute', top: 0, left: 0 }}
              jobPlacement={JobPlacement.DRIVERS_GRID}
            />
          </>
        )}
      </Box>
    );
  },
);
