import { useLocation } from 'react-router-dom';

export const useCompanySelectorVisibility = (
  routesWithQueryParams: {
    path: string;
    queryParams?: { key: string; values: string[] }[];
  }[],
) => {
  const location = useLocation();
  const pathParts = location.pathname.split('/').filter(Boolean);
  const searchParams = new URLSearchParams(location.search);

  const routeMatch = routesWithQueryParams.find(({ path }) =>
    pathParts.join('/').endsWith(path),
  );

  if (!routeMatch) return false;

  const { queryParams } = routeMatch;

  const queryMatches =
    !queryParams ||
    queryParams.every(({ key, values }) => {
      const paramValue = searchParams.get(key);
      return values.includes(paramValue || '');
    });

  return queryMatches;
};
