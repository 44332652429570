import { SxProps } from '@mui/system';
import { t } from 'i18next';
import { useMemo } from 'react';

import Tabs, { TabProps } from '~components/Tabs/Tabs';
import { JobAssignmentType } from '~constants/enums';

interface AssigneeTabsProps {
  externalDriversCount: number;
  internalDriversCount: number;
  onChange: (tab: JobAssignmentType) => void;
  selectedTab: JobAssignmentType;
  sx?: SxProps;
  vendorsCount: number;
}

export default function AssigneeTabs({
  externalDriversCount,
  internalDriversCount,
  onChange,
  selectedTab,
  sx,
  vendorsCount,
}: AssigneeTabsProps) {
  const assigneeTypeTabs = useMemo(() => {
    let internalDriversLabel = t('dispatch.job.assignment_popover.internal');
    let externalDriversLabel = t('dispatch.job.assignment_popover.external');
    let vendorsLabel = t('dispatch.job.assignment_popover.vendor');

    if (internalDriversCount) {
      const trucksCountFragment = t('common.trucks_count', {
        count: internalDriversCount,
        plural: internalDriversCount > 1 ? 's' : '',
      });
      internalDriversLabel = `${internalDriversLabel} (${trucksCountFragment.toLocaleLowerCase()})`;
    }

    if (externalDriversCount) {
      const trucksCountFragment = t('common.trucks_count', {
        count: externalDriversCount,
        plural: externalDriversCount > 1 ? 's' : '',
      });
      externalDriversLabel = `${externalDriversLabel} (${trucksCountFragment.toLocaleLowerCase()})`;
    }

    if (vendorsCount) {
      const trucksCountFragment = t('common.trucks_count', {
        count: vendorsCount,
        plural: vendorsCount > 1 ? 's' : '',
      });
      vendorsLabel = `${vendorsLabel} (${trucksCountFragment.toLocaleLowerCase()})`;
    }

    const tabs: TabProps<JobAssignmentType>[] = [
      {
        label: internalDriversLabel,
        value: JobAssignmentType.INTERNAL_DRIVER,
      },
      {
        label: externalDriversLabel,
        value: JobAssignmentType.EXTERNAL_DRIVER,
      },
      {
        label: vendorsLabel,
        value: JobAssignmentType.VENDOR,
      },
    ];

    return tabs;
  }, [internalDriversCount, externalDriversCount, vendorsCount]);

  return (
    <Tabs onChange={onChange} selected={selectedTab} tabs={assigneeTypeTabs} sx={sx} />
  );
}
