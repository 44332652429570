import styled from '@emotion/styled';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { outlinedInputClasses } from '@mui/material';
import InputAdornment, { inputAdornmentClasses } from '@mui/material/InputAdornment';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/system/Box';
import { t } from 'i18next';

import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import theme from '~theme/AppTheme';

export const FlexContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const FlexRow = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

type FlexColumnProps = BoxProps & { noGap?: boolean };

export const FlexColumn = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'noGap',
})<FlexColumnProps>(({ noGap }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  ...(noGap ? {} : { gap: theme.spacing(0.5) }),
}));

type LabelProps = TypographyProps & { isBold?: boolean };

export const Label = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'isBold',
})<LabelProps>(({ isBold }) => ({
  color: theme.brandV2.colors.treadBlack,
  fontSize: '12px',
  ...(isBold ? { fontWeight: 600 } : {}),
}));

type HintProps = TypographyProps & { isLarge?: boolean };

export const Hint = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'isLarge',
})<HintProps>(({ isLarge }) => ({
  color: theme.brandV2.colors.treadGray2,
  fontSize: isLarge ? '10px' : '8px',
  letterSpacing: '0.15px',
  minHeight: isLarge ? '15px' : '12px',
}));

type ValueProps = TypographyProps & { isLarge?: boolean };

export const Value = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'isLarge',
})<ValueProps>(({ isLarge }) => ({
  color: theme.brandV2.colors.treadBlack,
  fontSize: isLarge ? '18px' : '14px',
}));

export const requiredForEstimatesInputAdornment = {
  textFormFieldSx: {
    [`& .${outlinedInputClasses.root}`]: { pr: 1 },
    [`& .${inputAdornmentClasses.root}`]: { ml: 0.5 },
  },
  component: (
    <InputAdornment position="end">
      <BasicTooltip
        title={t('dispatch.order.order_details_v2.required_for_estimates_helper_text')}
      >
        <ErrorOutline sx={{ fontSize: '18px' }} />
      </BasicTooltip>
    </InputAdornment>
  ),
};
