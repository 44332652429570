import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AddOnRateType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { AddOn } from '~hooks/useAddOns';
import { useStores } from '~store';

import {
  AddOnDTO,
  addOnRateTypeOptions,
  addOnSchema,
  addOnTypeOptions,
  getAddOnFormDefaultValues,
} from './addOnFormSchema';

export interface AddOnFormHandler {
  onSubmit?: () => Promise<AddOnDTO>;
}

interface AddOnFormProps {
  addOn?: AddOn;
}

const AddOnForm = forwardRef<AddOnFormHandler, AddOnFormProps>(function AddOnForm(
  { addOn },
  ref,
) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<AddOnDTO>({
    resolver: yupResolver(addOnSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: getAddOnFormDefaultValues(addOn),
  });

  const { userStore } = useStores();
  const userCompany = userStore.userCompanies[0];
  const watchedAddOnRateType = watch('addOnRateType');
  const addOnRateTypeAddornmentText = useMemo(() => {
    if (watchedAddOnRateType?.id === AddOnRateType.RATE_FOR_EACH) {
      return '$';
    }

    if (watchedAddOnRateType?.id === AddOnRateType.RATE_PERCENT_OF_TOTAL) {
      return '%';
    }

    return '';
  }, [watchedAddOnRateType]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit() {
        return new Promise((resolve) => {
          handleSubmit((data) => {
            return resolve(data);
          })();
        });
      },
    }),
    [],
  );

  return (
    <Box mt={2.5}>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={3}>
        {!addOn?.id && (
          <CompanySelectorFormField
            control={control}
            errors={errors}
            name={'company'}
            disabled
            sx={{ gridColumn: 'span 2' }}
            defaultValue={userCompany}
          />
        )}

        <TextFormField
          control={control}
          errors={errors}
          isRequired
          label={`${t('common.description')}`}
          name="name"
        />

        <AutocompleteFormField
          clearable
          control={control}
          errors={errors}
          getLabel={(item: (typeof addOnRateTypeOptions)[number]) => item.name}
          getValue={(item: (typeof addOnRateTypeOptions)[number]) => item.id}
          isRequired
          label={`${t('form_fields.rate_type')}`}
          list={addOnRateTypeOptions}
          name="addOnRateType"
        />

        <TextFormField
          InputProps={{
            startAdornment: (
              <Typography mr={1} fontSize="inherit">
                {addOnRateTypeAddornmentText}
              </Typography>
            ),
          }}
          control={control}
          errors={errors}
          label={`${t('administration.rates.default_rate')}`}
          name="rate"
          type="number"
        />

        <TextFormField
          control={control}
          errors={errors}
          label={`${t('administration.rates.add_ons.default_quantity')}`}
          name="quantity"
          type="number"
        />

        <AutocompleteFormField
          clearable={false}
          control={control}
          errors={errors}
          getLabel={(item: (typeof addOnTypeOptions)[number]) => item.name}
          getValue={(item: (typeof addOnTypeOptions)[number]) => item.id}
          isRequired
          label={`${t('administration.rates.add_ons.add_on_types')}`}
          limitTags={2}
          list={addOnTypeOptions}
          multiple
          name="addOnTypes"
        />

        <TextFormField
          control={control}
          errors={errors}
          label={`${t('form_fields.external_id')}`}
          name="externalId"
        />
      </Box>
    </Box>
  );
});

export default AddOnForm;
