import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { OrderState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import ToggleSidebarButton from '~components/Buttons/ToggleSidebarButton';
import { SearchBar } from '~components/Search/SearchBar';
import Tabs from '~components/Tabs/Tabs';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { useStores } from '~store';

import LiveMapFilters from './SideBarComponents/LiveMapFilters';
import { LiveMapTabFilter } from './types';

const VIEW_TO_ORDER_STATES: Record<LiveMapTabFilter, OrderState[]> = {
  [LiveMapTabFilter.IN_PROGRESS]: [OrderState.IN_PROGRESS],
  [LiveMapTabFilter.HISTORY]: [
    OrderState.COMPLETED,
    OrderState.CANCELED,
    OrderState.REJECTED,
  ],
};

const availableViews = [
  {
    label: t('live_map.filters.in_progress'),
    value: LiveMapTabFilter.IN_PROGRESS,
  },
  {
    label: t('live_map.filters.history'),
    value: LiveMapTabFilter.HISTORY,
  },
];

const TreadLiveTopBar = ({ isSidebarCollapsed }: { isSidebarCollapsed?: boolean }) => {
  const theme = useTheme();
  const [view, setViewFilter] = useState(LiveMapTabFilter.IN_PROGRESS);
  const { liveMapStoreNew, userStore } = useStores();
  const companyId = userStore.currentCompanies[0]?.id || userStore.userCompany?.id;

  /**
   * Persist the view filter on mount given by persisted filters
   */
  useEffect(() => {
    const firstOrderState = liveMapStoreNew?.filters?.orderStates?.[0];
    const isOrderStateInView =
      firstOrderState && VIEW_TO_ORDER_STATES[view].includes(firstOrderState);

    if (!isOrderStateInView) {
      setViewFilter(LiveMapTabFilter.HISTORY);
    }
  }, []);

  const onViewChange = (view: LiveMapTabFilter) => {
    setViewFilter(view);
    liveMapStoreNew.resetOrders({
      ...liveMapStoreNew.filters,
      orderStates: VIEW_TO_ORDER_STATES[view],
    });
  };

  const applySearchFilter = _.debounce((value: string) => {
    if (liveMapStoreNew.filters.search !== value) {
      liveMapStoreNew.setFilters({ search: value }, true);
    }
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  return (
    <Box
      alignItems="center"
      bgcolor="white"
      borderBottom={
        isSidebarCollapsed ? 'none' : `solid 1px ${theme.brandV2.colors.treadGray7}`
      }
      py={1}
      sx={{ px: 2 }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        {!isSidebarCollapsed && (
          <Tabs
            onChange={onViewChange}
            selected={view}
            tabs={availableViews}
            sx={{
              width: '100%',
              mb: 1,
            }}
          />
        )}

        <ToggleSidebarButton
          isCollapsed={Boolean(isSidebarCollapsed)}
          onClick={() => liveMapStoreNew.toggleSidebar()}
          sx={{ ml: isSidebarCollapsed ? -0.5 : 1, mt: '3px' }}
        />
      </Box>

      {!isSidebarCollapsed && (
        <Box display={'flex'} gap={1}>
          <LiveMapFilters />
          <SearchBar
            key={companyId}
            alwaysExpanded
            setFilter={applySearchFilter}
            placeHolder={
              t('common.search_by', {
                field: `${t('common.account')}, ${t('common.order_id')}, ${t('common.or')} ${t('common.project_name')}`,
              }) as string
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default observer(TreadLiveTopBar);
