import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t } from 'i18next';

import { OverflowAwareText } from '~components/Order/ordersDispatchStyledComponents';
import theme from '~theme/AppTheme';

interface SelectionCountersProps {
  currentSelectionsCount: number;
  maxSelectionsCount: number;
  sx?: SxProps;
}

export default function SelectionCounters({
  currentSelectionsCount,
  maxSelectionsCount,
  sx,
}: SelectionCountersProps) {
  const maxSelectionsCountExceeded = currentSelectionsCount > maxSelectionsCount;
  const color = maxSelectionsCountExceeded ? theme.palette.error.main : undefined;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: 2,
        height: '20px',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <OverflowAwareText variant="body2" sx={{ flex: 1 }}>
        <Typography component="span" sx={{ fontSize: 'inherit', fontWeight: 600, color }}>
          {`${currentSelectionsCount} ${t('common.of')} ${maxSelectionsCount} `}
        </Typography>

        <Typography component="span" sx={{ fontSize: 'inherit' }}>
          {t('dispatch.order.trucks_selected')}
        </Typography>
      </OverflowAwareText>

      {maxSelectionsCountExceeded && (
        <Typography
          variant="body2"
          component="span"
          sx={{
            alignItems: 'center',
            color,
            display: 'flex',
            flex: 0,
            gap: 0.5,
            whiteSpace: 'nowrap',
            '@keyframes shake': {
              '0%': { transform: 'translateX(0)' },
              '25%': { transform: 'translateX(3px)' },
              '50%': { transform: 'translateX(-2px)' },
              '75%': { transform: 'translateX(1px)' },
              '100%': { transform: 'translateX(0)' },
            },
            animation: 'shake .2s linear',
          }}
        >
          <ErrorOutline sx={{ fontSize: '20px', color }} />

          {t('dispatch.order.max_selections_exceeded')}
        </Typography>
      )}
    </Box>
  );
}
