import { cloneDeep, get } from 'lodash';
import { makeObservable, observable, runInAction } from 'mobx';

import { Job, JobTimeline } from '~hooks/useJob';
import { JobTripEvent } from '~hooks/useJob/models';
import { Pagination, PaginationLink } from '~services/pagination';
import { RootStore } from '~store/RootStore';
import { getPageNumber } from '~utils/pagination';

class ApprovalsStore {
  jobs: Job[] = [];
  timeline: Record<string, JobTimeline[]> = {};
  tripEvents: Record<string, JobTripEvent[]> = {};

  pagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      jobs: observable,
      timeline: observable,
      tripEvents: observable,
      pagination: observable,
    });
    this.rootStore = rootStore;
  }

  setPagination(pagination: Pagination) {
    this.pagination = {
      ...this.pagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }

  updatePageNumber(link: PaginationLink) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        page: getPageNumber(this.pagination.page, link),
      };
    });
  }

  setJobs(jobs: Job[]) {
    this.jobs = [...jobs];
  }

  addJob(job: Job) {
    this.jobs = [...this.jobs, job];
    this.jobs = [...this.jobs];
  }

  removeJob(id: string) {
    this.jobs = this.jobs.filter((job) => job.id !== id);
  }

  updateJob(job: Job) {
    this.jobs = this.jobs.map((j) => (j.id === job.id ? job : j));
  }

  setTimeline(jobId: string, timeline: JobTimeline[]) {
    const ignoredStatus = ['created', 'assigned'];

    this.timeline[jobId] = timeline.filter(
      (t) => !ignoredStatus.includes(get(t, 'changes.state[1]')),
    );

    this.timeline = cloneDeep(this.timeline);
  }
  setJobTripEvents(jobId: string, events: JobTripEvent[]) {
    this.tripEvents[jobId] = events;
    this.tripEvents = cloneDeep(this.tripEvents);
  }
}

export default ApprovalsStore;
