import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextFormField } from '~components/FormFields/TextFormField';
import { NameIdSchemaRequired } from '~constants/regexConst';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { Service } from '~hooks/useServices';
import { nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

import { QuantityType, serviceIsStandingHaul } from '../newOrderFormSchema';
import {
  FlexColumn,
  FlexContainer,
  FlexRow,
  Hint,
  Label,
  Value,
} from './shared-components';

export default function DeliveryEstimates() {
  const form = useFormContext();

  const { numberFormatter } = useCompanyCurrency();

  const watchedQuantityType = form.watch('quantityType') as NameIdSchemaRequired | null;
  const watchedService = form.watch('service') as Service | null;
  const watchedServiceQuantity = form.watch('serviceQuantity') as string | number | null;
  const watchedJobQuantity = form.watch('jobQuantity') as string | number | null;
  const watchedUnitsPerHour = form.watch('unitsPerHour') as string | number | null;

  const isStandingHaulService = serviceIsStandingHaul(watchedService);

  const formattedEstimates = useMemo(() => {
    return {
      jobQuantity: nullableStringOrNumberIsValidNumber(watchedJobQuantity)
        ? numberFormatter(Number(watchedJobQuantity))
        : '-',
      unitsPerHour: nullableStringOrNumberIsValidNumber(watchedUnitsPerHour)
        ? numberFormatter(Number(watchedUnitsPerHour))
        : '-',
    };
  }, [watchedJobQuantity, watchedUnitsPerHour]);

  const shouldDisableTruckCountField = useMemo(() => {
    const selectedQuantityTypeId = watchedQuantityType?.id;

    if (!selectedQuantityTypeId) {
      return false;
    }

    return selectedQuantityTypeId === QuantityType.TRUCKS;
  }, [watchedQuantityType]);

  const shouldDisableTotalLoadsField = useMemo(() => {
    const selectedQuantityTypeId = watchedQuantityType?.id;

    if (!selectedQuantityTypeId) {
      return false;
    }

    return selectedQuantityTypeId === QuantityType.LOAD;
  }, [watchedQuantityType]);

  const shouldDisableQuantityField = useMemo(() => {
    const selectedQuantityTypeId = watchedQuantityType?.id;

    if (!selectedQuantityTypeId) {
      return false;
    }

    const isTrucksQuantityTypeSelected = selectedQuantityTypeId === QuantityType.TRUCKS;
    const isLoadsQuantityTypeSelected = selectedQuantityTypeId === QuantityType.LOAD;

    if (isTrucksQuantityTypeSelected || isLoadsQuantityTypeSelected) {
      return false;
    }

    return true;
  }, [watchedQuantityType]);

  useEffect(() => {
    if (shouldDisableTruckCountField) {
      form.setValue('truckCount', watchedServiceQuantity);
    }
  }, [shouldDisableTruckCountField, watchedQuantityType, watchedServiceQuantity]);

  useEffect(() => {
    if (shouldDisableTotalLoadsField) {
      form.setValue('totalLoads', watchedServiceQuantity);
    }
  }, [shouldDisableTotalLoadsField, watchedQuantityType, watchedServiceQuantity]);

  useEffect(() => {
    if (shouldDisableQuantityField) {
      form.setValue('quantity', watchedServiceQuantity);
    }
  }, [shouldDisableQuantityField, watchedQuantityType, watchedServiceQuantity]);

  return (
    <>
      <Label>{t('dispatch.order.delivery_estimates.estimates_based_on')}</Label>

      <FlexContainer sx={{ mt: 2 }}>
        <FlexRow>
          <FlexColumn noGap>
            <Label isBold>{t('dispatch.order.delivery_estimates.truck_qty')}</Label>

            <TextFormField
              control={form.control}
              disabled={shouldDisableTruckCountField}
              errors={form.formState.errors}
              isRequired
              name="truckCount"
              type="number"
            />
          </FlexColumn>

          <FlexColumn noGap>
            <Label isBold>{t('dispatch.order.delivery_estimates.loads_per_truck')}</Label>

            <TextFormField
              control={form.control}
              errors={form.formState.errors}
              name="loadsPerTruck"
              type="number"
            />

            <Hint>{t('dispatch.order.delivery_estimates.loads_per_truck_hint')}</Hint>
          </FlexColumn>

          <FlexColumn noGap>
            <Label isBold>{t('dispatch.order.delivery_estimates.total_loads')}</Label>

            <TextFormField
              control={form.control}
              disabled={shouldDisableTotalLoadsField}
              errors={form.formState.errors}
              name="totalLoads"
              type="number"
            />

            <Hint>{t('dispatch.order.delivery_estimates.total_loads_hint')}</Hint>
          </FlexColumn>
        </FlexRow>

        <FlexRow>
          <FlexColumn noGap>
            <Label isBold>
              {t('dispatch.order.delivery_estimates.delivered_per_truck')}
            </Label>

            <TextFormField
              control={form.control}
              errors={form.formState.errors}
              isRequired={!isStandingHaulService}
              name="jobQuantity"
              type="number"
            />

            <Hint>{t('dispatch.order.delivery_estimates.delivered_per_truck_hint')}</Hint>
          </FlexColumn>

          <FlexColumn noGap>
            <Label isBold>{t('dispatch.order.delivery_estimates.total_units')}</Label>

            <TextFormField
              control={form.control}
              disabled={shouldDisableQuantityField}
              errors={form.formState.errors}
              isRequired
              name="quantity"
              type="number"
            />

            <Hint>{t('dispatch.order.delivery_estimates.total_units_hint')}</Hint>
          </FlexColumn>

          <FlexColumn noGap>
            <Label isBold>{t('dispatch.order.delivery_estimates.units_per_hour')}</Label>

            <Value isLarge sx={{ minHeight: '46px', lineHeight: '46px' }}>
              {formattedEstimates.unitsPerHour}
            </Value>

            <Hint>{t('dispatch.order.delivery_estimates.units_per_hour_hint')}</Hint>
          </FlexColumn>
        </FlexRow>
      </FlexContainer>
    </>
  );
}
