import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';

import { CompanyBasic } from '~hooks/useCompany';
import { CompanySelectorMenu } from '~layout/AppHeader/CompanySelector/CompanySelectorMenu';
import { useStores } from '~store';

const CompanySelector = ({ handleChange }: { handleChange?: () => void }) => {
  const theme = useTheme();
  const { userStore } = useStores();
  const currentCompany = userStore.currentCompanies[0];

  const handleCompanyChange = (company: CompanyBasic) => {
    userStore.setCurrentCompanies([company]);
    if (handleChange) {
      handleChange();
    }
  };
  return (
    <Box
      sx={{
        width: '200px',
        borderRight: `1px solid ${theme.palette.divider}`,
        transition: 'all .3s ease',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      }}
    >
      <CompanySelectorMenu
        multiple={false}
        currentCompany={currentCompany}
        onChange={handleCompanyChange}
      />
    </Box>
  );
};

export default observer(CompanySelector);
