import {
  GeofenceTag,
  GeofenceType,
  getV1SitesSiteIdAdditionalGeofences,
  Identifier,
  patchV1SitesId,
  patchV1SitesSiteIdAdditionalGeofencesBulkAssign,
  postV1Sites,
  Site_Create,
  Site_Update,
} from '@treadinc/horizon-api-spec';
import { convertLength } from '@turf/helpers';
import { t } from 'i18next';
import { useState } from 'react';

import { Geofence, Site } from '~hooks/useSites/models';
import connection from '~services/connectionModule';
import { PaginationQuery } from '~services/pagination';
import { useStores } from '~store';

import { SiteFormV2Fields } from './types';

const generatePolygonGeoJson = (drawingCoordinates: { lat: number; lng: number }[]) => {
  if (
    drawingCoordinates.length &&
    drawingCoordinates[0].lat !== drawingCoordinates[drawingCoordinates.length - 1].lat &&
    drawingCoordinates[0].lng !== drawingCoordinates[drawingCoordinates.length - 1].lng
  ) {
    drawingCoordinates.push(drawingCoordinates[0]);
  }
  return {
    type: 'Polygon',
    coordinates: [
      drawingCoordinates.map((coordinate) => [coordinate.lng, coordinate.lat]),
    ],
  };
};

const generateCircleAttributes = ({
  lat,
  lon,
  radiusInFeet,
}: {
  lat: number;
  lon: number;
  radiusInFeet: number;
}) => ({
  circle_center: {
    lat: lat,
    lon: lon,
  },
  circle_radius: convertLength(radiusInFeet, 'feet', 'meters'),
});

export const useSiteFormV2 = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { companyAssetsStore } = useStores();

  const createNewSite = async (data: SiteFormV2Fields) => {
    const additionalGeofences = data.additionalGeofences;
    const [lat, lon] = data.latLon
      .split(',')
      .map((v) => v.trim())
      .map((v) => parseFloat(v));

    setIsUpdating(true);
    // The Site model is not well-typed and has a lot of 'any' types. This is a temporary solution.
    const payload = Site.deparse(data) as Site_Create;

    /*
     * The Site parse and deparse methods are littered with 'any' and hard to work with. When we don't have to support these variants,
     * we should move this logic into the Site model and fix the types.
     */
    if (data.drawingCoordinates?.length) {
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          type: GeofenceType.POLYGON,
          geojson: generatePolygonGeoJson(data.drawingCoordinates),
        },
        moving_geofence: false,
      };
    }
    if (data.geofenceType?.name === 'Circle') {
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          type: GeofenceType.CIRCLE,
          ...generateCircleAttributes({ lat, lon, radiusInFeet: Number(data.radius) }),
        },
        moving_geofence: false,
      };
    }
    if (data.geofenceType?.name === 'Equipment') {
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          type: GeofenceType.CIRCLE,
          ...generateCircleAttributes({ lat, lon, radiusInFeet: Number(data.radius) }),
        },
        moving_geofence: true,
      };
      payload['moving_site'] = {
        equipment_id: data.equipment?.id ?? '',
      };
    }
    try {
      const resp = await postV1Sites({ body: payload });
      const site = Site.parse(resp.data.data);
      companyAssetsStore.addSite(site);
      try {
        const geofenceData = additionalGeofences.map((geofence) => ({
          tag: geofence.tag?.id as GeofenceTag,
          moving_geofence: geofence.geofenceType?.name === 'Equipment',
          geofence: {
            id: geofence.geofenceId ?? undefined,
            name: data.name ?? '',
            ...(geofence.geofenceType?.name === 'Polygon'
              ? {
                  type: GeofenceType.POLYGON,
                  geojson: generatePolygonGeoJson(geofence.drawingCoordinates),
                }
              : {
                  type: GeofenceType.CIRCLE,
                  ...generateCircleAttributes({
                    lat,
                    lon,
                    radiusInFeet: Number(geofence.radius),
                  }),
                }),
          },
        }));
        const geofenceResp = await patchV1SitesSiteIdAdditionalGeofencesBulkAssign({
          path: { ['site-id']: site.id },
          body: {
            additional_geofences: geofenceData,
          },
        });
        const updatedGeofences = geofenceResp.data.data.map(Geofence.parse);
        companyAssetsStore.setActiveSiteAdditionalGeoFences(updatedGeofences);
      } catch (error) {
        connection.handleRequestError(
          error,
          t('error_messages.sites.failed_to_assign_additional_geofences') as string,
        );
      }
      setIsUpdating(false);
      return site;
    } catch (error) {
      setIsUpdating(false);
      connection.handleRequestError(
        error,
        t('error_messages.sites.failed_to_create') as string,
      );
    }
  };

  const updateSite = async (data: SiteFormV2Fields & { id: string }) => {
    const additionalGeofences = data.additionalGeofences;
    const [lat, lon] = data.latLon
      .split(',')
      .map((v) => v.trim())
      .map((v) => parseFloat(v));

    setIsUpdating(true);

    const payload = Site.deparseUpdate(data) as Site_Update;

    // Keeping for historical reasons, but this suggests deparsing improvements are needed.
    // Do not include an empty address.id in the update request.
    if (payload.address && !(payload.address as Identifier).id) {
      (payload.address as any).id = undefined;
    }

    /*
     * The Site parse and deparse methods are littered with 'any' and hard to work with. When we don't have to support these variants,
     * we should move this logic into the Site model and fix the types.
     */
    if (data.drawingCoordinates?.length) {
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          id: data.geofenceId ?? undefined,
          type: GeofenceType.POLYGON,
          geojson: generatePolygonGeoJson(data.drawingCoordinates),
        },
        moving_geofence: false,
      };
    }
    if (data.geofenceType?.name === 'Circle') {
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          id: data.geofenceId ?? undefined,
          type: GeofenceType.CIRCLE,
          ...generateCircleAttributes({ lat, lon, radiusInFeet: Number(data.radius) }),
        },
        moving_geofence: false,
      };
    }
    if (data.geofenceType?.name === 'Equipment') {
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          id: data.geofenceId ?? undefined,
          type: GeofenceType.CIRCLE,
          ...generateCircleAttributes({ lat, lon, radiusInFeet: Number(data.radius) }),
        },
        moving_geofence: true,
      };
      payload['moving_site'] = {
        equipment_id: data.equipment?.id,
      };
    }
    if (data.geofenceType?.name === 'None') {
      payload['next_billion_geofence'] = data.geofenceId
        ? {
            //@ts-ignore update the types here
            id: data.geofenceId,
            _destroy: 1,
          }
        : undefined;
    }

    try {
      const resp = await patchV1SitesId({
        path: { id: data.id },
        body: payload,
      });
      const site = Site.parse(resp.data.data);
      companyAssetsStore.updateSite(site);
      try {
        const geofenceData = additionalGeofences.map((geofence) => ({
          tag: geofence.tag?.id as GeofenceTag,
          moving_geofence: geofence.geofenceType?.name === 'Equipment',
          geofence: {
            id: geofence.geofenceId ?? undefined,
            name: data.name ?? '',
            ...(geofence.geofenceType?.name === 'Polygon'
              ? {
                  type: GeofenceType.POLYGON,
                  geojson: generatePolygonGeoJson(geofence.drawingCoordinates),
                }
              : {
                  type: GeofenceType.CIRCLE,
                  ...generateCircleAttributes({
                    lat,
                    lon,
                    radiusInFeet: Number(geofence.radius),
                  }),
                }),
          },
        }));
        const geofenceResp = await patchV1SitesSiteIdAdditionalGeofencesBulkAssign({
          path: { ['site-id']: site.id },
          body: {
            additional_geofences: geofenceData,
          },
        });
        const updatedGeofences = geofenceResp.data.data.map(Geofence.parse);
        companyAssetsStore.setActiveSiteAdditionalGeoFences(updatedGeofences);
      } catch (error) {
        connection.handleRequestError(
          error,
          t('error_messages.sites.failed_to_assign_additional_geofences') as string,
        );
      }
      setIsUpdating(false);
      return site;
    } catch (error) {
      setIsUpdating(false);
      connection.handleRequestError(
        error,
        t('error_messages.sites.failed_to_update') as string,
      );
    }
  };

  const getAdditionalGeoFencesForSite = async (id: string) => {
    setIsLoading(true);
    const params: PaginationQuery = {
      'page[limit]': companyAssetsStore.sitesPagination.limit,
    };
    try {
      const resp = await getV1SitesSiteIdAdditionalGeofences({
        path: { ['site-id']: id },
        body: params,
      });
      const additionalGeofences = resp.data.data.map(Geofence.parse);
      companyAssetsStore.setActiveSiteAdditionalGeoFences(additionalGeofences);
      setIsLoading(false);
      return additionalGeofences;
    } catch (error) {
      setIsLoading(false);
      connection.handleRequestError(
        error,
        t('error_messages.sites.failed_to_fetch_additional_geofences') as string,
      );
    }
  };

  return {
    createNewSite,
    getAdditionalGeoFencesForSite,
    isLoading,
    isUpdating,
    updateSite,
  };
};
