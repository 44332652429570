import ArrowCircleDown from '@mui/icons-material/ArrowCircleDown';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlined from '@mui/icons-material/CheckCircleOutlineOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { t } from 'i18next';
import _ from 'lodash';
import { ReactNode, useCallback, useRef } from 'react';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import JobStatusChip from '~components/Job/JobStatusChip';
import Menu, { MenuHandler } from '~components/Menu/Menu';
import {
  LoadingSpinner,
  OverflowAwareText,
} from '~components/Order/ordersDispatchStyledComponents';
import { Job } from '~hooks/useJob';
import { JobEventType } from '~hooks/useJob/useJob';
import theme from '~theme/AppTheme';

const colorByJobEventType: Partial<
  Record<JobEventType, keyof typeof theme.brandV2.colors>
> = {
  accept: 'treadGreen',
  arrive: 'treadBlue',
  cancel: 'treadRed',
  complete: 'treadGreen',
  enroute: 'treadBlue',
  load_material: 'treadBlue',
  reject: 'treadRed',
  request: 'treadYellowDark',
  unassign: 'treadGray3',
  unload_material: 'treadBlue',
} as const;

interface JobStatusTransitionerProps {
  closeOnTransitionComplete?: boolean;
  isTransitioningToStatus?: JobEventType;
  job: Job;
  onTransitionToStatus: (state: JobEventType) => Promise<void>;
  transitionableStates: JobEventType[];
}

export default function JobStatusTransitioner({
  closeOnTransitionComplete,
  isTransitioningToStatus,
  job,
  onTransitionToStatus,
  transitionableStates,
}: JobStatusTransitionerProps) {
  const menuHandler = useRef<MenuHandler>(null);
  const modalDialogRef = useRef<ModalDialogHandler>(null);
  const isTransitioning = Boolean(isTransitioningToStatus);

  const [completeAndCancelStates, otherStates] = _.partition(
    transitionableStates,
    (state) => {
      return ['complete', 'cancel'].includes(state);
    },
  );

  const isCompletable = completeAndCancelStates.includes('complete');
  const isCancellable = completeAndCancelStates.includes('cancel');

  const handleMenuItemClick = useCallback(
    async (state: JobEventType) => {
      if (isTransitioning) return;

      if (state === 'unassign') {
        modalDialogRef.current?.open();
      } else {
        try {
          await onTransitionToStatus(state);
        } finally {
          if (closeOnTransitionComplete) {
            menuHandler.current?.onClose?.();
          }
        }
      }
    },
    [isTransitioning, onTransitionToStatus, closeOnTransitionComplete],
  );

  if (transitionableStates.length === 0) {
    return <JobStatusChip jobState={job.status} hideDropdownArrow />;
  }

  return (
    <>
      <Menu
        ref={menuHandler}
        disableAutoFocusItem
        preventCloseCondition={isTransitioning}
        menuTrigger={
          <Box sx={{ cursor: 'pointer', display: 'inline-block' }}>
            <JobStatusChip jobState={job.status} />
          </Box>
        }
      >
        {otherStates.map((state) => (
          <JobStatusTransitionerMenuItem
            key={state}
            isDisabled={isTransitioning}
            isLoading={isTransitioningToStatus === state}
            onClick={() => handleMenuItemClick(state)}
            text={t('actions.move_to', { event: t(`actions.${state}`) })}
            leftIcon={
              <ArrowCircleDown
                sx={{
                  color: theme.brandV2.colors[colorByJobEventType[state] ?? 'treadGray3'],
                  fontSize: '15px',
                  transform: 'rotate(-90deg)',
                }}
              />
            }
          />
        ))}

        {(isCompletable || isCancellable) && otherStates.length > 0 && <Divider />}

        {isCompletable && (
          <JobStatusTransitionerMenuItem
            isDisabled={isTransitioning}
            isLoading={isTransitioningToStatus === 'complete'}
            onClick={() => handleMenuItemClick('complete')}
            text={t('dispatch.job.mark_as_completed')}
            leftIcon={
              <CheckCircleOutlineOutlined
                sx={{
                  color: theme.brandV2.colors.treadGreen,
                  fontSize: '15px',
                }}
              />
            }
          />
        )}

        {isCancellable && (
          <JobStatusTransitionerMenuItem
            isDisabled={isTransitioning}
            isLoading={isTransitioningToStatus === 'cancel'}
            onClick={() => handleMenuItemClick('cancel')}
            text={t('dispatch.job.cancel_job')}
            leftIcon={
              <CancelOutlined
                sx={{
                  color: theme.brandV2.colors.treadRed,
                  fontSize: '15px',
                }}
              />
            }
          />
        )}
      </Menu>

      <ModalDialog
        ref={modalDialogRef}
        title={t('actions.confirm_action')}
        content={
          job.driver
            ? t('dispatch.drivers.confirm_driver_removal')
            : t('dispatch.drivers.confirm_vendor_removal')
        }
        confirmButtonText={t('actions.confirm')}
        callBack={(event) => {
          event?.stopPropagation();
          onTransitionToStatus('unassign');
          modalDialogRef.current?.close();
        }}
        onCancel={(event) => {
          event?.stopPropagation();
          modalDialogRef.current?.close();
        }}
        onClose={(event) => {
          event?.stopPropagation();
        }}
      />
    </>
  );
}

interface JobStatusTransitionerMenuItemProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  leftIcon?: ReactNode;
  onClick: () => void;
  text: string;
}

function JobStatusTransitionerMenuItem({
  isDisabled,
  isLoading,
  leftIcon,
  onClick,
  text,
}: JobStatusTransitionerMenuItemProps) {
  return (
    <MenuItem
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();

        if (!isDisabled) {
          onClick();
        }
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        gap={1}
        justifyContent="space-between"
        width="100%"
      >
        {leftIcon}

        <OverflowAwareText
          flex={1}
          sx={{
            color: isDisabled ? theme.brandV2.colors.treadGray6 : undefined,
          }}
        >
          {text}
        </OverflowAwareText>

        <LoadingSpinner
          isVisible={isLoading}
          loadingIndicatorSize={15}
          sx={{ flex: 0, minWidth: '15px' }}
        />
      </Box>
    </MenuItem>
  );
}
