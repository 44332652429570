import { t } from 'i18next';
import * as yup from 'yup';

import { latLonSchema } from '~constants/regexConst';

export const SITE_FORM_SCHEMA = yup.object().shape({
  company: yup
    .object()
    .shape({
      legalName: yup.string(),
      id: yup.string(),
    })
    .notRequired(),
  name: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.name'),
    })}`,
  ),
  siteType: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.site_type'),
    })}`,
  ),
  externalId: yup.string().notRequired(),
  notes: yup.string().notRequired(),
  address: yup
    .object()
    .shape({
      streetAddress: yup.string(),
      placeId: yup.string(),
    })
    .notRequired(),
  latLon: latLonSchema.required(),
  geofenceId: yup.string().notRequired(),
  geofenceType: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .required(),
  radius: yup.number().when('geofenceType.id', {
    is: (type: string) => ['circle', 'equipment'].includes(type),
    then: (schema) => {
      return schema.required().min(1).max(50000).integer();
    },
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
  equipment: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .when('geofenceType.id', {
      is: 'equipment',
      then: (schema) => {
        return schema.required();
      },
      otherwise: (schema) => {
        return schema.notRequired().nullable();
      },
    }),
  drawingCoordinates: yup
    .array()
    .of(
      yup.object().shape({
        lat: yup
          .number()
          .required('Latitude is required')
          .typeError('Latitude must be a number'),
        lng: yup
          .number()
          .required('Longitude is required')
          .typeError('Longitude must be a number'),
      }),
    )
    .required('Coordinates are required'),
  additionalGeofences: yup.array().of(
    yup.object().shape({
      geofenceId: yup.string().notRequired(),
      geofenceType: yup
        .object()
        .shape({
          id: yup.string(),
          name: yup.string(),
        })
        .required(),
      radius: yup.number().when('geofenceType.id', {
        is: (type: string) => ['circle', 'equipment'].includes(type),
        then: (schema) => {
          return schema.required().min(1).max(50000).integer();
        },
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
      equipment: yup
        .object()
        .shape({
          id: yup.string(),
          name: yup.string(),
        })
        .when('geofenceType.id', {
          is: 'equipment',
          then: (schema) => {
            return schema.required();
          },
          otherwise: (schema) => {
            return schema.notRequired().nullable();
          },
        }),
      drawingCoordinates: yup
        .array()
        .of(
          yup.object().shape({
            lat: yup
              .number()
              .required('Latitude is required')
              .typeError('Latitude must be a number'),
            lng: yup
              .number()
              .required('Longitude is required')
              .typeError('Longitude must be a number'),
          }),
        )
        .required('Coordinates are required'),
      tag: yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
      }),
    }),
  ),
});
