import { AccountType } from '@treadinc/horizon-api-spec';
import { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { useAccount } from '~hooks/useAccount';
import { useDrivers } from '~hooks/useDrivers';
import { EquipmentTypeahead } from '~hooks/useEquipment';
import { useOrders, useOrdersDispatch } from '~hooks/useOrders';
import { useProjects } from '~hooks/useProjects';
import { Site, useSites } from '~hooks/useSites';
import { useUsers } from '~hooks/useUsers';

import { FilterMenuOption } from './FilterMenuItem';

const QUERY_PAGINATION_LIMIT = 10;

export type FetchFunction = (
  query: string,
  cursor?: string,
  cancelToken?: CancelTokenSource,
) => Promise<{ data: FilterMenuOption[]; nextPageCursor: string | undefined }>;

export type FetchDriversFunction = (
  query: string,
  sharedDriversCursor?: string,
  nonSharedDriversCursor?: string,
) => Promise<{
  data: FilterMenuOption[];
  nextSharedDriversPageCursor: string | undefined;
  nextNonSharedDriversPageCursor: string | undefined;
}>;

type FetchEquipmentsFunction = (
  query: string,
  cursor?: string,
  shared?: boolean,
  largePage?: boolean,
) => Promise<{
  data: FilterMenuOption[];
  nextPageCursor: string | undefined;
}>;

export const getEquipmentLabel = (equipment: EquipmentTypeahead) => {
  if (equipment.externalId) {
    return `${equipment.externalId} - ${equipment.name}`;
  }

  return equipment.name;
};

export default function useFetchers(userCompanyId?: string) {
  const [companyId, setCompanyId] = useState(userCompanyId);

  const { getAccountsByCompanyIdTypeahead } = useAccount();
  const { getOrderDispatchNumbersTypeahead } = useOrders();
  const { getDriversByCompanyIdTypeahead } = useDrivers();
  const { getProjectsByCompanyIdTypeahead, getProjectExternalIdsTypeahead } =
    useProjects();
  const { getCompanySitesTypeahead } = useSites();
  const { getCompanyEquipmentsTypeahead } = useOrdersDispatch();
  const { getCompanyUsersTypeahead } = useUsers();

  const fetchCustomers = useCallback<FetchFunction>(
    async (query, cursor?) => {
      if (!companyId) {
        throw new Error('Company ID not set');
      }

      try {
        const response = await getAccountsByCompanyIdTypeahead({
          accountTypes: [AccountType.CUSTOMER],
          companyId,
          cursor,
          query,
          searchParams: { limit: QUERY_PAGINATION_LIMIT },
        });

        if (!response) {
          throw new Error();
        }

        const data: FilterMenuOption[] = response.data.map((account) => ({
          label: account.name,
          value: account.id,
        }));

        return { data, nextPageCursor: response.pagination.after };
      } catch (error) {
        console.error(error);
        throw new Error('Customers request failed');
      }
    },
    [companyId],
  );

  const fetchOrdersDispatchNumbers = useCallback<FetchFunction>(
    async (query, cursor?, cancelToken?) => {
      if (!companyId) {
        throw new Error('Company ID not set');
      }

      try {
        const response = await getOrderDispatchNumbersTypeahead(
          {
            companyId,
            cursor,
            limit: QUERY_PAGINATION_LIMIT,
            query,
          },
          cancelToken,
        );

        if (!response) {
          throw new Error();
        }

        const data: FilterMenuOption[] = response.data.map((item) => ({
          label: item.dispatchNumber,
          value: item.dispatchNumber,
        }));

        return { data, nextPageCursor: response.pagination.after };
      } catch (error) {
        console.error(error);
        throw new Error('Orders dispatch numbers request failed');
      }
    },
    [companyId],
  );

  const fetchDrivers = useCallback<FetchDriversFunction>(
    async (query, sharedDriversCursor?, nonSharedDriversCursor?) => {
      if (!companyId) {
        throw new Error('Company ID not set');
      }

      try {
        const driversRequests = [true, false].map((shared) => {
          const cursor = shared ? sharedDriversCursor : nonSharedDriversCursor;

          return getDriversByCompanyIdTypeahead({
            companyId,
            shared,
            searchParams: {
              query,
              limit: Math.floor(QUERY_PAGINATION_LIMIT / 2),
              ...(cursor ? { link: { cursor, type: 'after' } } : undefined),
            },
          });
        });
        const driversResponses = await Promise.all(driversRequests);

        if (!driversResponses) {
          throw new Error();
        }

        const data: FilterMenuOption[] = driversResponses
          .flatMap((response) => response.data)
          .map((driver) => ({
            label: driver.fullName,
            value: driver.id,
          }));

        return {
          data,
          nextSharedDriversPageCursor: driversResponses[0].pagination.after,
          nextNonSharedDriversPageCursor: driversResponses[1].pagination.after,
        };
      } catch (error) {
        console.error(error);
        throw new Error('Drivers request failed');
      }
    },
    [companyId],
  );

  const fetchEquipments = useCallback<FetchEquipmentsFunction>(
    async (query, cursor, shared, largePage) => {
      if (!companyId) {
        throw new Error('Company ID not set');
      }

      try {
        const limit = largePage
          ? QUERY_PAGINATION_LIMIT
          : Math.floor(QUERY_PAGINATION_LIMIT / 2);

        const response = await getCompanyEquipmentsTypeahead(
          companyId,
          query,
          { limit, after: cursor },
          shared,
        );

        const data: FilterMenuOption[] = response.data.map((equipment) => ({
          label: getEquipmentLabel(equipment),
          value: equipment.id,
        }));

        return { data, nextPageCursor: response.pagination.after };
      } catch (error) {
        console.error(error);
        throw new Error('Equipments request failed');
      }
    },
    [companyId],
  );

  const fetchProjects = useCallback<FetchFunction>(
    async (query, cursor?) => {
      if (!companyId) {
        throw new Error('Company ID not set');
      }

      try {
        const response = await getProjectsByCompanyIdTypeahead({
          companyId,
          cursor,
          limit: QUERY_PAGINATION_LIMIT,
          query,
        });

        if (!response) {
          throw new Error();
        }

        const data: FilterMenuOption[] = response.data.map((project) => ({
          label: project.name,
          value: project.id,
        }));

        return { data, nextPageCursor: response.pagination.after };
      } catch (error) {
        console.error(error);
        throw new Error('Projects request failed');
      }
    },
    [companyId],
  );

  const fetchProjectExternalIds = useCallback<FetchFunction>(
    async (query, cursor?) => {
      if (!companyId) {
        throw new Error('Company ID not set');
      }

      try {
        const response = await getProjectExternalIdsTypeahead({
          companyId,
          cursor,
          limit: QUERY_PAGINATION_LIMIT,
          query,
        });

        if (!response) {
          throw new Error();
        }

        const data: FilterMenuOption[] = response.data.map((project) => ({
          label: project.externalId,
          value: project.externalId,
        }));

        return { data, nextPageCursor: response.pagination.after };
      } catch (error) {
        console.error(error);
        throw new Error('External Projects IDs request failed');
      }
    },
    [companyId],
  );

  const fetchRequesters = useCallback<FetchFunction>(
    async (query, cursor?) => {
      if (!companyId) {
        throw new Error('Company ID not set');
      }

      try {
        const response = await getCompanyUsersTypeahead({
          companyId,
          cursor,
          query,
          limit: QUERY_PAGINATION_LIMIT,
        });

        if (!response) {
          throw new Error();
        }

        const data: FilterMenuOption[] = response.data.map((user) => ({
          label: user.fullName,
          value: user.id,
        }));

        return { data, nextPageCursor: response.pagination.after };
      } catch (error) {
        console.error(error);
        throw new Error('Requesters request failed');
      }
    },
    [companyId],
  );

  const fetchVendors = useCallback<FetchFunction>(
    async (query, cursor?) => {
      if (!companyId) {
        throw new Error('Company ID not set');
      }

      try {
        const response = await getAccountsByCompanyIdTypeahead({
          accountTypes: [AccountType.VENDOR],
          companyId,
          cursor,
          query,
          searchParams: { limit: QUERY_PAGINATION_LIMIT },
        });

        if (!response) {
          throw new Error();
        }

        const data: FilterMenuOption[] = response.data.map((account) => ({
          label: account.name,
          value: account.id,
        }));

        return { data, nextPageCursor: response.pagination.after };
      } catch (error) {
        console.error(error);
        throw new Error('Vendors request failed');
      }
    },
    [companyId],
  );

  const fetchSites = useCallback<FetchFunction>(
    async (query, cursor?) => {
      if (!companyId) {
        throw new Error('Company ID not set');
      }

      try {
        const response = await getCompanySitesTypeahead({
          companyId,
          cursor,
          query,
          limit: QUERY_PAGINATION_LIMIT,
        });

        if (!response) {
          throw new Error();
        }

        const data: FilterMenuOption[] = (response.data as Site[]).map((site) => ({
          label: site.name,
          value: site.id,
        }));

        return { data, nextPageCursor: response.pagination.after };
      } catch (error) {
        console.error(error);
        throw new Error('Sites request failed');
      }
    },
    [companyId],
  );

  useEffect(() => {
    setCompanyId(userCompanyId);
  }, [userCompanyId]);

  return {
    fetchCustomers,
    fetchDrivers,
    fetchEquipments,
    fetchOrdersDispatchNumbers,
    fetchProjectExternalIds,
    fetchProjects,
    fetchRequesters,
    fetchSites,
    fetchVendors,
  };
}
