import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';

import { OverflowAwareText } from '~components/Order/ordersDispatchStyledComponents';
import { AccountTypeahead } from '~hooks/useAccount';
import { DriverBasic } from '~hooks/useDrivers';
import { getConsolidatedEquipmentName } from '~utils/drivers/driver-utils';
import { hexToRgba } from '~utils/utilFunctions';

interface AssigneeCardProps {
  assignee: DriverBasic | AccountTypeahead;
  jobCount?: number;
  style?: CSSProperties;
}

export const AssigneeCard = ({ assignee, style, jobCount = 0 }: AssigneeCardProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...style,
        backgroundColor: (theme) => hexToRgba(theme.brandV2.colors.treadGray8, 0.5),
        p: '12px 16px',
        borderRight: `1px solid ${theme.brandV2.colors.treadGray7}`,
        borderBottom: `1px solid ${theme.brandV2.colors.treadGray7}`,
      }}
      aria-label={'driver-card'}
    >
      {assignee instanceof DriverBasic ? (
        <DriverCardContent driver={assignee} jobCount={jobCount} />
      ) : (
        <VendorCardContent vendor={assignee} jobCount={jobCount} />
      )}
    </Box>
  );
};

interface DriverCardContentProps {
  driver: DriverBasic;
  jobCount?: number;
  sx?: SxProps;
}

export function DriverCardContent({ driver, jobCount, sx }: DriverCardContentProps) {
  const theme = useTheme();

  const fullName = driver.fullName;
  const equipmentName = getConsolidatedEquipmentName(driver);
  const truckType = driver.equipment?.equipmentType?.name;
  const vendor = driver.companyShare?.senderCompany.legalName;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
        position: 'relative',
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <OverflowAwareText variant="body2" sx={{ width: 'auto' }}>
          {fullName}
        </OverflowAwareText>

        <Typography variant="body2">•</Typography>

        <Typography variant="body2">{jobCount}</Typography>
      </Box>

      {(equipmentName || truckType) && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {equipmentName && (
            <OverflowAwareText variant="body2" sx={{ fontWeight: 600, width: 'auto' }}>
              {equipmentName}
            </OverflowAwareText>
          )}

          {truckType && (
            <OverflowAwareText
              variant="body2"
              sx={{
                color: theme.brandV2.colors.treadGray2,
                flex: 0,
                minWidth: 'fit-content',
              }}
            >
              {truckType}
            </OverflowAwareText>
          )}
        </Box>
      )}

      {vendor && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <OverflowAwareText
            variant="body2"
            sx={{ color: theme.brandV2.colors.treadGray2 }}
          >
            {vendor}
          </OverflowAwareText>
        </Box>
      )}
    </Box>
  );
}

interface VendorCardProps {
  jobCount?: number;
  sx?: SxProps;
  vendor: AccountTypeahead;
}

function VendorCardContent({ jobCount, sx, vendor }: VendorCardProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
        position: 'relative',
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, ...sx }}>
        <OverflowAwareText sx={{ fontWeight: 600, width: 'auto' }}>
          {vendor.name}
        </OverflowAwareText>

        <Typography variant="body2">•</Typography>

        <Typography variant="body2">{jobCount}</Typography>
      </Box>
    </Box>
  );
}
