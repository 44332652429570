import { yupResolver } from '@hookform/resolvers/yup';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { FilterMenuItemLoadingReason } from '~components/Filters/FilterMenuItem';
import CounterFormField from '~components/FormFields/CounterFormField';
import {
  LoadingSpinner,
  OverflowAwareText,
} from '~components/Order/ordersDispatchStyledComponents';
import { JobAssignmentType } from '~constants/enums';
import { DriverBasic } from '~hooks/useDrivers';
import { useStores } from '~store';
import { SelectedAssignment } from '~store/AssignDriverMenuStore';
import theme from '~theme/AppTheme';
import { getConsolidatedEquipmentName } from '~utils/drivers/driver-utils';
import { hexToRgba } from '~utils/utilFunctions';

import InfiniteScrollLoadingIndicator from '../InfiniteScrollLoadingIndicator';

const ROW_HEIGHT_IN_PX = 56;

const assigneeSchema = yup.object().shape({
  id: yup.string().trim().uuid().required(),
  count: yup.number().required(),
});

const assigneesSchema = yup.object().shape({
  assignees: yup.array(assigneeSchema.required()).required(),
});

type AssigneesDTO = yup.InferType<typeof assigneesSchema>;

interface AssigneesListProps {
  assigneeType: JobAssignmentType;
  assignees: SelectedAssignment[];
  fetchMore: () => void;
  hasMore?: boolean;
  loadingReason?: FilterMenuItemLoadingReason;
  showNoResultsMessage?: boolean;
  sx?: SxProps;
}

const AssigneesList = observer(
  ({
    assigneeType,
    assignees,
    fetchMore,
    hasMore,
    loadingReason,
    showNoResultsMessage,
    sx,
  }: AssigneesListProps) => {
    const { bulkAssignMultipleStore } = useStores();

    const form = useForm<AssigneesDTO>({
      resolver: yupResolver(assigneesSchema),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: { assignees: [] },
    });
    const formAssigneesArray = useFieldArray({
      control: form.control,
      name: 'assignees',
    });

    useEffect(() => {
      const formAssignees: AssigneesDTO['assignees'] = assignees.map((assignee) => {
        const selection = bulkAssignMultipleStore.getSelectedAssignee(assignee.id);
        const count = selection?.count ?? 0;

        return { id: assignee.id, count };
      });

      formAssigneesArray.replace(formAssignees);
    }, [assignees, formAssigneesArray.replace]);

    if (showNoResultsMessage) {
      const entity =
        assigneeType === JobAssignmentType.VENDOR
          ? t('form_fields.vendor').toLocaleLowerCase()
          : t('form_fields.driver').toLocaleLowerCase();

      return (
        <Box
          sx={{
            alignItems: 'center',
            bgcolor: hexToRgba(theme.brandV2.colors.treadOrangeDark, 0.1),
            borderRadius: theme.brandV2.borderRadius,
            display: 'flex',
            gap: 0.75,
            height: `${ROW_HEIGHT_IN_PX}px`,
            justifyContent: 'center',
            p: 1,
          }}
        >
          <InfoOutlined
            sx={{ color: theme.brandV2.colors.treadOrangeDark, fontSize: '18px' }}
          />

          <Typography variant="body2" color={theme.brandV2.colors.treadOrangeDark}>
            {t('dispatch.order.no_assignees_for_bulk_assign', { entity })}
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={sx}>
        <LoadingSpinner
          isVisible={loadingReason === FilterMenuItemLoadingReason.SEARCH_VALUE}
        />

        <FormProvider {...form}>
          {formAssigneesArray.fields.map((_, index) => {
            const actualAssignee = assignees[index];

            if (!actualAssignee) {
              return null;
            }

            return (
              <React.Fragment key={actualAssignee.id}>
                <AssigneeRow assignee={actualAssignee} index={index} />

                {index === assignees.length - 1 && hasMore && (
                  <InfiniteScrollLoadingIndicator
                    alignItems="center"
                    display="flex"
                    height="50px"
                    isLoading={
                      loadingReason === FilterMenuItemLoadingReason.INFINITE_SCROLL
                    }
                    justifyContent="center"
                    loadingIndicatorSize={30}
                    onFetchMore={fetchMore}
                  />
                )}
              </React.Fragment>
            );
          })}
        </FormProvider>
      </Box>
    );
  },
);

interface AssigneeRowProps {
  assignee: SelectedAssignment;
  index: number;
}

const AssigneeRow = observer(({ assignee, index }: AssigneeRowProps) => {
  const { bulkAssignMultipleStore } = useStores();

  const form = useFormContext();
  const rowCountFieldName = `assignees.${index}.count`;
  const watchedRowCount = form.watch(rowCountFieldName) as number;

  const selection = bulkAssignMultipleStore.getSelectedAssignee(assignee.id);
  const selectionCount = selection?.count ?? 0;
  const isSelected = selectionCount > 0;

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      form.setValue(rowCountFieldName, event.target.checked ? 1 : 0);
    },
    [form.setValue, rowCountFieldName],
  );

  useEffect(() => {
    if (watchedRowCount > 0) {
      bulkAssignMultipleStore.updateSelectedAssignee({
        id: assignee.id,
        type: assignee.type,
        count: watchedRowCount,
      });
    } else {
      bulkAssignMultipleStore.unselectAssignee(assignee.id);
    }
  }, [watchedRowCount, assignee.id, assignee.type]);

  useEffect(() => {
    form.setValue(rowCountFieldName, selectionCount);
  }, [form.setValue, rowCountFieldName, selectionCount]);

  let content: React.ReactNode = null;

  if (assignee.type !== JobAssignmentType.VENDOR) {
    const equipmentName = getConsolidatedEquipmentName(
      assignee as unknown as DriverBasic,
    );
    const equipmentType = assignee.equipment?.equipmentType.name;

    content = (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <OverflowAwareText variant="body2">
          <Typography component="span" sx={{ fontSize: 'inherit', fontWeight: 600 }}>
            {assignee.name}
          </Typography>

          {assignee.companyShare ? (
            <Typography
              component="span"
              sx={{
                fontSize: 'inherit',
                color: theme.brandV2.colors.treadGray3,
              }}
            >
              {` • ${assignee.companyShare.senderCompany.legalName}`}
            </Typography>
          ) : null}
        </OverflowAwareText>

        <OverflowAwareText variant="body2">
          {equipmentName && (
            <>
              <Typography
                component="span"
                sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
              >
                {`${t('form_fields.equipment_id')}:`}
              </Typography>

              <Typography
                component="span"
                sx={{ ml: 0.5, fontSize: 'inherit', fontWeight: 600 }}
              >
                {equipmentName}
              </Typography>

              {equipmentType && (
                <Typography
                  component="span"
                  sx={{
                    fontSize: 'inherit',
                    color: theme.brandV2.colors.treadGray3,
                  }}
                >
                  {` • ${equipmentType}`}
                </Typography>
              )}
            </>
          )}
        </OverflowAwareText>
      </Box>
    );
  } else {
    content = (
      <OverflowAwareText variant="body2" sx={{ fontWeight: 600 }}>
        {assignee.name}
      </OverflowAwareText>
    );
  }

  return (
    <Box
      sx={{
        borderRadius: theme.brandV2.borderRadius,
        height: `${ROW_HEIGHT_IN_PX}px`,
        p: 1,
        '&:hover': { bgcolor: theme.brandV2.colors.treadGray8, cursor: 'pointer' },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
        <Box sx={{ minWidth: '20px', flex: 0 }}>
          <Typography
            variant="body2"
            sx={{
              color: selection?.priority
                ? theme.brandV2.colors.treadGray3
                : theme.brandV2.colors.treadGray6,
              fontWeight: 600,
            }}
          >
            {selection?.priority ? `${selection.priority}.` : '-'}
          </Typography>
        </Box>

        <Box sx={{ minWidth: '20px', flex: 0 }}>
          <Checkbox
            size="small"
            sx={{
              '&.MuiCheckbox-root': {
                color: theme.brandV2.colors.treadGray6,
                mt: '-10px',
                p: 0,
              },
              '&.Mui-checked': { color: theme.brandV2.colors.treadOrange },
            }}
            checked={isSelected}
            onChange={handleCheckboxChange}
          />
        </Box>

        <Box sx={{ flex: 1 }}>{content}</Box>

        <Box sx={{ minWidth: '120px', flex: 0, alignSelf: 'center' }}>
          <CounterFormField
            control={form.control}
            max={999}
            min={0}
            name={`assignees.${index}.count`}
          />
        </Box>
      </Box>
    </Box>
  );
});

export default AssigneesList;
