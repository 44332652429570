import { UnitOfDistance } from '@treadinc/horizon-api-spec';
import axios from 'axios';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { NameIdSchemaRequired, WaypointSchema } from '~constants/regexConst';
import { useOrders } from '~hooks/useOrders';
import { DeparsedOrderEstimate } from '~hooks/useOrders/models';
import { DeparsedRouteEstimate } from '~hooks/useRoute/models';
import useRoute from '~hooks/useRoute/useRoute';
import { useStores } from '~store';
import { convertMeters, nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

import { QuantityType } from '../newOrderFormSchema';

type ValuesForOrderEstimateState = {
  cycleTime: string | number | null;
  equipmentCapacity: string | number | null;
  jobTime: string | number | null;
  quantityType: NameIdSchemaRequired | null;
  serviceQuantity: string | number | null;
};

type CycleEstimateArgs = {
  dropoffOnsite: number;
  pickupOnsite: number;
  pickupToDropoff: number;
};

interface OrderEstimateEffectsProps {
  orderId?: string;
}

export default function OrderEstimateEffects({ orderId }: OrderEstimateEffectsProps) {
  const form = useFormContext();

  const { userStore } = useStores();
  const { getRouteEstimate } = useRoute();
  const { getOrderEstimate } = useOrders();

  const [valuesForOrderEstimate, setValuesForOrderEstimate] =
    useState<ValuesForOrderEstimateState>({
      cycleTime: null,
      equipmentCapacity: null,
      jobTime: null,
      quantityType: null,
      serviceQuantity: null,
    });

  const shouldUseKilometers = userStore.userCompany.isMeters;

  const watchedCycleTime = form.watch('cycleTime') as string | number | null;
  const watchedDropOffWayPoint = form.watch('dropOffWayPoint') as WaypointSchema | null;
  const watchedDropoffOnsite = form.watch('dropoffOnsite') as string | number | null;
  const watchedGrossCapacity = form.watch('grossCapacity') as string | number | null;
  const watchedJobTime = form.watch('jobTime') as string | number | null;
  const watchedPickUpWayPoint = form.watch('pickUpWayPoint') as WaypointSchema | null;
  const watchedPickupOnsite = form.watch('pickupOnsite') as string | number | null;
  const watchedPickupToDropoff = form.watch('pickupToDropoff') as string | number | null;
  const watchedQuantityType = form.watch('quantityType') as NameIdSchemaRequired | null;
  const watchedServiceQuantity = form.watch('serviceQuantity') as string | number | null;

  const shouldPreventRouteEstimate = useRef(Boolean(orderId));
  const routeEstimateValues: DeparsedRouteEstimate | null = useMemo(() => {
    const pickupLat = watchedPickUpWayPoint?.site?.lat;
    const pickupLon = watchedPickUpWayPoint?.site?.lon;
    const dropoffLat = watchedDropOffWayPoint?.site?.lat;
    const dropoffLon = watchedDropOffWayPoint?.site?.lon;

    const canEstimate = [pickupLat, pickupLon, dropoffLat, dropoffLon].every((value) => {
      return !_.isNil(value);
    });

    if (!canEstimate) {
      return null;
    }

    return {
      pickupSiteLat: Number(pickupLat),
      pickupSiteLon: Number(pickupLon),
      dropoffSiteLat: Number(dropoffLat),
      dropoffSiteLon: Number(dropoffLon),
    };
  }, [
    orderId,
    watchedPickUpWayPoint,
    watchedPickUpWayPoint,
    watchedDropOffWayPoint,
    watchedDropOffWayPoint,
  ]);

  const shouldPreventCycleEstimate = useRef(Boolean(orderId));
  const cycleEstimateValues: CycleEstimateArgs | null = useMemo(() => {
    const isValidPickupOnsite =
      nullableStringOrNumberIsValidNumber(watchedPickupOnsite) &&
      Number(watchedPickupOnsite) > 0;

    const isValidDropoffOnsite =
      nullableStringOrNumberIsValidNumber(watchedDropoffOnsite) &&
      Number(watchedDropoffOnsite) > 0;

    const isValidPickupToDropoff =
      nullableStringOrNumberIsValidNumber(watchedPickupToDropoff) &&
      Number(watchedPickupToDropoff) > 0;

    const canEstimate =
      isValidPickupOnsite && isValidDropoffOnsite && isValidPickupToDropoff;

    if (!canEstimate) {
      return null;
    }

    return {
      dropoffOnsite: Number(watchedPickupOnsite),
      pickupOnsite: Number(watchedDropoffOnsite),
      pickupToDropoff: Number(watchedPickupToDropoff),
    };
  }, [orderId, watchedPickupOnsite, watchedDropoffOnsite, watchedPickupToDropoff]);

  const shouldPreventOrderEstimate = useRef(Boolean(orderId));
  const orderEstimateValues: DeparsedOrderEstimate | null = useMemo(() => {
    const { quantityType, serviceQuantity, equipmentCapacity, jobTime, cycleTime } =
      valuesForOrderEstimate;

    const isValidQuantityType = Boolean(quantityType?.id);

    const isValidServiceQuantity =
      nullableStringOrNumberIsValidNumber(serviceQuantity) && Number(serviceQuantity) > 0;

    const isValidEquipmentCapacity =
      nullableStringOrNumberIsValidNumber(equipmentCapacity) &&
      Number(equipmentCapacity) > 0;

    const isValidJobTime =
      nullableStringOrNumberIsValidNumber(jobTime) && Number(jobTime) > 0;

    const isValidCycleTime =
      nullableStringOrNumberIsValidNumber(cycleTime) && Number(cycleTime) > 0;

    const canEstimate =
      isValidQuantityType &&
      isValidServiceQuantity &&
      isValidEquipmentCapacity &&
      isValidJobTime &&
      isValidCycleTime;

    if (!canEstimate) {
      return null;
    }

    return {
      cycleTimeMinutes: Number(cycleTime),
      equipmentCapacity: Number(equipmentCapacity),
      jobDurationMinutes: Number(jobTime) * 60,
      quantity: Number(serviceQuantity),
      quantityType: quantityType?.id as QuantityType,
    };
  }, [orderId, JSON.stringify(valuesForOrderEstimate)]);

  const estimateRouteOrResetRelatedFields = async (args?: DeparsedRouteEstimate) => {
    // prevent getting an estimate if first time effectively called. this is to avoid overriding
    // the form default values with the ones returned by the estimation.
    if (shouldPreventRouteEstimate.current) {
      if (!args) {
        return;
      }

      shouldPreventRouteEstimate.current = false;

      return;
    }

    if (args) {
      const estimate = await getRouteEstimate(args);

      if (estimate) {
        const distance = convertMeters(
          estimate.pickupToDropOffDistanceMeters,
          shouldUseKilometers ? UnitOfDistance.KILOMETER : UnitOfDistance.MILE,
        );

        form.setValue('cycleDistance', distance.toFixed(2));
        form.setValue('pickupToDropoff', `${estimate.pickupToDropOffTimeMinutes}`);
      }
    } else {
      form.setValue('cycleDistance', null);
      form.setValue('pickupToDropoff', null);
    }
  };

  const estimateCycleTimeOrResetRelatedFields = (args?: CycleEstimateArgs) => {
    // prevent getting an estimate if first time effectively called. this is to avoid overriding
    // the form default values with the ones returned by the estimation.
    if (shouldPreventCycleEstimate.current) {
      if (!args) {
        return;
      }

      shouldPreventCycleEstimate.current = false;

      return;
    }

    if (args) {
      const { pickupOnsite, dropoffOnsite, pickupToDropoff } = args;
      const cycleTime = pickupOnsite + dropoffOnsite + pickupToDropoff * 2;

      form.setValue('cycleTime', `${cycleTime}`);
    } else {
      form.setValue('cycleTime', null);
    }
  };

  const estimateOrderIfPossible = async (args?: DeparsedOrderEstimate) => {
    // prevent getting an estimate if first time effectively called. this is to avoid overriding
    // the form default values with the ones returned by the estimation.
    if (shouldPreventOrderEstimate.current) {
      if (!args) {
        return;
      }

      shouldPreventOrderEstimate.current = false;

      return;
    }

    if (args) {
      form.clearErrors('jobTime');

      try {
        const estimate = await getOrderEstimate(args);

        if (estimate) {
          form.setValue('truckCount', `${estimate.truckQuantity ?? ''}`);
          form.setValue('loadsPerTruck', `${estimate.loadsPerTruck ?? ''}`);
          form.setValue('totalLoads', `${estimate.totalLoads ?? ''}`);
          form.setValue('jobQuantity', `${estimate.deliveredPerTruck ?? ''}`);
          form.setValue('quantity', `${estimate.totalUnits ?? ''}`);
          form.setValue('unitsPerHour', `${estimate.unitsPerHour ?? ''}`);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const field = error.response?.data?.error?.errors?.[0]?.field;

          if (field === 'job_duration_minutes') {
            const message = error.response?.data?.error?.errors?.[0]?.message;
            const { groups } = /(?<duration>[0-9]+)/.exec(message) ?? {};

            if (groups?.duration) {
              const updatedMessage = t(
                'dispatch.order.cycle.job_duration_minutes_greater_than_error_message',
                { value: (Number(groups.duration) / 60).toFixed(2) },
              );
              form.setError('jobTime', { message: updatedMessage, type: 'manual' });
            }
          }
        }
      }
    }
  };

  const debouncedEstimateOrderIfAllowed = _.debounce((args?: DeparsedOrderEstimate) => {
    estimateOrderIfPossible(args);
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  useEffect(() => {
    estimateRouteOrResetRelatedFields(routeEstimateValues ?? undefined);
  }, [JSON.stringify(routeEstimateValues)]);

  useEffect(() => {
    estimateCycleTimeOrResetRelatedFields(cycleEstimateValues ?? undefined);
  }, [JSON.stringify(cycleEstimateValues)]);

  useEffect(() => {
    setValuesForOrderEstimate({
      cycleTime: watchedCycleTime,
      equipmentCapacity: watchedGrossCapacity,
      jobTime: watchedJobTime,
      quantityType: watchedQuantityType,
      serviceQuantity: watchedServiceQuantity,
    });
  }, [
    watchedCycleTime,
    watchedGrossCapacity,
    watchedJobTime,
    watchedQuantityType,
    watchedServiceQuantity,
  ]);

  useEffect(() => {
    debouncedEstimateOrderIfAllowed(orderEstimateValues ?? undefined);

    return () => {
      debouncedEstimateOrderIfAllowed.cancel();
    };
  }, [JSON.stringify(orderEstimateValues)]);

  return null;
}
