import Check from '@mui/icons-material/Check';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { useRef } from 'react';

import {
  FILTER_MENU_ITEM_HEIGHT_IN_PX,
  FilterMenuItemLoadingReason,
} from '~components/Filters/FilterMenuItem';
import Menu, { MenuHandler } from '~components/Menu/Menu';
import { OverflowAwareText } from '~components/Order/ordersDispatchStyledComponents';
import { SearchBar } from '~components/Search/SearchBar';
import { CompanyBasic } from '~hooks/useCompany';
import { useCompanySelectorMenu } from '~layout/AppHeader/CompanySelector/hooks/useCompanySelectorMenu';
import InfiniteScrollLoadingIndicator from '~pages/Dispatch/components/InfiniteScrollLoadingIndicator';

interface CompanySelectorMenuProps {
  multiple: boolean;
  currentCompany: CompanyBasic;
  onChange: (company: CompanyBasic) => void;
}

export const CompanySelectorMenu = ({
  multiple,
  currentCompany,
  onChange,
}: CompanySelectorMenuProps) => {
  const theme = useTheme();
  const {
    hasMore,
    fetchMore,
    loadingReason,
    data,
    setSearchFieldValue,
    applyDebouncedFilter,
  } = useCompanySelectorMenu();
  const menuRef = useRef<MenuHandler>(null);

  return (
    <Menu
      ref={menuRef}
      sx={{ '& .MuiPaper-root': { p: '12px', maxWidth: '200px' } }}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
      }}
      onOpenStateChanged={(open) => {
        if (!open) setSearchFieldValue('');
      }}
      menuTrigger={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            cursor: 'pointer',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ maxWidth: '160px', px: 1.5 }}>
            <Typography variant="subtitle2">
              {t('dispatch.dispatch_v2.dispatch_as')}
            </Typography>
            <OverflowAwareText
              sx={{
                fontSize: '14px',
              }}
            >
              {currentCompany.legalName}
            </OverflowAwareText>
          </Box>
          <ExpandMore sx={{ mr: 1 }} />
        </Box>
      }
    >
      <SearchBar
        focusOnMount={true}
        alwaysExpanded
        setFilter={(value) => {
          applyDebouncedFilter(() => setSearchFieldValue(value));
        }}
      />

      <Divider sx={{ '&.MuiDivider-root': { mt: '8px', mb: 0 } }} />

      {data.length > 0 && (
        <Box maxHeight={FILTER_MENU_ITEM_HEIGHT_IN_PX * 10} overflow="auto">
          {data.map((company, index) => (
            <React.Fragment key={company.id}>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  if (company.id !== currentCompany.id) {
                    onChange(company);

                    if (!multiple && menuRef.current?.onClose) {
                      menuRef.current.onClose();
                    }
                  }
                }}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  <OverflowAwareText
                    color={theme.brandV2.colors.treadBlack}
                    variant="subtitle2"
                  >
                    {company.legalName}
                  </OverflowAwareText>

                  {company.id === currentCompany.id && (
                    <Check sx={{ fontSize: '16px' }} />
                  )}
                </Box>
              </MenuItem>
              {index === data.length - 1 && hasMore && (
                <InfiniteScrollLoadingIndicator
                  alignItems="center"
                  display="flex"
                  height={FILTER_MENU_ITEM_HEIGHT_IN_PX}
                  isLoading={
                    loadingReason === FilterMenuItemLoadingReason.INFINITE_SCROLL
                  }
                  justifyContent="center"
                  onFetchMore={fetchMore}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      )}
    </Menu>
  );
};
