import { observer } from 'mobx-react-lite';

import { AgaveManagementAccess } from './AgaveManagement';
import AgaveQuickBooksGrid from './AgaveQuickBooksGrid';

const AgaveQuickBooksOnline = observer(() => {
  return <AgaveManagementAccess component={AgaveQuickBooksGrid} />;
});

export default AgaveQuickBooksOnline;
