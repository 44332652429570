import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { FileAttachment_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { get } from 'lodash';
import React, { useMemo } from 'react';

import { GridCardWrapper } from '~components/Cards/GridCardWrapper';
import { SimpleCard } from '~components/Cards/SimpleCard';
import { BillingInfo } from '~components/Dispatch/BillingInfo';
import FileAttachmentsReadOnly from '~components/Files/FileAttachmentsReadOnly';
import { RowItemLabelValue } from '~components/Job/RowItemLabelValue';
import { Notes } from '~components/Order/Notes';
import { Status as OrderStatus } from '~components/Order/Status';
import { Order } from '~hooks/useOrders';
import { dateFormat } from '~utils/dateTime';

interface Props {
  order: Order;
}

const OrderDetails = ({ order }: Props) => {
  const orderDate = useMemo(() => {
    return order?.loadAt
      ? `${dateFormat(order.loadAt)} ${dateFormat(order.loadAt, 'hh:mm A')}`
      : '';
  }, [order?.loadAt]);

  const siteNames = useMemo(() => {
    return ['pickUpWayPoints', 'dropOffWayPoints']
      .map((item) => get(order, `${item}[0].site.name`, ''))
      .join(', ');
  }, [order]);

  return (
    <Box
      sx={{
        background: '#fff',
        my: 0,
        maxHeight: 400,
        overflow: 'auto',
      }}
    >
      <Grid container spacing={2} sx={{ mt: -2 }}>
        <Grid item xs={4}>
          <GridCardWrapper>
            <SimpleCard sx={{ flexGrow: 1, background: 'transparent', borderRadius: 0 }}>
              <Table>
                <TableBody>
                  <RowItemLabelValue
                    label={`${$t('dispatch.order.customer_account')}`}
                    value={order.account?.name}
                  />
                  <RowItemLabelValue
                    label={`${$t('order.project_name')}`}
                    value={order.projectName}
                  />

                  <RowItemLabelValue
                    label={`${$t('order.form.order_id')}`}
                    value={order.orderId}
                  />

                  <RowItemLabelValue
                    label={`${$t('dispatch.order.order_status')}`}
                    value={<OrderStatus status={order.state} />}
                    sx={{ borderBottomColor: 'transparent' }}
                  />
                </TableBody>
              </Table>
            </SimpleCard>
          </GridCardWrapper>
        </Grid>

        <Grid item xs={4}>
          <GridCardWrapper>
            <SimpleCard sx={{ flexGrow: 1, background: 'transparent', borderRadius: 0 }}>
              <Table>
                <TableBody>
                  <RowItemLabelValue
                    label={`${$t('dispatch.order.service_date_and_time')}`}
                    value={orderDate}
                  />
                  <RowItemLabelValue
                    label={`${$t('dispatch.order.service_ordered')}`}
                    value={order.service?.name}
                  />
                  <RowItemLabelValue
                    label={`${$t('dispatch.order.material_ordered')}`}
                    value={order.material?.name}
                  />
                  {order.editable && (
                    <RowItemLabelValue
                      label={`${$t('form_fields.quantity')}`}
                      value={`${order.quantity || 0} ${order?.unitOfMeasure?.name}`}
                    />
                  )}
                  <RowItemLabelValue
                    label={`${$t('dispatch.order.equipment_ordered')}`}
                    value={order.equipmentType?.name}
                  />
                  <RowItemLabelValue
                    label={`${$t('dispatch.order.site_s')}`}
                    value={siteNames}
                    sx={{ borderBottomColor: 'transparent' }}
                  />
                </TableBody>
              </Table>
            </SimpleCard>
          </GridCardWrapper>
        </Grid>

        <Grid item xs={4}>
          <GridCardWrapper>
            <SimpleCard sx={{ flexGrow: 1, background: 'transparent', borderRadius: 0 }}>
              <Notes value={order.notes || ''} />
              {order.project?.id && (
                <FileAttachmentsReadOnly
                  label={$t('form_fields.project_attachments')}
                  fileAttachableId={order.project?.id}
                  fileAttachableType={FileAttachment_Read.file_attachable_type.PROJECT}
                />
              )}
              <FileAttachmentsReadOnly
                label={$t('form_fields.order_attachments')}
                fileAttachableId={order.id}
                fileAttachableType={FileAttachment_Read.file_attachable_type.ORDER}
              />
              <BillingInfo details={order} />
            </SimpleCard>
          </GridCardWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

export { OrderDetails };
