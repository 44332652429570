import Box from '@mui/material/Box';
import { InvoiceCategoryFilter } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useMemo } from 'react';

import InvoicesTabContent from '~components/Invoice/InvoicesTabContent';
import { SmallTabs } from '~components/Tabs/SmallTabs';
import { Invoice } from '~hooks/useInvoices';
import { Job } from '~hooks/useJob';
import * as colsDef from '~pages/Approvals/DriverPay/driversPayDataGridColumnsDefinition';
import { useStores } from '~store';
import { InvoiceSearchParamName } from '~store/InvoicesStore';

interface DriverPayDataGridExpandedBlockProps {
  invoice: Invoice;
  job: Job;
  utils: colsDef.DriverPayRowDef['row']['utils'];
}

const DriverPayDataGridExpandedBlock = ({
  invoice,
  job,
  utils,
}: DriverPayDataGridExpandedBlockProps) => {
  const { invoicesStore } = useStores();
  const currentInvoiceCategory =
    invoicesStore.searchParams[InvoiceSearchParamName.INVOICE_CATEGORY];
  const tabs = useMemo(() => {
    return [
      {
        id:
          currentInvoiceCategory === InvoiceCategoryFilter.RECEIVABLES
            ? t('page_headings.driver_day_receivables')
            : 'driver-pay',
        headerClassName: 'jobs-tab',
        label:
          currentInvoiceCategory === InvoiceCategoryFilter.RECEIVABLES
            ? t('page_headings.driver_day_receivables')
            : t('page_headings.driver_pay'),
        content: <InvoicesTabContent invoice={invoice} job={job} utils={utils} />,
      },
    ];
  }, [currentInvoiceCategory]);

  return (
    <Box px={2} py={1}>
      <SmallTabs tabs={tabs} />
    </Box>
  );
};

export default DriverPayDataGridExpandedBlock;
