import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { RateType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useForm } from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { RateBasicWithValue } from '~hooks/useRates';
import {
  getRateTypeLabel,
  getRateTypeOptions,
} from '~pages/Settings/RatesManagement/rateUtils';
import { usePrevious } from '~utils/hooks/usePrevious';

import {
  RateDetailsDTO,
  rateDetailsSchema,
  setRateDetailsFormDefaultValues,
} from './schema';

interface RateDetailsProps {
  disableTargetRateControls?: boolean;
  fetchRates: (args?: { query: string; cursor: string }) => void;
  isEditing?: boolean;
  matchedAttributes?: MatchedAttributeProps[];
  onDirtyStatusChange?: () => void;
  primaryRate?: RateBasicWithValue;
  rateCardControlLabel: string;
  targetRate: RateBasicWithValue | null;
  targetRateType: RateType | null;
  targetRateValue: number | null;
  title?: string;
}

type HandlerCallback = (data: RateDetailsDTO | undefined) => void;

export type RateDetailsHandler = {
  onSubmit?: (callback: HandlerCallback) => void;
};

const rateTypeOptions = getRateTypeOptions();

const RateDetails = forwardRef<RateDetailsHandler, RateDetailsProps>(function RateDetails(
  {
    disableTargetRateControls,
    fetchRates,
    isEditing,
    matchedAttributes,
    onDirtyStatusChange,
    primaryRate,
    rateCardControlLabel,
    targetRate: initialTargetRate,
    targetRateType: initialTargetRateType,
    targetRateValue: initialTargetRateValue,
    title,
  },
  ref,
) {
  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<RateDetailsDTO>({
    resolver: yupResolver(rateDetailsSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: setRateDetailsFormDefaultValues(
      initialTargetRate,
      initialTargetRateType,
      initialTargetRateValue,
    ),
  });

  const { targetRate, targetRateType, targetRateValue } = watch();

  useEffect(() => {
    const defaultValues = setRateDetailsFormDefaultValues(
      initialTargetRate,
      initialTargetRateType,
      initialTargetRateValue,
    );

    reset(defaultValues);
  }, [initialTargetRate, initialTargetRateType, initialTargetRateValue, reset]);

  const currentTargetRate = targetRate?.id ?? null;
  const previousTargetRate = usePrevious(currentTargetRate);

  const isManualRate = useMemo(() => {
    const hasEmptyRateCard = _.isNil(targetRate);
    const hasEmptyRateType = _.isNil(targetRateType);
    const hasEmptyRateValue =
      _.isNil(targetRateValue) || String(targetRateValue).length === 0;

    if (hasEmptyRateCard && hasEmptyRateType && hasEmptyRateValue) {
      return false;
    }

    if (!hasEmptyRateCard) {
      return false;
    }

    return true;
  }, [targetRate, targetRateType, targetRateValue]);

  const resetTargetRateOnNextChange = useRef(false);
  const primaryRateSet = useRef(false);

  // When editing an entity having a rate card, values should not be derived from the card but from
  // The received type & value fields (if any). this is because the rate value could have been
  // Updated in the card, so we need to pull in the de-referenced value. this only happen in
  // Edition mode when first pre-filling the form fields; subsequent rate card selections should
  // Pull values from the rate card itself.
  const shouldNotDeriveValuesFromRateCard = useRef(
    Boolean(isEditing && initialTargetRate && initialTargetRateType),
  );

  const handleClearTargetRate = useCallback(() => {
    setValue('targetRate', null);
    setValue('targetRateType', null);
    setValue('targetRateValue', null);
  }, []);

  const applyRateTypeAndRateValueAndClockResetTargetRateOnNextChange = (
    rateType: RateType | string | null,
    rateValue: number | null,
  ) => {
    const selectedOption = rateTypeOptions.find((option) => {
      return option.value === rateType;
    });

    setValue('targetRateType', selectedOption ?? null);
    setValue('targetRateValue', Number(rateValue));
    clearErrors(['targetRateValue', 'targetRateType']);

    // This is to avoid clearing the selected rate card by the "useEffect" below right after the
    // Rate type and the rate value gets programatically updated based on the selected rate card.
    // Given that changes via "setValue" are asynchronously applied we need to wait somehow for
    // Them to take place. this is an anti-pattern in React, but could not find another way
    // Around to distinguish between programatic and manual value changes.
    setTimeout(() => {
      resetTargetRateOnNextChange.current = true;
    }, 300);
  };

  // Derive rate type and value from the selected rate card
  useEffect(() => {
    // From no card to card
    if (_.isNil(previousTargetRate) && !_.isNil(currentTargetRate)) {
      if (shouldNotDeriveValuesFromRateCard.current) {
        applyRateTypeAndRateValueAndClockResetTargetRateOnNextChange(
          initialTargetRateType,
          initialTargetRateValue,
        );
        shouldNotDeriveValuesFromRateCard.current = false;
      } else {
        applyRateTypeAndRateValueAndClockResetTargetRateOnNextChange(
          targetRate?.type ?? null,
          targetRate?.rate ?? null,
        );
      }
    }

    // From card to a different card
    else if (
      previousTargetRate &&
      currentTargetRate &&
      previousTargetRate !== currentTargetRate
    ) {
      resetTargetRateOnNextChange.current = false;
      applyRateTypeAndRateValueAndClockResetTargetRateOnNextChange(
        targetRate?.type ?? null,
        targetRate?.rate ?? null,
      );
    }
  }, [previousTargetRate, currentTargetRate, targetRate]);

  // Unselect the current rate card if the rate type or the rate value got manually changed. please
  // See the comment on the "useEffect" above.
  useEffect(() => {
    if (resetTargetRateOnNextChange.current) {
      setValue('targetRate', null);
      resetTargetRateOnNextChange.current = false;
    }
  }, [targetRateType, targetRateValue]);

  useEffect(() => {
    if (!isEditing && primaryRate && !primaryRateSet.current) {
      setValue('targetRate', {
        id: primaryRate.id,
        name: primaryRate.name,
        rate: primaryRate.rate,
        type: primaryRate.type,
      });

      primaryRateSet.current = true;
    }
  }, [primaryRate, isEditing, primaryRateSet.current]);

  useEffect(() => {
    if (onDirtyStatusChange) {
      const selectedRateCardDidChange =
        (initialTargetRate?.id ?? null) !== currentTargetRate;

      const selectedRateTypeDidChange =
        (initialTargetRateType ?? null) !== (targetRateType?.value ?? null);

      const selectedRateValueDidChange =
        (initialTargetRateValue ?? null) !== (targetRateValue ?? null);

      if (
        selectedRateCardDidChange ||
        selectedRateTypeDidChange ||
        selectedRateValueDidChange
      ) {
        onDirtyStatusChange();
      }
    }
  }, [
    onDirtyStatusChange,
    initialTargetRate?.id,
    currentTargetRate,
    initialTargetRateType,
    targetRateType,
    initialTargetRateValue,
    targetRateValue,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit(callback) {
        handleSubmit((data) => {
          const toSubmit = _.cloneDeep(data);

          if (isEditing) {
            const updatedValues: RateDetailsDTO = {
              targetRate: toSubmit.targetRate ?? null,
              targetRateType: toSubmit.targetRateType ?? null,
              targetRateValue: toSubmit.targetRateValue ?? null,
            };

            callback(updatedValues);
          } else {
            if (isManualRate) {
              toSubmit.targetRate = null;
            } else {
              toSubmit.targetRateType = null;
              toSubmit.targetRateValue = null;
            }

            callback(toSubmit);
          }
        })();
      },
    }),
    [isManualRate, isEditing],
  );

  return (
    <Box>
      {title && <FormSectionTitle sx={{ mb: 1 }} title={title} />}

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AutocompleteAsyncFormField
            asyncCallback={(args: { query: string; cursor: string }) => fetchRates(args)}
            clearable
            control={control}
            disabled={disableTargetRateControls}
            errors={errors}
            getLabel={(item) => item.name || ''}
            getValue={(item) => item.id}
            label={rateCardControlLabel}
            name="targetRate"
            onClear={handleClearTargetRate}
          />
        </Grid>

        <Grid item xs={4}>
          <AutocompleteFormField
            clearable
            control={control}
            disabled={disableTargetRateControls}
            errors={errors}
            getLabel={(item) => item?.label ?? getRateTypeLabel(item)}
            getValue={(item) => item?.value ?? item}
            isCheckEnabled={false}
            label={`${t('administration.rates.rate_type')}`}
            list={rateTypeOptions}
            name="targetRateType"
          />
        </Grid>

        <Grid item xs={2}>
          <TextFormField
            control={control}
            disabled={disableTargetRateControls}
            errors={errors}
            label={`${t('administration.rates.rate_value_label')}`}
            name="targetRateValue"
            type="number"
          />
        </Grid>

        <Grid item xs={12} sx={{ '&.MuiGrid-item': { pt: 0 } }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2.5}
          >
            <Box display="flex" alignItems="center" gap={1.5}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isManualRate}
                    defaultChecked={isManualRate}
                    readOnly
                    size="small"
                  />
                }
                label={t('order.form.manual_rate_selection')}
                labelPlacement="start"
                sx={{ ml: 0 }}
                slotProps={{ typography: { fontSize: '11px' } }}
              />
              <Typography fontSize="11px" fontWeight="normal">
                {t('common.off_on')}
              </Typography>
            </Box>

            {!isManualRate && matchedAttributes && (
              <Box display="flex" gap={1}>
                <Typography fontSize="11px" fontWeight="normal">
                  {t('order.form.matching_attributes')}
                </Typography>

                <Box display="flex" alignItems="center" gap={1}>
                  {matchedAttributes.map((attribute) => (
                    <MatchedAttribute
                      key={attribute.label}
                      label={attribute.label}
                      isMatched={Boolean(attribute.isMatched)}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

interface MatchedAttributeProps {
  isMatched?: boolean;
  label: string;
}

function MatchedAttribute({ isMatched, label }: MatchedAttributeProps) {
  let icon = null;

  if (isMatched) {
    icon = <CheckCircle fontSize="inherit" color="success" />;
  } else {
    icon = <CheckCircleOutline fontSize="inherit" />;
  }

  return (
    <Box display="flex" gap={0.5} alignItems="center" fontSize="11px">
      {icon}
      <Typography fontSize="11px" fontWeight="normal">
        {label}
      </Typography>
    </Box>
  );
}

export default RateDetails;
