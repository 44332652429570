import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useRef, useState } from 'react';

import { DialogHeader } from '~components/Dialog/DialogHeader';
import { Status } from '~components/Job/Status';
import { FormStateChangeProps } from '~formsShared';
import { Job, JobFormPropsWithRates, JobLoad, useJob } from '~hooks/useJob';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

import { JobEditForm, JobEditFormHandler } from '../JobEditForm';

interface JobDialogProps {
  isOpen?: boolean;
  job?: Job;
  onClose: () => void;
}

const JobDialog = observer(({ isOpen, job, onClose }: JobDialogProps) => {
  const { toasterStore } = useStores();
  const { updateJob, updateLoad, getJobTimeline, isUpdating } = useJob();
  const formRef = useRef<JobEditFormHandler>(null);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const handleFormStateChange = useCallback(({ isDirty }: FormStateChangeProps) => {
    setIsFormDirty(isDirty);
  }, []);

  const onFormSubmitSuccess = useCallback(
    async (job: Job) => {
      const jobId = job.id;

      if (jobId) {
        getJobTimeline(jobId);
      }

      await formRef.current?.fileAttachmentsOnSubmit?.(jobId);
      onClose();
      toasterStore.push(
        alert(t('dispatch.job.updated', { name: job.jobId }), AlertTypes.success),
      );
    },
    [onClose],
  );

  const handleFormSubmit = useCallback(() => {
    const jobId = job?.id;

    if (jobId) {
      formRef.current?.submit?.((data: JobFormPropsWithRates) => {
        const updatedLoads = data.loads.map((load) => {
          return updateLoad(load as unknown as JobLoad);
        });
        Promise.all(updatedLoads).then(() => {
          updateJob(jobId, data).then((updatedJob) => {
            onFormSubmitSuccess(updatedJob);
          });
        });
      });
    }
  }, [job?.id, onFormSubmitSuccess]);

  return (
    <Dialog
      maxWidth="lg"
      open={Boolean(isOpen)}
      onClose={(_: never, reason: DialogCloseReasonType) => {
        if (isActionClicked(reason)) {
          onClose();
        }
      }}
    >
      <DialogHeader
        closeCallBack={onClose}
        title={
          <Typography component="span" variant="h5">
            {t('dispatch.job.edit_job')} <Status status={job?.status} sx={{ ml: 1 }} />
            {job && (
              <Typography component="div" variant="subtitle1">
                {`${job.jobId}`}
              </Typography>
            )}
          </Typography>
        }
      />

      <DialogContent sx={{ backgroundColor: theme.palette.grey[100] }}>
        {job && (
          <JobEditForm
            ref={formRef}
            defaultJob={job}
            onFormStateChange={handleFormStateChange}
          />
        )}
      </DialogContent>

      <DialogActions
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'flex-start',
          m: 0,
          p: 2,
        }}
      >
        <LoadingButton
          color="primary"
          data-test-id={'job-action-btn'}
          disabled={isUpdating || !isFormDirty}
          loading={isUpdating}
          loadingPosition="start"
          onClick={handleFormSubmit}
          startIcon={<></>}
          sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
          type="button"
          variant="contained"
        >
          {t('actions.submit')}
        </LoadingButton>

        <Button
          color="secondary"
          disabled={isUpdating}
          onClick={onClose}
          sx={{ mr: 2, px: 2 }}
          variant="outlined"
        >
          {t('actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default JobDialog;
