import Box from '@mui/material/Box';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import { SearchBar } from '~components/Search/SearchBar';
import { useStores } from '~store';

import CreateOrderButton from '../CreateOrderButton';
import DateFilter from '../filters/CalendarDateFilter';
import CalendarFilters from '../filters/CalendarFilters';

interface OrdersDispatchFiltersBarProps {
  isOrderFormDrawerMinimized?: boolean;
  onNewOrderClick: () => void;
}

const CalendarDispatchFiltersBar = observer(
  ({ isOrderFormDrawerMinimized, onNewOrderClick }: OrdersDispatchFiltersBarProps) => {
    const { calendarDispatchStore } = useStores();

    return (
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
        {!calendarDispatchStore.filters.searchOrder && (
          <Box display="flex" gap={1} alignItems="center">
            <DateFilter />
            <CalendarFilters />
          </Box>
        )}

        <Box display="flex" gap={1} ml="auto">
          <SearchBar
            placeHolder="Search orders"
            setFilter={(value) => {
              calendarDispatchStore.setFilters({ searchOrder: value }, true);
            }}
          />
          <CreateOrderButton
            isForResumeOrderCreation={isOrderFormDrawerMinimized}
            onClick={onNewOrderClick}
          />
        </Box>
      </Box>
    );
  },
);

export default CalendarDispatchFiltersBar;
