import { getV1RouteEstimate } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useState } from 'react';

import connection from '~services/connectionModule';

import { DeparsedRouteEstimate, RouteEstimate } from './models';

export default function useRoute() {
  const [isEstimatingRoute, setIsEstimatingRoute] = useState(false);

  const getRouteEstimate = async (args: DeparsedRouteEstimate) => {
    try {
      setIsEstimatingRoute(true);

      const response = await getV1RouteEstimate({ body: RouteEstimate.deparse(args) });

      return RouteEstimate.parse(response.data.data);
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.route.failed_to_get_estimate') as string,
      );
    } finally {
      setIsEstimatingRoute(false);
    }
  };

  return { getRouteEstimate, isEstimatingRoute };
}
