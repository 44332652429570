import { useCallback, useEffect, useState } from 'react';

import { FilterMenuItemLoadingReason } from '~components/Filters/FilterMenuItem';

interface UseFetchMoreOptions<T, H> {
  dataFetcher: (hasMoreProps?: H) => Promise<{ data: T[]; hasMore: H | undefined }>;
}

interface UseFetchMoreResult<T> {
  data: T[];
  loadingReason: FilterMenuItemLoadingReason | null;
  hasMore: boolean;
  fetchMore: () => void;
}

export function useFetchMore<T, H>({
  dataFetcher,
}: UseFetchMoreOptions<T, H>): UseFetchMoreResult<T> {
  const [data, setData] = useState<T[]>([]);
  const [loadingReason, setLoadingReason] = useState<FilterMenuItemLoadingReason | null>(
    null,
  );
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [hasMoreProps, setHasMoreProps] = useState<H | undefined>(undefined);

  const fetchData = useCallback(
    async (
      fetchFunction: (hasMoreProps?: H) => Promise<{ data: T[]; hasMore: H | undefined }>,
      loadingReason: FilterMenuItemLoadingReason,
      hasMoreProps?: H | undefined,
    ) => {
      setLoadingReason(loadingReason);
      try {
        const newData = await fetchFunction(hasMoreProps || undefined);
        setData((prevData) => [...prevData, ...newData.data]);
        setHasMore(Boolean(newData.hasMore));
        setHasMoreProps(newData.hasMore);
      } catch (error) {
        console.error('Error fetching data:', error);
        setHasMore(false);
      } finally {
        setLoadingReason(null);
      }
    },
    [hasMoreProps],
  );

  useEffect(() => {
    // Reset data when dependencies change
    setData([]);
    fetchData(dataFetcher, FilterMenuItemLoadingReason.SEARCH_VALUE, undefined);
  }, [dataFetcher]);

  const fetchMore = useCallback(() => {
    if (!loadingReason && hasMore) {
      fetchData(dataFetcher, FilterMenuItemLoadingReason.INFINITE_SCROLL, hasMoreProps);
    }
  }, [loadingReason, hasMore, dataFetcher, fetchData, hasMoreProps]);

  return { data, loadingReason, hasMore, fetchMore };
}
