import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';

import { FormStateChangeProps } from '~formsShared';
import { Company, useCompany } from '~hooks/useCompany';
import { CompanyForm } from '~pages/Admin/Companies/CompanyForm';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';

export const GeneralSettings = observer(() => {
  const [currentCompany, setCurrentCompany] = useState<Nullable<Company>>(null);
  const [logo, setLogo] = useState<Nullable<File>>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [currentFormDirty, setCurrentFormDirty] = useState<boolean>(false);
  const { updateCompany, uploadLogo, isUpdating, getCompanyById } = useCompany();
  const { toasterStore, userStore } = useStores();
  const companyActionsRef = useRef<any>(null);
  const companyId = userStore.currentCompanies[0]?.id || userStore.userCompany?.id;

  const onSuccess = useCallback((company?: Company) => {
    toasterStore.push(
      alert(
        $t('company.company_updated', { name: company?.legalName }),
        AlertTypes.success,
      ),
    );
  }, []);

  const onCancel = () => {
    setIsEditing(false);
    companyActionsRef.current?.resetForm();
  };

  const onChangeLogo = (file: File) => {
    setCurrentFormDirty(true);
    setLogo(file);
  };

  const uploadCompanyLogo = useCallback(
    (id: string, logo: File) => {
      uploadLogo({
        id: id,
        file: logo,
        callBack: (company: Company) => {
          onSuccess(company);
        },
      });
    },
    [onSuccess],
  );

  const onSubmitCallback = useCallback(
    async (data: Company, logo: Nullable<File>) => {
      if (data.id && logo) {
        uploadCompanyLogo(data.id, logo);
      }

      await updateCompany({ company: data, callBack: onSuccess });
      setIsEditing(false);
    },
    [uploadCompanyLogo],
  );

  const onFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setCurrentFormDirty(isDirty);
  };

  const onSubmitForm = () => {
    // Need to pass in the logo to the submit function, since the useCallback function cannot access the logo state
    companyActionsRef.current?.submit((data: Company) => onSubmitCallback(data, logo));
  };

  useEffect(() => {
    if (companyId) {
      getCompanyById({
        id: companyId,
        callBack: setCurrentCompany,
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (currentCompany) {
      companyActionsRef.current?.resetForm();
    }
  }, [currentCompany]);

  return (
    <Box
      display={'flex'}
      width={'100%'}
      sx={{ maxWidth: '800px' }}
      flexDirection={'column'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'end'}
        sx={{
          py: 2,
          mt: -2,
          mb: -4,
          backgroundColor: 'background.paper',
        }}
        className="sticky-top"
      >
        <Box display={'flex'} alignItems={'center'} justifyContent={'end'}>
          {isEditing ? (
            <Button
              onClick={onCancel}
              sx={{ mr: 2, px: 2 }}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          ) : (
            <Button
              onClick={() => setIsEditing((prev) => !prev)}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdating}
              color="primary"
              variant="contained"
            >
              {$t(`actions.${isEditing ? 'cancel' : 'edit'}`)}
            </Button>
          )}
          <LoadingButton
            disabled={!isEditing || !currentFormDirty}
            loading={isUpdating}
            loadingPosition="start"
            startIcon={<></>}
            onClick={onSubmitForm}
            type="button"
            variant="contained"
            color="primary"
            sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
          >
            {$t(`actions.update`)}
          </LoadingButton>
        </Box>
      </Box>
      {currentCompany?.id && (
        <CompanyForm
          ref={companyActionsRef}
          editable={isEditing}
          defaultCompany={currentCompany}
          onFormStateChange={onFormStateChange}
          onChangeLogo={onChangeLogo}
        />
      )}
    </Box>
  );
});
