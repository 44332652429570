import { SymbolLayerSpecification } from '@nbai/nbmap-gl';

import TruckMarkerAvailableImage from '~components/Maps/images/truck-marker-available.png';
import {
  LIVE_MAP_TRUCK_LAYER_ID,
  LIVE_MAP_TRUCK_SOURCE_ID,
} from '~hooks/useLiveMap/constants';

const assetsList = [
  { name: 'truck_marker_available', path: TruckMarkerAvailableImage },
] as const;

enum TruckStatus {
  AVAILABLE = 'available',
}

const assetNameByTruckStatus: Record<TruckStatus, (typeof assetsList)[number]['name']> = {
  [TruckStatus.AVAILABLE]: 'truck_marker_available',
};

export default class TruckMarkerFactory {
  private constructor() {}

  public static makeMarker() {
    return new TruckMarker();
  }

  public static registerAssets() {
    return assetsList;
  }
}

class TruckMarker {
  private _layerData: Partial<SymbolLayerSpecification>;

  constructor() {
    this._layerData = {
      id: LIVE_MAP_TRUCK_LAYER_ID,
      source: LIVE_MAP_TRUCK_SOURCE_ID,
      type: 'symbol',
      layout: {
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-size': 0.8,
        'icon-optional': false,
        'icon-rotate': ['get', 'bearing'],
        'icon-rotation-alignment': 'map',
        'text-anchor': 'top',
        'text-offset': [0, 1.5],
        'text-size': 11,
      },
      paint: {
        'text-color': 'black',
        'text-halo-width': 0.25,
        'text-halo-color': 'black',
      },
    };

    return this.status('available');
  }

  public textField(field: string) {
    this._layerData.layout = {
      ...this._layerData.layout,
      'text-field': ['get', field],
    };

    return this;
  }

  public status(status: `${TruckStatus}`) {
    this._layerData.layout = {
      ...this._layerData.layout,
      'icon-image': assetNameByTruckStatus[status],
    };

    return this;
  }

  public toLayer() {
    return this._layerData;
  }
}
