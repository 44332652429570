import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { FileAttachment_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { get } from 'lodash';
import { useMemo } from 'react';

import { GridCardWrapper } from '~components/Cards/GridCardWrapper';
import { SimpleCard } from '~components/Cards/SimpleCard';
import { BillingInfo } from '~components/Dispatch/BillingInfo';
import FileAttachmentsReadOnly from '~components/Files/FileAttachmentsReadOnly';
import { ContactDetails } from '~components/Job/ContactDetails';
import { RowItemLabelValue } from '~components/Job/RowItemLabelValue';
import { Status as JobStatus } from '~components/Job/Status';
import { Notes } from '~components/Order/Notes';
import { Job } from '~hooks/useJob';
import { useStores } from '~store';
import { dateFormat } from '~utils/dateTime';

interface Props {
  details: Job;
}

const JobDetails = ({ details }: Props) => {
  const { ordersStore } = useStores();

  const startTime = useMemo(() => {
    // TODO: update by status
    if (details?.jobStartAt) {
      const date = details?.jobStartAt;

      return `${dateFormat(date)}, ${dateFormat(date, 'hh:mm A')}`;
    }
    return '';
  }, [details.jobStartAt]);

  const order = useMemo(() => {
    return ordersStore.orders.find((item) => item.id === details.order?.id);
  }, [details.order?.id, ordersStore]);

  const vendorName = useMemo(() => {
    return get(details, 'vendorJobAssignment.vendorAccount.name', '');
  }, [details?.vendorJobAssignment?.vendorAccount?.name]);

  const siteNames = useMemo(() => {
    return ['pickUpWayPoints', 'dropOffWayPoints']
      .map((item) => get(details, `${item}[0].site.name`, ''))
      .join(', ');
  }, [details]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <GridCardWrapper>
            <SimpleCard
              key={`job_general__${details.id}}`}
              sx={{ flexGrow: 1, background: 'transparent', borderRadius: 0 }}
            >
              <Table>
                <TableBody>
                  <RowItemLabelValue
                    label={`${$t('form_fields.job_id')}`}
                    value={details.jobId}
                  />
                  <RowItemLabelValue
                    label={`${$t('form_fields.status')}`}
                    value={<JobStatus status={details.status} />}
                  />
                  {order && (
                    <>
                      <RowItemLabelValue
                        label={`${$t('form_fields.phase_name')}`}
                        value={order.phase?.name || '-'}
                      />
                      <RowItemLabelValue
                        label={`${$t('form_fields.phase_code')}`}
                        value={order.phase?.code || '-'}
                      />
                    </>
                  )}
                  <RowItemLabelValue
                    label={`${$t('form_fields.start_time')}`}
                    value={startTime}
                  />
                  <RowItemLabelValue
                    label={`${$t('form_fields.service')}`}
                    value={details.service?.name}
                  />
                  <RowItemLabelValue
                    label={`${$t('form_fields.equipment_type')}`}
                    value={`${details.equipment?.name || ''}`}
                    sx={{ borderBottomColor: 'transparent' }}
                  />
                </TableBody>
              </Table>
            </SimpleCard>
          </GridCardWrapper>
        </Grid>

        <Grid item xs={4}>
          <GridCardWrapper>
            <SimpleCard
              key={`job_general__${details.id}}`}
              sx={{ flexGrow: 1, background: 'transparent', borderRadius: 0 }}
            >
              <Table>
                <TableBody>
                  <RowItemLabelValue
                    label={`${$t('form_fields.driver')}`}
                    value={details.driverFullName || $t('common.not_specified')}
                    sx={{ fontStyle: details.driverFullName ? 'normal' : 'italic' }}
                  />
                  {vendorName && (
                    <RowItemLabelValue
                      label={`${$t('common.vendor')}`}
                      value={vendorName}
                    />
                  )}
                  <RowItemLabelValue
                    label={`${$t('form_fields.materials')}`}
                    value={`${details.material?.name || ''}`}
                  />
                  <RowItemLabelValue
                    label={`${$t('form_fields.quantity')}`}
                    value={`${details.quantity || 0} ${details.unitOfMeasure?.name}`}
                  />
                  <RowItemLabelValue
                    label={`${$t('form_fields.priority')}`}
                    value={details.priority}
                  />
                  <RowItemLabelValue
                    label={`${$t('dispatch.order.site_s')}`}
                    value={siteNames}
                    sx={{ borderBottomColor: 'transparent' }}
                  />
                </TableBody>
              </Table>

              <Table sx={{ mt: 4, mb: 2, overflow: 'auto' }}>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ py: 0.5, pl: 0 }}>
                      <Typography variant={'body1'}>
                        {$t('form_fields.contact_type')}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0.5 }}>
                      <Typography variant={'body1'}>
                        {$t('form_fields.contact_type')}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0.5, pr: 0, textAlign: 'right' }}>
                      <Typography variant={'body1'}>{$t('form_fields.email')}</Typography>
                    </TableCell>
                  </TableRow>

                  {details.salesContact && (
                    <ContactDetails
                      contact={details.salesContact}
                      title={`${$t('form_fields.sales')}`}
                      sx={{ mt: -1.5 }}
                    />
                  )}

                  {details.foremanContact && (
                    <ContactDetails
                      contact={details.foremanContact}
                      title={`${$t('form_fields.foreman')}`}
                      sx={{ mt: -1.5 }}
                    />
                  )}

                  {details.supervisorContact && (
                    <ContactDetails
                      contact={details.supervisorContact}
                      title={`${$t('form_fields.supervisor')}`}
                      sx={{ mt: -1.5 }}
                    />
                  )}
                  {details.collaboratorContact && (
                    <ContactDetails
                      contact={details.collaboratorContact}
                      title={`${$t('form_fields.collaborator')}`}
                    />
                  )}
                </TableBody>
              </Table>
            </SimpleCard>
          </GridCardWrapper>
        </Grid>

        <Grid item xs={4}>
          <GridCardWrapper>
            <SimpleCard
              key={`job_general__${details.id}}`}
              sx={{ flexGrow: 1, background: 'transparent', borderRadius: 0 }}
            >
              <Notes value={details.notes || ''} />

              {details.project?.id && (
                <FileAttachmentsReadOnly
                  label={$t('form_fields.project_attachments') as string}
                  fileAttachableId={details.project.id}
                  fileAttachableType={FileAttachment_Read.file_attachable_type.PROJECT}
                />
              )}
              {details.order?.id && (
                <FileAttachmentsReadOnly
                  label={$t('form_fields.order_attachments') as string}
                  fileAttachableId={details.order.id}
                  fileAttachableType={FileAttachment_Read.file_attachable_type.ORDER}
                />
              )}

              <FileAttachmentsReadOnly
                label={$t('form_fields.job_attachments') as string}
                fileAttachableId={details.id}
                fileAttachableType={FileAttachment_Read.file_attachable_type.JOB}
              />

              <BillingInfo details={details} sx={{ mt: 4 }} />
            </SimpleCard>
          </GridCardWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

export { JobDetails };
