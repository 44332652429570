import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { t as $t } from 'i18next';
import { capitalize } from 'lodash';
import { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { AnimateComponentPresence } from '~components/AnimatePresence';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { CheckBoxFormField } from '~components/FormFields/CheckBoxFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { SwitchFormField } from '~components/FormFields/SwitchFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { RateTypes } from '~constants/enums';
import { FormStateChangeProps } from '~formsShared';
import { Account } from '~hooks/useAccount';
import { useEquipment } from '~hooks/useEquipment';
import { useProjects } from '~hooks/useProjects';
import { ItemColorLabelValue, Rate } from '~hooks/useRates';
import { useServices } from '~hooks/useServices';
import {
  rateFormValidationSchema,
  setDefaultRateValues,
} from '~pages/Settings/RatesManagement/rateFormValidationSchema';
import { rateTypeLabelColor } from '~pages/Settings/RatesManagement/rateUtils';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

interface RateFormProps {
  account?: Nullable<Account>;
  defaultRate: Rate;
  disabledFields: Array<string>;
  driverId?: string;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
  subFormsToHide: Array<RateTypes>;
}
const hourlyMinimumOptions = [
  `15 ${$t('administration.rates.minutes_abbreviation')}`,
  `30 ${$t('administration.rates.minutes_abbreviation')}`,
  `60 ${$t('administration.rates.minutes_abbreviation')}`,
];
const hourlyIncrementOptions = [
  `1 ${$t('administration.rates.minutes_abbreviation')}`,
  `15 ${$t('administration.rates.minutes_abbreviation')}`,
  `30 ${$t('administration.rates.minutes_abbreviation')}`,
];
const RateForm = forwardRef(function RateForm(
  {
    account,
    defaultRate,
    disabledFields,
    driverId,
    onFormStateChange,
    subFormsToHide,
  }: RateFormProps,
  ref: Ref<any>,
) {
  const { companyAssetsStore, userStore } = useStores();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
  } = useForm({
    resolver: yupResolver(rateFormValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: setDefaultRateValues(
      defaultRate,
      account || null,
      driverId,
      userStore.currentCompanies[0],
    ),
  });

  const { getProjectsByCompanyId, isLoadingProjects, companyRelatedProjects } =
    useProjects();
  const { getEquipmentByCompanyId, isLoading: isLoadingEquipment } = useEquipment();
  const { getAllServices, isLoading: isServicesLoading } = useServices();
  const companyId =
    watch('company')?.id || userStore.currentCompanies[0]?.id || userStore.userCompany.id;

  useImperativeHandle(ref, () => ({
    submit(callBack: () => void) {
      handleSubmit(callBack)();
    },
  }));

  const equipmentOptions = companyAssetsStore.equipment;
  const projectsOptions = companyRelatedProjects;
  const servicesOptions = companyAssetsStore.services;
  // Const getRateValueAndInputName = (type: RateTypes) => {
  //   Switch (type) {
  //     Case RateTypes.RATE_PER_HOUR || RateTypes.RATE_PER_DAY:
  //       Return 'rateInputs.timeRate';
  //     Case RateTypes.RATE_COMMISSION:
  //       Return 'rateInputs.commissionPercentage';
  //     Default:
  //       Return 'rateInputs.perUnitRate';
  //   }
  // };
  const rateTypeOptions = Object.values(RateTypes)
    .map((type) => {
      const res = rateTypeLabelColor(type) as ItemColorLabelValue;
      if (res?.value !== RateTypes.RATE_COMMISSION) {
        return {
          label: `${$t('administration.rates.rate_per_keyword')} ${res.label}`,
          value: type,
          color: res.color,
        };
      } else {
        return res;
      }
    })
    .filter((type) => !subFormsToHide.includes(type?.value as RateTypes));

  const typeWatchedOrDefault = defaultRate.type?.value?.length
    ? defaultRate.type?.value
    : watch('type')?.value;
  const isPrimaryDisabled = Boolean(disabledFields.includes('isPrimary'));
  useEffect(() => {
    if (companyId) {
      getProjectsByCompanyId(companyId);
      getEquipmentByCompanyId({ companyId });
    }
    getAllServices();
  }, [companyId]);

  useEffect(() => {
    onFormStateChange({ isValid, isDirty });
  }, [isValid, isDirty]);

  useEffect(() => {
    // Trigger();
  }, [typeWatchedOrDefault]);

  return (
    <Box component="form" data-test-id="company-form">
      <Box display={'flex'} flexDirection={'column'} sx={{ mb: 2, mt: 0.5 }}>
        <CompanySelectorFormField
          name={'company'}
          disabled={!!defaultRate?.company?.id}
          errors={errors}
          control={control}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        sx={{ mb: 1 }}
        alignItems={'flex-start'}
      >
        <TextFormField
          control={control}
          errors={errors}
          name="name"
          sx={{ mr: 1, maxWidth: '180px' }}
          label={`${$t('form_fields.rate_name')}`}
          isRequired={true}
        />
        <AutocompleteFormField
          control={control}
          disabled={Boolean(disabledFields.includes('type'))}
          name="type"
          errors={errors}
          multiple={false}
          isCheckEnabled={false}
          isRequired={true}
          list={rateTypeOptions}
          label={`${$t('administration.rates.rate_type')}`}
          getValue={(item) => item.value}
          getLabel={(item) => item?.label || ''}
        />
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            minHeight: '94px',
            alignItems: 'center',
          }}
        >
          <Tooltip
            title={
              isPrimaryDisabled
                ? $t('administration.rates.primary_rate_defined')
                : $t('administration.rates.ser_as_primary')
            }
          >
            <span>
              <SwitchFormField
                labelPlacement={'start'}
                sx={{ ml: 1, minWidth: '190px' }}
                control={control}
                errors={errors}
                disabled={!defaultRate.isPrimary && isPrimaryDisabled}
                name="isPrimary"
                label={$t('administration.rates.ser_as_primary') || ''}
                isRequired={false}
                value={defaultRate.isPrimary}
              />
            </span>
          </Tooltip>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <TextFormField
          sx={{ width: '30%' }}
          control={control}
          errors={errors}
          name={'rateInputs.rate'}
          label={`${
            typeWatchedOrDefault === RateTypes.RATE_COMMISSION
              ? $t('administration.rates.commission_rate')
              : $t('administration.rates.rate_value_label')
          }`}
          isRequired={true}
          type={'number'}
          InputProps={{
            endAdornment: (
              <Box sx={{ position: 'relative' }}>
                <InputAdornment position="end" sx={{ position: 'absolute', ml: 3 }}>
                  {Boolean(rateTypeLabelColor(typeWatchedOrDefault)?.label?.length) &&
                    `${$t('administration.rates.rate_per_keyword')} ${
                      rateTypeLabelColor(typeWatchedOrDefault)?.label
                    }`}
                </InputAdornment>
              </Box>
            ),
          }}
        />
      </Box>
      <Divider sx={{ my: 2 }}></Divider>
      <AnimateComponentPresence
        isVisible={typeWatchedOrDefault !== RateTypes.RATE_COMMISSION}
        identifier={RateTypes.RATE_COMMISSION}
      >
        <>
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            sx={{ mb: 1, mt: 1 }}
          >
            <Box display={'flex'}>
              <FormSectionTitle
                sx={{ mt: 2, mb: 1 }}
                title={`${$t('administration.rates.set_advanced_title')}`}
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              {typeWatchedOrDefault === RateTypes.RATE_PER_HOUR ? (
                <>
                  <AutocompleteFormField
                    control={control}
                    name={'rateInputs.minimum'}
                    limitTags={2}
                    errors={errors}
                    isRequired={true}
                    list={hourlyMinimumOptions}
                    multiple={false}
                    sx={{ width: '30%', mb: 1 }}
                    isCheckEnabled={false}
                    label={`${$t('administration.rates.minimum_charged')}`}
                    getValue={(item) => item}
                    getLabel={(item) => capitalize(item)}
                  />
                  <Box display={'flex'}>
                    <AutocompleteFormField
                      control={control}
                      name={'rateInputs.increment'}
                      limitTags={2}
                      errors={errors}
                      isRequired={true}
                      list={hourlyIncrementOptions}
                      multiple={false}
                      sx={{ width: '30%', mb: 1 }}
                      isCheckEnabled={false}
                      label={`${$t('administration.rates.increment_by')}`}
                      getValue={(item) => item}
                      getLabel={(item) => capitalize(item)}
                    />
                  </Box>
                </>
              ) : (
                <Box display={'flex'} alignItems={'center'}>
                  <TextFormField
                    sx={{ mr: 1, width: '30%' }}
                    control={control}
                    errors={errors}
                    isRequired={true}
                    type={'number'}
                    name={'rateInputs.minimum'}
                    label={`${$t('administration.rates.minimum_charged')}`}
                    InputProps={{
                      endAdornment: (
                        <Box sx={{ position: 'relative' }}>
                          {typeWatchedOrDefault?.length ? (
                            <InputAdornment
                              position="end"
                              sx={{ position: 'absolute', ml: 3 }}
                            >
                              {rateTypeLabelColor?.(typeWatchedOrDefault)?.label}
                            </InputAdornment>
                          ) : (
                            <></>
                          )}
                        </Box>
                      ),
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </>
      </AnimateComponentPresence>

      <Divider flexItem={true} sx={{ my: 2 }} orientation={'horizontal'}></Divider>
      <Box display={'flex'} flexDirection={'column'} sx={{ mb: 1 }}>
        <FormSectionTitle
          sx={{ mt: 2 }}
          title={`${$t('administration.rates.when_applied')}`}
        />
        <Typography variant={'caption'} color={'grey'}>
          {$t('administration.rates.when_applied_sub_text')}
        </Typography>
      </Box>
      <Box display={'flex'} flexDirection={'column'} width={'100%'} sx={{ mb: 1 }}>
        <AutocompleteFormField
          control={control}
          name="projects"
          limitTags={2}
          loading={isLoadingProjects}
          errors={errors}
          groupBy={(item) => item?.type}
          list={projectsOptions}
          multiple={true}
          sx={{ width: '75%', mb: 1 }}
          isCheckEnabled={true}
          label={`${$t('form_fields.project')}`}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
        />
        <AutocompleteFormField
          control={control}
          limitTags={2}
          name="equipment"
          errors={errors}
          loading={isLoadingEquipment}
          groupBy={(item) => item?.type?.name}
          list={equipmentOptions}
          multiple={true}
          sx={{ width: '75%', mb: 1 }}
          isCheckEnabled={true}
          label={`${$t('form_fields.equipment_type')}`}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
        />
        <AutocompleteFormField
          control={control}
          limitTags={2}
          name="services"
          sx={{ width: '75%', mb: 1 }}
          loading={isServicesLoading}
          groupBy={(item) => item?.type}
          errors={errors}
          multiple={true}
          isCheckEnabled={true}
          list={servicesOptions}
          label={`${$t('form_fields.service')}`}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
        />
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <CheckBoxFormField
          control={control}
          errors={errors}
          value={defaultRate.isFuelSurcharge || false}
          label={$t('administration.rates.apply_global_fuel_surcharge') || ''}
          name={'isFuelSurcharge'}
        />
      </Box>
    </Box>
  );
});
export { RateForm };
