import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { CSSProperties, useMemo } from 'react';

import { OverflowAwareText } from '~components/Order/ordersDispatchStyledComponents';
import { AccountTypeahead } from '~hooks/useAccount';
import { DriverBasic } from '~hooks/useDrivers';
import theme from '~theme/AppTheme';

import { DRIVER_PANEL_DRIVER_CARD_HEIGHT_IN_PX } from './constants';
import { DriverCardContent } from './drivers/AssigneeCard';
import { LEFT_COLUMN_WIDTH_IN_PX } from './OrdersView';

enum DriverPanelAssigneeCardPlacement {
  DRAGGING = 'dragging',
  DRIVER_PANEL = 'driver_panel',
}

interface DriverPanelAssigneeCardProps {
  assignee: DriverBasic | AccountTypeahead;
  placement?: `${DriverPanelAssigneeCardPlacement}`;
  style?: CSSProperties;
}

export default function DriverPanelAssigneeCard({
  assignee,
  placement,
  style,
}: DriverPanelAssigneeCardProps) {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: assignee.id,
  });

  const draggableStyle: CSSProperties = {
    ...style,
    width: `${LEFT_COLUMN_WIDTH_IN_PX}px`,
    ...(transform && { transform: CSS.Transform.toString(transform) }),
  };

  const cardSx = useMemo(() => {
    const sx: SxProps = {
      backgroundColor:
        placement === DriverPanelAssigneeCardPlacement.DRAGGING
          ? theme.brandV2.colors.treadGray8
          : theme.palette.common.white,
      border: `1px solid ${theme.brandV2.colors.treadGray7}`,
      borderRadius: '12px',
      boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.20)',
      display: 'flex',
      flexDirection: 'column',
      gap: 0.5,
      height: '100%',
      p: 1.5,
      width: '100%',
    };

    return sx;
  }, [placement]);

  if (isDragging && placement === DriverPanelAssigneeCardPlacement.DRIVER_PANEL) {
    return null;
  }

  return (
    <Box ref={setNodeRef} style={draggableStyle} {...listeners} {...attributes}>
      <Box
        sx={{
          height: `${DRIVER_PANEL_DRIVER_CARD_HEIGHT_IN_PX}px`,
          position: 'relative',
          px: 1.5,
          py: 0.5,
          width: '100%',
        }}
      >
        {assignee instanceof DriverBasic ? (
          <DriverCardContent driver={assignee} jobCount={0} sx={cardSx} />
        ) : (
          <VendorCard vendor={assignee} sx={cardSx} />
        )}
      </Box>
    </Box>
  );
}

interface VendorCardProps {
  sx?: SxProps;
  vendor: AccountTypeahead;
}

function VendorCard({ sx, vendor }: VendorCardProps) {
  return (
    <Box sx={sx}>
      <OverflowAwareText fontWeight={600}>{vendor.name}</OverflowAwareText>
    </Box>
  );
}
