import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { t } from 'i18next';

import { Company } from '~hooks/useCompany';
import { Settlement } from '~hooks/useSettlements';

export interface HeadingProps {
  company: Company;
  forCompany?: Company;
  settlement: Settlement;
}

export default function Heading(props: HeadingProps) {
  const styles = StyleSheet.create({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    companyRow: {
      width: '100%',
    },
    settlementInfoRow: {
      width: '100%',
      marginTop: 20,
    },
  });

  return (
    <View style={styles.root}>
      <View style={styles.companyRow}>
        <CompanyRow {...props} />
      </View>

      <View style={styles.settlementInfoRow}>
        <SettlementInfoRow {...props} />
      </View>
    </View>
  );
}

function CompanyRow({ company, settlement }: HeadingProps) {
  const styles = StyleSheet.create({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: '100%',
    },
    logoColumn: {
      flex: 1,
      marginRight: 20,
    },
    companyInfoColumn: {
      flex: 3,
      paddingRight: 20,
    },
    settlementIdColumn: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    companyName: {
      fontSize: 11,
    },
    companyInfo: {
      marginTop: 5,
    },
    companyInfoText: {
      marginTop: 5,
      fontSize: 8,
    },
    settlementIdText: {
      fontSize: 10,
    },
  });

  let addressLine1 = company.address?.streetAddress ?? '';

  if (company.address?.premise) {
    addressLine1 = `${addressLine1 ? `${addressLine1}, ` : ''}${company.address.premise}`;
  }

  let addressLine2 = company.address?.locality ?? '';

  if (company.address?.administrativeArea) {
    addressLine2 = `${addressLine2 ? `${addressLine2} ` : ''}${company.address.administrativeArea}`;
  }

  if (company.address?.postalCode) {
    addressLine2 = `${addressLine2 ? `${addressLine2}, ` : ''}${company.address.postalCode}`;
  }

  return (
    <View style={styles.root}>
      {/* FIXME @todo To be added in a follow-up ticket */}
      {/* <View style={styles.logoColumn}>
        <Image
          src={{
            uri: `${company.logoUrl}?t=${Math.floor(Math.random() * 100000)}`,
            method: 'GET',
            headers: { 'Cache-Control': 'no-cache, must-revalidate' },
            body: '',
          }}
          cache={false}
        />
      </View> */}

      <View style={styles.companyInfoColumn}>
        <Text style={styles.companyName}>{company.legalName}</Text>

        {Boolean(addressLine1 || addressLine2) && (
          <View style={styles.companyInfo}>
            {addressLine1 && <Text style={styles.companyInfoText}>{addressLine1}</Text>}
            {addressLine2 && <Text style={styles.companyInfoText}>{addressLine2}</Text>}
          </View>
        )}
      </View>

      <View style={styles.settlementIdColumn}>
        <Text style={styles.settlementIdText}>
          {t('approvals.driver_pay.settlements.pdf.settlement')} {settlement.settlementId}
        </Text>
      </View>
    </View>
  );
}

function SettlementInfoRow({ forCompany, settlement }: HeadingProps) {
  const styles = StyleSheet.create({
    root: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      borderColor: '#E0E0E0',
      borderStyle: 'solid',
      borderWidth: 2,
    },
    heading: {
      fontSize: 7,
      textTransform: 'uppercase',
    },
    text: {
      fontSize: 7,
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      paddingHorizontal: 4,
      paddingVertical: 5,
    },
    body: {
      marginTop: 5,
    },
    forColumn: {
      paddingLeft: 5,
      flex: 2,
    },
    ordersColumn: {
      flex: 2,
    },
    periodColumn: {
      flex: 1,
    },
    termsColumn: {
      flex: 1,
    },
    dateColumn: {
      flex: 1,
    },
  });

  const { driver, customerAccount, vendorAccount } = settlement;
  const name = driver?.fullName ?? customerAccount?.name ?? vendorAccount?.name;
  let forCompanyInfo: { addressLine1: string; addressLine2: string } | null = null;

  if (forCompany) {
    let addressLine1 = forCompany.address?.streetAddress ?? '';

    if (forCompany.address?.premise) {
      addressLine1 = `${addressLine1 ? `${addressLine1}, ` : ''}${forCompany.address.premise}`;
    }

    let addressLine2 = forCompany.address?.locality ?? '';

    if (forCompany.address?.administrativeArea) {
      addressLine2 = `${addressLine2 ? `${addressLine2} ` : ''}${forCompany.address.administrativeArea}`;
    }

    if (forCompany.address?.postalCode) {
      addressLine2 = `${addressLine2 ? `${addressLine2}, ` : ''}${forCompany.address.postalCode}`;
    }

    forCompanyInfo = { addressLine1, addressLine2 };
  }

  return (
    <View style={styles.root}>
      <View style={[styles.column, styles.forColumn]}>
        <Text style={styles.heading}>
          {t('approvals.driver_pay.settlements.pdf.for')}
        </Text>

        <View style={styles.body}>
          <Text style={styles.text}>{name}</Text>

          {Boolean(forCompanyInfo?.addressLine1 || forCompanyInfo?.addressLine2) && (
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              {forCompanyInfo?.addressLine1 && (
                <Text style={[styles.text, { marginTop: 2 }]}>
                  {forCompanyInfo.addressLine1}
                </Text>
              )}

              {forCompanyInfo?.addressLine2 && (
                <Text style={[styles.text, { marginTop: 2 }]}>
                  {forCompanyInfo.addressLine2}
                </Text>
              )}
            </View>
          )}
        </View>
      </View>

      {settlement.data.friendlyOrderIds && (
        <View style={[styles.column, styles.ordersColumn]}>
          <Text style={styles.heading}>
            {t('approvals.driver_pay.settlements.pdf.orders')}
          </Text>

          <View style={styles.body}>
            <Text style={styles.text}>{settlement.data.friendlyOrderIds.join(', ')}</Text>
          </View>
        </View>
      )}

      {/* FIXME @todo To be added in a follow-up ticket */}
      {/* <View style={[styles.column, styles.periodColumn]}>
        <Text style={styles.heading}>
          {t('approvals.driver_pay.settlements.pdf.period')}
        </Text>

        <View style={styles.body}>
          <Text style={styles.text}>period</Text>
        </View>
      </View> */}

      {/* FIXME @todo To be added in a follow-up ticket */}
      {/* <View style={[styles.column, styles.termsColumn]}>
        <Text style={styles.heading}>
          {t('approvals.driver_pay.settlements.pdf.terms')}
        </Text>

        <View style={styles.body}>
          <Text style={styles.text}>terms</Text>
        </View>
      </View> */}

      <View style={[styles.column, styles.dateColumn]}>
        <Text style={styles.heading}>
          {t('approvals.driver_pay.settlements.pdf.date')}
        </Text>

        <View style={styles.body}>
          <Text style={styles.text}>{settlement.createdAt.format('M/D/YYYY')}</Text>
        </View>
      </View>
    </View>
  );
}
