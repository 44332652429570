import { NextBillionAssetLocations_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';
import { PaginationLink, PaginationQuery } from '~services/pagination';
import { Nullable } from '~types/Nullable';

import { NextBillionAssetLocation } from './models';
interface GetLatestLocationsParams {
  order_id?: string;
  job_id?: string;
  limit?: number;
  linkType?: PaginationLink;
  link: Nullable<string>;
}

export const useNextBillionAssetLocationHistories = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getLatestLocations = ({
    order_id,
    job_id,
    limit,
    linkType,
    link,
  }: GetLatestLocationsParams) => {
    setIsLoading(true);
    const params: PaginationQuery = {
      'page[limit]': limit || 100,
    };

    if (linkType && link) {
      params[`page[${linkType}]`] = link;
    }

    if (order_id) {
      params['filter[order_id]'] = order_id;
    } else if (job_id) {
      params['filter[job_id]'] = job_id;
    }

    return connection
      .getPaginated<NextBillionAssetLocations_Read>(
        `${API_VERSION}/next_billion_asset_location_histories/latest_locations`,
        { params },
        $t('error_messages.next_billion.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => ({
        data: data.map(NextBillionAssetLocation.parse),
        pagination,
      }))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    getLatestLocations,
  };
};
