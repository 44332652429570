import { styled, TableCellBaseProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { LoadState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import JobStatusChip from '~components/Job/JobStatusChip';
import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { Job, JobLoadSummary, useJob } from '~hooks/useJob';
import theme from '~theme/AppTheme';
import { dateFormat } from '~utils/dateTime';

export const makeDeliveredLoadsCellString = (loads?: JobLoadSummary[]) => {
  if (!loads) {
    return;
  }

  const deliveredLoads = loads.filter(
    (load) => load.state === LoadState.COMPLETED,
  ).length;
  const totalLoads = loads.length;

  return `${deliveredLoads} / ${totalLoads}`;
};

export const makeDeliveredQuantityCellString = (loads?: JobLoadSummary[]) => {
  if (!loads) {
    return;
  }

  const [deliveredQuantity, totalQuantity] = loads.reduce(
    ([delivered, total], load) => {
      const quantity = load.quantity;

      total += quantity;

      if (load.state === LoadState.COMPLETED) {
        delivered += quantity;
      }

      return [delivered, total];
    },
    [0, 0],
  );

  return `${deliveredQuantity} / ${totalQuantity}`;
};

interface OrderJobListProps {
  jobs: Job[];
  selectedJobId?: string;
  onSelect: (job: Job) => void;
  onViewRoute: (job: Job) => void;
}

export const OrderJobList = ({
  jobs,
  selectedJobId,
  onSelect,
  onViewRoute,
}: OrderJobListProps) => {
  const { getJobLoads } = useJob();

  const [jobLoads, setJobLoads] = useState<{
    isLoading: boolean;
    jobLoads?: Map<string, JobLoadSummary[]>;
  }>({ isLoading: true });

  const jobIds = jobs.map((job) => job.id);

  useEffect(() => {
    if (!jobIds.length) {
      return;
    }

    const jobLoadsMap = new Map<string, JobLoadSummary[]>();
    jobIds.forEach((id) => jobLoadsMap.set(id, []));

    const jobLoadsRequests = jobIds.map((jobId) => getJobLoads(jobId));

    Promise.all(jobLoadsRequests).then((responses) => {
      responses.forEach((jobLoads, index) => {
        jobLoadsMap.set(jobIds[index], jobLoads);
      });

      setJobLoads({ isLoading: false, jobLoads: jobLoadsMap });
    });
  }, [JSON.stringify(jobIds)]);

  return (
    <Box
      sx={{
        backgroundColor: theme.brandV2.colors.treadGray8,
        border: `1px solid ${theme.brandV2.colors.treadGray7}`,
        overflow: 'hidden',
        borderRadius: theme.brandV2.borderRadius,
        mt: 1,
      }}
    >
      <Collapse in={jobs.length > 0}>
        {!jobLoads.isLoading && (
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell isHeader>{t('form_fields.time')}</StyledTableCell>
                <StyledTableCell isHeader>{t('live_map.truck_vendor')}</StyledTableCell>
                <StyledTableCell isHeader>
                  {_.capitalize(`${t('common.delivered')}`)}
                </StyledTableCell>
                <StyledTableCell isHeader>{t('form_fields.status')}</StyledTableCell>
                <StyledTableCell isHeader>{t('live_map.gps_data')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: 'white' }}>
              {jobs.map((job) => (
                <TableRow
                  key={job.id}
                  onClick={() => onSelect(job)}
                  sx={{
                    borderLeft:
                      selectedJobId === job.id
                        ? `2px solid ${theme.palette.info.main}`
                        : 'none',
                  }}
                >
                  <StyledTableCell>
                    <Typography display="flex" flexDirection="column" fontSize="inherit">
                      <Typography component="span" fontSize="inherit">
                        {dateFormat(dayjs(job.jobStartAt), 'hh:mmA')}
                      </Typography>

                      <Typography
                        component="span"
                        fontSize="inherit"
                        color={theme.brandV2.colors.treadGray3}
                      >
                        {job.jobId}
                      </Typography>
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Typography display="flex" flexDirection="column" fontSize="inherit">
                      <Typography component="span" fontSize="inherit">
                        {job.equipment?.name}
                      </Typography>

                      <Typography
                        component="span"
                        fontSize="inherit"
                        color={theme.brandV2.colors.treadGray3}
                      >
                        {job.vendorJobAssignment?.vendorAccount?.name}
                      </Typography>
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Typography display="flex" flexDirection="column" fontSize="inherit">
                      <Typography component="span" fontSize="inherit">
                        {`${makeDeliveredLoadsCellString(jobLoads.jobLoads?.get(job.id))} ${t('order.form.loads')}`}
                      </Typography>
                      <Typography
                        component="span"
                        fontSize="inherit"
                        color={theme.brandV2.colors.treadGray3}
                      >
                        {makeDeliveredQuantityCellString(jobLoads.jobLoads?.get(job.id))}{' '}
                        {job.unitOfMeasure?.name}
                      </Typography>
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <JobStatusChip jobState={job.status} hideDropdownArrow />
                  </StyledTableCell>

                  <StyledTableCell>
                    <Button
                      variant="text"
                      size="small"
                      color="info"
                      onClick={(e) => {
                        onViewRoute(job);
                        e.stopPropagation();
                      }}
                    >
                      {t('actions.view')}
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Collapse>

      <Box bgcolor={'white'}>
        <LoadingSpinner isVisible={jobLoads.isLoading} sx={{ py: 2 }} />
      </Box>
    </Box>
  );
};

interface StyledTableCellProps extends TableCellBaseProps {
  isHeader?: boolean;
}
const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (propName) => propName !== 'isHeader',
})<StyledTableCellProps>(({ isHeader, theme }) => ({
  '&.MuiTableCell-root': {
    padding: theme.spacing(0.5),
    border: 0,
    fontSize: isHeader ? '12px' : '10px',
    textAlign: 'center',
    verticalAlign: 'middle',
    ...(isHeader
      ? {
          color: theme.brandV2.colors.treadGray3,
          fontWeight: 600,
          borderBottom: `1px solid ${theme.brandV2.colors.treadGray7}`,
        }
      : {}),
  },
}));
