import {
  Material_Read,
  Material_Read_Nested,
  MaterialType_Read_Nested,
} from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';
import { t as $t } from 'i18next';

import { MaterialStates } from '~constants/enums';
import { CompanyBasic } from '~hooks/useCompany';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { Nullable } from '~types/Nullable';

const materialStateOptions = [
  { id: 'active', name: $t('common.active') },
  { id: 'inactive', name: $t('common.inactive') },
];
export class MaterialTypeItem {
  public static parse(proto: MaterialType_Read_Nested): MaterialTypeItem {
    return new MaterialTypeItem(
      proto?.name ?? '',
      proto?.id ?? '',
      proto?.system ?? false,
    );
  }
  public get system(): boolean {
    return this._system;
  }
  public get name(): string {
    return this._name;
  }
  public get id(): string {
    return this._id;
  }
  constructor(
    private _name: string,
    private _id: string,
    private _system: boolean,
  ) {}
}
export class BasicMaterial extends ItemNameAndId {
  public static parse(proto: Material_Read_Nested): BasicMaterial {
    return new BasicMaterial(proto.id ?? '', proto.name ?? '');
  }
  constructor(_id: string, _name: string) {
    super(_name, _id);
  }
}

export class Material {
  public static parse(proto: Material_Read): Material {
    const stateOption = materialStateOptions.find((option) => option.id === proto.state);
    return new Material(
      proto.id ?? '',
      proto.name ?? '',
      proto.system ?? false,
      MaterialTypeItem.parse(proto.material_type),
      proto.company ? CompanyBasic.parse(proto.company) : null,
      proto.external_id ?? null,
      proto.state ? ItemNameAndId.parse(stateOption) : null,
      dayjs(proto.created_at),
    );
  }
  public static deparseUpdate(data: any) {
    return {
      id: data.id,
      name: data.name,
      external_id: data.externalId,
      state: data.isNotActive ? MaterialStates.INACTIVE : MaterialStates.ACTIVE,
      // Company_id: data.company?.id,//cant be updated
      material_type_id: data.type?.id,
    };
  }
  public static deparse(data: any) {
    return {
      name: data.name,
      external_id: data.externalId,
      state: data.isNotActive ? MaterialStates.INACTIVE : MaterialStates.ACTIVE,
      company_id: data.company?.id,
      material_type_id: data.type?.id,
    };
  }
  public get system(): boolean {
    return this._system;
  }
  public get name(): string {
    return this._name || '';
  }
  public get id(): string {
    return this._id;
  }
  public get type(): MaterialTypeItem {
    return this._type;
  }
  public get typeName(): string {
    return this._type.name || '';
  }
  public get company(): Nullable<CompanyBasic> {
    return this._company;
  }

  public get companyName(): string {
    return this._company?.legalName || '';
  }

  public get isActive(): boolean {
    return this._state?.id === MaterialStates.ACTIVE;
  }

  public set isActive(value: boolean) {
    this._state = value
      ? ItemNameAndId.parse(materialStateOptions?.[0])
      : ItemNameAndId.parse(materialStateOptions?.[1]);
  }
  public get isNotActive(): boolean {
    return !this.isActive;
  }
  public set isNotActive(value: boolean) {
    this.isActive = !value;
  }
  public get externalId(): Nullable<string> {
    return this._externalId;
  }

  public get state(): Nullable<ItemNameAndId> {
    return this._state;
  }
  public get createdAt(): Dayjs {
    return this._createdAt;
  }
  constructor(
    private _id: string,
    private _name: string,
    private _system: boolean,
    private _type: MaterialTypeItem, // Temporary ? til we have full model ?
    private _company: Nullable<CompanyBasic>,
    private _externalId: Nullable<string>,
    private _state: Nullable<ItemNameAndId>,
    private _createdAt: Dayjs,
  ) {}
}
