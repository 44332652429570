import { Approval_Status } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';

import { ApprovalStatus } from './models';

export const useApprovalsAssignments = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getDriverDayApprovalStatus = (driverDayId: string) => {
    setIsLoading(true);

    return connection
      .get<Approval_Status>(
        `${API_VERSION}/driver_days/${driverDayId}/approval_status`,
        {},
        $t(
          'error_messages.approvals_assignments.failed_to_fetch_driver_day_approvals_status',
        ) as string,
      )
      .then((response) => {
        setIsLoading(false);
        return ApprovalStatus.parse(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    getDriverDayApprovalStatus,
  } as const;
};
