export enum MarkerType {
  truck = 'truck',
  site = 'site',
  moving_site = 'moving_site',
}

export enum GeofenceType {
  polygon = 'polygon',
  circle = 'circle',
}
