import _ from 'lodash';
import { makeObservable, observable, runInAction } from 'mobx';

import { FilterMenuItemLoadingReason } from '~components/Filters/FilterMenuItem';
import { JobAssignmentType } from '~constants/enums';
import { AccountTypeahead } from '~hooks/useAccount';
import { CompanyShareBasic } from '~hooks/useCompanyShares';
import { DriverBasic } from '~hooks/useDrivers';
import { BasicEquipment } from '~hooks/useEquipment';
import { Pagination } from '~services/pagination';

export interface SelectedAssignment {
  companyShare?: CompanyShareBasic;
  equipment?: BasicEquipment;
  id: string;
  name: string;
  type: JobAssignmentType;
}

const assignDriverMenuDataKeyByJobAssignmentType = {
  [JobAssignmentType.EXTERNAL_DRIVER]: 'externalDrivers',
  [JobAssignmentType.INTERNAL_DRIVER]: 'internalDrivers',
  [JobAssignmentType.VENDOR]: 'vendors',
} as const;

interface AssignDriverMenuQueryData {
  cursor?: string;
  data?: SelectedAssignment[];
}

export default class AssignDriverMenuStore {
  externalDrivers: AssignDriverMenuQueryData = {};
  internalDrivers: AssignDriverMenuQueryData = {};
  loadingReason: FilterMenuItemLoadingReason | null = null;
  vendors: AssignDriverMenuQueryData = {};

  constructor() {
    makeObservable(this, {
      externalDrivers: observable,
      internalDrivers: observable,
      loadingReason: observable,
      vendors: observable,
    });
  }

  #makeSelectedAssignment(data: DriverBasic | AccountTypeahead, type: JobAssignmentType) {
    const item: SelectedAssignment = {
      type,
      id: data.id,
      name: `${'fullName' in data ? data.fullName : data.name}`,
      companyShare: 'companyShare' in data ? data.companyShare ?? undefined : undefined,
      equipment: 'equipment' in data ? data.equipment ?? undefined : undefined,
    };

    return item;
  }

  fetchStart(type: JobAssignmentType, query: string, cursor?: string) {
    runInAction(() => {
      this.loadingReason = cursor
        ? FilterMenuItemLoadingReason.INFINITE_SCROLL
        : FilterMenuItemLoadingReason.SEARCH_VALUE;

      if (query && !cursor) {
        const dataKey = assignDriverMenuDataKeyByJobAssignmentType[type];
        this[dataKey] = {};
      }
    });
  }

  fetchEnd(
    {
      data,
      pagination,
    }: { data: (DriverBasic | AccountTypeahead)[]; pagination: Pagination },
    type: JobAssignmentType,
  ) {
    runInAction(() => {
      const items = data.map((item) => this.#makeSelectedAssignment(item, type));
      const dataKey = assignDriverMenuDataKeyByJobAssignmentType[type];

      this[dataKey].data = _.uniqBy(
        [...(this[dataKey].data ?? []), ...items],
        (item) => item.id,
      );
      this[dataKey].cursor = pagination.after;
      this.loadingReason = null;
    });
  }

  getQueryDataByJobAssignmentType(type: JobAssignmentType) {
    const dataKey = assignDriverMenuDataKeyByJobAssignmentType[type];
    const hasMore = 'cursor' in this[dataKey] ? Boolean(this[dataKey].cursor) : true;

    return { data: this[dataKey].data, hasMore };
  }

  resetQueryDataByJobAssignmentType(type: JobAssignmentType) {
    const dataKey = assignDriverMenuDataKeyByJobAssignmentType[type];

    this[dataKey] = {};
  }

  reset() {
    runInAction(() => {
      this.externalDrivers = {};
      this.internalDrivers = {};
      this.loadingReason = null;
      this.vendors = {};
    });
  }
}
