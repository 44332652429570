import { JobState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import _ from 'lodash';
import { uniqBy } from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { JobAssignmentType } from '~constants/enums';
import { AccountTypeahead } from '~hooks/useAccount';
import { DriverBasic } from '~hooks/useDrivers';
import { Job } from '~hooks/useJob';
import { Pagination } from '~services/pagination';
import { Nullable } from '~types/Nullable';

export const DEFAULT_PAGINATION_STATE: Pagination = { limit: 50, page: 1 };

enum DriverSchedulerStoreStorageKeys {
  APPLIED_ASSIGNED_JOBS_FILTERS = 'driverScheduler_appliedAssignedJobsFilters',
}

type DateFilters = {
  startDate?: string;
  endDate?: string;
};

export type JobsFilters = {
  customers?: string[];
  dispatchNumbers?: string[];
  dropOffSites?: string[];
  pickUpSites?: string[];
  projects?: string[];
  projectsExternalIds?: string[];
  search?: string;
};
type AssigneesFilters = {
  assigneeType: JobAssignmentType;
  search?: string;
};

class DriverSchedulerStore {
  assignedJobs: Job[] = [];
  assignedJobsFilters: JobsFilters = {};
  assignedJobsPagination: Pagination = DEFAULT_PAGINATION_STATE;
  assignees: (DriverBasic | AccountTypeahead)[] = [];
  assigneesFilters: AssigneesFilters = {
    assigneeType: JobAssignmentType.INTERNAL_DRIVER,
  };
  assigneesPagination: Pagination = DEFAULT_PAGINATION_STATE;
  dateFilters: DateFilters = {};
  draggedJob: Nullable<Job> = null;
  filteredAssignees: (DriverBasic | AccountTypeahead)[] = [];
  isLoadingAssignedJobs: boolean = false;
  isLoadingAssignees: boolean = false;
  isLoadingUnassignedJobs: boolean = false;
  isUpdatingJob: boolean = false;
  jobAssignments: Record<string, Nullable<Job>> = {};
  unassignedJobs: Job[] = [];
  unassignedJobsFilters: JobsFilters = {};
  unassignedJobsPagination: Pagination = DEFAULT_PAGINATION_STATE;

  constructor() {
    makeObservable(this, {
      assignedJobs: observable,
      assignedJobsFetchStart: action,
      assignedJobsFilters: observable,
      assignedJobsPagination: observable,
      assignees: observable,
      assigneesFetchEnd: action,
      assigneesFetchStart: action,
      assigneesFilters: observable,
      assigneesPagination: observable,
      clearAssignees: action,
      clearUnassignedJobs: action,
      dateFilters: observable,
      draggedJob: observable,
      fetchAssignedJobsEnd: action,
      fetchUnassignedJobsEnd: action,
      filteredAssignees: observable,
      isLoadingAssignedJobs: observable,
      isLoadingAssignees: observable,
      isLoadingUnassignedJobs: observable,
      isUpdatingJob: observable,
      jobAssignments: observable,
      setAssignedJobsFilters: action,
      setAssigneesFilters: action,
      setDateFilters: action,
      setDraggedJob: action,
      setFilteredAssignees: action,
      setJobAssignments: action,
      setUnassignedJobsFilters: action,
      unassignedJobs: observable,
      unassignedJobsFetchStart: action,
      unassignedJobsFilters: observable,
      unassignedJobsPagination: observable,
      updateJob: action,
      updateJobEnd: action,
      updateJobStart: action,
    });
  }

  unassignedJobsFetchStart() {
    runInAction(() => {
      this.isLoadingUnassignedJobs = true;
    });
  }

  assignedJobsFetchStart() {
    runInAction(() => {
      this.isLoadingAssignedJobs = true;
    });
  }

  assigneesFetchStart() {
    runInAction(() => {
      this.isLoadingAssignees = true;
    });
  }

  updateJobStart() {
    runInAction(() => {
      this.isUpdatingJob = true;
    });
  }

  updateJobEnd() {
    runInAction(() => {
      this.isUpdatingJob = false;
    });
  }

  fetchUnassignedJobsEnd(jobs: Job[], pagination?: Pagination, companyId?: string) {
    runInAction(() => {
      this.isLoadingUnassignedJobs = false;
      const filteredJobs = jobs.filter((job) => {
        const vendorCompanyIsCurrentCompany =
          job.vendorJobAssignment?.vendorAccount?.id === companyId;
        return (
          !job.driver ||
          (!job.driver && job.vendorJobAssignment && vendorCompanyIsCurrentCompany)
        );
      });

      this.unassignedJobs = uniqBy(
        [...this.unassignedJobs, ...filteredJobs],
        (job) => job.id,
      );

      this.unassignedJobsPagination = {
        limit: DEFAULT_PAGINATION_STATE.limit,
        after: pagination?.after ?? '',
        page: DEFAULT_PAGINATION_STATE.page,
      };
    });
  }

  fetchAssignedJobsEnd(jobs: Job[], pagination?: Pagination) {
    runInAction(() => {
      this.isLoadingAssignedJobs = false;
      this.assignedJobs = uniqBy([...this.assignedJobs, ...jobs], (job) => job.id);
      this.assignedJobsPagination = {
        limit: DEFAULT_PAGINATION_STATE.limit,
        after: pagination?.after ?? '',
        page: DEFAULT_PAGINATION_STATE.page,
      };
    });
  }

  updateAssignedJobsPagination(pagination: Pagination) {
    runInAction(() => {
      this.assignedJobsPagination = {
        limit: DEFAULT_PAGINATION_STATE.limit,
        after: pagination.after,
        page: DEFAULT_PAGINATION_STATE.page,
      };
    });
  }

  assigneesFetchEnd(
    assignees: (DriverBasic | AccountTypeahead)[],
    pagination?: Pagination,
  ) {
    runInAction(() => {
      this.isLoadingAssignees = false;
      this.assignees = uniqBy(
        this.assignees.concat(assignees),
        (assignee) => assignee.id,
      );
      this.filteredAssignees = this.assignees;

      this.assigneesPagination = {
        limit: DEFAULT_PAGINATION_STATE.limit,
        after: pagination?.after ?? '',
        page: DEFAULT_PAGINATION_STATE.page,
      };
    });
  }

  addUnassignedJob(job: Job) {
    runInAction(() => {
      this.unassignedJobs = uniqBy([...this.unassignedJobs, job], (job) => job.id);
    });
  }

  removeUnassignedJob(job: Job) {
    runInAction(() => {
      const unassignedIndex = this.unassignedJobs.findIndex((j) => j.id === job.id);

      if (unassignedIndex > -1) {
        this.unassignedJobs.splice(unassignedIndex, 1);
      }
    });
  }

  updateJob(job: Job) {
    if (!job) return;

    const unassignedIndex = this.unassignedJobs.findIndex((j) => j.id === job.id);
    const assignedIndex = this.assignedJobs.findIndex((j) => j.id === job.id);

    runInAction(() => {
      const isCanceled = job.status === JobState.CANCELED;
      const isUnassigned = !job.driver && !job.vendorJobAssignment;

      if (unassignedIndex > -1) {
        this.unassignedJobs.splice(unassignedIndex, 1, job);
      } else if (assignedIndex > -1) {
        this.assignedJobs.splice(assignedIndex, 1, job);
      } else if (
        this.dateFilters.startDate &&
        this.dateFilters.endDate &&
        isUnassigned &&
        !isCanceled
      ) {
        const jobStartDate = dayjs.tz(job.jobStartAt);
        const selectedStartDate = dayjs.tz(this.dateFilters.startDate);
        const selectedEndDate = dayjs.tz(this.dateFilters.endDate);
        const matchesCurrentlySelectedDateRange = jobStartDate.isBetween(
          selectedStartDate,
          selectedEndDate,
          'seconds',
          '[]',
        );

        if (matchesCurrentlySelectedDateRange) {
          this.unassignedJobs.push(job);
        }
      }
    });
  }

  setJobAssignments(newAssignments: Record<string, Nullable<Job>>) {
    runInAction(() => {
      this.jobAssignments = newAssignments;
    });
  }

  setUnassignedJobsFilters(filters: JobsFilters, merge?: boolean) {
    runInAction(() => {
      this.unassignedJobsFilters = merge
        ? { ...this.unassignedJobsFilters, ...filters }
        : filters;
    });
  }

  retrieveAssignedJobsStoredFilters() {
    const storedFilters = localStorage.getItem(
      DriverSchedulerStoreStorageKeys.APPLIED_ASSIGNED_JOBS_FILTERS,
    );
    const parsedFilters = (storedFilters ? JSON.parse(storedFilters) : {}) as JobsFilters;

    return parsedFilters;
  }

  restoreAssignedJobsFilters() {
    runInAction(() => {
      this.assignedJobsFilters = {
        ...this.assignedJobsFilters,
        ...this.retrieveAssignedJobsStoredFilters(),
      };
    });
  }

  persistAssignedJobsFilters(filters: JobsFilters) {
    runInAction(() => {
      const toPersist: Array<keyof JobsFilters> = [
        'customers',
        'dispatchNumbers',
        'dropOffSites',
        'pickUpSites',
        'projects',
        'projectsExternalIds',
      ];

      const shouldPersistFilters = Object.keys(filters).some((key) => {
        return toPersist.includes(key as keyof JobsFilters);
      });

      if (!shouldPersistFilters) {
        return;
      }

      const values = _.pick(filters, toPersist);
      localStorage.setItem(
        DriverSchedulerStoreStorageKeys.APPLIED_ASSIGNED_JOBS_FILTERS,
        JSON.stringify(values),
      );
    });
  }

  setAssignedJobsFilters(filters: JobsFilters, merge?: boolean) {
    const newFilters = merge ? { ...this.assignedJobsFilters, ...filters } : filters;

    if (filters.search) {
      this.resetAssignedJobs({ search: filters.search });
    } else {
      const previousFilters = this.retrieveAssignedJobsStoredFilters();

      this.resetAssignedJobs({
        ...previousFilters,
        ...newFilters,
        search: '',
      });
    }

    this.persistAssignedJobsFilters(newFilters);
  }

  resetAssignedJobs(assignedJobsFilters: JobsFilters = {}) {
    runInAction(() => {
      this.assignedJobs = [];
      this.isLoadingAssignedJobs = false;
      this.assignedJobsPagination = DEFAULT_PAGINATION_STATE;
      this.assignedJobsFilters = assignedJobsFilters;
      this.jobAssignments = {};
    });
  }

  setAssigneesFilters(filters: Partial<AssigneesFilters>) {
    runInAction(() => {
      this.assigneesFilters = { ...this.assigneesFilters, ...filters };
    });
  }

  setFilteredAssignees(assignees: (DriverBasic | AccountTypeahead)[]) {
    runInAction(() => {
      this.filteredAssignees = assignees;
    });
  }

  clearAssignees() {
    runInAction(() => {
      this.assignees = [];
      this.assigneesPagination = DEFAULT_PAGINATION_STATE;
    });
  }

  clearUnassignedJobs() {
    runInAction(() => {
      this.unassignedJobs = [];
      this.unassignedJobsPagination = DEFAULT_PAGINATION_STATE;
    });
  }

  setDateFilters(filters: DateFilters) {
    runInAction(() => {
      this.dateFilters = filters;
      this.resetAssignedJobs(this.assignedJobsFilters);
    });
  }

  setDraggedJob(job: Nullable<Job>) {
    runInAction(() => {
      this.draggedJob = job;
    });
  }
}

export default DriverSchedulerStore;
