import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import DateRangePicker, {
  DateRangePickerDateSelectFunction,
} from '~components/Filters/DateRangePicker';
import { useStores } from '~store';

const LiveMapDateFilter = observer(() => {
  const { liveMapStoreNew } = useStores();

  const initialStartDate = liveMapStoreNew.filters.startDate
    ? dayjs(liveMapStoreNew.filters.startDate)
    : null;
  const initialEndDate = liveMapStoreNew.filters.endDate
    ? dayjs(liveMapStoreNew.filters.endDate)
    : null;

  const [dateRange, setDateRange] = useState({
    start: initialStartDate,
    end: initialEndDate,
  });

  const handleDateFilterChange = useCallback<DateRangePickerDateSelectFunction>(
    (startDate, endDate) => {
      const start = startDate;
      const end = endDate ?? startDate;

      setDateRange({ start, end });
    },
    [],
  );

  const handleDateFilterClose = useCallback(() => {
    const startDate = dateRange.start?.format('YYYY-MM-DD');
    const endDate = dateRange.end?.format('YYYY-MM-DD');

    const startDateDidChange = liveMapStoreNew.filters.startDate !== startDate;
    const endDateDidChange = liveMapStoreNew.filters.endDate !== endDate;

    if (startDateDidChange || endDateDidChange) {
      liveMapStoreNew.setFilters({ startDate, endDate }, true);
    }
  }, [
    dateRange.start,
    dateRange.end,
    liveMapStoreNew.filters.startDate,
    liveMapStoreNew.filters.endDate,
  ]);

  return (
    <DateRangePicker
      allowAllDates
      endDate={dateRange.end}
      onAnchorClose={handleDateFilterClose}
      onDateSelect={handleDateFilterChange}
      showShortcuts
      startDate={dateRange.start}
      variant="single_date"
    />
  );
});

export default LiveMapDateFilter;
