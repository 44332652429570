import Add from '@mui/icons-material/Add';
import Check from '@mui/icons-material/Check';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import ToggleOffOutlined from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnOutlined from '@mui/icons-material/ToggleOnOutlined';
import WarningAmber from '@mui/icons-material/WarningAmber';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-premium';
import { ModelError_Item, ModelError_Response } from '@treadinc/horizon-api-spec';
import { AxiosError } from 'axios';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog } from '~components/Dialog/ModalDialog';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { data as enums } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { Site, useSites } from '~hooks/useSites';
import { SiteForm } from '~pages/Settings/Administration/Sites/SiteForm';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';
import { isActionClicked } from '~utils/utilFunctions';

import { useSiteFormV2 } from './site-form-v2/hooks';
import { SiteFormV2 } from './site-form-v2/SiteFormV2';

const SitesManagement = observer(() => {
  const theme = useTheme();

  const { companyAssetsStore, toasterStore, userStore } = useStores();
  const {
    isLoading: isLoadingSites,
    isUpdating,
    getCompanySites,
    createNewSite,
    updateSite,
    removeSite,
    toggleSiteStatus,
  } = useSites();
  const { createNewSite: createNewSiteV2, updateSite: updateSiteV2 } = useSiteFormV2();
  const { searchValue, setSearchQueryValue } = useDataGridSearch();
  const isLoading = isLoadingSites;
  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [asyncErrors, setAsyncErrors] = useState<ModelError_Item[]>([]);
  const selectedCompany = userStore.currentCompanies[0] || userStore.userCompany;
  const selectedCompanyID = selectedCompany?.id;
  const isGoogleMapPrimary = useFeatureFlag({
    featureFlagKey: FeatureFlags.googleMapsPrimary,
  });

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    if (selectedCompanyID) {
      getCompanySites({ companyId: selectedCompanyID });
    }
  }, [selectedCompanyID]);

  const handleErrors = (error: AxiosError<ModelError_Response>) => {
    setAsyncErrors(error.response?.data.error.errors || []);
  };

  const modalDialogRef = useRef<any>(null);
  const updateDialogRef = useRef<any>(null);
  const onChangeQuickFilter = (searchQuery: string) => {
    setSearchQueryValue(searchQuery);
    getCompanySites({ companyId: selectedCompanyID, searchQuery });
  };
  const handleClose = () => {
    setDialogOpen(false);
    setAsyncErrors([]);
  };
  const editRow = (id: string) => {
    setSelectedId(id);
    setDialogOpen(true);
  };

  const createNew = () => {
    setSelectedId(null);
    setDialogOpen(true);
  };

  const deleteRow = (id: string) => {
    setSelectedId(id);
    modalDialogRef.current?.open();
  };

  const deleteCallback = () => {
    if (selectedSite?.isActive) {
      // Just close
      modalDialogRef.current?.close();
    } else if (selectedId) {
      // Remove
      removeSite(selectedId).then(() => {
        modalDialogRef.current?.close();
        setSelectedId(null);
        toasterStore.push(
          alert($t('administration.site.site_deleted'), AlertTypes.success),
        );
      });
    }
  };

  const onSuccess = (site?: Site) => {
    handleClose();
    setSelectedId(null);

    const name = `${site?.name}`;
    toasterStore.push(
      alert(
        selectedId
          ? $t('administration.site.site_updated', { name })
          : $t('administration.site.site_created', { name }),
        AlertTypes.success,
      ),
    );
  };

  const toggleSite = (id: string, isActive: boolean) => {
    toggleSiteStatus({ id, isActive }).then(() => {
      toasterStore.push(
        alert($t('administration.site.site_status_updated'), AlertTypes.success),
      );
    });
  };

  const onSubmitCallback = (data: any) => {
    if (isGoogleMapPrimary) {
      if (selectedId) {
        updateSiteV2({ id: selectedId, ...data })
          .then(onSuccess)
          .catch(handleErrors);
      } else {
        createNewSiteV2(data).then(onSuccess).catch(handleErrors);
      }
    } else {
      if (selectedId) {
        updateSite({ id: selectedId, ...data })
          .then(onSuccess)
          .catch(handleErrors);
      } else {
        createNewSite(data).then(onSuccess).catch(handleErrors);
      }
    }
  };

  const onSubmitForm = () => {
    updateDialogRef.current?.submit(onSubmitCallback);
  };

  const onFormStateChange = ({ isDirty, isValid }: FormStateChangeProps) => {
    setIsDirty(isDirty);
    setIsValid(!!isValid);
  };

  const getSitesByCompany = (link?: PaginationLink, searchQuery?: string) => {
    getCompanySites({
      companyId: selectedCompanyID,
      link: link || ({} as PaginationLink),
      searchQuery: searchQuery,
    });
  };

  const rows = useMemo<Site[]>(
    () => companyAssetsStore.sites,
    [companyAssetsStore.sites],
  );

  const selectedSite = useMemo(() => {
    return companyAssetsStore.sites.find((site) => site.id === selectedId);
  }, [selectedId, rows]);

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: $t('form_fields.name'),
          flex: 1,
        },
        {
          field: 'externalId',
          headerName: $t('form_fields.id'),
          flex: 1,
          valueGetter: (params) => {
            return params.row?.externalId || '';
          },
        },
        {
          field: 'fullAddress',
          headerName: $t('form_fields.address'),
          flex: 1,
          valueGetter: (params) => {
            return params.row?.address?.thoroughfare || '';
          },
        },
        {
          field: 'latLong',
          headerName: $t('form_fields.lat_lon_column_heading'),
          flex: 1,
          valueGetter: (params) => {
            return params?.row?.latLon || '';
          },
        },
        {
          field: 'companyName',
          headerName: $t('form_fields.company'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.companyName))],
          flex: 1,
        },
        {
          field: 'siteType',
          headerName: $t('form_fields.site_type'),
          type: 'singleSelect',
          valueOptions: enums.site_type.values,
          flex: 1,
        },
        {
          field: 'isActive',
          headerName: $t('form_fields.status'),
          type: 'singleSelect',
          valueOptions: [
            { value: true, label: $t('common.active') },
            { value: false, label: $t('common.inactive') },
          ],
          renderCell: (params) => {
            const { value } = params;
            return (
              <Chip
                color={value ? 'success' : 'default'}
                label={value ? $t('common.active') : $t('common.inactive')}
                variant={'outlined'}
              />
            );
          },
          flex: 1,
        },
        {
          field: 'geofenceType',
          headerName: $t('form_fields.geo_fence_type'),
          flex: 1,
          renderCell: (params) => {
            const nextBillionGeofence = params.row?.nextBillionGeofence;
            const nextBillionGeofenceId = nextBillionGeofence?.nbId;
            const isMovingGeofence = nextBillionGeofence?.movingGeofence;

            const nextBillionGeofenceType = isMovingGeofence
              ? 'Equipment'
              : nextBillionGeofence?.type;
            const hasDefinedGeofence =
              nextBillionGeofenceId &&
              (nextBillionGeofence?.geojson || nextBillionGeofence?.circleCenter);
            if (nextBillionGeofence) {
              return (
                <Box display={'flex'} sx={{ alignItems: 'center' }} gap={1}>
                  <Typography variant={'body1'} sx={{ textTransform: 'capitalize' }}>
                    {nextBillionGeofenceType}
                    {hasDefinedGeofence && ':'}
                  </Typography>

                  {hasDefinedGeofence && (
                    <BasicTooltip title={`NextBillion ID: ${nextBillionGeofenceId}`}>
                      <Check color="success" />
                    </BasicTooltip>
                  )}
                </Box>
              );
            }
          },
        },
        {
          field: 'notes',
          headerName: $t('form_fields.notes'),
          flex: 1,
        },
        {
          field: 'alerts',
          headerName: $t('actions.alerts'),
          sortable: false,
          filterable: true,
          disableColumnMenu: true,
          hideable: false,
          display: 'flex',
          headerAlign: 'center',
          align: 'center',
          renderCell: (params) => {
            return (
              !params.row.routable && (
                <Box>
                  <BasicTooltip
                    title={t('administration.site.address_confirmation_required')}
                  >
                    <WarningAmber color="primary" />
                  </BasicTooltip>
                </Box>
              )
            );
          },
        },
        {
          field: 'extra.actions',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={companyAssetsStore.sites?.length || 0}
              loading={loadingRef.current}
              pagination={companyAssetsStore.sitesPagination}
              callback={getSitesByCompany}
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params) => {
            const { id, isActive } = params.row;

            const options = [];
            if (userPermissions.canDeleteSite) {
              options.push({
                title: t('actions.delete'),
                icon: <Delete />,
                callBack: () => deleteRow(id),
              });
            }

            if (userPermissions.canEditSite) {
              options.push({
                title: `${isActive ? t('actions.deactivate') : t('actions.activate')}`,
                icon: isActive ? (
                  <ToggleOffOutlined />
                ) : (
                  <ToggleOnOutlined color={'primary'} />
                ),
                callBack: () => toggleSite(id, isActive),
              });
            }

            return (
              <>
                {userPermissions.canEditSite && (
                  <IconButton size="small" onClick={() => editRow(id)}>
                    <Edit />
                  </IconButton>
                )}
                <SimpleMenu options={options}>
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows, userPermissions],
  );

  const initialDataGridState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          notes: false,
        },
      },
    }),
    [],
  );

  return (
    <>
      <Box>
        <DataGrid
          id="company-assets-sites"
          columns={columns}
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoading}
          initialState={initialDataGridState}
          onChangeFilter={onChangeQuickFilter}
          headerActionsComponent={
            <>
              {userPermissions.canCreateSite && (
                <Box display={'flex'}>
                  <Button
                    size="medium"
                    color={'primary'}
                    onClick={createNew}
                    startIcon={<Add />}
                  >
                    {t('administration.site.add_new')}
                  </Button>
                </Box>
              )}
            </>
          }
        />
      </Box>

      <Dialog
        fullWidth
        maxWidth="xl"
        open={isDialogOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={t(`administration.site.${selectedId ? 'update_site' : 'add_new'}`)}
        />
        <DialogContent>
          {isGoogleMapPrimary ? (
            <SiteFormV2
              ref={updateDialogRef}
              defaultSite={selectedSite}
              onFormStateChange={onFormStateChange}
              errors={asyncErrors}
              company={selectedCompany}
            />
          ) : (
            <SiteForm
              ref={updateDialogRef}
              defaultSite={selectedSite}
              onFormStateChange={onFormStateChange}
              errors={asyncErrors}
              company={selectedCompany}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <LoadingButton
            disabled={isUpdating || !isDirty || !isValid}
            loading={isUpdating}
            loadingPosition="start"
            startIcon={<></>}
            onClick={onSubmitForm}
            type="button"
            variant="contained"
            color="primary"
            sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
          >
            {$t(`actions.${selectedId ? 'submit' : 'create'}`)}
          </LoadingButton>
          <Button
            onClick={handleClose}
            sx={{ mr: 2, px: 2 }}
            disabled={isUpdating}
            color="secondary"
            variant="outlined"
          >
            {$t('actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <ModalDialog
        ref={modalDialogRef}
        loading={isUpdating}
        title={$t('administration.site.delete_title')}
        content={$t(
          `administration.site.${
            selectedSite?.isActive ? 'unable_delete_active' : 'delete_description'
          }`,
        )}
        confirmButtonText={`${$t(
          `actions.${selectedSite?.isActive ? 'ok' : 'confirm'}`,
        )}`}
        confirmButtonColor={selectedSite?.isActive ? 'primary' : 'error'}
        callBack={deleteCallback}
      />
    </>
  );
});

export { SitesManagement };
