import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { t } from 'i18next';

import { AssignJobToView } from '~components/Job/AssignJobToView/AssignJobToView';
import {
  OverflowAwareText,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { Account } from '~hooks/useAccount';
import { Job } from '~hooks/useJob';
import { User } from '~hooks/useUsers';

import { canAcceptJob } from '../utils';

interface JobAssigneeHandlerProps {
  canEditJob?: boolean;
  job: Job;
  filterDate?: string;
  onAssigneeChange: (job: Job) => void;
}

export default function JobAssigneeHandler({
  canEditJob,
  job,
  onAssigneeChange,
  filterDate,
}: JobAssigneeHandlerProps) {
  const {
    id,
    editable,
    driver,
    status,
    customerJobAssignment,
    vendorJobAssignment,
    stateEvents,
  } = job;

  const customerCompanyId = customerJobAssignment?.customerAccount?.accountBasicCompanyId;

  const vendorName = vendorJobAssignment?.vendorAccount?.name;
  const driverName = driver?.fullName;
  const hasVendorOrDriver = Boolean(vendorName || driverName);

  const isAcceptable = canAcceptJob(job);
  const isEditable = Boolean(editable && hasVendorOrDriver);
  const isUnassignable = stateEvents.includes('unassign');

  if (isAcceptable) {
    return null;
  }

  return (
    <AssignJobToView
      companyId={String(customerCompanyId)}
      filterDate={filterDate}
      driver={(driver as unknown as User) ?? undefined}
      editable={isEditable}
      isUnassignable={isUnassignable}
      jobId={id}
      onSuccess={onAssigneeChange}
      status={status}
      valueToDisplay={driverName ? 'driver' : 'vendor'}
      vendor={(vendorJobAssignment?.vendorAccount as Account) ?? undefined}
      customAssignCTA={
        <SmallButton
          color="brandV2Orange"
          variant="text"
          sx={{
            '&.MuiButtonBase-root': {
              borderStyle: 'dashed',
              borderWidth: '1px',
              '&:not(:hover)': { backgroundColor: 'white' },
            },
          }}
        >
          <OverflowAwareText>{t('actions.assign')}</OverflowAwareText>
        </SmallButton>
      }
      customUnassignCTA={
        isUnassignable ? (
          <IconButton size="small" sx={{ width: '20px', height: '20px' }}>
            <Close sx={{ width: '12px', height: '12px' }} />
          </IconButton>
        ) : undefined
      }
    />
  );
}
