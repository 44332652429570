import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { t as $t } from 'i18next';
import React, { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormStateChangeProps } from '~formsShared';
import { Account } from '~hooks/useAccount';
import { useEquipment } from '~hooks/useEquipment';
import { useProjects } from '~hooks/useProjects';
import { Rate } from '~hooks/useRates';
import { useServices } from '~hooks/useServices';
import {
  copyRateValidationSchema,
  setDefaultRateValuesForCopy,
} from '~pages/Settings/RatesManagement/rateFormValidationSchema';
import { useStores } from '~store';

interface RateCopyToFormProps {
  accountOptions: Account[];
  rate: Rate;
  onCopyFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
}
const RateCopyToForm = forwardRef(function RateCopyToForm(
  { accountOptions, onCopyFormStateChange, rate }: RateCopyToFormProps,
  ref: Ref<any>,
) {
  const { getCompanyProjects, isLoadingProjects } = useProjects();
  const { getEquipmentByCompanyId, isLoading: isLoadingEquipment } = useEquipment();
  const { getAllServices, isLoading: isServicesLoading } = useServices();
  const { companyAssetsStore, projectsStore, userStore } = useStores();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(copyRateValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: setDefaultRateValuesForCopy(rate),
  });
  useImperativeHandle(ref, () => ({
    submit(callBack: () => void) {
      handleSubmit(callBack)();
    },
  }));
  useEffect(() => {
    onCopyFormStateChange({ isValid, isDirty });
  }, [isValid, isDirty]);
  const companyId = userStore.userCompany?.id;

  useEffect(() => {
    getCompanyProjects();
    if (companyId) {
      getEquipmentByCompanyId({ companyId });
    }
    getAllServices();
  }, [companyId]);
  const equipmentOptions = companyAssetsStore.equipment;
  const projectsOptions = projectsStore.projects;
  const servicesOptions = companyAssetsStore.services;
  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} sx={{ mb: 1 }}>
      <TextFormField
        control={control}
        errors={errors}
        name="name"
        sx={{ width: '100%' }}
        label={`${$t('form_fields.name')}`}
        isRequired={true}
      />
      <Box display={'flex'} flexDirection={'row'} width={'100%'} sx={{ mb: 1 }}>
        <AutocompleteFormField
          control={control}
          name="account"
          loading={isLoadingProjects}
          errors={errors}
          list={accountOptions}
          label={`${$t('form_fields.account')}`}
          isRequired={true}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
        />
      </Box>
      <Box display={'flex'} flexDirection={'row'} width={'100%'} sx={{ mb: 1 }}>
        <AutocompleteFormField
          control={control}
          name="projects"
          loading={isLoadingProjects}
          errors={errors}
          groupBy={(item) => item?.type}
          list={projectsOptions}
          multiple={true}
          isCheckEnabled={true}
          label={`${$t('form_fields.project')}`}
          isRequired={false}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
        />
      </Box>
      <Box display={'flex'} flexDirection={'row'} width={'100%'} sx={{ mb: 1 }}>
        <AutocompleteFormField
          control={control}
          name="equipment"
          multiple={true}
          isCheckEnabled={true}
          errors={errors}
          loading={isLoadingEquipment}
          groupBy={(item) => item?.typeName}
          list={equipmentOptions}
          label={`${$t('form_fields.equipment_type')}`}
          isRequired={false}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
        />
      </Box>
      <Box display={'flex'} flexDirection={'row'} width={'100%'} sx={{ mb: 1 }}>
        <AutocompleteFormField
          control={control}
          name="services"
          multiple={true}
          isCheckEnabled={true}
          loading={isServicesLoading}
          groupBy={(item) => item?.type}
          errors={errors}
          list={servicesOptions}
          label={`${$t('form_fields.service')}`}
          isRequired={false}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
        />
      </Box>
    </Box>
  );
});
export { RateCopyToForm };
