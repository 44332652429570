import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';

import { Order } from '~hooks/useOrders';
import { formatNumber } from '~pages/Dispatch/components/OrderSummary';
import theme from '~theme/AppTheme';

const minutesToHoursAndMinutesString = (value: number) => {
  const minutesToHours = Math.floor(value / 60);
  const minutesLeft = Math.floor(value % 60);

  const minutesToHoursAsString = minutesToHours > 0 ? `${minutesToHours}hr ` : '';
  const minutesLeftAsString = `${minutesLeft}min`;

  return `${minutesToHoursAsString}${minutesLeftAsString}`;
};

export const makeCycleAvgCellString = (order: Order) => {
  return minutesToHoursAndMinutesString(order.loadCycleAvg);
};

export const makeLoadsPickupAvgCellString = (order: Order) => {
  return minutesToHoursAndMinutesString(order.loadsPickupTimeMinutesAvg);
};

const OrderSummaryRow = ({ order }: { order: Order }) => {
  const { material, orderSummary } = order;
  const deliveredQuantity =
    orderSummary?.deliveredQuantities.find(
      (deliveredQuantity) => deliveredQuantity.materialName === material?.name,
    ) || orderSummary?.deliveredQuantities[0];

  return (
    <Box
      sx={{
        borderRadius: theme.brandV2.borderRadius,
        border: `1px solid ${theme.brandV2.colors.treadGray7}`,
        overflow: 'hidden',
      }}
    >
      <Table
        sx={{
          backgroundColor: theme.brandV2.colors.treadGray8,
        }}
      >
        <TableRow>
          <OrderSummaryAttribute
            label={t('live_map.active_trucks')}
            text={`${orderSummary?.acceptedJobsCount ?? 0} / ${orderSummary?.jobsCount ?? 0}`}
          />
          <OrderSummaryAttribute
            label={_.capitalize(`${t('common.delivered')}`)}
            text={`${formatNumber(deliveredQuantity?.delivered ?? 0)} / ${formatNumber(deliveredQuantity?.total ?? 0)}`}
          />
          <OrderSummaryAttribute
            label={t('live_map.pickup_avg')}
            text={makeLoadsPickupAvgCellString(order)}
          />
          <OrderSummaryAttribute
            label={t('live_map.cycle_avg')}
            text={makeCycleAvgCellString(order)}
          />
        </TableRow>
      </Table>
    </Box>
  );
};

interface OrderAttributeProps {
  label: string;
  text: string;
}

const OrderSummaryAttribute = ({ label, text }: OrderAttributeProps) => {
  return (
    <TableCell
      align="center"
      padding="none"
      sx={{
        paddingY: 1,
        borderRight: `1px solid ${theme.brandV2.colors.treadGray7}40`,
        borderBottom: 'none',
        '&:last-child': {
          borderRight: 'none',
        },
      }}
      width={'25%'}
    >
      <Typography fontSize="12px" fontWeight={600}>
        {text}
      </Typography>
      <Typography
        fontSize="12px"
        fontWeight={600}
        color={theme.brandV2.colors.treadGray4}
      >
        {label}
      </Typography>
    </TableCell>
  );
};

export default OrderSummaryRow;
