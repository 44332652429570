import Box from '@mui/material/Box';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { styled } from '@mui/system';
import { Control, Controller } from 'react-hook-form';

import theme from '~theme/AppTheme';

const CONTROLS_SIZE_IN_PX = 30;

interface CounterFormFieldProps {
  control: Control;
  max?: number;
  min?: number;
  name: string;
}

export default function CounterFormField({
  control,
  max = 99999,
  min = -99999,
  name,
}: CounterFormFieldProps) {
  const shouldAllowNegatives = min < 0;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const updateValue = (val: string) => {
          let parsedValue = parseInt(val, 10);
          const isValid = Number.isFinite(parsedValue);

          if (!isValid) {
            parsedValue = 0;
          } else if (parsedValue < min) {
            parsedValue = min;
          } else if (parsedValue > max) {
            parsedValue = max;
          }

          onChange(parsedValue);
        };

        return (
          <Box sx={{ width: '100%', display: 'inline-flex' }}>
            <CounterButton isLeft onClick={() => updateValue(String((value ?? min) - 1))}>
              -
            </CounterButton>
            <CounterInput
              value={value}
              onKeyDown={(e) => {
                if (e.code === 'ArrowUp') {
                  updateValue(String((value ?? 0) + 1));
                } else if (e.code === 'ArrowDown') {
                  updateValue(String((value ?? 0) - 1));
                }
              }}
              onChange={(e) => {
                if (shouldAllowNegatives && e.target.value === '-') {
                  onChange(e.target.value);
                } else {
                  updateValue(e.target.value);
                }
              }}
            />
            <CounterButton onClick={() => updateValue(String((value ?? max) + 1))}>
              +
            </CounterButton>
          </Box>
        );
      }}
    />
  );
}

type CounterButtonProps = ButtonBaseProps & { isLeft?: boolean };

const CounterButton = styled(ButtonBase, {
  shouldForwardProp: (propName) => propName !== 'isLeft',
})<CounterButtonProps>(({ isLeft }) => {
  const borderSize = 4;

  return {
    backgroundColor: 'white',
    border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
    borderBottomLeftRadius: isLeft ? borderSize : 0,
    borderBottomRightRadius: isLeft ? 0 : borderSize,
    borderTopLeftRadius: isLeft ? borderSize : 0,
    borderTopRightRadius: isLeft ? 0 : borderSize,
    color: theme.brandV2.colors.treadBlack,
    flex: 0,
    height: CONTROLS_SIZE_IN_PX,
    minWidth: CONTROLS_SIZE_IN_PX,
  };
});

type CounterInputProps = InputBaseProps;

const CounterInput = styled(InputBase)<CounterInputProps>(() => {
  return {
    backgroundColor: 'white',
    borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
    borderTop: `solid 1px ${theme.brandV2.colors.treadGray7}`,
    color: theme.brandV2.colors.treadBlack,
    flex: 1,
    fontSize: '12px',
    height: CONTROLS_SIZE_IN_PX,
    padding: theme.spacing(),
    input: { textAlign: 'center' },
  };
});
