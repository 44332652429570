import { AddOnRateType, AddOnType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import * as yup from 'yup';

import { nameIdSchemaRequired } from '~constants/regexConst';
import { AddOn } from '~hooks/useAddOns';

export const addOnRateTypeOptions = [
  {
    id: AddOnRateType.RATE_FOR_EACH,
    name: t('administration.rates.add_ons.rate_for_each'),
  },
  {
    id: AddOnRateType.RATE_PERCENT_OF_TOTAL,
    name: t('administration.rates.add_ons.rate_percent_of_total'),
  },
];

export const addOnTypeOptions = [
  {
    id: AddOnType.DRIVER_PAY,
    name: t('administration.rates.add_ons.add_on_type_driver_pay'),
  },
  {
    id: AddOnType.INVOICING,
    name: t('administration.rates.add_ons.add_on_type_invoicing'),
  },
  {
    id: AddOnType.JOB,
    name: t('administration.rates.add_ons.add_on_type_job'),
  },
  {
    id: AddOnType.SETTLEMENT,
    name: t('administration.rates.add_ons.add_on_type_settlement'),
  },
  {
    id: AddOnType.DRIVER_DAY,
    name: t('administration.rates.add_ons.add_on_type_driver_day'),
  },
];

export const addOnSchema = yup.object().shape({
  id: yup.string().uuid().notRequired(),
  name: yup
    .string()
    .trim()
    .min(1, `${t('form_validation_errors.required', { field: t('common.description') })}`)
    .required(),
  addOnRateType: nameIdSchemaRequired.required(
    `${t('form_validation_errors.required', { field: t('form_fields.rate_type') })}`,
  ),
  rate: yup
    .number()
    .transform((val) => (Number.isNaN(val) ? null : Number(val)))
    .notRequired()
    .when('addOnRateType', ([addOnRateType], schema) => {
      if (!addOnRateType) {
        return schema;
      }

      const type = addOnRateType as (typeof addOnRateTypeOptions)[number];

      if (type.id === AddOnRateType.RATE_PERCENT_OF_TOTAL) {
        const message = t('form_validation_errors.must_be_between_min_and_max', {
          field: t('administration.rates.default_rate'),
          min: -100,
          max: 100,
        });

        return schema.min(-100, message).max(100, message);
      }

      return schema;
    }),
  quantity: yup
    .number()
    .transform((val) => (Number.isNaN(val) ? null : Number(val)))
    .notRequired(),
  addOnTypes: yup
    .array()
    .of(nameIdSchemaRequired)
    .min(
      1,
      `${t('form_validation_errors.min_count_one', { field: t('administration.rates.add_ons.add_on_types'), count: 1 })}`,
    )
    .required(),
  externalId: yup.string().trim(),
});

export const getAddOnFormDefaultValues = (addOn?: AddOn) => {
  const id = addOn?.id;
  const name = addOn?.name ?? '';
  const addOnRateType = addOn?.addOnRateType
    ? addOnRateTypeOptions.find(({ id }) => id === addOn.addOnRateType)
    : null;
  const rate =
    addOn?.addOnRateType === AddOnRateType.RATE_FOR_EACH
      ? addOn?.rate ?? ''
      : addOn?.percentage ?? '';
  const quantity = addOn?.quantity ?? '';
  const addOnTypes = addOn?.addOnTypes
    ? addOnTypeOptions.filter(({ id }) => addOn.addOnTypes.includes(id))
    : [];
  const externalId = addOn?.externalId ?? '';

  return {
    id,
    name,
    addOnRateType,
    rate,
    quantity,
    addOnTypes,
    externalId,
  } as unknown as AddOnDTO;
};

export type AddOnDTO = yup.InferType<typeof addOnSchema>;
