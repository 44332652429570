import { useDraggable } from '@dnd-kit/core';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';

import JobStatusChip from '~components/Job/JobStatusChip';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { dateFormat } from '~utils/dateTime';

import { JOB_WIDTH } from './constants';
import JobCardActionButton from './JobCardActionButton';

export const JOB_CARD_INNER_GUTTER_SIZE = 1;

export enum JobPlacement {
  DRAGGING = 'dragging',
  JOBS_COLUMN = 'jobs_column',
  DRIVERS_GRID = 'drivers_grid',
}

const getBackgroundColor = (jobPlacement: JobPlacement | undefined) => {
  switch (jobPlacement) {
    case JobPlacement.DRAGGING:
      return theme.brandV2.colors.treadGray8;
    case JobPlacement.JOBS_COLUMN:
      return 'common.white';
    default:
      return theme.brandV2.colors.treadGray10;
  }
};

interface DraggableJobProps {
  jobId: string;
  style?: CSSProperties;
  jobPlacement?: JobPlacement;
  columnSpan?: number;
}

export const JobCard = observer(
  ({ jobId, style, jobPlacement, columnSpan = 1 }: DraggableJobProps) => {
    const theme = useTheme();
    const { driverSchedulerStore } = useStores();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const job =
      driverSchedulerStore.unassignedJobs.find((job) => job.id === jobId) ||
      driverSchedulerStore.assignedJobs.find((job) => job.id === jobId) ||
      driverSchedulerStore.draggedJob;
    const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
      id: jobId,
      disabled: isMenuOpen,
    });

    if (!job) return null;

    const {
      customerJobAssignment,
      dropOffWayPoints,
      jobStartAt,
      loadsCount,
      material,
      pickUpWayPoints,
      quantity,
      unitOfMeasure,
      jobId: treadJobId,
      order,
    } = job;

    // The site name can be a full address, and in that case, we only want to grab the street name
    const pickUpSiteName = pickUpWayPoints?.[0]?.site?.name.split(',')[0];
    const dropOffSiteName = dropOffWayPoints?.[0]?.site?.name.split(',')[0];

    const jobQuantity =
      quantity && unitOfMeasure?.name ? `${quantity} ${unitOfMeasure.name}` : '';
    const customerName = customerJobAssignment?.customerAccount?.name;
    const jobStartTime = jobStartAt ? dateFormat(jobStartAt, 'hh:mm A') : '';
    const materialName = material?.name;
    const dispatchNumber = order?.dispatchNumber;

    const draggableStyle: CSSProperties = {
      ...style,
      width: `${JOB_WIDTH * columnSpan}px`,
      zIndex: 1,
      ...(jobPlacement === JobPlacement.JOBS_COLUMN && {
        left: '4px',
      }),
      ...(transform && {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }),
    };

    if (isDragging && jobPlacement === JobPlacement.DRIVERS_GRID) {
      return null;
    }

    return (
      <Box
        ref={setNodeRef}
        style={draggableStyle}
        {...listeners}
        {...attributes}
        aria-label={'job-card'}
      >
        <Box
          sx={{
            p: JOB_CARD_INNER_GUTTER_SIZE,
            borderRadius: '12px',
            background: getBackgroundColor(jobPlacement),
            boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.20)',
            position: 'relative',
            transition: 'background 0.2s',
            ...(jobPlacement === JobPlacement.DRAGGING && {
              boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
            }),
            ...(jobPlacement === JobPlacement.JOBS_COLUMN && {
              border: `1px solid ${theme.brandV2.colors.treadGray7}`,
            }),
            ...(jobPlacement === JobPlacement.DRIVERS_GRID && {
              pt: 0.75,
              pb: 0.75,
              borderRadius: '0 12px 12px 0',
              borderLeft: `4px solid ${theme.brandV2.colors.treadGray4}`,
              boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.30)',
            }),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1.5,
            }}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '130px' }}
              gap={0.3}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography
                  variant="body2"
                  component="span"
                  noWrap
                  sx={{
                    fontWeight: 600,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: theme.brandV2.colors.treadBlack,
                  }}
                >
                  {pickUpSiteName}
                </Typography>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: theme.brandV2.colors.treadGray3 }}
                >
                  &nbsp;{t('common.to')}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                fontWeight={600}
                noWrap
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: theme.brandV2.colors.treadBlack,
                }}
              >
                {dropOffSiteName}
              </Typography>
            </Box>

            <JobStatusChip jobState={job.status} hideDropdownArrow />
          </Box>

          <Box sx={{ display: 'flex', width: '200px', overflow: 'hidden', mt: 0.5 }}>
            <Typography
              variant="body2"
              sx={{ color: theme.brandV2.colors.treadGray2, flexShrink: 0 }}
            >
              {jobQuantity}
            </Typography>
            {jobQuantity && (
              <Typography variant={'body2'} sx={{ ml: 0.5, mr: 0.5, flexShrink: 0 }}>
                •
              </Typography>
            )}
            <Typography
              variant="body2"
              sx={{ color: theme.brandV2.colors.treadGray2, flexShrink: 0 }}
            >
              {loadsCount} {t('dispatch.drivers.loads')}
            </Typography>
            {customerName && (
              <Typography variant={'body2'} sx={{ ml: 0.5, mr: 0.5, flexShrink: 0 }}>
                •
              </Typography>
            )}
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: 0,
                flexGrow: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: theme.brandV2.colors.treadGray2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {customerName}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              maxWidth: '200px',
              overflow: 'hidden',
              mt: 0.5,
            }}
          >
            {dispatchNumber && (
              <>
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    minWidth: 0,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.brandV2.colors.treadGray2,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {dispatchNumber}
                  </Typography>
                </Box>

                <Typography variant="body2" sx={{ ml: 0.5, mr: 0.5, flexShrink: 0 }}>
                  •
                </Typography>
              </>
            )}

            <Typography
              variant="body2"
              sx={{ color: theme.brandV2.colors.treadGray2, flexShrink: 0 }}
            >
              {treadJobId}
            </Typography>
          </Box>

          <Box display={'flex'} sx={{ width: '200px', overflow: 'hidden', mt: 0.5 }}>
            <Typography
              variant="body2"
              sx={{ color: theme.brandV2.colors.treadGray2, flexShrink: 0 }}
            >
              {jobStartTime}
            </Typography>
            {materialName && (
              <Typography variant={'body2'} sx={{ ml: 0.5, mr: 0.5, flexShrink: 0 }}>
                •
              </Typography>
            )}
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: 0,
                flexGrow: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: theme.brandV2.colors.treadGray2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {materialName}
              </Typography>
            </Box>
          </Box>

          <JobCardActionButton job={job} onMenuOpenedStateChange={setIsMenuOpen} />
        </Box>
      </Box>
    );
  },
);
