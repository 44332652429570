import { t } from 'i18next';
import * as yup from 'yup';

import { nameIdNonRequiredSchema } from '~constants/regexConst';

export type EquipmentTypeValidation = yup.InferType<
  typeof equipmentTypeFormValidationSchema
>;

export const equipmentTypeFormValidationSchema = yup.object().shape({
  company: yup
    .object()
    .typeError(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    )
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    ),
  name: yup
    .string()
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.first_name') })}`,
    ),
  externalId: yup.string().notRequired(),
  grossCapacity: yup
    .number()
    .nullable()
    .min(
      0.01,
      `${t('form_validation_errors.min', {
        field: t('form_fields.gross_capacity'),
        min: 0.01,
      })}`,
    )
    .typeError(
      `${t('form_validation_errors.should_be_number', {
        field: t('form_fields.gross_capacity'),
      })}`,
    )
    .transform((_, val) => (val ? Number(val) : null)),
  grossCapacityUnitOfMeasure: nameIdNonRequiredSchema.nullable(),
  id: yup.string(),
});
