import Check from '@mui/icons-material/Check';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FilterList from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { InvoiceCategoryFilter } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AppliedFiltersChips, {
  AppliedFiltersChipsCollectedData,
} from '~components/Filters/AppliedFiltersChips';
import FilterMenuItem, {
  FilterMenuItemLoadingReason,
  FilterMenuOption,
} from '~components/Filters/FilterMenuItem';
import useFetchers, { FetchFunction } from '~components/Filters/useFetchers';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import {
  payableJobsOptions,
  rateTypeOptions,
  receivableJobsOptions,
} from '~constants/enums';
import { TypeaheadedFilterState } from '~hooks/useLiveMap/useLiveMapFilters';
import { NextPageCursorsState } from '~pages/LiveMap/SideBarComponents/LiveMapFilters';
import { useStores } from '~store';

import { payableInvoiceCategories, receivableInvoiceCategories } from './DriverPay';

enum SelectedFilter {
  PROJECTS = 'projects',
  DRIVERTYPE = 'drivers',
  CUSTOMER = 'customers',
  DROPOFF = 'dropOffSites',
  VENDORS = 'vendors',
  PICKUP = 'pickUpSites',
  RATES = 'rates',
}

const driverPayFilterKeyBySelectedFilter: Record<SelectedFilter, string> = {
  [SelectedFilter.PROJECTS]: 'project_ids',
  [SelectedFilter.RATES]: 'rate_types',
  [SelectedFilter.DRIVERTYPE]: 'category',
  [SelectedFilter.CUSTOMER]: 'customer_account_ids',
  [SelectedFilter.DROPOFF]: 'dropoff_site_ids',
  [SelectedFilter.VENDORS]: 'vendor_account_ids',
  [SelectedFilter.PICKUP]: 'pickup_site_ids',
};

type AvailableOptionsByFilter = Record<SelectedFilter, TypeaheadedFilterState>;

const availableOptionsInitialState = Object.values(SelectedFilter).reduce(
  (obj, filter) => {
    obj[filter] = {
      loading: false,
      options: [],
      searchValue: '',
    };

    return obj;
  },
  {} as AvailableOptionsByFilter,
);

const DriverPayMainFilters = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchor);
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>();
  const [availableOptions, setAvailableOptions] = useState(availableOptionsInitialState);
  const [nextPageCursors, setNextPageCursors] = useState<NextPageCursorsState>({});
  const { userStore } = useStores();

  const companyId = userStore.userCompany?.id;

  const fetchers = useFetchers(companyId);

  const allSearchParams = useMemo(() => {
    const res: Record<string, string> = {};

    for (const key of searchParams.keys()) {
      res[`${key}`] = searchParams.get(key) ?? '';
    }

    return res;
  }, [searchParams]);

  const isJobsTab = useMemo(() => {
    return allSearchParams.grid === 'jobs';
  }, [allSearchParams.grid]);

  const isPayableCategory = useMemo(() => {
    return Boolean(
      allSearchParams.category &&
        payableInvoiceCategories.includes(
          allSearchParams.category as InvoiceCategoryFilter,
        ),
    );
  }, [allSearchParams.category]);

  const isReceivableCategory = useMemo(() => {
    return Boolean(
      receivableInvoiceCategories.includes(
        allSearchParams.category as InvoiceCategoryFilter,
      ),
    );
  }, [allSearchParams.category]);

  // get driver types options based on the category
  const driverTypesOptions = useMemo(() => {
    return isPayableCategory ? payableJobsOptions : receivableJobsOptions;
  }, [isPayableCategory]);

  const isDefaultCategoryFilter = useMemo(() => {
    return (
      allSearchParams[driverPayFilterKeyBySelectedFilter[SelectedFilter.DRIVERTYPE]] ===
      (isPayableCategory ? 'payables' : 'receivables')
    );
  }, [allSearchParams]);

  const showCheckBeforeFilter = useMemo(
    () => (filter: SelectedFilter) => {
      if (filter === SelectedFilter.DRIVERTYPE) {
        return !isDefaultCategoryFilter;
      } else {
        return (
          (allSearchParams[driverPayFilterKeyBySelectedFilter[filter]] ?? []).length > 0
        );
      }
    },
    [allSearchParams],
  );

  // get available filters based on the category
  const availableFilters = useMemo(() => {
    return [
      SelectedFilter.PROJECTS,
      SelectedFilter.RATES,
      ...(isJobsTab ? [SelectedFilter.DRIVERTYPE] : []),
      ...(isReceivableCategory ? [SelectedFilter.CUSTOMER] : []),
      ...(isReceivableCategory ? [SelectedFilter.DROPOFF] : []),
      ...(isPayableCategory ? [SelectedFilter.VENDORS] : []),
      ...(isPayableCategory ? [SelectedFilter.PICKUP] : []),
    ];
  }, [isJobsTab, isReceivableCategory, isPayableCategory]);

  const filterChipsData = useMemo(() => {
    const filters = _.pick(
      allSearchParams,
      Object.values(driverPayFilterKeyBySelectedFilter),
    );

    const appliedFilters = Object.entries(filters).reduce(
      (obj, [key, value]) => {
        const selectedFilter = Object.entries(driverPayFilterKeyBySelectedFilter).find(
          ([_, filterKey]) => filterKey === key,
        )?.[0] as SelectedFilter | undefined;

        if (selectedFilter) {
          const selectedFilterName = driverPayFilterKeyBySelectedFilter[selectedFilter];

          // if the value is the default value for the category filter, we don't want to show it as a chip
          obj[selectedFilterName as SelectedFilter] =
            selectedFilter === SelectedFilter.DRIVERTYPE && isDefaultCategoryFilter
              ? []
              : value.split('|');
        }
        return obj;
      },
      {} as Record<SelectedFilter, string[]>,
    );

    const collectedData = Object.entries(availableOptions).reduce((obj, [key, val]) => {
      const pageFilterKey = driverPayFilterKeyBySelectedFilter[key as SelectedFilter];
      obj[pageFilterKey] = val.options;
      return obj;
    }, {} as AppliedFiltersChipsCollectedData);

    return { appliedFilters, collectedData };
  }, [searchParams, JSON.stringify(availableOptions)]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedFilter(undefined);
    }
  }, [isOpen]);

  const hasFiltersApplied = useMemo(
    () =>
      Object.values(filterChipsData.appliedFilters).filter((value) => {
        return Boolean(value && value.length > 0);
      }).length > 0,
    [filterChipsData.appliedFilters],
  );

  const applySearchResponse = useCallback(
    (key: SelectedFilter, data: FilterMenuOption[]) => {
      setAvailableOptions((state) => ({
        ...state,
        [key]: {
          ...state[key],
          loading: false,
          options: _.uniqBy([...state[key].options, ...data], (i) => i.value),
        },
      }));
    },
    [setAvailableOptions],
  );

  const handleSearchValueChange = useCallback(
    (value: string) => {
      setAvailableOptions((state) => ({
        ...state,
        [selectedFilter as SelectedFilter]: {
          loading: FilterMenuItemLoadingReason.SEARCH_VALUE,
          options: [],
          searchValue: value,
        },
      }));
    },
    [selectedFilter, setAvailableOptions],
  );

  const fetchersBySelectedFilter = useMemo(
    () => ({
      [SelectedFilter.PROJECTS]: fetchers.fetchProjects,
      [SelectedFilter.CUSTOMER]: fetchers.fetchCustomers,
      [SelectedFilter.DROPOFF]: fetchers.fetchSites,
      [SelectedFilter.VENDORS]: fetchers.fetchVendors,
      [SelectedFilter.PICKUP]: fetchers.fetchSites,
    }),
    [fetchers.fetchProjects],
  );

  const handleOnSelectedDriverTypeChange = (selectedOptions: string[]) => {
    if (selectedOptions.length === 0) {
      setSearchParams((params) => {
        params.set(
          driverPayFilterKeyBySelectedFilter[SelectedFilter.DRIVERTYPE],
          isPayableCategory ? 'payables' : 'receivables',
        );
        return params;
      });
    } else {
      setSearchParams((params) => {
        params.set(
          driverPayFilterKeyBySelectedFilter[SelectedFilter.DRIVERTYPE],
          selectedOptions[1],
        );
        return params;
      });
    }
  };

  const handleSelectedOptionsChange = useCallback(
    (selectedOptions: string[]) => {
      if (selectedFilter === SelectedFilter.DRIVERTYPE) {
        handleOnSelectedDriverTypeChange(selectedOptions);
      } else if (!selectedOptions.length) {
        handleRemoveOneFilter(
          driverPayFilterKeyBySelectedFilter[selectedFilter as SelectedFilter],
        );
      } else {
        const value = selectedOptions.reduce(
          (acc, val) => (acc.length ? `${acc}|${val}` : val),
          '',
        );
        setSearchParams((params) => {
          params.set(
            driverPayFilterKeyBySelectedFilter[selectedFilter as SelectedFilter],
            value,
          );
          return params;
        });
      }
    },
    [selectedFilter, searchParams],
  );

  const fetchMoreOptions = useCallback(() => {
    if (
      !selectedFilter ||
      selectedFilter === SelectedFilter.RATES ||
      selectedFilter === SelectedFilter.DRIVERTYPE
    ) {
      return;
    }
    setAvailableOptions((state) => ({
      ...state,
      [selectedFilter]: {
        ...state[selectedFilter],
        cursor: nextPageCursors[selectedFilter],
        loading: FilterMenuItemLoadingReason.INFINITE_SCROLL,
      },
    }));
  }, [selectedFilter, nextPageCursors, setAvailableOptions]);

  const onFetchMore = useMemo(() => {
    return selectedFilter ? fetchMoreOptions : undefined;
  }, [selectedFilter]);

  const handleRemoveAllFilters = useCallback(() => {
    setSearchParams((params) => {
      for (const key of Object.values(driverPayFilterKeyBySelectedFilter)) {
        if (key === driverPayFilterKeyBySelectedFilter[SelectedFilter.DRIVERTYPE]) {
          params.set(key, isPayableCategory ? 'payables' : 'receivables');
          continue;
        }
        params.delete(key);
      }
      return params;
    });
  }, []);

  const handleRemoveOneFilter = useCallback((filter: string) => {
    setSearchParams((params) => {
      if (filter === driverPayFilterKeyBySelectedFilter[SelectedFilter.DRIVERTYPE]) {
        params.set(filter, isPayableCategory ? 'payables' : 'receivables');
        return params;
      } else {
        params.delete(filter);
        return params;
      }
    });
  }, []);

  // check for applied filters and fetch corresponding options if needed
  const checkAndFetchAppliedFilters = useCallback(() => {
    // Get all applied filters from search params
    const appliedFilters = Object.entries(driverPayFilterKeyBySelectedFilter).reduce(
      (applied, [filterKey, searchParamKey]) => {
        const value = searchParams.get(searchParamKey);
        if (value) {
          applied[filterKey as SelectedFilter] = value.split('|');
        }
        return applied;
      },
      {} as Record<SelectedFilter, string[]>,
    );

    // Fetch options for each applied filter if they are not already loaded
    Object.entries(appliedFilters).forEach(([filterKey, values]) => {
      const selectedFilter = filterKey as SelectedFilter;

      // If options are not yet available, fetch them
      if (availableOptions[selectedFilter].options.length === 0) {
        // Fetch options for the selected filter if it's rates
        if (
          selectedFilter === SelectedFilter.RATES ||
          selectedFilter === SelectedFilter.DRIVERTYPE
        ) {
          applySearchResponse(
            selectedFilter,
            selectedFilter === SelectedFilter.RATES
              ? rateTypeOptions
              : driverTypesOptions,
          );
        } else {
          const fetcher = fetchersBySelectedFilter[selectedFilter];
          if (fetcher) {
            fetcher('', '').then((response: any) => {
              applySearchResponse(selectedFilter, response.data);
            });
          }
        }
      }
    });
  }, [searchParams, availableOptions, fetchersBySelectedFilter, applySearchResponse]);

  useEffect(() => {
    checkAndFetchAppliedFilters();
  }, [searchParams]);

  useEffect(() => {
    if (selectedFilter && availableOptions[selectedFilter].loading) {
      if (
        selectedFilter === SelectedFilter.RATES ||
        selectedFilter === SelectedFilter.DRIVERTYPE
      ) {
        const { searchValue } = availableOptions[selectedFilter];
        const options =
          selectedFilter === SelectedFilter.RATES ? rateTypeOptions : driverTypesOptions;

        const filteredOptions = options.filter((option) =>
          option.label.toLowerCase().includes(searchValue.toLowerCase()),
        );

        applySearchResponse(selectedFilter, options);
        setAvailableOptions((state) => ({
          ...state,
          [selectedFilter]: {
            ...state[selectedFilter],
            loading: false,
            options: filteredOptions,
          },
        }));
      } else {
        const fetcher = fetchersBySelectedFilter[selectedFilter];

        const { searchValue, cursor } = availableOptions[selectedFilter];

        (fetcher as FetchFunction)(searchValue, cursor as string).then((response) => {
          setNextPageCursors((state) => ({
            ...state,
            [selectedFilter]: response.nextPageCursor,
          }));
          applySearchResponse(selectedFilter, response.data);
        });
      }
    }
  }, [
    selectedFilter,
    fetchersBySelectedFilter,
    availableOptions.projects.loading,
    availableOptions.drivers.loading,
    availableOptions.rates.loading,
    availableOptions.customers.loading,
    availableOptions.dropOffSites.loading,
    availableOptions.vendors.loading,
    availableOptions.pickUpSites.loading,
  ]);

  useEffect(() => {
    if (selectedFilter) {
      setAvailableOptions((state) => ({
        ...state,
        [selectedFilter]: {
          loading: FilterMenuItemLoadingReason.SEARCH_VALUE,
          options: [],
          searchValue: '',
        },
      }));
    }
  }, [selectedFilter, allSearchParams]);

  return (
    <Box sx={{ display: 'flex' }}>
      <SmallButton
        onClick={(e) => setAnchor(e.currentTarget)}
        size="small"
        startIcon={<FilterList />}
        sx={(theme) => ({
          '&.MuiButton-root': {
            backgroundColor: 'white',
            border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            ...(hasFiltersApplied
              ? {
                  borderBottomLeftRadius: theme.brandV2.borderRadius,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: theme.brandV2.borderRadius,
                  borderTopRightRadius: 0,
                }
              : { borderRadius: theme.brandV2.borderRadius }),
          },
        })}
      >
        {t('common.filters')}
      </SmallButton>
      <Menu
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        open={isOpen}
        sx={(theme) => ({
          '& .MuiPaper-root': {
            border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            boxShadow: '0px 1px 10px 0px rgba(60, 64, 67, 0.15)',
            mt: '3px',
            width: '250px',
          },
          '& .MuiItem-root': {
            px: theme.spacing(1.5),
          },
        })}
      >
        {selectedFilter && (
          <FilterMenuItem
            focusSearchFieldOnMount
            searchValue={availableOptions[selectedFilter].searchValue}
            onSearchValueChange={handleSearchValueChange}
            loadingReason={availableOptions[selectedFilter].loading || undefined}
            options={availableOptions[selectedFilter].options}
            selectedOptions={
              allSearchParams[driverPayFilterKeyBySelectedFilter[selectedFilter]]?.split(
                '|',
              ) ?? []
            }
            onSelectedOptionsChange={handleSelectedOptionsChange}
            onFetchMore={onFetchMore}
          />
        )}

        {!selectedFilter &&
          Object.values(SelectedFilter).map((filter) =>
            availableFilters.includes(filter) ? (
              <MenuItem key={filter} onClick={() => setSelectedFilter(filter)}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={(theme) => ({ color: theme.brandV2.colors.treadBlack })}
                  >
                    {t(`dispatch.dispatch_v2.filters.${_.snakeCase(filter)}`)}
                  </Typography>

                  <Box display="flex" alignItems="center" gap={1}>
                    {showCheckBeforeFilter(filter) && (
                      <Check
                        sx={{
                          fontSize: '16px',
                          color: (theme) => theme.brandV2.colors.treadOrange,
                        }}
                      />
                    )}

                    <ChevronRight sx={{ fontSize: '16px' }} />
                  </Box>
                </Box>
              </MenuItem>
            ) : null,
          )}
      </Menu>
      <AppliedFiltersChips
        {...filterChipsData}
        onRemoveAllFilters={handleRemoveAllFilters}
        onRemoveFilter={handleRemoveOneFilter}
      />
    </Box>
  );
});

export default DriverPayMainFilters;
