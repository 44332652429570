import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { DialogHeader } from '~components/Dialog/DialogHeader';
import { TextFormField } from '~components/FormFields/TextFormField';
import { useEquipment } from '~hooks/useEquipment';
import { useJob } from '~hooks/useJob';
import { useStores } from '~store/RootStore';
import theme from '~theme/AppTheme';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

import { AutocompleteFormField } from '../FormFields/AutocompleteFormField';
import { SingleDateTimeFormField } from '../FormFields/SingleDateTimeFormField';

interface BulkEditJobDialogProps {
  isOpen: boolean;
  jobIds: string[];
  onClose: () => void;
}

const notesSchema = yup.object().shape({
  notes: yup.string().trim(),
  job_start_at: yup.string().notRequired(),
  requested_loads_count: yup
    .number()
    .transform((_, val) => (String(val).trim() !== '' ? Number(val) : undefined))
    .notRequired(),
  requested_equipment_type_name: yup.string().notRequired(),
});

type NotesDTO = yup.InferType<typeof notesSchema>;

export default function BulkEditJobDialog({
  isOpen,
  jobIds,
  onClose,
}: BulkEditJobDialogProps) {
  const { bulkEditJobNotes, isUpdating } = useJob();

  const formMethods = useForm<NotesDTO>({
    resolver: yupResolver(notesSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      notes: '',
      job_start_at: '',
      requested_loads_count: null,
      requested_equipment_type_name: null,
    },
  });
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
  } = formMethods;

  const { companyAssetsStore, userStore } = useStores();
  const { isLoading: isEquipmentTypesLoading, getEquipmentTypesByCompanyId } =
    useEquipment();
  const equipmentTypesOptions = companyAssetsStore.equipmentTypes;
  const companyId = userStore.userCompany?.id;

  useEffect(() => {
    // Fetch data if it's not loaded yet
    if (companyId && !equipmentTypesOptions.length) {
      getEquipmentTypesByCompanyId({ companyId });
    }
  }, [companyId, equipmentTypesOptions.length]);

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      event.stopPropagation();

      handleSubmit(
        async ({
          notes,
          job_start_at,
          requested_loads_count,
          requested_equipment_type_name,
        }) => {
          const data = {
            ...(notes ? { notes } : {}),
            ...(job_start_at ? { job_start_at: dayjs(job_start_at).toISOString() } : {}),
            ...(requested_loads_count ? { requested_loads_count } : {}),
            ...(requested_equipment_type_name ? { requested_equipment_type_name } : {}),
          };
          await bulkEditJobNotes({ data, job_ids: jobIds });
          onClose();
        },
      )();
    },
    [jobIds, onClose],
  );

  useEffect(() => {
    if (isOpen) {
      setValue('notes', '');
      setValue('requested_loads_count', null);
      setValue('job_start_at', '');
      setValue('requested_equipment_type_name', null);
    }
  }, [isOpen, setValue]);

  return (
    <FormProvider {...formMethods}>
      <Dialog
        open={isOpen}
        onClose={(__: never, reason: DialogCloseReasonType) => {
          if (isActionClicked(reason)) {
            onClose();
          }
        }}
      >
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
          <DialogHeader
            closeCallBack={onClose}
            title={
              <Typography component="span" variant="h5">
                {t('dispatch.order.edit_jobs')}
              </Typography>
            }
          />

          <DialogContent sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography component="span" variant="h6" sx={{ fontWeight: 400 }}>
                {t('dispatch.order.times')}
              </Typography>

              <SingleDateTimeFormField
                dateLabel={`${t('form_fields.load_at_date')}`}
                timeLabel={`${t('form_fields.load_at_time')}`}
                control={control}
                errors={errors}
                name={'job_start_at'}
                clearOnNull={true}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography component="span" variant="h6" sx={{ fontWeight: 400 }}>
                {t('dispatch.order.loads_per_truck')}
              </Typography>

              <TextFormField
                label={`${t('dispatch.order.loads_per_truck')}`}
                control={control}
                errors={errors}
                name={'requested_loads_count'}
                sx={{ width: '50%' }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography component="span" variant="h6" sx={{ fontWeight: 400 }}>
                {t('form_fields.equipment_type')}
              </Typography>

              <AutocompleteFormField
                control={control}
                name="requested_equipment_type_name"
                errors={errors}
                list={equipmentTypesOptions.map((item) => item.name)}
                label={`${t('form_fields.requested_equipment_type')}`}
                loading={isEquipmentTypesLoading}
                multiple={false}
                isRequired={false}
                getValue={(item) => item}
                getLabel={(item) => item}
                sx={{ width: '50%' }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography component="span" variant="h6" sx={{ fontWeight: 400 }}>
                {t('dispatch.order.notes')}
              </Typography>

              <TextFormField
                errors={errors}
                control={control}
                multiline
                name="notes"
                placeholder={`${t('dispatch.order.add_note')}`}
                rows={5}
              />
            </Box>
          </DialogContent>

          <DialogActions
            sx={{
              m: 0,
              p: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <LoadingButton
              color="primary"
              disabled={isUpdating}
              loading={isUpdating}
              loadingPosition="start"
              startIcon={<></>}
              sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
              type="submit"
              variant="contained"
            >
              {t('actions.save')}
            </LoadingButton>

            <Button
              color="secondary"
              disabled={isUpdating || !isDirty}
              onClick={onClose}
              sx={{ mr: 2, px: 2 }}
              variant="outlined"
            >
              {t('actions.cancel')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
}
