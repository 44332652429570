export enum JobsSelectedFilter {
  CUSTOMERS = 'customers',
  DISPATCH_NUMBERS = 'dispatchNumbers',
  DROP_OFF_SITES = 'dropOffSites',
  PICK_UP_SITES = 'pickUpSites',
  PROJECTS = 'projects',
  PROJECTS_EXTERNAL_IDS = 'projectsExternalIds',
}

export enum DriversSelectedFilter {
  SHARED = 'shared',
}

export const DRIVER_PANEL_DRIVER_CARD_HEIGHT_IN_PX = 100;
