import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { useStores } from '~store/RootStore';

import { SearchInput } from './Filters/SearchInput';

export const DriverSearch = observer(() => {
  const [searchValue, setSearchValue] = useState('');

  const { driverSchedulerStore } = useStores();

  const applyDriversFilter = useRef(
    _.debounce(
      (search: string) => driverSchedulerStore.setAssigneesFilters({ search }),
      DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS,
    ),
  ).current;

  useEffect(() => {
    applyDriversFilter(searchValue);

    return () => {
      applyDriversFilter.cancel();
    };
  }, [searchValue]);

  return <SearchInput onChange={setSearchValue} value={searchValue} />;
});
