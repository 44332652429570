import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import {
  OverflowAwareText,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

interface SummaryCountProps {
  color: string;
  label: string;
  value: number;
}

interface LoadMetric {
  [unit: string]: {
    loaded: string[];
    delivered: string[];
  };
}

const loadMetrics: LoadMetric[] = [
  {
    tons: {
      loaded: ['4 loads', '80 tons'],
      delivered: ['505/510 loads', '10,100/10,200 tons'],
    },
  },
  {
    bushels: {
      loaded: ['4 loads', '80 bushels'],
      delivered: ['505/510 loads', '10,100/10,200 bushels'],
    },
  },
  {
    gallons: {
      loaded: ['4 loads', '80 gallons'],
      delivered: ['505/510 loads', '10,100/10,200 gallons'],
    },
  },
];

const summaryCountTypes: SummaryCountProps[] = [
  {
    color: theme.brandV2.colors.treadGray5,
    label: t('dispatch.summary_counts.unassigned'),
    value: 100,
  },
  {
    color: theme.brandV2.colors.treadGray5,
    label: t('dispatch.summary_counts.accepted'),
    value: 0,
  },
  {
    color: theme.brandV2.colors.treadGray5,
    label: t('dispatch.summary_counts.assigned'),
    value: 0,
  },
  {
    color: theme.brandV2.colors.treadGray5,
    label: t('dispatch.summary_counts.en_route'),
    value: 0,
  },
  {
    color: theme.brandV2.colors.treadGray5,
    label: t('dispatch.summary_counts.sent'),
    value: 0,
  },
  {
    color: theme.brandV2.colors.treadGray5,
    label: t('dispatch.summary_counts.loaded'),
    value: 0,
  },
  {
    color: theme.brandV2.colors.treadGray5,
    label: t('dispatch.summary_counts.rejected'),
    value: 0,
  },
  {
    color: theme.brandV2.colors.treadGray5,
    label: t('dispatch.summary_counts.unloaded'),
    value: 0,
  },
  {
    color: theme.brandV2.colors.treadGray5,
    label: t('dispatch.summary_counts.canceled'),
    value: 0,
  },
  {
    color: theme.brandV2.colors.treadGray5,
    label: t('dispatch.summary_counts.completed'),
    value: 0,
  },
];

export const SummaryCounts = observer(() => {
  // We'll want to keep track of this data in the store and reflect the updates in the UI as they come in
  const { ordersDispatchStore } = useStores();
  const [areMetricDetailsVisible, setAreMetricDetailsVisible] = useState(true);
  const [openMetrics, setOpenMetrics] = useState<Record<string, boolean>>({});

  const handleToggleMetric = (unit: string) => {
    setOpenMetrics((prev) => ({
      ...prev,
      [unit]: !prev[unit],
    }));
  };

  const handleMetricDetailsDisplay = useCallback(() => {
    setAreMetricDetailsVisible((areVisible) => !areVisible);
  }, []);

  return (
    <Box sx={{ mt: 1.5 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          columnGap: 1,
          pb: 1,
        }}
      >
        <Box>
          <Typography variant={'body1'} sx={{ fontWeight: 600 }}>
            {t('dispatch.summary_counts.accepted')}
          </Typography>
          <Typography variant={'h6'} sx={{ fontWeight: 400 }}>
            0/100
          </Typography>
        </Box>

        <Box>
          <Typography variant={'body1'} sx={{ fontWeight: 600 }}>
            {t('dispatch.summary_counts.available')}
          </Typography>
          <Typography variant={'h6'} sx={{ fontWeight: 400 }}>
            0/80
          </Typography>
        </Box>
      </Box>

      <Collapse in={areMetricDetailsVisible} timeout={400} easing={'ease'}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '4px 8px',
            pb: 1.5,
          }}
        >
          {summaryCountTypes.map((summaryCount) => (
            <Box
              key={summaryCount.label}
              sx={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <OverflowAwareText sx={{ width: 'auto', minWidth: '80px' }}>
                {summaryCount.label}
              </OverflowAwareText>
              <Typography variant={'body2'} sx={{ flexShrink: 0 }}>
                {summaryCount.value}
              </Typography>
            </Box>
          ))}
        </Box>

        <Divider sx={{ mx: -1.5 }} />

        <Box sx={{ pt: 1.5 }}>
          <Typography variant={'body1'} sx={{ mb: 1, fontWeight: 600 }}>
            {t('dispatch.summary_counts.load_metrics')}
          </Typography>

          {loadMetrics.map((metric) => {
            const [unit, details] = Object.entries(metric)[0];
            const isOpen = openMetrics[unit] || false;

            return (
              <Box key={unit} sx={{ mt: 0.5 }}>
                <Box
                  onClick={() => handleToggleMetric(unit)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textTransform: 'uppercase',
                      color: theme.brandV2.colors.treadGray3,
                    }}
                  >
                    {unit}
                  </Typography>
                  <ExpandMore
                    sx={{
                      transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.4s',
                    }}
                  />
                </Box>

                {isOpen && (
                  <Box sx={{ mt: 0.75 }}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {t('dispatch.summary_counts.loaded')}
                    </Typography>
                    <Typography variant="body2">{details.loaded.join(' • ')}</Typography>
                    <Typography variant="body1" sx={{ mt: 1, fontWeight: 600 }}>
                      {t('dispatch.summary_counts.delivered')}
                    </Typography>
                    {details.delivered.map((detail) => {
                      return (
                        <Typography key={detail} variant="body2" sx={{ my: 0.75 }}>
                          {detail}
                        </Typography>
                      );
                    })}
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Collapse>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
        <SmallButton
          size={'small'}
          color={'brandV2Orange'}
          onClick={handleMetricDetailsDisplay}
          variant={'text'}
          sx={{
            '&.MuiButtonBase-root': {
              border: 'solid 1px transparent',
              '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
            },
          }}
        >
          {areMetricDetailsVisible
            ? t('dispatch.summary_counts.show_less')
            : t('dispatch.summary_counts.show_more')}
        </SmallButton>
      </Box>
    </Box>
  );
});
