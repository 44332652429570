import {
  Company_Create,
  Company_Read,
  Company_Update,
  deleteV1CompaniesId,
  getV1Companies,
  getV1CompaniesId,
  patchV1CompaniesId,
  postV1Companies,
} from '@treadinc/horizon-api-spec';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { AdminCompany } from '~hooks/admin/useCompaniesAdmin/models';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import connection from '~services/connectionModule';
import { extractPagination, PaginationLink, PaginationQuery } from '~services/pagination';
import { useStores } from '~store';

interface UpdateCompanyParams {
  company: AdminCompany;
  callBack?: (company: AdminCompany) => void;
}
interface UploadLogoParams {
  id: string;
  file: File;
  callBack?: (company: AdminCompany) => void;
}

interface DeleteCompanyParams {
  id: string;
  callBack?: () => void;
}

export const useCompaniesAdmin = () => {
  const [isLoadingCompanies, setIsLoading] = useState<boolean>(false);
  const [isLoadingAllCompanies, setIsLoadingAllCompanies] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { companyAdminStore } = useStores();
  const { addSearchHeaderQuery } = useDataGridSearch();
  const getAllCompanies = async (link?: PaginationLink, searchQuery?: string) => {
    setIsLoadingAllCompanies(true);

    let query: PaginationQuery = {
      'page[limit]': companyAdminStore.pagination.limit,
    };
    query = addSearchHeaderQuery({ searchValue: searchQuery, query });
    if (link && companyAdminStore.pagination[link]) {
      query[`page[${link}]`] = companyAdminStore.pagination[link];
    }

    try {
      const response = await getV1Companies({ query });
      companyAdminStore.setCompanies(
        response.data.data.map((company: Company_Read) => AdminCompany.parse(company)),
      );
      const pagination = extractPagination(response);
      companyAdminStore.setPagination(pagination);
      companyAdminStore.updatePageNumber(link);
    } catch (error) {
      // NOTE: this used to happen in the pagination code but now it's here. see notes
      // in src/services/pagination.tsx for more info
      connection.handleRequestError(error);
      console.error(error);
      throw new Error(`Unable to retrieve companies`);
    } finally {
      setIsLoadingAllCompanies(false);
    }
  };
  const getCurrentUserCompany = async (id: string) => {
    await getCompanyById(id).then((resp) => {
      companyAdminStore.setCurrentCompany(resp);
    });
  };
  const getCompanyById = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await getV1CompaniesId({ path: { id } });
      return AdminCompany.parse(response.data.data);
    } finally {
      setIsLoading(false);
    }
  };
  const createCompany = ({ company, callBack }: UpdateCompanyParams) => {
    setIsUpdating(true);

    return postV1Companies({ body: AdminCompany.deparse(company) as Company_Create })
      .then((response) => {
        const company = AdminCompany.parse(response.data?.data);
        companyAdminStore.addCompany(company);
        callBack?.(company);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const updateCompany = ({ company, callBack }: UpdateCompanyParams) => {
    setIsUpdating(true);
    return patchV1CompaniesId({
      path: { id: company.id },
      body: AdminCompany.deparse(company) as Company_Update,
    })
      .then((response) => {
        const company = AdminCompany.parse(response.data.data);
        companyAdminStore.updateCompany(company);
        callBack?.(company);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  // META request to handle case, if company has been created but LOGO POST REQUEST is failed.
  // In this case the safest way is to update just created company instead of creating new one with the same data OR operate with old data
  const postCompanyUpdate = ({ company, callBack }: UpdateCompanyParams) => {
    return company.id
      ? updateCompany({ company, callBack })
      : createCompany({ company, callBack });
  };

  const deleteCompany = ({ id, callBack }: DeleteCompanyParams) => {
    setIsUpdating(true);
    return deleteV1CompaniesId({ path: { id } })
      .then(() => {
        companyAdminStore.removeCompany(id);
        callBack?.();
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const uploadLogo = ({ id, file, callBack }: UploadLogoParams) => {
    setIsUpdating(true);
    const formData = new FormData();
    formData.append('logo', file);

    // formdata doesn't work correctly with codegen, see:
    // https://github.com/hey-api/openapi-ts/issues/497
    return connection
      .post<Company_Read>(`${API_VERSION}/companies/${id}/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((resp) => {
        const company = AdminCompany.parse(resp);
        companyAdminStore.updateCompany(company);
        callBack?.(company);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return {
    isLoadingCompanies,
    isLoadingAllCompanies,
    isUpdating,
    getAllCompanies,
    getCurrentUserCompany,
    getCompanyById,
    postCompanyUpdate,
    createCompany,
    updateCompany,
    deleteCompany,
    uploadLogo,
  } as const;
};
