import { TimeZone } from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { data as enums } from '~constants/enums';

// Todo: USE User settings for date in right format
const dateFormat = (date: Date | Dayjs, format?: string) => {
  const dateFormat = format || enums.date_format.default;

  return dayjs.tz(date).format(dateFormat);
};

const formatDuration = (
  seconds: number,
  showSeconds?: boolean,
  spacer?: string,
  useLongFormat?: boolean,
) => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const daysSuffix = useLongFormat ? ' days' : 'd';
  const hoursSuffix = useLongFormat ? ' hours' : 'h';
  const minutesSuffix = useLongFormat ? ' minutes' : 'm';
  const secondsSuffix = useLongFormat ? ' seconds' : 's';

  const formattedDuration = [
    days > 0 ? `${days}${daysSuffix}` : '',
    hours > 0 ? `${hours}${hoursSuffix}` : '',
    minutes > 0 ? `${minutes}${minutesSuffix}` : '',
    showSeconds && remainingSeconds > 0 ? `${remainingSeconds}${secondsSuffix}` : '',
  ]
    .filter(Boolean)
    .join(spacer || ' ');

  return formattedDuration;
};

/**
 * Converts minutes to hours and minutes format.
 * @param minutes - The number of minutes to convert.
 * @returns The formatted string in the format "hh:mm".
 */
function minutesToHoursMins(minutes: number): string {
  const hh =
    Math.floor(minutes / 60)
      ?.toString()
      ?.padStart(2, '0') ?? '00';
  const mm = (minutes % 60)?.toString()?.padStart(2, '0') ?? '00';

  return `${hh}:${mm}`;
}

/**
 * Converts minutes to a duration in format "Ad Bh Cm" with "A" days, "B" hours and "C" minutes.
 * @param minutes - The number of minutes to convert.
 * @returns The formatted duration.
 */
export const minutesToFormattedDuration = (minutes: number) => {
  const days = Math.floor(minutes / 60 / 24);
  const hours = Math.floor((minutes / 60) % 24);
  const mins = Math.floor(minutes % 60);

  return `${days > 0 ? `${days}d ` : ''}${hours > 0 || days > 0 ? `${hours}h ` : ''}${mins}m`;
};

export const allowedTimezones = [
  TimeZone.CANADA_ATLANTIC,
  TimeZone.CANADA_CENTRAL,
  TimeZone.CANADA_EASTERN,
  TimeZone.CANADA_MOUNTAIN,
  TimeZone.CANADA_NEWFOUNDLAND,
  TimeZone.CANADA_PACIFIC,
  TimeZone.CANADA_SASKATCHEWAN,
  TimeZone.CANADA_YUKON,
  TimeZone.US_ALASKA,
  TimeZone.US_ALEUTIAN,
  TimeZone.US_ARIZONA,
  TimeZone.US_CENTRAL,
  TimeZone.US_EASTERN,
  TimeZone.US_EAST_INDIANA,
  TimeZone.US_HAWAII,
  TimeZone.US_INDIANA_STARKE,
  TimeZone.US_MICHIGAN,
  TimeZone.US_MOUNTAIN,
  TimeZone.US_PACIFIC,
  TimeZone.US_PACIFIC_NEW,
  TimeZone.US_SAMOA,
];

export const ignoreSunday = (date: Dayjs) => {
  if (date.day() === 0) {
    return date.add(1, 'day');
  }

  return date;
};

export { dateFormat, formatDuration, minutesToHoursMins };
