import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { ReactElement, useEffect } from 'react';
import React from 'react';

import { JobAssignmentType } from '~constants/enums';
import InfiniteScrollLoadingIndicator from '~pages/Dispatch/components/InfiniteScrollLoadingIndicator';
import { SelectedAssignment } from '~store/AssignDriverMenuStore';

import {
  FILTER_MENU_ITEM_HEIGHT_IN_PX,
  FilterMenuItemLoadingReason,
} from '../../Filters/FilterMenuItem';
import Menu from '../../Menu/Menu';
import { SearchBar } from '../../Search/SearchBar';
import Tabs from '../../Tabs/Tabs';
import { DriverMenuItem } from './DriverMenuItem';
import useAssignDriverMenu from './hooks/useAssignDriverMenu';

const tabs = [
  {
    label: t('dispatch.job.assignment_popover.internal'),
    value: JobAssignmentType.INTERNAL_DRIVER,
  },
  {
    label: t('dispatch.job.assignment_popover.external'),
    value: JobAssignmentType.EXTERNAL_DRIVER,
  },
  {
    label: t('dispatch.job.assignment_popover.vendor'),
    value: JobAssignmentType.VENDOR,
  },
];

interface AssignDriverMenuProps {
  companyId: string;
  customAssignCTA?: ReactElement;
  filterDate?: string;
  onChange: (assignment: SelectedAssignment) => void;
  onOpenStateChanged: (open: boolean) => void;
}

const AssignDriverMenu = ({
  companyId,
  customAssignCTA,
  onOpenStateChanged,
  onChange,
}: AssignDriverMenuProps) => {
  const theme = useTheme();

  const {
    applyDebouncedFilter,
    data,
    fetchMore,
    hasMore,
    loadingReason,
    open,
    reset,
    searchBarRef,
    selectedTab,
    setOpen,
    setSearchFieldValue,
    setSelectedTab,
  } = useAssignDriverMenu(companyId);

  const AssignCTA = customAssignCTA ? (
    React.cloneElement(customAssignCTA, {
      onClick(event: React.MouseEvent<HTMLElement>) {
        event?.stopPropagation();
      },
      sx: () => ({
        ...(customAssignCTA.props.sx || {}),
        backgroundColor: open ? theme.brandV2.colors.treadOrange : 'inherit',
        '& > p': { color: open ? '#FFFFFF' : 'inherit' },
      }),
    })
  ) : (
    <Button color="primary">{t('dispatch.job.assign_to')}</Button>
  );

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  return (
    <Menu
      menuTrigger={AssignCTA}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
      }}
      onOpenStateChanged={(open) => {
        onOpenStateChanged(open);
        setOpen(open);
      }}
      sx={{
        '& .MuiList-root': { padding: '12px', display: 'flex', flexDirection: 'column' },
      }}
    >
      <Tabs
        onChange={(tab) => setSelectedTab(tab)}
        selected={selectedTab}
        tabs={tabs}
        sx={{ paddingBottom: '12px' }}
      />

      <SearchBar
        ref={searchBarRef}
        focusOnMount={true}
        placeHolder={
          selectedTab === JobAssignmentType.VENDOR
            ? t('dispatch.job.assignment_popover.search_for_vendor') || ''
            : t('dispatch.job.assignment_popover.search_for_driver') || ''
        }
        alwaysExpanded
        isLoading={loadingReason === FilterMenuItemLoadingReason.SEARCH_VALUE}
        setFilter={(value) => {
          applyDebouncedFilter(() => setSearchFieldValue(value));
        }}
      />

      <Divider sx={{ '&.MuiDivider-root': { mt: '8px', mb: 0 } }} />

      <Box
        sx={{
          overflow: 'auto',
          height: `${FILTER_MENU_ITEM_HEIGHT_IN_PX * 9}px`,
        }}
      >
        {(data ?? []).map((driver, index) => (
          <React.Fragment key={driver.id}>
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                onChange(driver);
              }}
              sx={{
                '&.MuiMenuItem-root': { padding: '0px' },
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              <Box display="flex" width="100%">
                <DriverMenuItem
                  name={driver.name}
                  company={driver.companyShare?.senderCompany?.legalName}
                  equipmentId={
                    driver.equipment?.companyShare?.externalId ||
                    driver.equipment?.externalId
                  }
                  equipmentType={driver.equipment?.equipmentType.name}
                />
              </Box>
            </MenuItem>

            {index === (data ?? []).length - 1 && hasMore && (
              <InfiniteScrollLoadingIndicator
                alignItems="center"
                display="flex"
                height={FILTER_MENU_ITEM_HEIGHT_IN_PX}
                isLoading={loadingReason === FilterMenuItemLoadingReason.INFINITE_SCROLL}
                justifyContent="center"
                onFetchMore={fetchMore}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Menu>
  );
};

export { AssignDriverMenu };
