import { JobState } from '@treadinc/horizon-api-spec';

import { Job } from '~hooks/useJob';
import { nonEditableJobStates } from '~pages/Dispatch/constants/jobStates';

interface Props {
  orderId?: string;
  // Search value in orders datagrid filters on the job list
  orderSearchValue?: string;
  jobs?: Job[];
  searchValue: string;
  jobStatusFilter?: string;
  isNestedFromOrdersGrid?: boolean;
}

interface SendTextDialogState {
  isOpen: boolean;
  job?: Job;
}

type BulkAssignJobsDialogState = {
  isOpen: boolean;
  selectedVendorAccountId: string | null;
};

// Deprecated: Use `isJobSendable` (see: src/utils/jobs/job-utils.ts)
export const canSendInvitation = (job: Job) => {
  const { editable: userCanEditJob, driver, vendorJobAssignment, status } = job;
  const jobIsEligibleToBeSent =
    !!(driver || vendorJobAssignment) &&
    ![...nonEditableJobStates, JobState.ACCEPTED, JobState.REJECTED].includes(status);
  const jobIsAssignedToVendor = !!vendorJobAssignment;

  if (!jobIsEligibleToBeSent) {
    return false;
  }

  if (userCanEditJob) {
    const userCanAccessDriver = !!driver?.id;
    if (userCanAccessDriver || jobIsAssignedToVendor) {
      return true;
    }
  }

  return false;
};

export const canAcceptJob = (job: Job) => {
  const { status, driver, vendorJobAssignment, editable } = job;

  return editable && [JobState.SENT].includes(status) && !driver && !vendorJobAssignment;
};
