import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ButtonPropsColorOverrides } from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Breakpoint, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { OverridableStringUnion } from '@mui/types';
import { AxiosError } from 'axios';
import { DefaultTFuncReturn, t as $t } from 'i18next';
import React, {
  forwardRef,
  ReactNode,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { DialogCloseButton } from '../Buttons/DialogCloseButton';

export interface CallbackErrorState {
  code: number | null;
  message: string | null;
}

export interface ModalDialogHandler {
  open: () => void;
  close: () => void;
  toggle: () => void;
  setError: (error: CallbackErrorState) => void;
}

interface ModalDialogProps {
  title: string;
  content: ReactNode;
  maxWidth?: Breakpoint;
  loading?: boolean;
  confirmButtonText?: string | DefaultTFuncReturn;
  confirmButtonColor?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  >;
  cancelButtonText?: string | DefaultTFuncReturn;
  callBack: (event?: React.MouseEvent<Element, MouseEvent>) => void;
  onCancel?: (event?: React.MouseEvent<Element, MouseEvent>) => void;
  onClose?: (event?: React.MouseEvent<Element, MouseEvent>) => void;
  confirmedDisabled?: boolean;
  handleErrorsInline?: boolean;
  errorComponent?: (error: CallbackErrorState) => ReactNode;
}

const ModalDialog = forwardRef(function ModalDialog(
  {
    title,
    content,
    maxWidth,
    loading,
    confirmButtonText,
    confirmButtonColor,
    cancelButtonText,
    callBack,
    onCancel,
    onClose,
    confirmedDisabled,
    handleErrorsInline = false,
    errorComponent,
  }: ModalDialogProps,
  ref: Ref<ModalDialogHandler>,
) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [callbackError, setCallbackError] = useState<CallbackErrorState>({
    code: null,
    message: null,
  });
  const shouldShowCustomErrorComponent =
    handleErrorsInline && errorComponent && callbackError.code;

  useImperativeHandle(ref, () => ({
    open() {
      setIsOpen(true);
    },
    close() {
      setIsOpen(false);
    },
    toggle() {
      setIsOpen((prev) => !prev);
    },
    setError(error: CallbackErrorState) {
      if (handleErrorsInline) {
        setCallbackError(error);
      }
    },
  }));

  useEffect(() => {
    if (!isOpen) {
      onClose?.();
      setCallbackError({ code: null, message: null });
    }
  }, [isOpen]);

  const onSubmit = (event?: React.MouseEvent) => {
    event?.stopPropagation();
    if (!handleErrorsInline) {
      callBack();
      return;
    }

    try {
      callBack();
    } catch (error) {
      const axiosError = error as AxiosError;
      const code = axiosError?.response?.status || null;
      const message = axiosError?.message || 'An unexpected error occurred';
      setCallbackError({ code, message });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      disableEscapeKeyDown={true}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <Typography component={'span'} variant={'h5'} sx={{ width: '100%' }}>
          {title}
        </Typography>
        <DialogCloseButton
          callBack={(event) => {
            onClose?.(event);
            setIsOpen(false);
          }}
        ></DialogCloseButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ py: 4 }}>
          {content}
          {shouldShowCustomErrorComponent && errorComponent(callbackError)}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          m: 0,
          px: 2,
          py: 2,
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row-reverse',
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <LoadingButton
          onClick={onSubmit}
          loading={loading}
          loadingPosition="start"
          startIcon={<></>}
          type="button"
          variant="contained"
          color={confirmButtonColor || 'error'}
          sx={loading ? { pl: 5, pr: 2 } : { pr: 2 }}
          disabled={confirmedDisabled}
        >
          {confirmButtonText || $t('actions.proceed')}
        </LoadingButton>
        <Button
          color="secondary"
          variant="outlined"
          onClick={(event) => {
            onCancel?.(event);
            setIsOpen(false);
          }}
          sx={{ mr: 2, px: 2 }}
          disabled={loading}
        >
          {cancelButtonText || $t('actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export { ModalDialog };
