import {
  ResourceUsageLog_Read,
  ResourceUsageLog_Update_Destroyable,
} from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { ResourceUsageLogDTO } from '~pages/Approvals/DriverPay/helpers';
import { Nullable } from '~types/Nullable';

import { DriverBasic } from '../useDrivers';
import { BasicEquipment } from '../useEquipment';
import { Phase } from '../useProjects/models';

export class ResourceUsageLog {
  constructor(
    private _id: string,
    private _created_at: Dayjs,
    private _updated_at: Dayjs,
    private _usage_minutes: number,
    private _equipment: Nullable<BasicEquipment>,
    private _driver: Nullable<DriverBasic>,
    private _phase: Nullable<Phase>,
    private _resource_usage_loggable_type: string,
    private _resource_usage_loggable_id: string,
  ) {}

  public get id() {
    return this._id;
  }

  public get createdAt() {
    return this._created_at;
  }

  public get updatedAt() {
    return this._updated_at;
  }

  public get usageMinutes() {
    return this._usage_minutes;
  }

  public get equipment() {
    return this._equipment;
  }

  public get driver() {
    return this._driver;
  }

  public get phase() {
    return this._phase;
  }

  public get resourceUsageLoggableType() {
    return this._resource_usage_loggable_type;
  }

  public get resourceUsageLoggableId() {
    return this._resource_usage_loggable_id;
  }

  public static parse(proto: ResourceUsageLog_Read) {
    return new ResourceUsageLog(
      proto.id,
      dayjs(proto.created_at),
      dayjs(proto.updated_at),
      proto.usage_minutes,
      proto.equipment ? BasicEquipment.parse(proto.equipment) : null,
      proto.driver ? DriverBasic.parse(proto.driver) : null,
      proto.phase ? Phase.parse(proto.phase) : null,
      proto.resource_usage_loggable_type,
      proto.resource_usage_loggable_id,
    );
  }

  public static deparse(proto: ResourceUsageLogDTO) {
    if (proto._destroy === 1) {
      return {
        id: proto.id,
        _destroy: 1,
      };
    }

    const data: ResourceUsageLog_Update_Destroyable = {
      id: proto.id,
      usage_minutes: parseInt(proto.quantity.toString(), 10) * 60,
      phase_id: proto.phase.id ?? null,
      // equipment_id: proto.equipment ? proto.equipment.id : undefined,
    };
    return data;
  }
}
