import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ModelError_Item } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { ControllerProps, FieldErrors, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { driverFormValidationSchema } from '~components/DriverForm/driverFormValidationSchema';
import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { CheckBoxFormField } from '~components/FormFields/CheckBoxFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { PhoneCodeFlagInput } from '~components/FormFields/PhoneCodeFlagInput';
import { TextFormField } from '~components/FormFields/TextFormField';
import { UserFormStateChangeProps } from '~components/UserForm/UserForm';
import {
  userEmailSchema,
  userPhoneSchema,
} from '~components/UserForm/userFormValidationSchema';
import { data as enums } from '~constants/enums';
import { AddressItem } from '~hooks/useAddress';
import { SupportedAddressProvider } from '~hooks/useAddress/models/AddressProvider';
import { Company, useCompany } from '~hooks/useCompany';
import { useGeocode } from '~hooks/useGeocode';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';
import { allowedTimezones } from '~utils/dateTime';

interface DriverFormProps {
  errors?: ModelError_Item[];
  onFormStateChange: ({
    isValid,
    isDirty,
    hasValidEmail,
    hasValidPhone,
  }: UserFormStateChangeProps) => void;
}

export type DriverFormValues = yup.InferType<typeof driverFormValidationSchema>;

export interface DriverFormHandler {
  submit: (callback: (data: DriverFormValues) => void) => void;
}

const validateEmail = (email: Nullable<string>) => {
  let isValid = true;

  try {
    const input = email || '';

    if (input.length) {
      userEmailSchema.validateSync(input);
    } else {
      isValid = false;
    }
  } catch {
    isValid = false;
  }

  return isValid;
};

const validatePhone = (phone: Nullable<string>) => {
  let isValid = true;

  try {
    const input = phone || '';

    if (input.length) {
      userPhoneSchema.validateSync(input);
    } else {
      isValid = false;
    }
  } catch {
    isValid = false;
  }

  return isValid;
};

const getErrorMapping = (fieldName: string, defaultMessage: string) => {
  switch (fieldName) {
    case 'email':
      return {
        field: 'email',
        message: t('error_messages.users.email_taken'),
      };
    case 'base':
      return {
        field: 'firstName',
        message: t('error_messages.users.full_name_taken'),
      };
    case 'phone':
      return {
        field: 'phone',
        message: t('error_messages.users.phone_taken'),
      };
    default:
      return {
        field: fieldName,
        message: defaultMessage,
      };
  }
};

export const DriverForm = forwardRef<DriverFormHandler, DriverFormProps>(
  ({ onFormStateChange, errors: errorsProp }, ref) => {
    const { userStore } = useStores();
    const { getCompanyById } = useCompany();
    const currentCompany = userStore.currentCompanies[0] || userStore.userCompany;

    useEffect(() => {
      if (currentCompany) {
        getCompanyById({
          id: currentCompany.id,
          callBack: (company: Company) => {
            if (company?.timeZone) {
              setValue('timeZone', company.timeZone || enums.time_zone.default);
            }
          },
        });
      }
    }, [currentCompany]);

    useEffect(() => {
      if (errorsProp) {
        for (const error of errorsProp) {
          const newErrorField = getErrorMapping(error.field, error.message);
          if (newErrorField.field && newErrorField.message) {
            setError(newErrorField.field as keyof ReturnType<typeof setError>, {
              type: 'manual',
              message: newErrorField.message,
            });
          }
        }
      }
    }, [errorsProp]);

    const defaultValues = {
      company: currentCompany,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: null,
      timeZone: '',
      notes: '',
      createEquipment: false,
      equipmentName: '',
      equipmentType: '',
      externalId: '',
      sharedId: '',
      licenseNumber: '',
    };

    const {
      control,
      handleSubmit,
      watch,
      setValue,
      setError,
      formState: { errors, isValid, isDirty },
    } = useForm({
      resolver: yupResolver(driverFormValidationSchema),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues,
    });

    const watchedEmail = watch('email');
    const watchedPhone = watch('phone');
    const watchedCreateEquipment = watch('createEquipment');

    const hasValidEmail = validateEmail(watchedEmail);
    const hasValidPhone = validatePhone(watchedPhone);

    useEffect(() => {
      onFormStateChange({ isValid, isDirty, hasValidEmail, hasValidPhone });
    }, [isValid, isDirty, hasValidEmail, hasValidPhone]);

    const addressProvider = SupportedAddressProvider.GOOGLE_MAPS_PLACES_AUTOCOMPLETE;
    const { getPlaces, getAddressItemByGoogleMapsPlaceId } = useGeocode({
      addressProvider,
    });

    const handleAddressChange = useCallback(
      async (item: AddressItem) => {
        if (
          addressProvider === SupportedAddressProvider.GOOGLE_MAPS_PLACES_AUTOCOMPLETE
        ) {
          return await getAddressItemByGoogleMapsPlaceId(item.placeId);
        }
        return item;
      },
      [getAddressItemByGoogleMapsPlaceId],
    );

    useImperativeHandle(
      ref,
      () => ({
        submit(callback) {
          handleSubmit((data) => callback(data as DriverFormValues))();
        },
      }),
      [handleSubmit],
    );

    return (
      <Box
        component={'form'}
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', flexGrow: 1 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CompanySelectorFormField
              sx={{ width: '100%', minWidth: '100%' }}
              name="company"
              isRequired={true}
              disabled={false}
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFormField
              control={control}
              errors={errors}
              name="firstName"
              label={t('form_fields.first_name') as string}
              isRequired={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFormField
              control={control}
              errors={errors}
              name="lastName"
              label={t('form_fields.last_name') as string}
              isRequired={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFormField
              control={control}
              errors={errors}
              name="email"
              type="email"
              label={t('form_fields.email') as string}
            />
            <Typography component={'p'} variant="caption">
              {t('form_fields.contact_required')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <PhoneCodeFlagInput
              control={control as unknown as ControllerProps['control']}
              errors={errors as unknown as FieldErrors}
              name="phone"
              label={t('form_fields.phone') as string}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteAsyncFormField
              control={control}
              errors={errors}
              name="address"
              getLabel={(item) => item.streetAddress}
              getValue={(item) => item.placeId}
              label={t('form_fields.address') as string}
              placeholder="Start typing to search"
              asyncCallback={getPlaces}
              onSelect={handleAddressChange}
              debounceTime={500}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteFormField
              control={control}
              name="timeZone"
              errors={errors}
              list={allowedTimezones}
              label={t('form_fields.time_zone') as string}
              isRequired={true}
              clearable={false}
              getValue={(item) => item}
              getLabel={(item) => item}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              control={control}
              errors={errors}
              name="notes"
              multiline={true}
              rows={2}
              label={t('form_fields.notes') as string}
              isRequired={false}
            />
          </Grid>
          {/* Equipment */}
          <Grid item xs={12}>
            <Typography variant="h6">{t(`form_fields.equipment`)}</Typography>
            <CheckBoxFormField
              control={control}
              errors={errors}
              value={false}
              name="createEquipment"
              label={`${t('administration.equipment.create_equipment')}`}
            />
          </Grid>
          {watchedCreateEquipment && (
            <>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="equipmentName"
                  label={t('form_fields.equipment_name') as string}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="equipmentType"
                  label={t('form_fields.equipment_type') as string}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="externalId"
                  label={t('form_fields.equipment_id') as string}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="sharedId"
                  label={t('form_fields.shared_id') as string}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="licenseNumber"
                  label={`${t('form_fields.license_number')}`}
                  isRequired={false}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    );
  },
);

DriverForm.displayName = 'DriverForm';
