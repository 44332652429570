import {
  getV1CompaniesCompanyIdOmniReports,
  getV1CompaniesCompanyIdOmniReportsId,
  getV1OmniReports,
  getV1OmniReportsReportName,
} from '@treadinc/horizon-api-spec';
import { useState } from 'react';

import connection from '~services/connectionModule';

import { OmniReport } from './models';

export const useOmniReports = () => {
  const [isLoadingAllReports, setIsLoadingAllReports] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  const getOmniReports = async () => {
    setIsLoadingAllReports(true);

    try {
      const resp = await getV1OmniReports();
      return resp.data.data.map(OmniReport.parse);
    } catch (error) {
      connection.handleRequestError(error);
      console.error(error);
      throw new Error('Unable to retrieve omni reports');
    } finally {
      setIsLoadingAllReports(false);
    }
  };

  const getCompanyOmniReports = async (companyId: string) => {
    setIsLoadingAllReports(true);

    try {
      const resp = await getV1CompaniesCompanyIdOmniReports({
        path: { 'company-id': companyId },
      });
      return resp.data.data.map(OmniReport.parse);
    } catch (error) {
      connection.handleRequestError(error);
      console.error(error);
      throw new Error('Unable to retrieve omni reports');
    } finally {
      setIsLoadingAllReports(false);
    }
  };

  const getOmniReportById = async (id: string) => {
    setIsLoadingReport(true);

    try {
      const resp = await getV1OmniReportsReportName({ path: { id } });
      return resp.data.data.dashboard_url;
    } catch (error) {
      connection.handleRequestError(error);
      console.error(error);
      throw new Error(`Unable to retrieve omni report ${id}`);
    } finally {
      setIsLoadingReport(false);
    }
  };

  const getCompanyOmniReportById = async (companyId: string, id: string) => {
    setIsLoadingReport(true);

    try {
      const resp = await getV1CompaniesCompanyIdOmniReportsId({
        path: { 'company-id': companyId, id },
      });
      return resp.data.data.dashboard_url;
    } catch (error) {
      connection.handleRequestError(error);
      console.error(error);
      throw new Error(`Unable to retrieve omni report ${id}`);
    } finally {
      setIsLoadingReport(false);
    }
  };

  return {
    isLoadingAllReports,
    getOmniReports,
    isLoadingReport,
    getOmniReportById,
    getCompanyOmniReports,
    getCompanyOmniReportById,
  };
};
