import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React, { useMemo } from 'react';

import { ContactItem } from '~hooks/useContact';
import { User } from '~hooks/useUsers';

interface Props {
  contact: ContactItem;
  title: string;
  sx?: SxProps<Theme>; // Style
}

const ContactDetails = ({ contact, title, sx = {} }: Props) => {
  return (
    <TableRow>
      <TableCell sx={{ pl: 0, py: 0.25, fontWeight: 'bold', ...sx }}>{title}</TableCell>
      <TableCell sx={{ py: 0.25, fontWeight: 'bold', ...sx }}>{contact.name}</TableCell>
      <TableCell sx={{ pr: 0.25, py: 0, fontWeight: 'bold', textAlign: 'right', ...sx }}>
        {contact.email ? (
          <>
            <Typography variant={'body1'}>
              <a href={`mailto:${contact.email}`}>{contact.email}</a>
            </Typography>
          </>
        ) : null}
      </TableCell>
      <TableCell sx={{ display: 'none', ...sx }}>
        {contact.phone ? (
          <Typography variant={'body1'}>
            <a href={`tel:${contact.phone.replace(/\s+/g, '')}`}>{contact.phone}</a>
          </Typography>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export { ContactDetails };
