import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SettlementLineItemType } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';

import { SettlementLineItem } from '~hooks/useSettlementLineItems/models';
import { Settlement } from '~hooks/useSettlements/models';

import { COMMON_GRID_CONFIG, EM_DASH } from './constants';

export function SettlementLineItems({
  lineItems,
  settlement,
  currencyFormatter,
}: {
  lineItems: SettlementLineItem[];
  settlement?: Settlement;
  currencyFormatter: (value: number) => string;
}) {
  return lineItems.map((lineItem) => {
    const uniqueKey = lineItem.date.toISOString() + JSON.stringify(lineItem.ids);
    return (
      <Box
        key={uniqueKey}
        sx={{
          ...COMMON_GRID_CONFIG,
          borderBottom: '1px solid #E2E2E2',
        }}
      >
        <Typography variant="body2">
          {dayjs.tz(lineItem.date.toISOString()).format('DD-MMM-YY')}
        </Typography>
        <Typography variant="body2">
          {lineItem.truckExternalIds.length
            ? lineItem.truckExternalIds.join(' | ')
            : EM_DASH}
        </Typography>
        <Typography variant="body2">{lineItem.equipmentTypes.join(' | ')}</Typography>
        <Typography variant="body2">{lineItem.materials.join(' | ')}</Typography>
        <Typography variant="body2">
          {lineItem.ids.map(({ friendlyJobId }) => friendlyJobId).join(' | ')}
        </Typography>
        <Typography variant="body2">
          {t('approvals.driver_pay.settlements.pdf.num_loads', {
            num: lineItem.loadsCount,
          })}
          {` , ${lineItem.quantities.map((qty) => `${qty.quantity} ${qty.unitOfMeasure}`).join(' | ')}`}
        </Typography>
        <Typography variant="body2">
          {lineItem.rateTypes
            .map((type) => `Per ${type.replace(/RatePer/g, '').toLowerCase()}`)
            .join(' | ')}{' '}
          {settlement?.settlementLineItemType === SettlementLineItemType.JOB && (
            <>
              {lineItem.payStartTime?.format('h:mmA')}
              {` - `}
              {lineItem.payEndTime?.format('h:mmA')}
            </>
          )}
        </Typography>
        <Typography variant="body2">
          {dayjs
            .duration({ minutes: lineItem.billingAdjustmentMinutes ?? 0 })
            .asHours()
            .toFixed(2)}
          {` ${t('common.hrs')}`}
        </Typography>
        <Typography variant="body2">
          {lineItem.totalQuantity.toFixed(2)} {t('common.hrs')}
        </Typography>
        <Typography variant="body2">{currencyFormatter(lineItem.rateValue)}</Typography>
        <Typography variant="body2" sx={{ gridColumn: 'span 2' }}>
          {currencyFormatter(lineItem.totalAmount)}
        </Typography>
      </Box>
    );
  });
}
