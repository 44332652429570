import { Driver, DriverBasic } from '~hooks/useDrivers';

export function getConsolidatedEquipmentName(driver: Driver | DriverBasic) {
  const { equipment } = driver;

  if (equipment) {
    const extId = equipment.companyShare?.externalId || equipment.externalId;

    return `${extId ? `${extId} - ` : ''}${equipment.name}`;
  }

  return null;
}
