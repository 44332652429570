import {
  Company_Read_Nested,
  Impersonation_Read_Minimal,
  M2MClient_Read_Nested,
  Principal_Read_Nested,
  User_Read_Minimal,
} from '@treadinc/horizon-api-spec';

export const createdBy = (createdBy: Principal_Read_Nested): string => {
  switch (createdBy.type) {
    case Principal_Read_Nested.type.COMPANY: {
      const company = createdBy as Company_Read_Nested;
      return company.legal_name;
    }
    case Principal_Read_Nested.type.USER: {
      const user = createdBy as User_Read_Minimal;
      return user.first_name === 'Automation'
        ? 'System'
        : `${user.first_name} ${user.last_name}`;
    }
    case Principal_Read_Nested.type.M2MCLIENT: {
      const client = createdBy as M2MClient_Read_Nested;
      return client.company.legal_name;
    }
    case Principal_Read_Nested.type.IMPERSONATION: {
      const impersonation = createdBy as Impersonation_Read_Minimal;
      return `${impersonation.impersonator.first_name} ${impersonation.impersonator.last_name} impersonating ${impersonation.impersonated.first_name} ${impersonation.impersonated.last_name}`;
    }
    default:
      throw new Error('Unknown createdBy type');
  }
};
