import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { usePDF } from '@react-pdf/renderer';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { Company, useCompany } from '~hooks/useCompany';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { useSettlementLineItems } from '~hooks/useSettlementLineItems';
import { Settlement, useSettlements } from '~hooks/useSettlements';
import * as colsDef from '~pages/Approvals/DriverPay/Settlements/settlementsDataGridColumnsDefinition';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

import {
  SettlementForm,
  SettlementFormHandler,
  useSettlementForm,
} from '../components/SettlementForm';
import { SettlementDetails } from './SettlementDetails/SettlementDetails';
import SettlementPDF from './SettlementPdf/SettlementPdf';

interface SettlementsDataGridProps {
  sx?: SxProps;
}

const SettlementsDataGrid = observer(({ sx }: SettlementsDataGridProps) => {
  const { settlementsStore, toasterStore, userStore } = useStores();
  const {
    getSettlements,
    isLoading: isLoadingSettlements,
    isUpdating: isUpdatingSettlement,
    subscribeToSettlementsRTU,
    updateSettlement,
  } = useSettlements();
  const { currencyFormatter } = useCompanyCurrency();
  const { getCompanyById } = useCompany();
  const settlementsFormRef = useRef<SettlementFormHandler>(null);
  const { dialogHandler } = useSettlementForm();
  const { getAllSettlementLineItems } = useSettlementLineItems();
  const [instance, update] = usePDF();
  const [isGeneratingPdfForSettlementId, setIsGeneratingPdfForSettlementId] =
    useState<string>();

  const isLoading = Boolean(isLoadingSettlements || !userStore.userCompany);

  const exportToPDF = useCallback(
    async (settlement: Settlement) => {
      try {
        setIsGeneratingPdfForSettlementId(String(settlement.id));

        const lineItemsRequest = getAllSettlementLineItems(String(settlement.id));

        let forCompanyRequest: Promise<Company> | null = null;
        const account = settlement.customerAccount ?? settlement.vendorAccount;

        if (account?.accountBasicCompanyId) {
          forCompanyRequest = getCompanyById({ id: account.accountBasicCompanyId });
        }

        const [lineItems, forCompany] = await Promise.all([
          lineItemsRequest,
          forCompanyRequest,
        ]);

        update(
          <SettlementPDF
            key={new Date().getTime()}
            company={userStore.userCompany}
            forCompany={forCompany ?? undefined}
            lineItemGroups={lineItems}
            settlement={settlement}
          />,
        );
      } catch (error) {
        setIsGeneratingPdfForSettlementId(undefined);

        console.error(error);
        throw new Error('Unable to generate settlement PDF');
      }
    },
    [update, userStore.userCompany],
  );

  const rows = useMemo(() => {
    return settlementsStore.settlements.map((settlement) => {
      const row: colsDef.SettlementsRowDef['row'] = {
        id: String(settlement.id),
        settlement,
        isGeneratingPdfForSettlementId,
        utils: {
          currencyFormatter,
          onEditButtonClick(settlement) {
            dialogHandler.open(settlement);
          },
          onExportToPDFButtonClick: exportToPDF,
        },
      };

      return row;
    });
  }, [settlementsStore.settlements, exportToPDF, isGeneratingPdfForSettlementId]);

  const columns = useMemo(() => {
    return [
      colsDef.createdDateColDef,
      colsDef.settlementIdColDef,
      colsDef.forColDef,
      colsDef.orderIdsColDef,
      colsDef.jobsColDef,
      colsDef.qtysAndUomsColDef,
      colsDef.adjustmentsColDef,
      colsDef.totalColDef,
      colsDef.stateColDef,
      colsDef.exportedColDef,
      colsDef.actionsColDef(() => (
        <HeaderNavigation
          callback={getSettlements}
          count={settlementsStore.settlements.length}
          loading={isLoadingSettlements}
          pagination={settlementsStore.pagination}
        />
      )),
    ];
  }, [
    getSettlements,
    isLoadingSettlements,
    settlementsStore.pagination,
    settlementsStore.settlements.length,
  ]);

  const handleSettlementsFormSubmit = useCallback(() => {
    settlementsFormRef.current?.onSubmit?.(async (data) => {
      if (!dialogHandler.settlement?.id) {
        throw new Error('Settlement id not set');
      }

      await updateSettlement(dialogHandler.settlement.id, data);

      toasterStore.push(
        alert(
          t('approvals.driver_pay.settlements.settlement_updated', {
            name: dialogHandler.settlement.settlementId,
          }),
          AlertTypes.success,
        ),
      );
      dialogHandler.close();
    });
  }, [dialogHandler.settlement?.id]);

  useEffect(() => {
    dialogHandler.updateBusyState(isUpdatingSettlement);
  }, [isUpdatingSettlement]);

  useEffect(() => {
    getSettlements();
  }, [JSON.stringify(settlementsStore.searchParams)]);

  useEffect(() => {
    let subscription: ReturnType<typeof subscribeToSettlementsRTU> | null = null;

    if (userStore.userCompany.id) {
      subscription = subscribeToSettlementsRTU(userStore.userCompany.id);
    }

    return () => {
      subscription?.unsubscribe?.();
    };
  }, [userStore.userCompany.id]);

  useEffect(() => {
    if (instance.url) {
      window.open(instance.url, '_blank');
      setIsGeneratingPdfForSettlementId(undefined);
    }
  }, [instance.url]);

  return (
    <>
      <Box>
        <DataGrid
          id="settlements-data-grid"
          sx={{
            "& .MuiDataGrid-cell[data-field='actions']": { px: '5px' },
            '& .MuiDataGrid-columnHeaderTitle': { fontSize: '10px' },
            ...sx,
          }}
          columns={columns}
          rows={rows}
          loading={isLoading}
          getDetailPanelContent={(args) => {
            return <SettlementDetails settlementId={args.row.id} />;
          }}
        />
      </Box>

      <SettlementForm
        ref={settlementsFormRef}
        isBusy={dialogHandler.isBusy}
        isOpen={dialogHandler.isOpen}
        onClose={dialogHandler.close}
        onSubmit={handleSettlementsFormSubmit}
        settlement={dialogHandler.settlement}
      />
    </>
  );
});

export default SettlementsDataGrid;
