import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

export const DisconnectIntegrationErrorComponent = () => {
  return (
    <Alert severity="warning" sx={{ mt: 4 }}>
      <AlertTitle sx={{ fontSize: '16px', fontWeight: 600 }}>
        {t('error_messages.agave.unable_to_disconnect')}
      </AlertTitle>
      <Typography variant={'body1'}>{t('actions.try_again_later')}</Typography>
    </Alert>
  );
};
