import {
  AuthMethod,
  BillingStatus,
  Company_Create,
  Company_Read as CompanyProto,
  Company_Read_Nested,
  Company_Update,
  CompanyType,
  Language,
  SaasBillingPlan,
  UsageStatus,
} from '@treadinc/horizon-api-spec';
import _ from 'lodash';

import { data as enums, UnitOfDistanceEnum } from '~constants/enums';
import { AddressItem } from '~hooks/useAddress';
import { ContactItem } from '~hooks/useContact';
import { Nullable } from '~types/Nullable';

export class Company {
  public static parse(proto: CompanyProto): Company {
    return new Company(
      proto?.id ?? '',
      proto?.created_at ?? null,
      proto.parent_company ? CompanyBasic.parse(proto.parent_company) : null,
      proto.legal_name ?? '',
      proto?.dba_name ?? '',
      proto?.address ? AddressItem.parse(proto.address) : null,
      proto.company_type ?? null,
      proto?.tread_customer ?? '',
      proto?.tread_id ?? '',
      proto?.language ?? enums.language.default,
      proto?.date_format ?? enums.date_format.default,
      proto?.time_format ?? enums.time_format.default,
      proto?.system_of_measure ?? enums.system_of_measure.default,
      proto?.unit_of_distance ?? enums.unit_of_distance.default,
      proto.currency ?? enums.currency.default,
      proto?.time_zone ?? enums.time_zone.default,
      proto?.primary_contact ? ContactItem.parse(proto.primary_contact) : null,
      proto?.billing_contact ? ContactItem.parse(proto.billing_contact) : null,
      proto.logo_url ?? null,
      proto?.usage_status ?? enums.usage_status.default,
      proto?.billing_status ?? enums.billing_status.default,
      proto?.saas_billing_plan ?? enums.saas_billing_plan.default,
      proto?.fuel_surcharge_percentage ?? 0,
      proto.auth_methods,
      proto.auto_complete_order,
      _.isNil(proto?.default_lat) ? null : Number(proto.default_lat),
      _.isNil(proto?.default_lon) ? null : Number(proto?.default_lon),
      Number(proto.site_radius) || null,
    );
  }

  public static deparse(proto: any): Company_Create | Company_Update {
    const data = {
      parent_company_id: undefined,
      legal_name: proto.legalName,
      dba_name: proto.doingBusinessName,
      address: proto.address ? AddressItem.deparse(proto.address) : undefined,
      company_type: proto.companyType,
      primary_contact: proto.primaryContact
        ? ContactItem.deparse(proto.primaryContact)
        : undefined,
      billing_contact: proto.billingContact
        ? ContactItem.deparse(proto.billingContact)
        : undefined,
      tread_customer: true, // Tread default
      tread_id: proto.tread_id, // Tread default
      language: enums.language.default,
      time_format: proto.timeFormat,
      date_format: proto.dateFormat,
      system_of_measure: proto.systemOfMeasure,
      unit_of_distance: proto.unitOfDistance.value,
      currency: proto.currency,
      time_zone: proto.timeZone,
      logo_url: proto.logoUrl || undefined,
      usage_status: proto.usageStatus || enums.usage_status.default,
      billing_status: proto.billingStatus || enums.billing_status.default,
      saas_billing_plan: proto.SaasBillingPlan || enums.saas_billing_plan.default,
      fuel_surcharge_percentage: proto.fuelSurcharge || 0,
      site_radius: proto.site_radius,
      default_lat: _.isNil(proto?.defaultLat) ? null : proto.defaultLat.toString(),
      default_lon: _.isNil(proto?.defaultLon) ? null : proto.defaultLon.toString(),
    };

    // ?? maybe ID is required in case of edit company
    return data as Company_Create | Company_Update;
  }

  public static deparseUpdate(proto: any): Company_Create | Company_Update {
    const data = {
      legal_name: proto.legalName || undefined,
      dba_name: proto.doingBusinessName || undefined,
      address: proto.address ? AddressItem.deparse(proto.address) : undefined,
      company_type: proto.companyType || undefined,
      primary_contact: proto.primaryContact
        ? ContactItem.deparse(proto.primaryContact)
        : undefined,
      billing_contact: proto.billingContact
        ? ContactItem.deparse(proto.billingContact)
        : undefined,
      tread_customer: true, // Tread default
      tread_id: proto.tread_id,
      language: enums.language.default,
      time_format: proto.timeFormat || undefined,
      date_format: proto.dateFormat || undefined,
      system_of_measure: proto.systemOfMeasure || undefined,
      unit_of_distance: proto.unitOfDistance.value || undefined,
      currency: proto.currency || undefined,
      time_zone: proto.timeZone || undefined,
      logo_url: proto.logoUrl || undefined,
      usage_status: proto.usageStatus || undefined,
      billing_status: proto.billingStatus || undefined,
      saas_billing_plan: proto.SaasBillingPlan || undefined,
      fuel_surcharge_percentage: _.isUndefined(proto.fuelSurcharge)
        ? undefined
        : proto.fuelSurcharge || 0,
      default_lat: _.isNil(proto?.defaultLat) ? null : proto.defaultLat.toString(),
      default_lon: _.isNil(proto?.defaultLon) ? null : proto.defaultLon.toString(),
      auto_complete_order: proto.autoCompleteOrder,
      site_radius: proto.siteRadius,
    };

    // ?? maybe ID is required in case of edit company
    return data as Company_Create | Company_Update;
  }
  public get id(): string {
    return this._id;
  }

  public get createdAt(): string {
    return this._created_at;
  }

  public get legalName(): string {
    return this._legal_name;
  }

  public get parentCompany(): Nullable<CompanyBasic> {
    return this._parentCompany;
  }

  public get dbaName(): string {
    return this._dba_name;
  }

  public get isTreadCustomer(): boolean {
    return this._tread_customer;
  }

  public get address(): Nullable<AddressItem> {
    return this._address;
  }

  public get primaryContact(): Nullable<ContactItem> {
    return this._primary_contact;
  }

  public get billingContact(): Nullable<ContactItem> {
    return this._billing_contact;
  }

  public get timeZone(): Nullable<string> {
    return this._time_zone;
  }

  public get companyType(): Nullable<CompanyType> {
    return this._company_type;
  }
  public get language(): Language {
    return this._language;
  }

  public get timeFormat(): string {
    return this._time_format;
  }

  public get dateFormat(): string {
    return this._date_format;
  }

  public get unitOfDistance(): string {
    return this._unit_of_distance;
  }
  public get isFeet(): boolean {
    return this._unit_of_distance === UnitOfDistanceEnum.MILE;
  }
  public get isMeters(): boolean {
    return this._unit_of_distance === UnitOfDistanceEnum.KILOMETER;
  }
  public get systemOfMeasure(): string {
    return this._system_of_measure;
  }

  public get currency(): string {
    return this._currency;
  }
  public get logoUrl(): Nullable<string> {
    return this._logo_url;
  }

  public get usageStatus(): Nullable<UsageStatus> {
    return this._usage_status;
  }

  public get billingStatus(): Nullable<BillingStatus> {
    return this._billing_status;
  }

  public get saasBillingPlan(): Nullable<SaasBillingPlan> {
    return this._saas_billing_plan;
  }

  public get treadId(): string {
    return this._tread_id;
  }

  public get fuelSurcharge(): string | number {
    return this._fuel_surcharge_percentage;
  }
  public set fuelSurcharge(value: string | number) {
    this._fuel_surcharge_percentage = Number(value);
  }
  public get country(): string {
    return 'CA';
  }
  public get authMethods() {
    return this._auth_methods;
  }
  public get autoCompleteOrder() {
    return this._auto_complete_order;
  }
  public get siteRadius() {
    return this._site_radius;
  }

  public get defaultLat() {
    return this._default_lat;
  }

  public get defaultLon() {
    return this._default_lon;
  }

  constructor(
    private _id: string,
    private _created_at: string,
    private _parentCompany: Nullable<CompanyBasic>,

    private _legal_name: string,
    private _dba_name: string,
    private _address: Nullable<AddressItem>,

    private _company_type: CompanyType,

    private _tread_customer: boolean,
    private _tread_id: string,

    private _language: Language,
    private _date_format: string,
    private _time_format: string,
    private _system_of_measure: string,
    private _unit_of_distance: string,
    private _currency: string,
    private _time_zone: string,

    private _primary_contact: Nullable<ContactItem>,
    private _billing_contact: Nullable<ContactItem>,
    private _logo_url: Nullable<string>,
    private _usage_status: Nullable<UsageStatus>,
    private _billing_status: Nullable<BillingStatus>,
    private _saas_billing_plan: Nullable<SaasBillingPlan>,
    private _fuel_surcharge_percentage: string | number,
    private _auth_methods: Array<AuthMethod>,
    private _auto_complete_order: boolean,
    private _default_lat: Nullable<number>,
    private _default_lon: Nullable<number>,
    private _site_radius: Nullable<number>,
  ) {}
}

export class CompanyBasic {
  public static parse(proto: Company_Read_Nested): CompanyBasic {
    return new CompanyBasic(proto.id, proto.legal_name, proto.company_type);
  }
  public get id(): string {
    return this._id;
  }
  public get legalName(): string {
    return this._legal_name;
  }
  public get companyType(): string {
    return this._company_type;
  }

  constructor(
    private _id: string,
    private _legal_name: string,
    private _company_type: string,
  ) {}
}
