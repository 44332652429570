import { RateType } from '@treadinc/horizon-api-spec';
import _ from 'lodash';
import * as yup from 'yup';

import { RateBasicWithValue } from '~hooks/useRates';
import { getRateTypeOptions } from '~pages/Settings/RatesManagement/rateUtils';

export const rateDetailsSchema = yup.object().shape(
  {
    targetRate: yup
      .object()
      .shape({
        id: yup.string().min(1).required(),
        name: yup.string().min(1).required(),
        type: yup.string().min(1).required(),
        rate: yup.number().min(0).required(),
      })
      .nullable(),
    targetRateType: yup
      .object()
      .shape({
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
      })
      .nullable()
      .when(['targetRateValue'], {
        is: (targetRateValue: string | number | null | undefined) => {
          if (_.isNil(targetRateValue)) {
            return false;
          }

          const hasRateValue = String(targetRateValue).trim().length > 0;

          return hasRateValue;
        },
        then: (schema) => schema.nonNullable().required(),
        otherwise: (schema) => schema,
      }),
    targetRateValue: yup
      .number()
      .transform((val) => (Number.isNaN(val) ? null : val))
      .min(0)
      .nullable()
      .when(['targetRateType'], {
        is: (targetRateType: { value: string } | null | undefined) => {
          const hasRateType = !_.isNil(targetRateType);

          return hasRateType;
        },
        then: (schema) => schema.nonNullable().required(),
        otherwise: (schema) => schema,
      }),
  },
  [['targetRateType', 'targetRateValue']],
);

export type RateDetailsDTO = yup.InferType<typeof rateDetailsSchema>;

export const setRateDetailsFormDefaultValues = (
  targetRate: RateBasicWithValue | null,
  targetRateType: RateType | null,
  targetRateValue: number | null,
) => {
  const selectedRateTypeOption = getRateTypeOptions().find((option) => {
    return option.value === targetRateType;
  });

  return {
    targetRate: targetRate?.id
      ? {
          id: targetRate.id,
          name: targetRate.name,
          type: targetRate.type,
          rate: targetRate.rate,
        }
      : null,
    targetRateValue: targetRate?.id ? null : targetRateValue ?? null,
    targetRateType: targetRate?.id ? null : selectedRateTypeOption ?? null,
  } as unknown as RateDetailsDTO;
};

type KeyValuePairWithTemplatedKey<Key extends string, Value> = {
  [K in keyof object & string as Key]: Value | null;
};

type DeparsedRateDetailsDTO<T extends string> = KeyValuePairWithTemplatedKey<
  `${T}_rate_id`,
  string
> &
  KeyValuePairWithTemplatedKey<`${T}_rate_type`, RateType> &
  KeyValuePairWithTemplatedKey<`${T}_rate_value`, number>;

type AllowedRateTargets = 'customer' | 'vendor' | 'driver';

export const deparseRateDetailsDTO = <T extends AllowedRateTargets>(
  prefix: T,
  input: RateDetailsDTO,
) => {
  const rateCardId = input.targetRate?.id ?? null;
  const rateType = input.targetRateType ? (input.targetRateType.value as RateType) : null;
  const rateValue = _.isNil(input.targetRateValue) ? null : Number(input.targetRateValue);

  const deparsed = {
    ...(rateCardId
      ? {
          [`${prefix}_rate_id`]: rateCardId,
          [`${prefix}_rate_type`]: null,
          [`${prefix}_rate_value`]: null,
        }
      : {
          [`${prefix}_rate_id`]: null,
          [`${prefix}_rate_type`]: rateType,
          [`${prefix}_rate_value`]: rateValue,
        }),
  } as DeparsedRateDetailsDTO<T>;

  return deparsed;
};

export const deparseUpdateRateDetailsDTO = <T extends AllowedRateTargets>(
  target: T,
  input: RateDetailsDTO,
) => {
  const rateCardId = input.targetRate?.id ?? null;
  const rateType = input.targetRateType ? (input.targetRateType.value as RateType) : null;
  const rateValue = _.isNil(input.targetRateValue) ? null : Number(input.targetRateValue);

  const deparsed = {
    [`${target}_rate_id`]: rateCardId,
    [`${target}_rate_type`]: rateType,
    [`${target}_rate_value`]: rateValue,
  } as DeparsedRateDetailsDTO<T>;

  return deparsed;
};
