import { observer } from 'mobx-react-lite';

import DateRangePicker from '~components/Filters/DateRangePicker';
import { useStores } from '~store/RootStore';

export const DriverDayDateFilter = observer(function () {
  const { driverDayStore } = useStores();

  return (
    <DateRangePicker
      startDate={driverDayStore.dateFilters.startDate}
      endDate={driverDayStore.dateFilters.endDate}
      onDateSelect={(startDate, endDate) => {
        driverDayStore.setDateFilters(startDate, endDate);
      }}
    />
  );
});
