import { just, nothing } from 'maybeasy';
import { computed, makeObservable, observable, runInAction } from 'mobx';

import { RootStore } from '~store/RootStore';
import { Alert } from '~types/AlertTypes';

class ToasterStore {
  rootStore: RootStore;
  alerts: Alert[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      alerts: observable,
      current: computed,
    });

    this.rootStore = rootStore;
  }

  get current() {
    return this.alerts.length === 0 ? nothing<Alert>() : just(this.alerts[0]);
  }

  hide() {
    runInAction(() => {
      if (this.alerts.length > 0) {
        this.alerts.splice(0, 1);
      }
    });
  }

  process() {
    runInAction(() => {
      this.alerts = this.alerts.slice(1);
    });
  }

  decodeHtml(html: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;

    return textArea.value;
  }

  push(alert: Alert, isHtml: boolean = false) {
    runInAction(() => {
      this.hide();

      this.alerts.push({
        ...alert,
        message: isHtml ? alert.message : this.decodeHtml(alert.message),
        display: true,
      });
    });
  }

  removeFirst() {
    runInAction(() => {
      if (this.alerts.length > 0) {
        this.alerts.shift();
      }
    });
  }
}

export default ToasterStore;
