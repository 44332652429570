import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import { t } from 'i18next';

import { DriverDay } from './DriverDay';
import DriverDayStatus from './DriverDayStatus';

const emDash = '-';

export const DRIVER_DAY_COLUMNS: GridColDef[] = [
  {
    field: 'date',
    headerName: `${t('form_fields.date')}`,
    type: 'date',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      dayjs.tz(params.value.toISOString()).format('MMM D, YY'),
  },
  {
    field: 'driver',
    headerName: `${t('form_fields.driver')}`,
    flex: 1,
  },
  {
    field: 'truckNo',
    headerName: `${t('approvals.driver_day.truck_no')}`,
    flex: 1,
    valueFormatter: (params) => params.value ?? emDash,
  },
  {
    field: 'vendor',
    headerName: `${t('form_fields.vendor')}`,
    flex: 1,
    valueFormatter: (params) => params.value ?? emDash,
  },
  {
    field: 'shiftStart',
    headerName: `${t('approvals.driver_day.shift_start')}`,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      params.value ? dayjs.tz(params.value).format('hh:mm A') : emDash,
  },
  {
    field: 'shiftEnd',
    headerName: `${t('approvals.driver_day.shift_end')}`,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      params.value ? dayjs.tz(params.value).format('hh:mm A') : emDash,
  },
  {
    field: 'break',
    headerName: `${t('approvals.driver_day.break')}`,
    flex: 1,
    valueFormatter: (params) => `${params.value} min`,
  },
  {
    field: 'totalHrs',
    headerName: `${t('approvals.driver_day.total_hrs')}`,
    flex: 1,
    valueFormatter: (params) => `${params.value.toFixed(2)}`,
  },
  {
    field: 'jobCt',
    headerName: `${t('approvals.driver_day.job_ct')}`,
    flex: 1,
  },
  {
    field: 'loadCt',
    headerName: `${t('approvals.driver_day.load_ct')}`,
    flex: 1,
  },
  {
    field: 'tickets',
    headerName: `${t('approvals.driver_day.tickets')}`,
    flex: 1,
  },
  {
    field: 'materials',
    headerName: `${t('administration.material.title')}`,
    flex: 1,
  },
  {
    field: 'status',
    headerName: `${t('approvals.driver_day.shift_status')}`,
    flex: 1,
    renderCell: (params) => {
      return <DriverDayStatus {...params.row.status} />;
    },
  },
] as GridColDef[];
