import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { t as $t } from 'i18next';
import { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormStateChangeProps } from '~formsShared';
import { Department } from '~hooks/useDepartment';
import { departmentFormValidationSchema } from '~pages/Settings/DepartmentManagement/departmentFormValidationSchema';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

interface DepartmentFormProps {
  defaultDepartment: Nullable<Department>;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
  onFormDirty?: (isDirty: boolean) => void;
}

/**
 * This file contains the DepartmentForm component.
 * The DepartmentForm component is responsible for rendering and managing the form for creating or editing a department.
 */
export const DepartmentForm = forwardRef(function DepartmentForm(
  { defaultDepartment, onFormStateChange }: DepartmentFormProps,
  ref: Ref<any>,
) {
  const { userStore } = useStores();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(departmentFormValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      id: defaultDepartment?.id || '',
      name: defaultDepartment?.name || '',
      company:
        defaultDepartment?.company ||
        userStore.currentCompanies[0] ||
        userStore.user.company,
    },
  });

  useImperativeHandle(ref, () => ({
    submit(callBack: () => void) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  useEffect(() => {
    onFormStateChange({ isValid, isDirty });
  }, [isValid, isDirty]);

  return (
    <form>
      <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%', flexGrow: 1 }}>
        <Box sx={{ width: '100%' }}>
          <CompanySelectorFormField
            sx={{ width: '100%', minWidth: '100%' }}
            name={'company'}
            isRequired={true}
            disabled={!!defaultDepartment?.company?.id}
            errors={errors}
            control={control}
          />
        </Box>
        <Box sx={{ width: '100%' }} mt={1}>
          <TextFormField
            control={control}
            errors={errors}
            name="name"
            label={`${$t('form_fields.name')}`}
            isRequired={true}
          />
        </Box>
      </Box>
    </form>
  );
});
