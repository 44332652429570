import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { SxProps } from '@mui/system';
import { t } from 'i18next';

import theme from '~theme/AppTheme';

interface ToggleSidebarButtonProps {
  isCollapsed: boolean;
  onClick: () => void;
  sx?: SxProps;
}

export default function ToggleSidebarButton({
  isCollapsed,
  onClick,
  sx,
}: ToggleSidebarButtonProps) {
  return (
    <IconButton
      sx={{
        border: `1px solid ${theme.brandV2.colors.treadGray7}`,
        borderRadius: '6px',
        height: '24px',
        width: '24px',
        ...sx,
      }}
      onClick={onClick}
      aria-label={
        isCollapsed
          ? (t('live_map.actions.open_sidebar') as string)
          : (t('live_map.actions.close_sidebar') as string)
      }
    >
      {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
    </IconButton>
  );
}
