import Box from '@mui/material/Box';

import { DriverDayDateFilter } from './DriverPayDateFilter';
import DriverPayMainFilters from './DriverPayMainFilters';

const DriverPayFilters = () => {
  return (
    <Box aria-label="filter area" sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <DriverDayDateFilter />
      <DriverPayMainFilters />
    </Box>
  );
};

export default DriverPayFilters;
