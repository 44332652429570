import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { FormStateChangeProps } from '~formsShared';
import { CompanyBasic } from '~hooks/useCompany';
import { EquipmentTypeItem, useEquipment } from '~hooks/useEquipment';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { dateFormat } from '~utils/dateTime';
import { isActionClicked } from '~utils/utilFunctions';

import { EquipmentTypeForm, EquipmentTypeFormHandler } from './EquipmentTypeForm';

const initialState = {
  columns: {
    columnVisibilityModel: {},
  },
};

const EquipmentTypeManagement = observer(() => {
  const theme = useTheme();
  const { companyAssetsStore, userStore, toasterStore } = useStores();
  const companyId = userStore.currentCompanies[0]?.id || userStore.userCompany?.id;

  // EquipmentType APIs
  const {
    getEquipmentTypesByCompanyId,
    createEquipmentTypeByCompanyId,
    updateEquipmentType,
    deleteEquipmentType,
    isUpdating,
    isLoading: isEquipmentTypesLoading,
  } = useEquipment();

  // Fetch initial EquipmentTypes list, and user permissions on page load
  const fetchEquipmentTypes = (link?: PaginationLink) => {
    getEquipmentTypesByCompanyId({ companyId, link });
  };
  const isLoading = isEquipmentTypesLoading;
  useEffect(() => {
    fetchEquipmentTypes();
  }, [companyId]);

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  // Get user permissions to determine if user can create/edit/delete equipment types
  const userPermissions = userStore.getPermissions();

  // Modal Actions
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const modalDialogRef = useRef<ModalDialogHandler>(null);
  const updateDialogRef = useRef<EquipmentTypeFormHandler>(null);

  const createNew = () => {
    setSelectedId(null);
    setDialogOpen(true);
  };

  const editRow = (id: string) => {
    setSelectedId(id);
    setDialogOpen(true);
  };

  const deleteRow = (id: string) => {
    setSelectedId(id);
    modalDialogRef.current?.open();
  };

  const deleteEquipmentTypeCallback = () => {
    if (selectedId) {
      deleteEquipmentType({
        equipmentTypeId: selectedId,
        callBack: () => {
          modalDialogRef.current?.close();
          toasterStore.push(
            alert(t('administration.equipment_type.deleted_message'), AlertTypes.success),
          );
        },
      });
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleErrors = () => {};
  const onSuccess = (e?: EquipmentTypeItem) => {
    handleClose();
    const name = `${e?.name}`;
    toasterStore.push(
      alert(
        selectedId
          ? t('administration.equipment_type.updated_message', { name })
          : t('administration.equipment_type.created_message', { name }),
        AlertTypes.success,
      ),
    );
  };

  const onSubmitCallback = (
    data: EquipmentTypeItem & {
      company: CompanyBasic;
    },
  ) => {
    if (selectedId) {
      updateEquipmentType({
        equipmentType: data,
        callBack: onSuccess,
      }).catch(handleErrors);
    } else {
      createEquipmentTypeByCompanyId({
        companyId: data.company.id,
        equipmentType: data,
        callBack: onSuccess,
      }).catch(handleErrors);
    }
  };

  const onFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setIsDirty(isDirty);
  };

  const onSubmitForm = () => {
    updateDialogRef.current?.submit(onSubmitCallback);
  };

  // DataGrid Configuration
  const pagination = companyAssetsStore.equipmentTypePagination;
  const rows = useMemo<EquipmentTypeItem[]>(
    () => companyAssetsStore.equipmentTypes,
    [companyAssetsStore.equipmentTypes],
  );
  const selectedEquipmentType = useMemo(() => {
    return companyAssetsStore.equipmentTypes.find((d) => d.id === selectedId) || null;
  }, [selectedId, rows]);

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: t('form_fields.name'),
          flex: 1,
        },
        {
          field: 'grossCapacity',
          flex: 1,
          headerName: `${t('form_fields.gross_capacity')}`,
          type: Number,
          valueGetter: (params) =>
            params.row.grossCapacity ? Number(params.row.grossCapacity) : null,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <Typography fontSize="12px">
                {params.value} {params.row.grossCapacityUnitOfMeasure?.name}
              </Typography>
            );
          },
        },
        {
          field: 'externalId',
          headerName: t('form_fields.external_id'),
          flex: 1,
        },
        {
          field: 'createdAt',
          headerName: t('common.created_on'),
          type: 'date',
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                key={`created__${params.row.id}`}
                title={`${t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <span>{dateFormat(params.row.createdAt)}</span>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'source',
          headerName: t('form_fields.source'),
          flex: 1,
        },
        {
          field: 'actions',
          headerName: t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={companyAssetsStore.equipmentTypes.length || 0}
              loading={loadingRef.current}
              pagination={pagination}
              callback={fetchEquipmentTypes}
              altText={`${t('actions.actions')}`}
            />
          ),
          renderCell: (params: GridValueGetterParams) => {
            const options =
              userPermissions.canDeleteEquipmentType && !params.row.system
                ? [
                    {
                      title: t('actions.delete'),
                      icon: <Delete />,
                      callBack: () => deleteRow(params.row.id),
                    },
                  ]
                : [];
            return (
              <>
                {userPermissions.canEditEquipmentType && !params.row.system && (
                  <IconButton size="small" onClick={() => editRow(params.row.id)}>
                    <Edit />
                  </IconButton>
                )}
                {options && (
                  <SimpleMenu options={options}>
                    <MoreHoriz />
                  </SimpleMenu>
                )}
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows],
  );

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <DataGrid
          id="equipment-types-data-grid"
          columns={columns}
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoading}
          initialState={initialState}
          headerActionsComponent={
            <>
              <Box display={'flex'}>
                <Button
                  size="medium"
                  color={'primary'}
                  onClick={createNew}
                  startIcon={<Add />}
                  disabled={!userPermissions.canCreateEquipmentType}
                >
                  {t('administration.equipment_type.create_title')}
                </Button>
              </Box>
            </>
          }
        />
      </Box>

      {/* Create/Edit Dialogs */}
      <Dialog
        open={isDialogOpen}
        onClose={(_: any, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={t(
            selectedId
              ? 'administration.equipment_type.update_title'
              : 'administration.equipment_type.create_title',
          )}
        />
        <DialogContent sx={{ my: 4 }}>
          <EquipmentTypeForm
            ref={updateDialogRef}
            defaultEquipementType={selectedEquipmentType}
            onFormStateChange={onFormStateChange}
          />
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={isUpdating || !isDirty}
              loading={isUpdating}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {t(selectedId ? `actions.update` : 'actions.create')}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdating}
              color="secondary"
              variant="outlined"
            >
              {t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <ModalDialog
        ref={modalDialogRef}
        title={t('administration.equipment_type.delete_title')}
        content={t('administration.equipment_type.delete_text')}
        confirmButtonText={`${t('actions.confirm')}`}
        callBack={deleteEquipmentTypeCallback}
        loading={isUpdating}
      />
    </>
  );
});

export { EquipmentTypeManagement };
