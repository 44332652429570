import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { AuthMethod } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextFormField } from '~components/FormFields/TextFormField';
import { useLogin } from '~hooks/useLogin/useLogin';
import { inputTypes } from '~pages/Auth/types';
import {
  emailSchema,
  getAuthMethodsValidationSchema,
} from '~pages/Auth/validationSchemas';

interface SignInEmailFormProps {
  email?: string;
  onFetchAvailableStrategies: (args: { email: string; strategies: AuthMethod[] }) => void;
}

interface GetAuthMethodsData
  extends yup.InferType<typeof getAuthMethodsValidationSchema> {}

const SignInEmailForm = ({ email, onFetchAvailableStrategies }: SignInEmailFormProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { getAuthMethods, isLoading } = useLogin();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<GetAuthMethodsData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(getAuthMethodsValidationSchema),
    defaultValues: { email: email ?? '' },
  });

  const handleToggleInputFocus = useCallback(() => {
    setIsInputFocused((isFocused) => !isFocused);
  }, []);

  const onSubmit = (data: GetAuthMethodsData) => {
    getAuthMethods({ email: data.email }).then((response) => {
      if (response) {
        onFetchAvailableStrategies({
          email: data.email,
          strategies: response.data.data.auth_methods,
        });
      }
    });
  };

  const enteredEmail = watch('email');
  const isValidEmail = useMemo(
    () => emailSchema.isValidSync(enteredEmail),
    [enteredEmail],
  );
  const shouldDisableSendMagicLinkButton = useMemo(
    () => !isValidEmail || isLoading,
    [isValidEmail, isLoading],
  );

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={1}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextFormField
        control={control}
        errors={errors}
        name={inputTypes.email}
        type={inputTypes.email}
        label={`${t('form_fields.email')}`}
        inputProps={{ 'data-test-id': inputTypes.email }}
        onFocus={handleToggleInputFocus}
        onBlur={handleToggleInputFocus}
      />

      <LoadingButton
        fullWidth
        color={isInputFocused ? 'primary' : 'inherit'}
        sx={isInputFocused ? undefined : { backgroundColor: '#E1E1E1' }}
        variant="contained"
        type="submit"
        disabled={shouldDisableSendMagicLinkButton}
        loading={isLoading}
      >
        {t('actions.continue_with_email')}
      </LoadingButton>
    </Box>
  );
};

export default SignInEmailForm;
