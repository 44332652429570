import Add from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { AddOn, useAddOns } from '~hooks/useAddOns';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import * as colsDef from '~pages/Settings/RatesManagement/AddOnCharges/addOnChargesDataGridColumnsDefinition';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

import AddOnForm, { AddOnFormHandler } from './components/AddOnForm';

interface DialogState {
  addOn?: AddOn;
  isOpen: boolean;
}

const AddOnCharges = observer(function AddOnCharges() {
  const { addOnsStore, userStore, toasterStore } = useStores();
  const { currencyFormatter } = useCompanyCurrency();
  const { createAddOn, getAddOns, isCreating, isLoading, isUpdating, updateAddOn } =
    useAddOns();
  const [dialogState, setDialogState] = useState<DialogState>({ isOpen: false });
  const dialogRef = useRef<AddOnFormHandler>({});
  const { searchValue, setSearchQueryValue } = useDataGridSearch();
  const companyId = userStore.currentCompanies[0].id || userStore.userCompany.id;

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  const isCreatingOrUpdating = isCreating || isUpdating;
  const isEditingAddOn = Boolean(dialogState.addOn?.id);

  const handleFilterChange = useCallback((query: string) => {
    setSearchQueryValue(query);
  }, []);

  const openDialog = useCallback((addOn?: AddOn) => {
    setDialogState({ isOpen: true, addOn });
  }, []);

  const closeDialog = useCallback(() => {
    setDialogState((state) => ({ ...state, isOpen: false }));
  }, []);

  const handleAddOnFormSubmit = useCallback(async () => {
    const data = await dialogRef.current?.onSubmit?.();

    if (!data) {
      return;
    }

    let alertMessage: string;

    if (isEditingAddOn) {
      await updateAddOn(String(data.id), data);
      alertMessage = t('administration.rates.add_ons.add_on_updated');
    } else {
      await createAddOn(data);
      alertMessage = t('administration.rates.add_ons.add_on_created');
    }

    toasterStore.push(alert(alertMessage, AlertTypes.success));
    closeDialog();
  }, [isEditingAddOn, closeDialog]);

  const rows = useMemo(() => {
    return addOnsStore.addOns.map((addOn) => {
      const row: colsDef.AddOnChargesRowDef['row'] = {
        id: String(addOn.id),
        addOn,
        utils: {
          currencyFormatter,
          onEditButtonClick(addOn) {
            openDialog(addOn);
          },
        },
      };

      return row;
    });
  }, [addOnsStore.addOns]);

  const columns = useMemo(() => {
    return [
      colsDef.descriptionColDef,
      colsDef.defaultRateColDef,
      colsDef.rateTypeColDef,
      colsDef.addOnTypesColDef,
      colsDef.actionsColDef(() => (
        <HeaderNavigation
          count={addOnsStore.addOns.length}
          loading={loadingRef.current}
          pagination={addOnsStore.pagination}
          searchQuery={searchValue}
          callback={(ref: PaginationLink) => {
            getAddOns(companyId, ref);
          }}
        />
      )),
    ];
  }, [addOnsStore.addOns, isLoading, addOnsStore.pagination, searchValue, companyId]);

  useEffect(() => {
    getAddOns(companyId);
  }, [companyId]);

  return (
    <Box>
      <DataGrid
        id="add-on-charges-grid"
        sx={{ "& .MuiDataGrid-cell[data-field='actions']": { px: '5px' } }}
        columns={columns}
        rows={rows}
        loading={isLoading}
        onChangeFilter={handleFilterChange}
        headerActionsComponent={
          <Button
            color="primary"
            onClick={() => openDialog()}
            startIcon={<Add />}
            sx={{ mt: -0.5 }}
          >
            {t('administration.rates.add_ons.create_add_on')}
          </Button>
        }
      />

      <Dialog
        sx={{ '& .MuiPaper-root': { maxWidth: '700px' } }}
        open={dialogState.isOpen}
        onClose={(__: never, reason: DialogCloseReasonType) => {
          if (isActionClicked(reason)) {
            closeDialog();
          }
        }}
      >
        <DialogHeader
          closeCallBack={closeDialog}
          title={
            <Typography component="span" variant="h5">
              {isEditingAddOn
                ? t('administration.rates.add_ons.update_add_on')
                : t('administration.rates.add_ons.create_add_on')}
            </Typography>
          }
        />

        <DialogContent>
          <AddOnForm ref={dialogRef} addOn={dialogState.addOn} />
        </DialogContent>

        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <LoadingButton
            color="primary"
            disabled={isCreatingOrUpdating}
            loading={isCreatingOrUpdating}
            loadingPosition="start"
            onClick={handleAddOnFormSubmit}
            startIcon={<></>}
            sx={isCreatingOrUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
            type="button"
            variant="contained"
          >
            {isEditingAddOn ? t('actions.update') : t('actions.create')}
          </LoadingButton>

          <Button
            onClick={closeDialog}
            sx={{ mr: 2, px: 2 }}
            disabled={isCreatingOrUpdating}
            color="secondary"
            variant="outlined"
          >
            {t('actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default AddOnCharges;
