import { JobState, SiteType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AppliedFiltersChipsCollectedData } from '~components/Filters/AppliedFiltersChips';
import {
  FilterMenuItemLoadingReason,
  FilterMenuOption,
} from '~components/Filters/FilterMenuItem';
import { SelectedFilter } from '~constants/enums';
import { useStores } from '~store';
import {
  Filters as LiveMapStoreFilters,
  LiveMapStorageKeys,
  OrderJobsFilters as LiveMapStoreOrderJobsFilters,
} from '~store/LiveMapStoreNew';

const ordersFilters: Array<
  Extract<
    keyof LiveMapStoreFilters,
    | 'customerAccountIds'
    | 'dispatchNumbers'
    | 'driverIds'
    | 'dropoffSites'
    | 'jobStates'
    | 'pickUpSites'
    | 'projectIds'
    | 'projectsExternalIds'
    | 'requesterIds'
    | 'siteTypes'
    | 'vendorAccountIds'
  >
> = [
  'customerAccountIds',
  'dispatchNumbers',
  'driverIds',
  'dropoffSites',
  'jobStates',
  'pickUpSites',
  'projectIds',
  'projectsExternalIds',
  'requesterIds',
  'siteTypes',
  'vendorAccountIds',
];
type OrdersFilters = (typeof ordersFilters)[number];

const orderJobsFilters: Array<Extract<keyof LiveMapStoreOrderJobsFilters, 'jobStates'>> =
  ['jobStates'];
type OrderJobsFilters = (typeof orderJobsFilters)[number];

type OrdersDispatchFilterKey = OrdersFilters | OrderJobsFilters;

type AppliedFiltersState = Record<
  SelectedFilter,
  {
    ordersDispatchFilterKey: OrdersDispatchFilterKey;
    search: string;
  }
>;

const ordersDispatchFilterKeyBySelectedFilter: Record<
  SelectedFilter,
  OrdersDispatchFilterKey
> = {
  [SelectedFilter.CUSTOMERS]: 'customerAccountIds',
  [SelectedFilter.DISPATCH_NUMBERS]: 'dispatchNumbers',
  [SelectedFilter.DRIVERS]: 'driverIds',
  [SelectedFilter.DROP_OFF_SITES]: 'dropoffSites',
  [SelectedFilter.JOB_STATES]: 'jobStates',
  [SelectedFilter.PICK_UP_SITES]: 'pickUpSites',
  [SelectedFilter.PROJECTS]: 'projectIds',
  [SelectedFilter.PROJECTS_EXTERNAL_IDS]: 'projectsExternalIds',
  [SelectedFilter.REQUESTERS]: 'requesterIds',
  [SelectedFilter.SITE_TYPES]: 'siteTypes',
  [SelectedFilter.VENDORS]: 'vendorAccountIds',
};

export type DriversRequestCursors = {
  sharedDriversCursor?: string;
  nonSharedDriversCursor?: string;
};

export type TypeaheadedFilterState = {
  cursor?: string | DriversRequestCursors;
  loading: FilterMenuItemLoadingReason | false;
  nonSharedDriversCursor?: string;
  options: FilterMenuOption[];
  searchValue: string;
  sharedDriversCursor?: string;
};

type FiltersAvailableOptionsState = Record<SelectedFilter, TypeaheadedFilterState>;

const getAppliedFiltersInInitialState = () => {
  const initialState = Object.entries(ordersDispatchFilterKeyBySelectedFilter).reduce(
    (obj, [filterKey, storeKey]) => {
      const key = filterKey as SelectedFilter;

      obj[key] = {
        ordersDispatchFilterKey: storeKey,
        search: '',
      };

      return obj;
    },
    {} as AppliedFiltersState,
  );

  return initialState;
};

const AVAILABLE_OPTIONS_STORAGE_KEY = 'liveMap_availableOptions';
type AppliedFiltersStoredAvailableOptions = Partial<
  Record<SelectedFilter, FilterMenuOption[]>
>;

const getAvailableOptionsInitialState = () => {
  const stored = localStorage.getItem(AVAILABLE_OPTIONS_STORAGE_KEY);
  const parsed = (
    stored ? JSON.parse(stored) : {}
  ) as AppliedFiltersStoredAvailableOptions;

  const initialState = {
    ...Object.values(SelectedFilter).reduce((obj, filter) => {
      obj[filter] = {
        loading: false,
        options: parsed[filter] ?? [],
        searchValue: '',
      };

      return obj;
    }, {} as FiltersAvailableOptionsState),
  };

  initialState.jobStates = {
    loading: false,
    options: Object.values(JobState).map((state) => ({
      label: t(`status.${state}`),
      value: `${state}`,
    })),
    searchValue: '',
  };

  initialState.siteTypes = {
    loading: false,
    options: Object.values(SiteType).map((type) => ({
      label: t(`site_types.${type}`),
      value: `${type}`,
    })),
    searchValue: '',
  };

  return initialState;
};

const getAppliedFiltersChipsCollectedDataInitialState = (
  availableOptions: FiltersAvailableOptionsState,
) => {
  const collectedData = Object.entries(availableOptions).reduce((obj, [key, values]) => {
    obj[key as SelectedFilter] = values.options;

    return obj;
  }, {} as AppliedFiltersChipsCollectedData);

  return collectedData;
};

export default function useLiveMapFilters() {
  const { liveMapStoreNew } = useStores();

  const [appliedFilters, setAppliedFilters] = useState(getAppliedFiltersInInitialState());
  const [availableOptions, setAvailableOptions] = useState(
    getAvailableOptionsInitialState(),
  );
  const [appliedFilterChipsCollectedData, setAppliedFilterChipsCollectedData] = useState(
    getAppliedFiltersChipsCollectedDataInitialState(availableOptions),
  );

  const defaultLiveMapFilters = useMemo(
    () => ({
      startDate: liveMapStoreNew.filters.startDate,
      endDate: liveMapStoreNew.filters.endDate,
      orderStates: liveMapStoreNew.filters.orderStates,
      search: '',
    }),
    [
      liveMapStoreNew.filters.startDate,
      liveMapStoreNew.filters.endDate,
      liveMapStoreNew.filters.orderStates,
    ],
  );

  // Only persist the date and order states when company changes
  const handleLiveMapFiltersReset = useCallback(() => {
    localStorage.removeItem(LiveMapStorageKeys.APPLIED_FILTERS);
    liveMapStoreNew.resetOrders(defaultLiveMapFilters);
    liveMapStoreNew.setFilters(defaultLiveMapFilters);
  }, [liveMapStoreNew, defaultLiveMapFilters]);

  useEffect(() => {
    liveMapStoreNew.restoreFilters();
  }, []);

  useEffect(() => {
    const options = Object.entries(availableOptions).reduce((obj, [key, value]) => {
      obj[key as SelectedFilter] = value.options;

      return obj;
    }, {} as AppliedFiltersStoredAvailableOptions);

    localStorage.setItem(AVAILABLE_OPTIONS_STORAGE_KEY, JSON.stringify(options));
  }, [JSON.stringify(availableOptions)]);

  return {
    appliedFilters,
    updateAppliedFilters: setAppliedFilters,
    availableOptions,
    updateAvailableOptions: setAvailableOptions,
    appliedFilterChipsCollectedData,
    updateAppliedFilterChipsCollectedData: setAppliedFilterChipsCollectedData,
    defaultLiveMapFilters,
    handleLiveMapFiltersReset,
  };
}
