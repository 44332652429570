import _ from 'lodash';
import React, { useCallback, useRef, useState } from 'react';

import { DriverFormHandler } from '~components/DriverForm/DriverForm';
import { UserFormStateChangeProps } from '~components/UserForm/UserForm';
import { InvitesToSendState } from '~pages/Settings/UserManagement/UserManagementDataGrid';

export const useDriverForm = () => {
  const driverDialogRef = useRef<DriverFormHandler>(null);
  const [isDriverDialogOpen, setIsDriverDialogOpen] = useState(false);
  const [driverInvitesToSend, setDriverInvitesToSend] = useState<InvitesToSendState>();
  const [driverFormIsDirty, setDriverFormIsDirty] = useState(false);

  const onChangeDriverInvite = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name as 'email' | 'sms';
      const checked = event.target.checked;

      setDriverInvitesToSend((state) => {
        const newState = _.cloneDeep(state);

        if (newState) {
          newState[name] = {
            ...newState[name],
            shouldSend: checked,
          };
        }

        return newState;
      });
    },
    [],
  );

  const onDriverFormStateChange = ({
    isDirty,
    hasValidEmail,
    hasValidPhone,
  }: UserFormStateChangeProps) => {
    setDriverFormIsDirty(isDirty);

    if (driverInvitesToSend) {
      setDriverInvitesToSend((state) => {
        const newState = _.cloneDeep(state);

        if (newState) {
          newState.email = {
            ...newState.email,
            isDisabled: !hasValidEmail,
          };

          newState.sms = {
            ...newState.sms,
            isDisabled: !hasValidPhone,
          };
        }

        return newState;
      });
    }
  };

  const createNewDriver = () => {
    setIsDriverDialogOpen(true);
    setDriverInvitesToSend({
      email: { isDisabled: true, shouldSend: false },
      sms: { isDisabled: true, shouldSend: false },
    });
  };

  return {
    driverDialogRef,
    driverFormIsDirty,
    isDriverDialogOpen,
    setIsDriverDialogOpen,
    driverInvitesToSend,
    setDriverInvitesToSend,
    onDriverFormStateChange,
    onChangeDriverInvite,
    createNewDriver,
  };
};
