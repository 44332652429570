import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog } from '~components/Dialog/ModalDialog';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { FormStateChangeProps } from '~formsShared';
import { ServiceClass, useServiceClass } from '~hooks/useServiceClass';
import { ServiceClassForm } from '~pages/Settings/ServiceClasses/ServiceClassForm';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { dateFormat } from '~utils/dateTime';
import { isActionClicked } from '~utils/utilFunctions';

export const ServiceClassManagement = observer(() => {
  const theme = useTheme();
  const { companyAssetsStore, userStore, toasterStore } = useStores();
  const companyId = userStore.currentCompanies[0]?.id || userStore.userCompany?.id;
  const isCurrentCompanyActive = userStore.isCurrentCompanyActive;

  // Service Class APIs
  const {
    getServiceClasses,
    getServiceClassesByCompanyId,
    createServiceClass,
    updateServiceClass,
    deleteServiceClass,
    isLoading: isLoadingServiceClasses,
    isUpdating,
  } = useServiceClass();

  // Fetch initial Service Class list, and user permissions on page load
  const isLoading = isLoadingServiceClasses;
  useEffect(() => {
    if (companyId && !isCurrentCompanyActive) {
      getServiceClassesByCompanyId(companyId);
    } else {
      getServiceClasses();
    }
  }, [companyId, isCurrentCompanyActive]);

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  // Get user permissions to determine if user can create/edit/delete service classes
  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);

  // Modal Actions
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const deleteDialogRef = useRef<any>(null);
  const updateDialogRef = useRef<any>(null);

  const createNew = () => {
    setSelectedId(null);
    setDialogOpen(true);
  };

  const editRow = (id: string) => {
    setSelectedId(id);
    setDialogOpen(true);
  };

  const deleteRow = (id: string) => {
    setSelectedId(id);
    deleteDialogRef.current?.open();
  };

  const deleteServiceClassCallback = () => {
    if (selectedId) {
      deleteServiceClass({
        serviceClassId: selectedId,
        callBack: () => {
          deleteDialogRef.current?.close();
          toasterStore.push(
            alert($t('administration.service_class.deleted'), AlertTypes.success),
          );
        },
      });
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleErrors = () => {};
  const onSuccess = (serviceClass?: ServiceClass) => {
    handleClose();
    const name = `${serviceClass?.name}`;
    toasterStore.push(
      alert(
        selectedId
          ? $t('administration.service_class.updated', { name })
          : $t('administration.service_class.created', { name }),
        AlertTypes.success,
      ),
    );
  };

  const onSubmitCallback = (data: any) => {
    if (selectedId) {
      updateServiceClass({ serviceClass: data, callBack: onSuccess }).catch(handleErrors);
    } else {
      createServiceClass({ serviceClass: data, callBack: onSuccess }).catch(handleErrors);
    }
  };

  const onFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setIsDirty(isDirty);
  };

  const onSubmitForm = () => {
    updateDialogRef.current?.submit(onSubmitCallback);
  };

  // DataGrid Configuration
  const pagination = companyAssetsStore.serviceClassPagination;
  const rows = useMemo<ServiceClass[]>(
    () => companyAssetsStore.serviceClasses,
    [companyAssetsStore.serviceClasses],
  );
  const selectedServiceClass = useMemo(() => {
    return (
      companyAssetsStore.serviceClasses.find((service) => service.id === selectedId) ||
      null
    );
  }, [selectedId, rows]);
  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: $t('form_fields.name'),
          flex: 1,
        },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          type: 'date',
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                key={`created__${params.row.id}`}
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <span>{dateFormat(params.row.createdAt)}</span>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'actions',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={companyAssetsStore.serviceClasses.length || 0}
              loading={loadingRef.current}
              pagination={pagination}
              callback={getServiceClasses}
              altText={`${$t('actions.actions')}`}
            />
          ),
          renderCell: (params: GridValueGetterParams) => {
            const options = userPermissions.canDeleteServiceClass
              ? [
                  {
                    title: t('actions.delete'),
                    icon: <Delete />,
                    callBack: () => deleteRow(params.row.id),
                  },
                ]
              : [];
            return (
              <>
                {userPermissions.canEditServiceClass && (
                  <IconButton size="small" onClick={() => editRow(params.row.id)}>
                    <Edit />
                  </IconButton>
                )}
                {options && (
                  <SimpleMenu options={options}>
                    <MoreHoriz />
                  </SimpleMenu>
                )}
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows],
  );

  return (
    <>
      {/* Service Class DataGrid table */}
      <Box sx={{ mt: 3 }} data-test-id={'service-class-data-grid'}>
        <DataGrid
          id="service-class-data-grid"
          columns={columns}
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoading}
          headerActionsComponent={
            <>
              <Box display={'flex'}>
                <Button
                  size="medium"
                  color={'primary'}
                  onClick={createNew}
                  startIcon={<Add />}
                  disabled={!userPermissions.canCreateServiceClass}
                >
                  {t('administration.service_class.create')}
                </Button>
              </Box>
            </>
          }
        />
      </Box>

      {/* Create/Edit Dialogs */}
      <Dialog
        open={isDialogOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={t(
            selectedId
              ? 'administration.service_class.update'
              : 'administration.service_class.create',
          )}
        />
        <DialogContent sx={{ my: 4 }}>
          <ServiceClassForm
            defaultServiceClass={selectedServiceClass}
            onFormStateChange={onFormStateChange}
            ref={updateDialogRef}
          />
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={isUpdating || !isDirty}
              loading={isUpdating}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t(selectedId ? `actions.update` : 'actions.create')}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdating}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <ModalDialog
        ref={deleteDialogRef}
        title={$t('administration.service_class.delete')}
        content={$t('administration.service_class.delete_description')}
        confirmButtonText={`${$t('actions.confirm')}`}
        callBack={deleteServiceClassCallback}
        loading={isUpdating}
      />
    </>
  );
});
