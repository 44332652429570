import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { JobState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';

import {
  FILTER_MENU_ITEM_HEIGHT_IN_PX,
  FilterMenuItemLoadingReason,
  FilterMenuOption,
} from '~components/Filters/FilterMenuItem';
import SearchField from '~components/Filters/SearchField';
import useFetchers, { getEquipmentLabel } from '~components/Filters/useFetchers';
import { OverflowAwareText } from '~components/Order/ordersDispatchStyledComponents';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { Job } from '~hooks/useJob';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import InfiniteScrollLoadingIndicator from './InfiniteScrollLoadingIndicator';

type TypeaheadedEquipmentsQuery = {
  loading: FilterMenuItemLoadingReason | false;
  nextNonSharedEquipmentsCursor?: string;
  nextSharedEquipmentsCursor?: string;
  nonSharedEquipmentsCursor?: string;
  options: FilterMenuOption[];
  searchValue: string;
  sharedEquipmentsCursor?: string;
};

interface EquipmentSelectorProps {
  companyId: string;
  equipment: Job['equipment'];
  job: Job;
  onChange: (equipmentId?: string) => void;
  placeholder: string;
}

const equipmentsQueryInitialState: TypeaheadedEquipmentsQuery = {
  loading: false,
  searchValue: '',
  options: [],
};

const jobStatesAllowingEquipmentRemoval = [
  JobState.ACCEPTED,
  JobState.ASSIGNED,
  JobState.CREATED,
  JobState.SENT,
];

export default function EquipmentSelector({
  companyId,
  equipment,
  job,
  onChange,
  placeholder,
}: EquipmentSelectorProps) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchor);

  const { ordersDispatchStore } = useStores();
  const { fetchEquipments } = useFetchers(companyId);
  const removeEquipmentRef = useRef<SVGSVGElement | null>(null);

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [equipmentsQuery, setEquipmentsQuery] = useState(equipmentsQueryInitialState);

  const isEquipmentRemovable = Boolean(
    equipment && jobStatesAllowingEquipmentRemoval.includes(job.status),
  );

  const isOpenAndEditable = isOpen && job.editable;
  const allNonSharedEquipmentsFetched =
    'nextNonSharedEquipmentsCursor' in equipmentsQuery &&
    _.isUndefined(equipmentsQuery.nextNonSharedEquipmentsCursor);
  const allSharedEquipmentsFetched =
    'nextSharedEquipmentsCursor' in equipmentsQuery &&
    _.isUndefined(equipmentsQuery.nextSharedEquipmentsCursor);
  const hasMore = !allNonSharedEquipmentsFetched || !allSharedEquipmentsFetched;

  const fetch = async () => {
    try {
      const requests: ReturnType<typeof fetchEquipments>[] = [];
      const largePage = allNonSharedEquipmentsFetched || allSharedEquipmentsFetched;

      if (allNonSharedEquipmentsFetched) {
        requests.push(Promise.resolve({ data: [], nextPageCursor: undefined }));
      } else {
        requests.push(
          fetchEquipments(
            equipmentsQuery.searchValue,
            equipmentsQuery.nonSharedEquipmentsCursor,
            false,
            largePage,
          ),
        );
      }

      if (allSharedEquipmentsFetched) {
        requests.push(Promise.resolve({ data: [], nextPageCursor: undefined }));
      } else {
        requests.push(
          fetchEquipments(
            equipmentsQuery.searchValue,
            equipmentsQuery.sharedEquipmentsCursor,
            true,
            largePage,
          ),
        );
      }

      const response = await Promise.all(requests);
      const allRequestsData = response.flatMap((response) => response.data);

      setEquipmentsQuery((state) => ({
        ...state,
        loading: false,
        options: _.uniqBy(state.options.concat(allRequestsData), (i) => i.value),
        nextNonSharedEquipmentsCursor: response[0].nextPageCursor,
        nextSharedEquipmentsCursor: response[1].nextPageCursor,
      }));
    } catch {
      setEquipmentsQuery((state) => ({
        ...state,
        loading: false,
        nextNonSharedEquipmentsCursor: undefined,
        nextSharedEquipmentsCursor: undefined,
      }));
    }
  };

  const handleFetchMore = useCallback(() => {
    if (!hasMore || !isOpenAndEditable) {
      return undefined;
    }

    setEquipmentsQuery((state) => {
      if (state.loading) {
        return state;
      }

      return {
        ...state,
        loading: FilterMenuItemLoadingReason.INFINITE_SCROLL,
        nonSharedEquipmentsCursor: state.nextNonSharedEquipmentsCursor,
        sharedEquipmentsCursor: state.nextSharedEquipmentsCursor,
      };
    });
  }, [hasMore, isOpenAndEditable]);

  useEffect(() => {
    if (equipmentsQuery.loading) {
      fetch();
    }
  }, [equipmentsQuery.loading]);

  useEffect(() => {
    const debouncedSearch = _.debounce(() => {
      setEquipmentsQuery(() => ({
        loading: FilterMenuItemLoadingReason.SEARCH_VALUE,
        options: [],
        searchValue: searchFieldValue,
      }));
    }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

    if (isOpenAndEditable) {
      debouncedSearch();
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [isOpenAndEditable, searchFieldValue]);

  useEffect(() => {
    if (!isOpenAndEditable) {
      setSearchFieldValue('');
      setEquipmentsQuery(equipmentsQueryInitialState);
    }
  }, [isOpenAndEditable]);

  if (!job.editable) {
    if (equipment) {
      return <OverflowAwareText>{getEquipmentLabel(equipment)}</OverflowAwareText>;
    }

    return null;
  }

  return (
    <>
      <ButtonBase
        disabled={Boolean(ordersDispatchStore.isUpdatingOrderJob.get(job.id))}
        sx={{
          alignItems: 'center',
          border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
          borderRadius: theme.spacing(0.5),
          gap: 0.5,
          height: 24,
          maxWidth: '100%',
          pl: 0.5,
          pr: equipment ? 0.5 : 0,
          backgroundColor: 'white',
          '&.Mui-disabled': { opacity: 0.6, pointerEvents: 'auto' },
          '&:hover': {
            backgroundColor: theme.brandV2.colors.treadGray10,
            borderColor: theme.brandV2.colors.treadGray3,
            '& .MuiSvgIcon-root': isEquipmentRemovable
              ? {
                  '&:hover': {
                    backgroundColor: theme.brandV2.colors.treadGray3,
                    color: 'white',
                  },
                }
              : { color: theme.brandV2.colors.treadGray2 },
          },
        }}
        onClick={(event) => {
          event.stopPropagation();

          if (event.target === removeEquipmentRef.current) {
            setAnchor(null);
            onChange();
          } else {
            setAnchor(event.currentTarget);
          }
        }}
      >
        <OverflowAwareText sx={{ flex: 1 }}>
          {equipment ? getEquipmentLabel(equipment) : placeholder}
        </OverflowAwareText>

        {isEquipmentRemovable ? (
          <BasicTooltip title={t('actions.remove_entity', { entity: placeholder })}>
            <Close
              ref={removeEquipmentRef}
              sx={{
                backgroundColor: 'transparent',
                borderRadius: '50%',
                color: theme.brandV2.colors.treadGray2,
                flex: 0,
                fontSize: '12px',
                height: '13px',
                minWidth: '13px',
                width: '13px',
              }}
            />
          </BasicTooltip>
        ) : (
          <ArrowDropDown
            sx={{
              color: theme.brandV2.colors.treadGray4,
              flex: 0,
              fontSize: '18px',
              minWidth: '18px',
            }}
          />
        )}
      </ButtonBase>

      <Menu
        anchorEl={anchor}
        open={isOpen}
        onClose={(event: React.MouseEvent) => {
          event.stopPropagation();
          setAnchor(null);
        }}
        sx={{
          '& .MuiPaper-root': {
            border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            boxShadow: '0px 1px 10px 0px rgba(60, 64, 67, 0.15)',
            mt: '3px',
            width: '250px',
          },
          '& .MuiItem-root': {
            px: 1.5,
          },
        }}
      >
        <SearchField
          isLoading={equipmentsQuery.loading === FilterMenuItemLoadingReason.SEARCH_VALUE}
          onChange={(value) => setSearchFieldValue(value)}
          setFocusOnMount
          value={searchFieldValue}
        />

        <Divider sx={{ '&.MuiDivider-root': { mt: 0, mb: 1, mx: 2 } }} />

        {equipmentsQuery.options.length > 0 && (
          <Box maxHeight={FILTER_MENU_ITEM_HEIGHT_IN_PX * 5} overflow="auto">
            {equipmentsQuery.options.map((availableEquipment, index) => (
              <React.Fragment key={availableEquipment.value}>
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    setAnchor(null);
                    onChange(availableEquipment.value);
                  }}
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                    justifyContent="space-between"
                    width="100%"
                  >
                    <OverflowAwareText>{availableEquipment.label}</OverflowAwareText>

                    {availableEquipment.value === equipment?.id && (
                      <Check sx={{ fontSize: '16px' }} />
                    )}
                  </Box>
                </MenuItem>

                {index === equipmentsQuery.options.length - 1 && hasMore && (
                  <InfiniteScrollLoadingIndicator
                    alignItems="center"
                    display="flex"
                    height={FILTER_MENU_ITEM_HEIGHT_IN_PX}
                    isLoading={
                      equipmentsQuery.loading ===
                      FilterMenuItemLoadingReason.INFINITE_SCROLL
                    }
                    justifyContent="center"
                    onFetchMore={handleFetchMore}
                  />
                )}
              </React.Fragment>
            ))}
          </Box>
        )}
      </Menu>
    </>
  );
}
