import {
  AuthMethod,
  BillingStatus,
  Company_Create,
  Company_Read as CompanyProto,
  Company_Update,
  CompanyType,
  Language,
  SaasBillingPlan,
  UsageStatus,
} from '@treadinc/horizon-api-spec';
import _ from 'lodash';

import { data as enums } from '~constants/enums';
import { AddressItem } from '~hooks/useAddress';
import { CompanyBasic } from '~hooks/useCompany';
import { ContactItem } from '~hooks/useContact';
import { Nullable } from '~types/Nullable';

export class AdminCompany {
  public static parse(proto: CompanyProto): AdminCompany {
    return new AdminCompany(
      proto?.id ?? '',
      proto?.created_at ?? null,
      proto.parent_company ? CompanyBasic.parse(proto.parent_company) : null,
      proto.legal_name ?? '',
      proto?.dba_name ?? '',
      proto?.address ? AddressItem.parse(proto.address) : null,
      proto.company_type ?? null,
      proto?.tread_customer ?? '',
      proto?.language ?? enums.language.default,
      proto?.date_format ?? enums.date_format.default,
      proto?.time_format ?? enums.time_format.default,
      proto?.system_of_measure ?? enums.system_of_measure.default,
      proto?.unit_of_distance ?? enums.unit_of_distance.default,
      proto.currency ?? enums.currency.default,
      proto?.time_zone ?? enums.time_zone.default,
      proto?.primary_contact ? ContactItem.parse(proto.primary_contact) : null,
      proto?.billing_contact ? ContactItem.parse(proto.billing_contact) : null,
      proto.logo_url ?? null,
      proto?.usage_status ?? enums.usage_status.default,
      proto?.billing_status ?? enums.billing_status.default,
      proto?.saas_billing_plan ?? enums.saas_billing_plan.default,
      proto.auth_methods,
      proto.tread_id,
      proto.auto_complete_order,
      _.isNil(proto?.default_lat) ? null : Number(proto.default_lat),
      _.isNil(proto?.default_lon) ? null : Number(proto?.default_lon),
    );
  }

  public static deparse(proto: any): Company_Create | Company_Update {
    const data = {
      parent_company_id: proto.parentCompany?.id,
      legal_name: proto.legalName,
      dba_name: proto.doingBusinessName,
      address: AddressItem.deparse(proto.address),
      company_type: proto.companyType,
      primary_contact: ContactItem.deparseCompany(proto.primaryContact),
      billing_contact: ContactItem.deparseCompany(proto.billingContact),
      tread_customer: true, // Tread default
      language: enums.language.default,
      time_format: proto.timeFormat,
      date_format: proto.dateFormat,
      system_of_measure: proto.systemOfMeasure,
      unit_of_distance: proto.unitOfDistance.value,
      currency: proto.currency,
      time_zone: proto.timeZone,
      logo_url: proto.logoUrl,
      usage_status: proto.usageStatus || enums.usage_status.default,
      billing_status: proto.billingStatus || enums.billing_status.default,
      saas_billing_plan: proto.SaasBillingPlan || enums.saas_billing_plan.default,
      default_lat: proto.defaultLat.toString(),
      default_lon: proto.defaultLon.toString(),
    };

    if (proto.id) {
      const updateAdminCompanyData = data as Company_Update;
      updateAdminCompanyData.auth_methods = proto.authMethods;

      // Password login strategy is hidden in the UI because it cannot be disabled, but BE still
      // Expects it to be present the "auth_methods" field of the payload.
      if (!(updateAdminCompanyData.auth_methods ?? []).includes(AuthMethod.PASSWORD)) {
        updateAdminCompanyData.auth_methods?.push(AuthMethod.PASSWORD);
      }
    }

    // ?? maybe ID is required in case of edit company
    return data as Company_Create | Company_Update;
  }

  public get id(): string {
    return this._id;
  }

  public get createdAt(): string {
    return this._created_at;
  }

  public get legalName(): string {
    return this._legal_name;
  }

  public get parentCompany(): Nullable<CompanyBasic> {
    return this._parentCompany;
  }

  public get dbaName(): string {
    return this._dba_name;
  }

  public get isTreadCustomer(): boolean {
    return this._tread_customer;
  }

  public get address(): Nullable<AddressItem> {
    return this._address;
  }

  public get primaryContact(): Nullable<ContactItem> {
    return this._primary_contact;
  }

  public get billingContact(): Nullable<ContactItem> {
    return this._billing_contact;
  }

  public get timeZone(): Nullable<string> {
    return this._time_zone;
  }

  public get companyType(): Nullable<CompanyType> {
    return this._company_type;
  }
  public get language(): Language {
    return this._language;
  }

  public get timeFormat(): string {
    return this._time_format;
  }

  public get dateFormat(): string {
    return this._date_format;
  }

  public get unitOfDistance(): string {
    return this._unit_of_distance;
  }
  public get systemOfMeasure(): string {
    return this._system_of_measure;
  }

  public get currency(): string {
    return this._currency;
  }
  public get logoUrl(): Nullable<string> {
    return this._logo_url;
  }

  public get usageStatus(): Nullable<UsageStatus> {
    return this._usage_status;
  }

  public get billingStatus(): Nullable<BillingStatus> {
    return this._billing_status;
  }

  public get saasBillingPlan(): Nullable<SaasBillingPlan> {
    return this._saas_billing_plan;
  }

  public get country(): string {
    return 'CA';
  }

  public get authMethods() {
    return this._auth_methods;
  }

  public get treadId(): string {
    return this._tread_id;
  }

  public get autoCompleteOrder() {
    return this._auto_complete_order;
  }

  public get defaultLat(): Nullable<number> {
    return this._default_lat;
  }

  public get defaultLon(): Nullable<number> {
    return this._default_lon;
  }

  constructor(
    private _id: string,
    private _created_at: string,
    private _parentCompany: Nullable<CompanyBasic>,

    private _legal_name: string,
    private _dba_name: string,
    private _address: Nullable<AddressItem>,

    private _company_type: CompanyType,

    private _tread_customer: boolean,

    private _language: Language,
    private _date_format: string,
    private _time_format: string,
    private _system_of_measure: string,
    private _unit_of_distance: string,
    private _currency: string,
    private _time_zone: string,

    private _primary_contact: Nullable<ContactItem>,
    private _billing_contact: Nullable<ContactItem>,
    private _logo_url: Nullable<string>,
    private _usage_status: Nullable<UsageStatus>,
    private _billing_status: Nullable<BillingStatus>,
    private _saas_billing_plan: Nullable<SaasBillingPlan>,
    private _auth_methods: Array<AuthMethod>,
    private _tread_id: string,
    private _auto_complete_order: boolean,

    private _default_lat: Nullable<number>,
    private _default_lon: Nullable<number>,
  ) {}
}
