import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { t } from 'i18next';
import React from 'react';

import { BasicTooltip } from '~components/Tooltip/BasicTooltip';

interface Props {
  props: React.HTMLAttributes<HTMLElement>;
  option: Record<string, any>;
  getLabel: (value: Record<string, any> | any) => string;
  inputValue: string;
  selected?: boolean;
  isCheckEnabled?: boolean;
  onDelete?: () => void;
}
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const SelectOption = ({
  props,
  option,
  getLabel,
  inputValue,
  selected = false,
  isCheckEnabled = true,
  onDelete,
}: Props) => {
  const str = getLabel(option);
  const matches = match(str, inputValue, {
    insideWords: true,
  });
  const parts = parse(str, matches);
  const theme = useTheme();
  return (
    <Box component="li" {...props}>
      {isCheckEnabled && (
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: '8px', padding: 0 }}
          checked={selected}
        />
      )}
      <BasicTooltip
        title={str}
        placement="right-start"
        enterTouchDelay={500}
        enterDelay={500}
        enterNextDelay={500}
      >
        <Typography noWrap variant="body2" sx={{ display: 'flex', flex: '1' }}>
          {parts.map((part, index) => (
            <span
              key={`${part?.text}-${index}`}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {/* Spaces won't be rendered in a span so we replace with nbsp character */}
              {part.text.replaceAll(' ', '\u00a0')}
            </span>
          ))}
        </Typography>
      </BasicTooltip>
      {onDelete && (
        <Box>
          <BasicTooltip
            title={t('actions.delete')}
            placement="right-start"
            enterTouchDelay={500}
            enterDelay={500}
            enterNextDelay={500}
          >
            <Typography noWrap variant="body2">
              <DeleteOutline
                fontSize="small"
                style={{ padding: 0, color: `${theme.brandV2.colors.treadBlack}` }}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete?.();
                }}
              />
            </Typography>
          </BasicTooltip>
        </Box>
      )}
    </Box>
  );
};

export { SelectOption };
