import { Active, DragEndEvent, DragStartEvent, Over } from '@dnd-kit/core';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _, { uniqBy } from 'lodash';
import { runInAction } from 'mobx';
import { useState } from 'react';

import { Driver } from '~hooks/useDrivers';
import { Job } from '~hooks/useJob';
import { useDriverSchedulerFetch } from '~pages/Dispatch/hooks/useDriverSchedulerFetch';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';

import {
  DUMMY_COLUMN_INCREMENTS,
  INCREMENTS_PER_HOUR,
  MINUTES_PER_INCREMENT,
} from '../components/drivers/constants';

export enum ConfirmChangeReason {
  DRIVER_CHANGE = 'DRIVER_CHANGE',
  DRIVER_REMOVAL = 'DRIVER_REMOVAL',
  VENDOR_CHANGE = 'VENDOR_CHANGE',
  VENDOR_REMOVAL = 'VENDOR_REMOVAL',
}

type ConfirmChangeProps =
  | {
      reason: ConfirmChangeReason.DRIVER_CHANGE;
      props: {
        over: Over;
        active: Active;
      };
    }
  | {
      reason: ConfirmChangeReason.DRIVER_REMOVAL;
      props: {
        active: Active;
      };
    }
  | {
      reason: ConfirmChangeReason.VENDOR_CHANGE;
      props: {
        over: Over;
        active: Active;
      };
    }
  | {
      reason: ConfirmChangeReason.VENDOR_REMOVAL;
      props: {
        active: Active;
      };
    };

type ConfirmChangeState = {
  state: boolean;
  content: string;
} & ConfirmChangeProps;

export const useDriverScheduler = () => {
  const { toasterStore, driverSchedulerStore } = useStores();
  const { assignVendor, unassignDriver, unassignVendor, updateJob } =
    useDriverSchedulerFetch();
  const [unassignedJobs, setUnassignedJobs] = useState<Job[]>([]);
  // Used to preserve the original order of unassigned jobs
  // Allows us to sort the unassigned jobs by their original order when unassigning a job
  const [originalUnassignedJobs, setOriginalUnassignedJobs] = useState<Job[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [isConfirmingChange, setIsConfirmingChange] = useState<ConfirmChangeState>({
    state: false,
    content: '',
    reason: ConfirmChangeReason.DRIVER_CHANGE,
    props: {
      over: undefined as unknown as Over,
      active: undefined as unknown as Active,
    },
  });

  const columnIndexToDateString = (
    columnIndex: number,
    startDate: string | undefined,
  ): string => {
    const selectedDate = dayjs.tz(startDate);
    // Subtract the total dummy column increments to calculate the correct time
    const adjustedColumnIndex = columnIndex - DUMMY_COLUMN_INCREMENTS;
    const hour = Math.floor(adjustedColumnIndex / INCREMENTS_PER_HOUR);
    const minute = (adjustedColumnIndex % INCREMENTS_PER_HOUR) * MINUTES_PER_INCREMENT;
    const date = selectedDate.hour(hour).minute(minute).startOf('minute');
    return date.toISOString();
  };

  const assignAndUpdateJob = async (
    job: Job,
    assigneeId: string,
    newStartDate: string,
    columnIndex: number,
    assignedVendorDidChange: boolean,
  ) => {
    const isTargetingDriver =
      driverSchedulerStore.assigneesFilters.assigneeType !== 'vendor';

    // Optimistic update start
    const previousAssignments = { ...driverSchedulerStore.jobAssignments };
    const previousUnassignedJobs = [...driverSchedulerStore.unassignedJobs];
    const previousAssignedJobs = [...driverSchedulerStore.assignedJobs];

    const newAssignments: Record<string, Nullable<Job>> = {
      ...driverSchedulerStore.jobAssignments,
    };

    Object.keys(newAssignments).forEach((key) => {
      if (newAssignments[key] && newAssignments[key]?.id === job.id) {
        newAssignments[key] = null;
      }
    });
    newAssignments[`${assigneeId}:${columnIndex}`] = job;

    runInAction(() => {
      driverSchedulerStore.setJobAssignments(newAssignments);
    });

    // Optimistic update end

    try {
      let updatedJob: Job | null = null;

      if (isTargetingDriver) {
        updatedJob = await updateJob(
          job.id,
          {
            ...job,
            driver_id: assigneeId,
            jobStartAt: newStartDate,
          },
          true,
        );
      } else {
        if (assignedVendorDidChange) {
          updatedJob = await assignVendor(job.id, assigneeId);
        }

        updatedJob = await updateJob(job.id, { ...job, jobStartAt: newStartDate }, true);
      }

      runInAction(() => {
        if (!updatedJob) {
          throw new Error('Updated job is null or undefined');
        }

        // Update the assigned jobs
        driverSchedulerStore.assignedJobs = uniqBy(
          [...driverSchedulerStore.assignedJobs, updatedJob],
          (j) => j.id,
        );

        // Remove the job from unassigned jobs
        driverSchedulerStore.unassignedJobs = driverSchedulerStore.unassignedJobs.filter(
          (unassignedJob) => unassignedJob.id !== job.id,
        );
      });

      toasterStore.removeFirst();
      toasterStore.push(
        alert(t('dispatch.drivers.job_successfully_assigned'), AlertTypes.success),
      );

      return { updatedJob };
    } catch (error) {
      console.error(
        `Failed to assign ${isTargetingDriver ? 'driver' : 'vendor'} and update job:`,
        error,
      );

      // Rollback optimistic update
      runInAction(() => {
        driverSchedulerStore.setJobAssignments(previousAssignments);
        driverSchedulerStore.unassignedJobs = previousUnassignedJobs;
        driverSchedulerStore.assignedJobs = previousAssignedJobs;
      });

      throw error;
    }
  };

  const assignAndUpdateJobTransaction = async (over: Over, active: Active) => {
    const allJobs = _.uniqBy(
      [...driverSchedulerStore.assignedJobs, ...driverSchedulerStore.unassignedJobs],
      (job) => job.id,
    );
    const draggedJob = allJobs.find((job) => job.id === active.id);

    if (!draggedJob) {
      return;
    }

    const [assigneeId, columnIndex] = String(over.id).split(':');

    const previousAssignments = { ...driverSchedulerStore.jobAssignments };
    const previousUnassignedJobs = [...driverSchedulerStore.unassignedJobs];
    const previousAssignedJobs = [...driverSchedulerStore.assignedJobs];
    const newAssignments: Record<string, Nullable<Job>> = {
      ...driverSchedulerStore.jobAssignments,
    };

    Object.keys(newAssignments).forEach((key) => {
      if (newAssignments[key] && newAssignments[key]?.id === active.id) {
        newAssignments[key] = null;
      }
    });
    newAssignments[`${assigneeId}:${columnIndex}`] = draggedJob;

    runInAction(() => {
      driverSchedulerStore.jobAssignments = newAssignments;
      driverSchedulerStore.unassignedJobs = driverSchedulerStore.unassignedJobs.filter(
        (job) => job.id !== active.id,
      );
      driverSchedulerStore.assignedJobs = uniqBy(
        [...driverSchedulerStore.assignedJobs, draggedJob],
        (job) => job.id,
      );
    });

    const selectedStartDate = driverSchedulerStore.dateFilters.startDate;
    const newStartDate = columnIndexToDateString(Number(columnIndex), selectedStartDate);

    setIsConfirmingChange({
      state: false,
      content: '',
      reason: ConfirmChangeReason.DRIVER_CHANGE,
      props: {
        over: undefined as unknown as Over,
        active: undefined as unknown as Active,
      },
    });

    const isTargetingDriver =
      driverSchedulerStore.assigneesFilters.assigneeType !== 'vendor';

    try {
      const isJobAssigned = Boolean(
        draggedJob.driver?.id ?? draggedJob.vendorJobAssignment?.vendorAccount?.id,
      );

      const shouldUnassignDriver =
        isJobAssigned && isTargetingDriver && draggedJob.driver?.id !== assigneeId;

      const assignedVendorDidChange =
        !isTargetingDriver &&
        draggedJob.vendorJobAssignment?.vendorAccount?.id !== assigneeId;
      const shouldUnassignVendor = isJobAssigned && assignedVendorDidChange;

      if (shouldUnassignDriver) {
        await unassignDriver(draggedJob.id);
      } else if (shouldUnassignVendor) {
        await unassignVendor(draggedJob.id);
      }

      await assignAndUpdateJob(
        draggedJob,
        assigneeId,
        newStartDate,
        Number(columnIndex),
        assignedVendorDidChange,
      );
    } catch (error) {
      console.error(
        `Failed to assign ${isTargetingDriver ? 'driver' : 'vendor'} and update job:`,
        error,
      );
      // Rollback the optimistic update if there's an error
      runInAction(() => {
        driverSchedulerStore.jobAssignments = previousAssignments;
        driverSchedulerStore.unassignedJobs = previousUnassignedJobs;
        driverSchedulerStore.assignedJobs = previousAssignedJobs;
      });
    }
  };

  const unassignTransaction = async (active: Active) => {
    const assignedJob = driverSchedulerStore.assignedJobs.find(
      (job) => job.id === active.id,
    );

    if (!assignedJob) {
      return;
    }

    // Optimistically update unassignedJobs and jobAssignments
    const previousAssignments = { ...driverSchedulerStore.jobAssignments };
    const previousUnassignedJobs = [...driverSchedulerStore.unassignedJobs];
    const previousAssignedJobs = [...driverSchedulerStore.assignedJobs];

    const newAssignments: Record<string, Nullable<Job>> = { ...previousAssignments };
    const newAssignedJobs = previousAssignedJobs.filter((job) => job.id !== active.id);

    Object.keys(newAssignments).forEach((key) => {
      if (newAssignments[key] && newAssignments[key]?.id === active.id) {
        newAssignments[key] = null; // Or delete newAssignments[key];
      }
    });

    runInAction(() => {
      driverSchedulerStore.jobAssignments = newAssignments;
      driverSchedulerStore.assignedJobs = newAssignedJobs;
      driverSchedulerStore.unassignedJobs = uniqBy(
        [...driverSchedulerStore.unassignedJobs, assignedJob],
        (job) => job.id,
      ).sort(
        (a, b) =>
          originalUnassignedJobs.findIndex((job) => job.id === a.id) -
          originalUnassignedJobs.findIndex((job) => job.id === b.id),
      );
    });

    setIsConfirmingChange({
      state: false,
      content: '',
      reason: ConfirmChangeReason.DRIVER_CHANGE,
      props: {
        over: undefined as unknown as Over,
        active: undefined as unknown as Active,
      },
    });

    const isTargetingDriver =
      driverSchedulerStore.assigneesFilters.assigneeType !== 'vendor';

    // Perform the actual unassign call
    try {
      if (isTargetingDriver) {
        await unassignDriver(assignedJob.id);
      } else {
        await unassignVendor(assignedJob.id);
      }

      toasterStore.removeFirst();
      toasterStore.push(
        alert(t('dispatch.drivers.job_successfully_unassigned'), AlertTypes.success),
      );
    } catch (error) {
      console.error(
        `Failed to unassign ${isTargetingDriver ? 'driver' : 'vendor'}:`,
        error,
      );
      // Rollback the optimistic update if there's an error
      runInAction(() => {
        driverSchedulerStore.jobAssignments = previousAssignments;
        driverSchedulerStore.unassignedJobs = previousUnassignedJobs;
        driverSchedulerStore.assignedJobs = previousAssignedJobs;
      });
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const unassignedJob = driverSchedulerStore.unassignedJobs.find(
      (job) => job.id === active.id,
    );
    const assignedJob = driverSchedulerStore.assignedJobs.find(
      (job) => job.id === active.id,
    );

    const draggedJob = assignedJob || unassignedJob;
    driverSchedulerStore.setDraggedJob(draggedJob || null);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) {
      driverSchedulerStore.setDraggedJob(null);
      return;
    }

    const draggedJob = driverSchedulerStore.draggedJob;

    if (!draggedJob) {
      driverSchedulerStore.setDraggedJob(null);
      return;
    }

    const isTargetingDriver =
      driverSchedulerStore.assigneesFilters.assigneeType !== 'vendor';

    if (over.id === 'jobs-column') {
      const isJobAssigned = Boolean(
        draggedJob.driver?.id ?? draggedJob.vendorJobAssignment?.vendorAccount?.id,
      );

      if (isJobAssigned) {
        setIsConfirmingChange({
          state: true,
          reason: isTargetingDriver
            ? ConfirmChangeReason.DRIVER_REMOVAL
            : ConfirmChangeReason.VENDOR_REMOVAL,
          content: isTargetingDriver
            ? t('dispatch.drivers.confirm_driver_removal')
            : t('dispatch.drivers.confirm_vendor_removal'),
          props: { active },
        });
      }
    } else {
      const [assigneeId] = String(over.id).split(':');
      const jobAssigneeId =
        draggedJob.driver?.id ?? draggedJob.vendorJobAssignment?.vendorAccount?.id;
      const isJobAssigned = jobAssigneeId && jobAssigneeId !== assigneeId;

      if (isJobAssigned) {
        setIsConfirmingChange({
          state: true,
          reason: isTargetingDriver
            ? ConfirmChangeReason.DRIVER_CHANGE
            : ConfirmChangeReason.VENDOR_CHANGE,
          content: isTargetingDriver
            ? t('dispatch.drivers.confirm_driver_change')
            : t('dispatch.drivers.confirm_vendor_change'),
          props: { over, active },
        });
      } else {
        await assignAndUpdateJobTransaction(over, active);
      }
    }

    driverSchedulerStore.setDraggedJob(null);
  };

  const handleDragCancel = () => {
    driverSchedulerStore.setDraggedJob(null);
  };

  const handleCellDrop = (assigneeId: string, columnIndex: number) => {
    const draggedJob = driverSchedulerStore.draggedJob;
    if (draggedJob) {
      const newAssignments: Record<string, Nullable<Job>> = {
        ...driverSchedulerStore.jobAssignments,
      };

      // Remove the job from its previous assignment
      Object.keys(newAssignments).forEach((key) => {
        if (newAssignments[key]?.id === draggedJob.id) {
          newAssignments[key] = null; // Or delete newAssignments[key];
        }
      });

      // Add the job to its new assignment
      newAssignments[`${assigneeId}:${columnIndex}`] = draggedJob;

      runInAction(() => {
        driverSchedulerStore.jobAssignments = newAssignments;
        driverSchedulerStore.unassignedJobs = driverSchedulerStore.unassignedJobs.filter(
          (job) => job.id !== draggedJob.id,
        );
      });
    }
  };

  return {
    unassignedJobs,
    setUnassignedJobs,
    originalUnassignedJobs,
    setOriginalUnassignedJobs,
    drivers,
    setDrivers,
    isConfirmingChange,
    setIsConfirmingChange,
    assignAndUpdateJobTransaction,
    unassignTransaction,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    handleCellDrop,
  };
};
