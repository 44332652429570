import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { PhaseOptions, PhaseSelection } from '~components/Order/PhaseSelection';
import { FeatureFlags } from '~constants/featureFlags';
import { AccountTypeahead } from '~hooks/useAccount';
import { useDepartment } from '~hooks/useDepartment';
import { Project, useProjects } from '~hooks/useProjects';
import { Phase } from '~hooks/useProjects/models';
import { useStores } from '~store';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

interface ProjectDetailsV2Props {
  companyId?: string;
}

export default function ProjectDetailsV2({ companyId }: ProjectDetailsV2Props) {
  const form = useFormContext();

  const { companyAssetsStore } = useStores();

  const projectPhaseEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.projectPhase,
  });

  const { getProjectsByCompanyId, getProjectPhasesTypeahead } = useProjects();
  const { getDepartmentByCompanyId } = useDepartment();

  const [loaded, setLoaded] = useState(false);
  const [localPhaseNameOptions, setLocalPhaseNameOptions] = useState<PhaseOptions[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<PhaseOptions | null>(null);

  const departmentOptions = companyAssetsStore.departments;

  const watchedAccount = form.watch('account') as AccountTypeahead | null;
  const watchedProject = form.watch('project') as Project | null;
  const watchedPhases = form.watch('phases') as Phase[] | null;
  const watchedOrderId = form.watch('id') as string | undefined;

  const allowCreate = Boolean(!watchedProject?.id || watchedOrderId);

  useEffect(() => {
    if (companyId) {
      getProjectsByCompanyId(companyId);
      getDepartmentByCompanyId(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (watchedAccount?.id) {
      setLocalPhaseNameOptions([]);
      setSelectedPhase(null);
    }
  }, [watchedAccount?.id]);

  useEffect(() => {
    // only clear phase options on create, not on update
    if (!watchedOrderId && !watchedProject?.id) {
      setLocalPhaseNameOptions([]);
      setSelectedPhase(null);
    }
  }, [watchedProject?.id, watchedOrderId]);

  useEffect(() => {
    if (!loaded) {
      if (watchedPhases) {
        setLocalPhaseNameOptions(
          watchedPhases.map((item: Phase) => ({
            id: item.id,
            name: item.name,
            code: item.code,
            primary: item.primary,
          })),
        );

        const primary = watchedPhases.find((item: Phase) => item.primary);

        if (primary) {
          const phaseOption: PhaseOptions = {
            id: primary.id,
            name: primary.name,
            code: primary.code,
            primary: true,
            _destroy: 0,
          };

          setSelectedPhase(phaseOption);
        }
      }

      setLoaded(true);
    }
  }, [JSON.stringify(watchedPhases), loaded]);

  useEffect(() => {
    async function fetchData() {
      if (watchedProject?.id && !watchedOrderId && loaded) {
        const response = await getProjectPhasesTypeahead({
          projectId: watchedProject?.id,
          query: '',
        });

        const phases =
          response?.data.map((phase) => ({
            id: phase.id,
            name: phase.name,
            code: phase.code,
            primary: phase.primary,
          })) || [];

        setLocalPhaseNameOptions(phases);
        setSelectedPhase(null);
      }
    }

    fetchData();
  }, [watchedProject?.id, loaded, watchedOrderId]);

  return (
    <>
      <Grid item xs={6}>
        <TextFormField
          control={form.control}
          disabled
          errors={form.formState.errors}
          isRequired={false}
          label={`${t('form_fields.project_id')}`}
          name="projectExternalId"
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteFormField
          clearable
          control={form.control}
          errors={form.formState.errors}
          getLabel={(item) => item.name || ''}
          getValue={(item) => item.id}
          groupBy={(item) => item?.typeName}
          label={`${t('project.form.department')}`}
          list={departmentOptions}
          name="department"
          sx={{ mr: 2 }}
        />
      </Grid>

      {projectPhaseEnabled && (
        <PhaseSelection
          canEdit={allowCreate}
          onPhaseOptionsChange={(options) => form.setValue('phases', options)}
          phaseOptions={localPhaseNameOptions}
          selectedPhase={selectedPhase}
        />
      )}
    </>
  );
}
