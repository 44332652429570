import CancelOutlined from '@mui/icons-material/CancelOutlined';
import SvgIcon from '@mui/icons-material/CancelOutlined';
import Check from '@mui/icons-material/Check';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import Pause from '@mui/icons-material/Pause';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { JobEventName } from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import React from 'react';
import { createElement, useEffect, useMemo, useState } from 'react';

import { Job, JobTripEvent, useJob } from '~hooks/useJob';
import theme from '~theme/AppTheme';

import {
  EVENT_TIMLINE_JOB_EVENT_TIME_FORMAT,
  EventTimeline,
  EventTimelineConnector,
  EventTimelineContent,
  EventTimelineContentContainer,
  EventTimelineDot,
  EventTimelineDotProps,
  EventTimelineIcon,
  EventTimelineItem,
  EventTimelinePrimaryText,
  EventTimelineSecondaryText,
  EventTimelineSeparator,
} from '../EventTimeline/EventTimeline';

const stateChangeJobEvents: JobEventName[] = [
  JobEventName.JOB_ACCEPTED,
  JobEventName.JOB_ASSIGNED,
  JobEventName.JOB_CANCELED,
  JobEventName.JOB_COMPLETED,
  JobEventName.JOB_CREATED,
  JobEventName.JOB_DECLINED,
  JobEventName.JOB_IN_REVIEW,
  JobEventName.JOB_PAUSED,
  JobEventName.JOB_REQUESTED,
  JobEventName.JOB_RESUMED,
  JobEventName.JOB_SENT,
  JobEventName.JOB_UNASSIGNED,
];

const loadChangeJobEvents: JobEventName[] = [
  JobEventName.AT_DROPOFF,
  JobEventName.AT_PICKUP,
  JobEventName.LOADED,
  JobEventName.LOAD_CANCELED,
  JobEventName.LOAD_COMPLETED,
  JobEventName.TO_DROPOFF,
  JobEventName.TO_PICKUP,
  JobEventName.UNLOADED,
];

enum JobTimelineEventType {
  LOAD_CHANGE = 'load_change',
  STATE_CHANGE = 'state_change',
}

type JobTimelineEntry = { type: JobTimelineEventType; timeline: JobTripEvent };

interface JobTimelineComponentProps {
  isExpanded?: boolean;
  isGrouped?: boolean;
  isLast?: boolean;
  loadNumber?: number;
  onExpand?: () => void;
  timeline: JobTripEvent;
}

const componentsByJobTimelineEventType: Record<
  JobTimelineEventType,
  React.JSXElementConstructor<JobTimelineComponentProps>
> = {
  [JobTimelineEventType.LOAD_CHANGE]: LoadChangeTimelineItem,
  [JobTimelineEventType.STATE_CHANGE]: StateChangeTimelineItem,
};

const jobEventIconsByJobEventName: Partial<
  Record<JobEventName, { icon: typeof SvgIcon; color: string }>
> = {
  [JobEventName.JOB_CANCELED]: {
    icon: CancelOutlined,
    color: theme.brandV2.colors.treadRed,
  },
  [JobEventName.JOB_DECLINED]: {
    icon: CancelOutlined,
    color: theme.brandV2.colors.treadRed,
  },
  [JobEventName.LOAD_CANCELED]: {
    icon: CancelOutlined,
    color: theme.brandV2.colors.treadRed,
  },
};

const timlineDotIconByJobEventName: Partial<
  Record<JobEventName, { icon: typeof SvgIcon; color: string; size: number }>
> = {
  [JobEventName.JOB_COMPLETED]: {
    icon: Check,
    color: theme.brandV2.colors.treadGreen,
    size: 12,
  },
  [JobEventName.JOB_CREATED]: {
    icon: FiberManualRecord,
    color: theme.brandV2.colors.treadGreen,
    size: 8,
  },
  [JobEventName.JOB_PAUSED]: {
    icon: Pause,
    color: theme.brandV2.colors.treadYellowDark,
    size: 12,
  },
  [JobEventName.JOB_RESUMED]: {
    icon: PlayArrow,
    color: theme.brandV2.colors.treadGreen,
    size: 12,
  },
};

const groupEvents = (timeline: JobTripEvent[]) => {
  const prunedTimeline = timeline.filter((item) => {
    const isStateChangeEvent = stateChangeJobEvents.includes(item.eventName);
    const isLoadChangeEvent = loadChangeJobEvents.includes(item.eventName);

    return isStateChangeEvent || isLoadChangeEvent;
  });

  const loadPointer = { loadId: '', index: 0 };

  const groupedTimeline = prunedTimeline.reduce(
    (acc, event) => {
      const isStateChangeEvent = stateChangeJobEvents.includes(event.eventName);

      if (isStateChangeEvent) {
        acc.push({
          createdAt: event.createdAt,
          type: JobTimelineEventType.STATE_CHANGE,
          entries: { type: JobTimelineEventType.STATE_CHANGE, timeline: event },
        });

        return acc;
      }

      if (event.loadId !== loadPointer.loadId) {
        loadPointer.loadId = String(event.loadId);
        loadPointer.index = acc.length;

        acc.push({
          createdAt: event.createdAt,
          type: JobTimelineEventType.LOAD_CHANGE,
          entries: [{ type: JobTimelineEventType.LOAD_CHANGE, timeline: event }],
        });
      } else {
        (acc[loadPointer.index].entries as JobTimelineEntry[]).push({
          type: JobTimelineEventType.LOAD_CHANGE,
          timeline: event,
        });
      }

      return acc;
    },
    [] as Array<{
      createdAt: Dayjs;
      type: JobTimelineEventType;
      entries: JobTimelineEntry | JobTimelineEntry[];
    }>,
  );

  return groupedTimeline;
};

interface JobTimelineProps {
  job: Job;
  sx?: SxProps;
}

export default function JobTimeline({ job, sx }: JobTimelineProps) {
  const { getAllJobEvents } = useJob();
  const [timeline, setTimeline] = useState<JobTripEvent[]>([]);
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);

  const events = useMemo(() => groupEvents(timeline), [timeline]);
  let loadNumber = events.filter((event) => Array.isArray(event.entries)).length + 1;

  useEffect(() => {
    getAllJobEvents(job.id).then((response) => {
      setTimeline(response);
    });
  }, [job.id, job.status]);

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <EventTimeline>
        {events.map((item, index) => {
          const itemIsAGroupOfLoadEvents = Array.isArray(item.entries);

          if (itemIsAGroupOfLoadEvents) {
            const entries = item.entries as JobTimelineEntry[];
            loadNumber = loadNumber - 1;

            return entries.map((entry, entryIndex) => {
              const isFirst = entryIndex === 0;
              const isExpanded = expandedIndexes.includes(index);
              const isExpansible = Boolean(isFirst && entries.length > 1);

              if (isFirst || isExpanded) {
                return createElement(componentsByJobTimelineEventType[item.type], {
                  key: `${index}-${entryIndex}`,
                  isExpanded: isExpanded,
                  isGrouped: entryIndex !== 0,
                  isLast: index === events.length - 1,
                  loadNumber,
                  timeline: entry.timeline,
                  onExpand: isExpansible
                    ? () => {
                        setExpandedIndexes((currentExpandedIndexes) => {
                          const newCurrentExpandedIndexes = [...currentExpandedIndexes];
                          const indexPositionInList = newCurrentExpandedIndexes.findIndex(
                            (i) => i === index,
                          );

                          if (indexPositionInList > -1) {
                            newCurrentExpandedIndexes.splice(indexPositionInList, 1);
                          } else {
                            newCurrentExpandedIndexes.push(index);
                          }

                          return newCurrentExpandedIndexes;
                        });
                      }
                    : undefined,
                });
              }

              return null;
            });
          }

          const entry = item.entries as JobTimelineEntry;

          return createElement(componentsByJobTimelineEventType[item.type], {
            key: index,
            isLast: index === events.length - 1,
            timeline: entry.timeline,
          });
        })}
      </EventTimeline>
    </Box>
  );
}

function StateChangeTimelineItem({ isLast, timeline }: JobTimelineComponentProps) {
  const state = timeline.eventName;
  const icon = jobEventIconsByJobEventName[state];

  const dotIcon = timlineDotIconByJobEventName[state];
  const dotProps = useMemo(() => {
    const props: EventTimelineDotProps = {
      shape: 'dot',
      innerColor: theme.brandV2.colors.treadGreen,
    };

    if (
      [
        JobEventName.JOB_CANCELED,
        JobEventName.JOB_DECLINED,
        JobEventName.LOAD_CANCELED,
      ].includes(state)
    ) {
      props.innerColor = theme.brandV2.colors.treadRed;

      return props;
    }

    if (state === JobEventName.JOB_UNASSIGNED) {
      props.shape = 'square';
      props.innerColor = theme.brandV2.colors.treadRed;

      return props;
    }

    return props;
  }, [state]);

  return (
    <EventTimelineItem>
      <EventTimelineSeparator>
        <EventTimelineDot
          innerColor={dotIcon?.color ?? dotProps.innerColor}
          shape={dotIcon ? 'icon' : dotProps.shape}
        >
          {dotIcon &&
            createElement(EventTimelineIcon, {
              icon: dotIcon.icon,
              sx: {
                color: dotIcon.color,
                width: `${dotIcon.size}px`,
                height: `${dotIcon.size}px`,
              },
            })}
        </EventTimelineDot>

        {!isLast && <EventTimelineConnector />}
      </EventTimelineSeparator>

      <EventTimelineContentContainer hasDotIconAtLeft={Boolean(dotIcon)}>
        <EventTimelineContent
          date={timeline.createdAt}
          sx={{ ...(dotIcon ? { pt: '4px' } : {}) }}
        >
          <EventTimelinePrimaryText sx={{ color: icon?.color }}>
            {icon &&
              createElement(icon.icon, {
                sx: { color: icon.color, fontSize: '16px', mt: '-2px', mr: 0.25 },
              })}

            {t(`job_events.${_.snakeCase(state)}`)}
          </EventTimelinePrimaryText>

          <EventTimelineSecondaryText>
            {timeline.createdByName}
          </EventTimelineSecondaryText>
        </EventTimelineContent>
      </EventTimelineContentContainer>
    </EventTimelineItem>
  );
}

function LoadChangeTimelineItem({
  isExpanded,
  isGrouped,
  isLast,
  loadNumber,
  onExpand,
  timeline,
}: JobTimelineComponentProps) {
  const state = timeline.eventName;
  const isLoadCompleted = state === JobEventName.LOAD_COMPLETED;
  const icon = jobEventIconsByJobEventName[state];
  const dotProps = useMemo(() => {
    const props: EventTimelineDotProps = {
      shape: 'icon',
      innerColor: theme.brandV2.colors.treadGray7,
    };

    if (isGrouped && isLast) {
      props.shape = 'dot';
      props.innerColor = theme.brandV2.colors.treadGray7;
    } else if (isGrouped) {
      props.shape = 'empty';
    }

    return props;
  }, [isGrouped, isLast]);

  return (
    <EventTimelineItem>
      <EventTimelineSeparator>
        <EventTimelineDot innerColor={dotProps.innerColor} shape={dotProps.shape}>
          {!isGrouped && (
            <EventTimelineIcon
              icon={ExpandMoreOutlined}
              onClick={onExpand}
              sx={{
                cursor: 'pointer',
                transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
                transition: 'transform .2s linear',
              }}
            />
          )}
        </EventTimelineDot>

        {!isLast && <EventTimelineConnector />}
      </EventTimelineSeparator>

      <EventTimelineContentContainer
        hasDotIconAtLeft={!isGrouped}
        sx={{ ...(isGrouped ? { pl: '18px' } : {}) }}
      >
        <EventTimelineContent
          {...(isGrouped
            ? { date: timeline.createdAt }
            : { label: `${t('status.load')} ${loadNumber}`, sx: { pt: '4px' } })}
        >
          {isGrouped ? (
            <>
              <EventTimelinePrimaryText sx={{ color: icon?.color }}>
                {icon &&
                  createElement(icon.icon, {
                    sx: { color: icon.color, fontSize: '16px', mt: '-2px', mr: 0.25 },
                  })}

                {t(`job_events.${_.snakeCase(state)}`)}
              </EventTimelinePrimaryText>
              <EventTimelineSecondaryText>
                {timeline.createdByName}
              </EventTimelineSecondaryText>
            </>
          ) : (
            <EventTimelinePrimaryText sx={{ color: icon?.color }}>
              {icon &&
                createElement(icon.icon, {
                  sx: { color: icon.color, fontSize: '16px', mt: '-2px', mr: 0.25 },
                })}

              {isLoadCompleted
                ? t('status.delivered')
                : t(`job_events.${_.snakeCase(state)}`)}
              <EventTimelineSecondaryText component="span">{` • ${dayjs.tz(timeline.createdAt).format(EVENT_TIMLINE_JOB_EVENT_TIME_FORMAT)}`}</EventTimelineSecondaryText>
            </EventTimelinePrimaryText>
          )}
        </EventTimelineContent>
      </EventTimelineContentContainer>
    </EventTimelineItem>
  );
}
