import { makeObservable, observable, runInAction } from 'mobx';

import { TelematicsVehicle } from '~hooks/useTelematicsVehicles';
import { Pagination, PaginationLink } from '~services/pagination';
import { getPageNumber } from '~utils/pagination';

class TelematicsStore {
  remoteVehicles: TelematicsVehicle[] = [];

  remoteVehiclesPagination: Pagination = {
    limit: 25,
    after: '',
    before: '',
    page: 1,
  };

  constructor() {
    makeObservable(this, {
      remoteVehicles: observable,
    });
  }

  setRemoteVehiclesPagination(pagination: Pagination) {
    this.remoteVehiclesPagination = {
      ...this.remoteVehiclesPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }

  updateRemoteVehiclesPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.remoteVehiclesPagination = {
        ...this.remoteVehiclesPagination,
        page: getPageNumber(this.remoteVehiclesPagination.page, link),
      };
    });
  }

  setRemoteVehicles(remoteVehicles: TelematicsVehicle[]) {
    this.remoteVehicles = [...remoteVehicles];
  }

  updateRemoteVehicle(remoteVehicle: TelematicsVehicle) {
    this.remoteVehicles = this.remoteVehicles.map((rv) =>
      rv.id === remoteVehicle.id ? remoteVehicle : rv,
    );
  }
}

export default TelematicsStore;
