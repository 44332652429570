import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { t as $t } from 'i18next';
import { get } from 'lodash';
import React, { useCallback } from 'react';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { CheckBoxFormField } from '~components/FormFields/CheckBoxFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { AddressItem } from '~hooks/useAddress';
import { SupportedAddressProvider } from '~hooks/useAddress/models/AddressProvider';
import { useGeocode } from '~hooks/useGeocode';
import { useStores } from '~store';

interface Props {
  control: any;
  errors: any;
  connected: boolean;
  isBillingAddressHidden: boolean;
  selectedAccountId?: string;
}

const AccountGeneralInfo = ({
  control,
  errors,
  connected,
  isBillingAddressHidden,
  selectedAccountId,
}: Props) => {
  const { userStore } = useStores();
  const hasChildCompanies = userStore.userCompanies.length > 1;

  const addressProvider = SupportedAddressProvider.GOOGLE_MAPS_PLACES_AUTOCOMPLETE;
  const { getPlaces, getAddressItemByGoogleMapsPlaceId } = useGeocode({
    addressProvider,
  });

  const handleAddressChange = useCallback(
    async (item: AddressItem) => {
      if (addressProvider === SupportedAddressProvider.GOOGLE_MAPS_PLACES_AUTOCOMPLETE) {
        const addressItem = await getAddressItemByGoogleMapsPlaceId(item.placeId);

        return addressItem;
      }

      return item;
    },
    [getAddressItemByGoogleMapsPlaceId],
  );

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormSectionTitle title={$t('account.form.account_details')} />
        </Grid>
        {hasChildCompanies && (
          <Grid item xs={12}>
            <CompanySelectorFormField
              sx={{ width: '100%', minWidth: '100%' }}
              name={'company'}
              isRequired={true}
              disabled={!!selectedAccountId}
              errors={errors}
              control={control}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextFormField
            control={control}
            errors={errors}
            name="name"
            label={`${$t('form_fields.account_name')}`}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            control={control}
            errors={errors}
            name="externalId"
            label={`${$t('form_fields.external_id')}`}
          />
        </Grid>

        <Grid item xs={12}>
          <AutocompleteAsyncFormField
            control={control}
            errors={errors}
            name="address"
            getLabel={(item) => get(item, 'streetAddress', '')}
            getValue={(item) => get(item, 'placeId', '')}
            label={`${$t('form_fields.address')}`}
            placeholder={`${$t('common.address_autocomplete_hint')}`}
            asyncCallback={getPlaces}
            onSelect={handleAddressChange}
            isRequired={connected}
            debounceTime={500}
          />
        </Grid>
      </Grid>

      {(!connected || selectedAccountId) && (
        <Box>
          <CheckBoxFormField
            control={control}
            errors={errors}
            value={false}
            label={$t('account.form.billing_same_as_primary_address') || ''}
            name={'isBillingAddressSame'}
          />
          <Collapse in={!isBillingAddressHidden}>
            <AutocompleteAsyncFormField
              control={control}
              errors={errors}
              name="billingAddress"
              getLabel={(item) => get(item, 'streetAddress', '')}
              getValue={(item) => get(item, 'placeId', '')}
              label={`${$t('form_fields.billing_address')}`}
              placeholder={`${$t('common.address_autocomplete_hint')}`}
              asyncCallback={getPlaces}
              onSelect={handleAddressChange}
              sx={{ mt: 2 }}
            />
          </Collapse>
        </Box>
      )}
    </Card>
  );
};

export { AccountGeneralInfo };
