import {
  Account_Read_Nested,
  Agave_Customer_Read,
  Agave_Item_Read,
  Agave_Itemizable_Read,
  Agave_LedgerAccount_Read,
  Agave_LinkedAccount_Read,
  Agave_SourceSystem_Read,
  Agave_Vendor_Read,
  AgaveApi_Customer_Read,
  AgaveApi_Item_Read,
  AgaveApi_LedgerAccount_Read,
  AgaveApi_Vendor_Read,
  AgaveLedgerAccountType,
} from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { Nullable } from '~types/Nullable';

export class AgaveLinkedAccount {
  constructor(
    private _id: string,
    private _status: Agave_LinkedAccount_Read.status,
    private _synchronized_at: Nullable<Dayjs> | null,
    private _agave_source_system: Agave_SourceSystem_Read,
  ) {}

  public get id() {
    return this._id;
  }

  public get status(): Agave_LinkedAccount_Read.status {
    return this._status;
  }

  public get synchronizedAt(): Nullable<Dayjs> | null {
    return this._synchronized_at;
  }

  public get agaveSourceSystem(): Agave_SourceSystem_Read {
    return this._agave_source_system;
  }

  public static parse(proto: Agave_LinkedAccount_Read) {
    return new AgaveLinkedAccount(
      proto.id,
      proto.status as Agave_LinkedAccount_Read.status,
      proto.synchronized_at ? dayjs(proto.synchronized_at) : null,
      {
        name: proto.agave_source_system.name,
        slug: proto.agave_source_system.slug,
        theme: proto.agave_source_system.theme,
      },
    );
  }
}

export class AgaveCustomer {
  constructor(
    private _id: string,
    private _agaveApiCustomer: AgaveApi_Customer_Read,
    private _customerAccount: Nullable<Account_Read_Nested>,
  ) {}

  public get id() {
    return this._id;
  }

  public get agaveApiCustomer() {
    return this._agaveApiCustomer;
  }

  public get customerAccount(): Nullable<Account_Read_Nested> {
    return this._customerAccount;
  }

  public static parse(proto: Agave_Customer_Read) {
    return new AgaveCustomer(proto.id, proto.data, proto.customer_account ?? null);
  }
}

export class AgaveVendor {
  constructor(
    private _id: string,
    private _agaveApiVendor: AgaveApi_Vendor_Read,
    private _vendorAccount: Nullable<Account_Read_Nested>,
  ) {}

  public get id() {
    return this._id;
  }

  public get agaveApiVendor() {
    return this._agaveApiVendor;
  }

  public get vendorAccount(): Nullable<Account_Read_Nested> {
    return this._vendorAccount;
  }

  public static parse(proto: Agave_Vendor_Read) {
    return new AgaveVendor(proto.id, proto.data, proto.vendor_account ?? null);
  }
}

export class AgaveItem {
  constructor(
    private _id: string,
    private _agaveApiItem: AgaveApi_Item_Read,
    private _itemizable: Nullable<Agave_Itemizable_Read>,
  ) {}

  public get id() {
    return this._id;
  }

  public get agaveApiItem() {
    return this._agaveApiItem;
  }

  public get itemizable(): Nullable<Agave_Itemizable_Read> {
    return this._itemizable;
  }

  public static parse(proto: Agave_Item_Read) {
    return new AgaveItem(proto.id, proto.data, proto.agave_itemizable);
  }
}
export class AgaveLedgerAccount {
  constructor(
    private _id: string,
    private _agaveApiLedgerAccount: AgaveApi_LedgerAccount_Read,
    private _type: AgaveLedgerAccountType,
  ) {}

  public get id() {
    return this._id;
  }

  public get agaveApiLedgerAccount() {
    return this._agaveApiLedgerAccount;
  }

  public get type(): AgaveLedgerAccountType {
    return this._type;
  }

  public static parse(proto: Agave_LedgerAccount_Read) {
    return new AgaveLedgerAccount(
      proto.id,
      proto.data,
      proto.type as AgaveLedgerAccountType,
    );
  }
}
