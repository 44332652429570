import Close from '@mui/icons-material/Close';
import React from 'react';

interface DialogCloseButtonProps {
  callBack: (event?: React.MouseEvent<Element, MouseEvent>) => void;
}
const DialogCloseButton = ({ callBack }: DialogCloseButtonProps) => {
  return <Close sx={{ cursor: 'pointer' }} type="button" onClick={callBack}></Close>;
};
export { DialogCloseButton };
