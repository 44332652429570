import { InputBaseProps } from '@mui/material';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { DatePicker, TimePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';

import { FormFieldLabel } from '~components/FormFields/FormFieldLabel';

interface SingleDateFormFieldProps {
  control: Control<any, any>; // Form control
  errors: any; // Object of errors
  name: string;
  dateLabel?: string;
  timeLabel?: string;
  type?: string;
  disabled?: boolean;
  isRequired?: boolean;
  sx?: SxProps<Theme>; // Style
  placeholder?: string;
  InputProps?: Partial<OutlinedInputProps>;
  id?: string;
  inputProps?: InputBaseProps['inputProps'];
  multiline?: boolean;
  rows?: number;
  fullWidth?: boolean;
  views?: ('year' | 'month' | 'day' | 'hours' | 'minutes')[];
  clearOnNull?: boolean;
}

const SingleDateTimeFormField = ({
  control,
  errors,
  name,
  dateLabel,
  timeLabel,
  type = 'text',
  disabled = false,
  isRequired,
  sx,
  placeholder,
  id,
  inputProps,
  fullWidth = true,
  InputProps,
  views,
  clearOnNull = false,
}: SingleDateFormFieldProps) => {
  const form = useFormContext();
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs | null>(null);
  const watchedDateTime = form.watch(name);

  useEffect(() => {
    if (clearOnNull) {
      if (!selectedDate && !selectedTime) {
        setSelectedDate(null);
        setSelectedTime(null);
        form.setValue(name, undefined);
        return;
      }
    }

    if (
      !(selectedDate?.isValid() && selectedTime?.isValid() && selectedDate.year() > 2000)
    )
      return;

    const newValue = selectedDate
      .clone()
      .set('hour', selectedTime.hour())
      .set('minute', selectedTime.minute());

    form.setValue(name, newValue);
  }, [selectedDate, selectedTime]);

  useEffect(() => {
    if (watchedDateTime && watchedDateTime.toString().length > 0) {
      setSelectedDate(dayjs.tz(watchedDateTime));
      setSelectedTime(dayjs.tz(watchedDateTime));
    }
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box display={'flex'} flexDirection={'row'} gap={2} width={'100%'}>
          <FormGroup sx={{ width: '100%' }}>
            {dateLabel && (
              <FormFieldLabel label={`${dateLabel}${isRequired ? ' *' : ''}`} />
            )}
            <DatePicker
              {...field}
              value={field.value ? dayjs.tz(field.value) : selectedDate}
              closeOnSelect={false}
              slotProps={{
                textField: {
                  fullWidth: fullWidth,
                  placeholder: placeholder ?? 'mm/dd/yyyy',
                  required: isRequired,
                  variant: 'outlined',
                  className: isRequired ? 'required' : '',
                  sx: { ...sx },
                  error: Boolean(get(errors, name)),
                  inputProps: {
                    style: {
                      fontSize: '12px',
                      height: '23px',
                    },
                  },
                },
              }}
              disabled={disabled}
              onChange={(e) => setSelectedDate(e)}
            />
          </FormGroup>
          <FormGroup sx={{ width: '100%' }}>
            {timeLabel && (
              <FormFieldLabel label={`${timeLabel}${isRequired ? ' *' : ''}`} />
            )}
            <TimePicker
              {...field}
              value={field.value ? dayjs.tz(field.value) : selectedTime}
              closeOnSelect={false}
              slotProps={{
                textField: {
                  fullWidth: fullWidth,
                  required: isRequired,
                  variant: 'outlined',
                  className: isRequired ? 'required' : '',
                  sx: { ...sx },
                  error: Boolean(get(errors, name)),
                  inputProps: {
                    style: {
                      fontSize: '12px',
                      height: '23px',
                    },
                  },
                },
              }}
              onChange={(e) => setSelectedTime(e)}
              disabled={disabled}
            />
          </FormGroup>
        </Box>
      )}
    />
  );
};

export { SingleDateTimeFormField };
