import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AccountTypes } from '~constants/enums';
import { Account, AccountTypeahead } from '~hooks/useAccount';
import { AgaveCustomer, AgaveVendor } from '~hooks/useAgave/models';
import useAgave from '~hooks/useAgave/useAgave';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';

import { AccountsDialog } from '../Accounts/AccountsDialog';

const treadNameSelectorSchema = yup.object().shape({
  customerAccount: yup
    .object()
    .shape({
      name: yup.string().required(),
      id: yup.string().required(),
    })
    .nullable(),
});

type TreadNameSelectorDTO = yup.InferType<typeof treadNameSelectorSchema>;

interface TreadNameSelectorProps {
  type: AccountTypes.CUSTOMER | AccountTypes.VENDOR;
  agaveCustomer: Nullable<AgaveCustomer>;
  agaveVendor: Nullable<AgaveVendor>;
  linkedAccountId: string;
}

export const TreadNameSelector = observer(
  ({ type, agaveCustomer, agaveVendor, linkedAccountId }: TreadNameSelectorProps) => {
    const {
      getCustomerAccountsTypeahead,
      getVendorAccountsTypeahead,
      updateCustomer,
      updateVendor,
    } = useAgave();
    const { toasterStore } = useStores();
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const {
      control,
      setValue,
      formState: { errors },
    } = useForm<TreadNameSelectorDTO>({
      resolver: yupResolver(treadNameSelectorSchema),
      reValidateMode: 'onChange',
      defaultValues: getDefaultValues(type, agaveCustomer, agaveVendor),
    });

    const saveTreadNameForCustomer = async (data: Nullable<AccountTypeahead>) => {
      if (!agaveCustomer) return;
      const updatedCustomer = await updateCustomer(agaveCustomer.id, data?.id ?? null);
      if (!updatedCustomer) return;

      setValue('customerAccount', data ?? null);

      toasterStore.push(
        alert(
          t(
            'administration.integration.agave.quick_books_online.grid.tread_name_updated',
            {
              name: updatedCustomer.agaveApiCustomer.name,
            },
          ),
          AlertTypes.success,
        ),
      );

      return data ? data : null;
    };
    const saveTreadNameForVendor = async (data: Nullable<AccountTypeahead>) => {
      if (!agaveVendor) return;
      const updatedVendor = await updateVendor(agaveVendor.id, data?.id ?? null);
      if (!updatedVendor) return;

      setValue('customerAccount', data ?? null);

      toasterStore.push(
        alert(
          t(
            'administration.integration.agave.quick_books_online.grid.tread_name_updated',
            {
              name: updatedVendor.agaveApiVendor.name,
            },
          ),
          AlertTypes.success,
        ),
      );

      return data ? data : null;
    };

    return (
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
        <AutocompleteAsyncFormField
          control={control}
          errors={errors}
          name={'customerAccount'}
          getValue={(item) => item?.id || ''}
          getLabel={(item) => item?.name || ''}
          asyncCallback={
            type === 'customer'
              ? getCustomerAccountsTypeahead
              : getVendorAccountsTypeahead
          }
          extraRequestOptions={{
            linkedAccountId,
            pagination: {
              limit: 10,
            },
          }}
          filterSelectedOptions={false}
          clearable
          hideLabel
          disablePortal={false}
          onSelect={
            type === 'customer' ? saveTreadNameForCustomer : saveTreadNameForVendor
          }
          onClear={() =>
            type === 'customer'
              ? saveTreadNameForCustomer(null)
              : saveTreadNameForVendor(null)
          }
          inputProps={{
            onKeyDown: (event) => {
              if (event.key === ' ') {
                // override MUI's default behavior of consuming space key to prevent scrolling.
                // https://github.com/mui/material-ui/issues/34566
                event.stopPropagation();
              }
            },
          }}
          onInput={(_, value) => {
            setInputValue(value);
          }}
          noOptionsText={
            <Chip
              size={'small'}
              variant={'filled'}
              color={'primary'}
              sx={{ cursor: 'pointer' }}
              label={
                type === AccountTypes.CUSTOMER
                  ? t(
                      'administration.integration.agave.quick_books_online.grid.create_account',
                    )
                  : t(
                      'administration.integration.agave.quick_books_online.grid.create_vendor',
                    )
              }
              onClick={() => {
                setOpen(true);
              }}
            />
          }
        />
        <AccountsDialog
          open={open}
          onClose={() => setOpen(false)}
          disableAccountType={true}
          defaultAccount={
            Account.parse({
              account_types: [type],
              created_at: '',
              dba_name: null,
              department: null,
              external_id: null,
              name: inputValue,
              notes: '',
              updated_at: '',
            }) as Account
          }
        />
      </Box>
    );
  },
);

const getDefaultValues = (
  type: AccountTypes.CUSTOMER | AccountTypes.VENDOR,
  agaveCustomer: Nullable<AgaveCustomer>,
  agaveVendor: Nullable<AgaveVendor>,
) => {
  if (type === AccountTypes.CUSTOMER) {
    return {
      customerAccount: agaveCustomer?.customerAccount
        ? {
            id: agaveCustomer.customerAccount.id,
            name: agaveCustomer.customerAccount.name,
          }
        : null,
    };
  }

  if (type === AccountTypes.VENDOR) {
    return {
      customerAccount: agaveVendor?.vendorAccount
        ? {
            id: agaveVendor.vendorAccount.id,
            name: agaveVendor.vendorAccount.name,
          }
        : null,
    };
  }
};
