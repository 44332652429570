import Box from '@mui/material/Box';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import { ReactNode } from 'react';

import { Nullable } from '~types/Nullable';

import { DataGridCustomizedExport } from './DataGridCustomizedExport';

interface DataGridToolBarProps {
  toolbarAdditionalContent?: Nullable<ReactNode>;
  toolbarSidebarContent?: Nullable<ReactNode>;
  csvExportCallBack?: () => void;
  excelExportCallBack?: () => void;
  apiRef?: any;
  hideQuickFilter?: boolean;
  hideExport?: boolean;
}
const DataGridToolBar = ({
  toolbarAdditionalContent,
  toolbarSidebarContent,
  csvExportCallBack,
  excelExportCallBack,
  apiRef,
  hideQuickFilter = false,
  hideExport = false,
}: DataGridToolBarProps) => {
  const csvExportHandler = () => {
    apiRef?.current?.exportDataAsCsv?.();
    csvExportCallBack?.();
  };
  const excelExportHandler = () => {
    apiRef?.current?.exportDataAsExcel?.();
    excelExportCallBack?.();
  };
  return (
    <>
      <GridToolbarContainer
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            mb: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
            <Box
              sx={{
                order: 1,
                display: 'flex',
                mr: 2,
                ml: 0,
              }}
            >
              {toolbarSidebarContent}
            </Box>
            <Box display={'flex'} sx={{ order: 2 }}>
              <GridToolbarColumnsButton sx={{ mr: 1 }} variant="text" color="secondary" />
              <GridToolbarFilterButton
                componentsProps={{ button: { variant: 'text' } }}
                color="secondary"
              />
              {!hideExport && (
                <DataGridCustomizedExport
                  onCsvExport={csvExportHandler}
                  onExcelExport={excelExportHandler}
                />
              )}
            </Box>
          </Box>
          <Box display={'flex'} sx={{ alignItems: 'center' }}>
            {!hideQuickFilter && (
              <GridToolbarQuickFilter
                debounceMs={1000}
                sx={{ mr: toolbarAdditionalContent ? 2 : 0 }}
              />
            )}
            {toolbarAdditionalContent}
          </Box>
        </Box>
      </GridToolbarContainer>
    </>
  );
};

export { DataGridToolBar };
