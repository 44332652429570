import Close from '@mui/icons-material/Close';
import FilterAlt from '@mui/icons-material/FilterAlt';
import FilterList from '@mui/icons-material/FilterList';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import { Theme, useTheme } from '@mui/material/styles';
import { styled, SxProps } from '@mui/system';
import { GridRowParams } from '@mui/x-data-grid';
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridFeatureMode,
  gridFilteredSortedRowEntriesSelector,
  GridRowId,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { t as $t } from 'i18next';
import { assignIn, cloneDeep, get, omit, uniqBy, unset } from 'lodash';
import * as React from 'react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { v4 as UUID } from 'uuid';

import { DataGridToolBar } from '~components/DataGrid/DataGridToolbar';
import { NoDataFound } from '~components/DataGrid/NoDataFound';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';

interface DataGridProps {
  id: string; // Is using for save state to localstorage and restore it
  rows: Record<string, unknown>[];
  columns: GridColDef[];
  leafField?: string;
  headerActionsComponent?: Nullable<ReactNode>;
  headerSidebarComponent?: Nullable<ReactNode>;
  footerComponent?: Nullable<ReactNode>;
  hiddenColumns?: Array<string>;
  loading?: boolean;
  sx?: SxProps<Theme>; // Style
  getRowId?: (row: Record<string, unknown>) => string;
  getDetailPanelContent?: (params: GridRowParams<Record<string, any>>) => ReactNode;
  getDetailPanelHeight?: (params?: GridRowParams<Record<string, any>>) => number | 'auto';
  initialState?: any;
  onChangeFilter?: (searchValue: string) => void;
  excelExportCallBack?: () => void;
  csvExportCallBack?: () => void;
  onCellEditStop?: any;
  processRowUpdate?: any;
  disableColumnFilter?: boolean;
  hideQuickFilter?: boolean;
  hideToolbar?: boolean;
  filterMode?: GridFeatureMode;
  onRowClick?: (params: GridRowParams<Record<string, any>>) => void;
  columnVisibilityModel?: {
    [key: string]: boolean;
  };
  autoHeight?: boolean;
  selectedRowIDs?: string[];
  onSelectedRowIDsChange?: (rowIDs: string[]) => void;
  bottomBarProps?: { hideClearSelectionButton?: boolean; elements?: ReactNode };
  hideExport?: boolean;
}

type DataGridTableModel = {
  model: GridValidRowModel;
};

export type DataGridTableAPI = {
  gridFilteredSortedRowEntries: () => DataGridTableModel[];
  updateColumns: (columns: GridColDef[]) => void;
};

export const DATAGRID_ID_PREFIX = 'dataGridState__';

const DataGridTable = React.forwardRef<DataGridTableAPI, DataGridProps>(
  (props, outterRef) => {
    const {
      rows,
      columns,
      headerActionsComponent,
      headerSidebarComponent,
      id,
      loading,
      sx,
      initialState = {},
      getRowId = (row) => (row.id as string) || UUID(),
      getDetailPanelContent,
      getDetailPanelHeight = () => 'auto',
      onChangeFilter,
      excelExportCallBack,
      csvExportCallBack,
      leafField = '',
      onCellEditStop,
      processRowUpdate,
      disableColumnFilter = false,
      hideQuickFilter = false,
      hideToolbar = false,
      filterMode = 'client',
      onRowClick,
      columnVisibilityModel,
      autoHeight = true,
      selectedRowIDs,
      onSelectedRowIDsChange,
      bottomBarProps,
      hideExport = false,
    } = props;
    const apiRef = useGridApiRef();
    const theme = useTheme();
    const { toasterStore } = useStores();
    const gridId = useMemo(() => `${DATAGRID_ID_PREFIX}${id}`, [id]);

    const hasRowSelectionModel = Boolean(selectedRowIDs);
    const hasDetailPanel = Boolean(getDetailPanelContent);

    useEffect(() => {
      const stored = JSON.parse(localStorage.getItem(gridId) || '{}');

      // Remove quickFilterValues(search bar values) from store on component mount
      unset(stored, 'filter.filterModel.quickFilterValues');
      localStorage.setItem(gridId, JSON.stringify(stored));
    }, []);

    const onRowSelectionModelChange = React.useCallback(
      (rowSelectionModel: GridRowId[]) => {
        onSelectedRowIDsChange?.(rowSelectionModel as string[]);
      },
      [],
    );

    const columnMemorized = useMemo(() => {
      const columnMemorized = columns.map((item) => ({
        aggregable: false,
        groupable: false,
        ...item,
      }));

      if (hasRowSelectionModel) {
        columnMemorized.unshift({
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          aggregable: false,
          groupable: false,
          sortable: false,
          disableReorder: true,
        });
      }

      if (hasDetailPanel) {
        columnMemorized.unshift({
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          aggregable: false,
          groupable: false,
          sortable: false,
        });
      }

      return columnMemorized;
    }, [JSON.stringify(columns), hasRowSelectionModel, hasDetailPanel]);

    React.useImperativeHandle(outterRef, () => ({
      gridFilteredSortedRowEntries: () => {
        const paginationModel = gridFilteredSortedRowEntriesSelector(apiRef);
        return paginationModel;
      },
      updateColumns: (columns: GridColDef[]) => {
        apiRef.current.updateColumns(columns);
      },
    }));

    const [expandedGroupRows, setExpandedGroupRows] = useState<Array<GridRowId>>([]);

    const errorHandler = () => {
      toasterStore.push(
        alert(`${$t('form_validation_errors.unable_update_row')}`, AlertTypes.error),
      );
    };

    const updateGridStateLocallyStored = (model: any) => {
      const state = apiRef.current.exportState();

      // Pagination and preferencePanel is not needed to store
      const toStore = omit(state, ['preferencePanel', 'pagination']);

      localStorage.setItem(gridId, JSON.stringify(toStore));
      if (model?.quickFilterValues && onChangeFilter) {
        const quickFilterValues = Object.values(model.quickFilterValues).join(' ');

        if (quickFilterValues.length > 2 || quickFilterValues.length === 0) {
          onChangeFilter(quickFilterValues);
        }
      }
    };

    // Init(first render) state from localstorage
    useEffect(() => {
      const stored = JSON.parse(localStorage.getItem(gridId) || '{}');
      const value = assignIn(cloneDeep(initialState), cloneDeep(stored));

      if (hasRowSelectionModel && value?.columns?.orderedFields) {
        const checksFieldIndex = value.columns.orderedFields.findIndex(
          (fieldName: string) => {
            return fieldName === GRID_CHECKBOX_SELECTION_COL_DEF.field;
          },
        );

        if (checksFieldIndex > -1) {
          value.columns.orderedFields.splice(checksFieldIndex, 1);
        }

        value.columns.orderedFields.splice(
          hasDetailPanel ? 1 : 0,
          0,
          GRID_CHECKBOX_SELECTION_COL_DEF.field,
        );
      }

      // Merge filter items:
      // Only existing should be re-written by external(initialState) values
      // Quick filter should be the same
      const filterItems = uniqBy(
        [
          ...get(initialState, 'filter.filterModel.items', []),
          ...get(stored, 'filter.filterModel.items', []),
        ],
        'field',
      );

      value.filter = {
        ...stored.filter,
        filterModel: {
          ...stored.filter?.filterModel,
          items: filterItems,
        },
      };

      apiRef?.current?.restoreState(value);
      // Most of our data grids are updateing the column details based on the row data
      // The column updates that happen after the intial loads will just set the columns to their intial state
      // So the stored column states should be applied after the columns have been updated to fix this issue
    }, [gridId, initialState, columnMemorized, hasRowSelectionModel, hasDetailPanel]);

    useEffect(() => {
      apiRef.current.subscribeEvent('rowExpansionChange', (params) => {
        const { childrenExpanded, id } = params;

        setExpandedGroupRows((prev) => {
          if (childrenExpanded) {
            return [...new Set([...prev, id])];
          } else {
            return prev.filter((item) => item !== id);
          }
        });
      });
    }, [apiRef]);

    useEffect(() => {
      expandedGroupRows.forEach((groupId) => {
        apiRef.current.setRowChildrenExpansion(groupId, true);
      });
    }, [columns]);

    return (
      <>
        <Box display={'block'} sx={{ ...sx }}>
          <DataGridPremium
            key={id}
            apiRef={apiRef}
            loading={loading}
            rows={rows}
            autoHeight={autoHeight}
            columns={columnMemorized}
            getRowId={getRowId}
            groupingColDef={{ leafField: leafField }}
            onCellEditStop={onCellEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={errorHandler}
            sx={{
              border: `1px  solid ${theme.palette.divider}`,
              py: 2,
              '& .MuiDataGrid-overlayWrapperInner, .MuiDataGrid-virtualScroller': {
                minHeight: '96px',
              },
              '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell, .MuiDataGrid-columnHeader, .MuiDataGrid-virtualScroller':
                {
                  borderBottom: `1px solid ${theme.palette.divider}`,
                },
              '& .MuiDataGrid-toolbarContainer': {
                px: 2,
              },
              '& .MuiDataGrid-columnHeaders': {
                background: theme.palette.grey[50],
              },
              "& .MuiDataGrid-columnHeader[data-field='__check__'], .MuiDataGrid-cellCheckbox[data-field='__check__']":
                {
                  minWidth: '42px !important',
                  maxWidth: '42px !important',
                  width: '42px !important',
                },
            }}
            slotProps={{
              filterPanel: {
                sx: {
                  py: 2,
                  pl: 2,
                  pr: 0,
                  '& .MuiDataGrid-panelFooter': {
                    mt: 1,
                    ml: 0.5,
                    mr: 2.5,
                  },
                },
              },
              columnsPanel: {
                sx: {
                  '& .MuiDataGrid-panelFooter': {
                    mx: 0.5,
                  },
                },
              },
              baseSelect: {
                variant: 'outlined',
                size: 'small',
                native: true,
                sx: {
                  mr: 2,
                },
              },
              baseTextField: {
                variant: 'outlined',
                size: 'small',
                sx: {
                  mr: 2,
                },
              },
            }}
            slots={{
              noRowsOverlay: () => <NoDataFound />,
              noResultsOverlay: () => <NoDataFound />,
              columnFilteredIcon: () => {
                return (
                  <FilterAlt sx={{ fill: theme.palette.primary.main, height: 18 }} />
                );
              },
              openFilterButtonIcon: () => {
                return (
                  <FilterList sx={{ fill: theme.palette.secondary.main, height: 18 }} />
                );
              },
              detailPanelCollapseIcon: () => {
                return (
                  <KeyboardArrowDown
                    sx={{ fill: theme.palette.grey[600], height: 18, width: 18 }}
                  />
                );
              },
              detailPanelExpandIcon: () => {
                return (
                  <KeyboardArrowRight
                    sx={{ fill: theme.palette.grey[600], height: 18, width: 18 }}
                  />
                );
              },
              toolbar: () => {
                return (
                  !hideToolbar && (
                    <DataGridToolBar
                      toolbarAdditionalContent={headerActionsComponent}
                      toolbarSidebarContent={headerSidebarComponent}
                      excelExportCallBack={excelExportCallBack}
                      csvExportCallBack={csvExportCallBack}
                      apiRef={apiRef}
                      hideQuickFilter={hideQuickFilter}
                      hideExport={hideExport}
                    />
                  )
                );
              },
              // Todo: add footer and pass filtered count
              // Footer: footerComponent as ReactElement<any, any> | null,
            }}
            onRowGroupingModelChange={updateGridStateLocallyStored}
            onDetailPanelExpandedRowIdsChange={updateGridStateLocallyStored}
            onFilterModelChange={updateGridStateLocallyStored}
            onColumnOrderChange={updateGridStateLocallyStored}
            onPinnedColumnsChange={updateGridStateLocallyStored}
            onColumnResize={updateGridStateLocallyStored}
            onColumnVisibilityModelChange={updateGridStateLocallyStored}
            onSortModelChange={updateGridStateLocallyStored}
            initialState={{}}
            disableRowSelectionOnClick
            hideFooter={true}
            hideFooterPagination={true}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            defaultGroupingExpansionDepth={0}
            hideFooterRowCount={rows.length <= 100}
            disableColumnFilter={disableColumnFilter}
            filterMode={filterMode}
            onRowClick={onRowClick}
            columnVisibilityModel={columnVisibilityModel}
            rowSelectionModel={selectedRowIDs}
            onRowSelectionModelChange={onRowSelectionModelChange}
            checkboxSelection={hasRowSelectionModel}
          />
        </Box>

        {hasRowSelectionModel && (
          <DataGridBottomBar
            selectedRowIDs={selectedRowIDs ?? []}
            onClearSelectedRows={
              bottomBarProps?.hideClearSelectionButton
                ? undefined
                : () => onRowSelectionModelChange([])
            }
            elements={bottomBarProps?.elements}
          />
        )}
      </>
    );
  },
);

interface DataGridBottomBarProps {
  elements?: ReactNode;
  onClearSelectedRows?: () => void;
  selectedRowIDs: string[];
}

function DataGridBottomBar({
  elements,
  onClearSelectedRows,
  selectedRowIDs,
}: DataGridBottomBarProps) {
  const shouldShowBar = Boolean(
    selectedRowIDs.length && (onClearSelectedRows || elements),
  );

  if (!shouldShowBar) {
    return <></>;
  }

  return (
    <Box
      bottom={theme.spacing(10)}
      display="flex"
      justifyContent="center"
      left={0}
      position="fixed"
      right={0}
      sx={{ pointerEvents: 'none' }}
    >
      <Box
        alignItems="center"
        bgcolor={theme.palette.grey[900]}
        borderRadius={1}
        boxShadow="0px 2px 40px 0px rgba(0, 0, 0, 0.40);"
        display="flex"
        gap={1}
        p={1}
        sx={{ pointerEvents: 'auto' }}
      >
        {onClearSelectedRows && (
          <DataGridBottomBarButton
            endIcon={<Close />}
            onClick={onClearSelectedRows}
            isSelectedRowsCountButton
          >
            {$t('common.count_selected', { count: selectedRowIDs.length })}
          </DataGridBottomBarButton>
        )}

        {elements}
      </Box>
    </Box>
  );
}

interface DataGridBottomBarButtonProps extends Omit<ButtonProps, 'size' | 'sx'> {
  isSelectedRowsCountButton?: boolean;
}

const StyledDataGridBottomBarButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'isSelectedRowsCountButton',
})<DataGridBottomBarButtonProps>(({ isSelectedRowsCountButton, theme, color }) => {
  let overrides = {};

  if (isSelectedRowsCountButton) {
    overrides = {
      backgroundColor: theme.palette.grey[900],
      border: `dashed 1px ${theme.palette.grey[700]}`,
      color: theme.palette.grey[50],
      '&.MuiButton-root:hover': {
        backgroundColor: theme.palette.grey[800],
      },
    };
  }

  return {
    backgroundColor: color ? theme.palette[color].main : theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    color: color ? theme.palette[color].contrastText : theme.palette.primary.contrastText,
    fontSize: '12px',
    height: '28px',
    letterSpacing: '0.01px',
    minWidth: '90px',
    ...overrides,
    '&.MuiButton-root': {
      '&.Mui-disabled': {
        backgroundColor: color ? theme.palette[color].light : theme.palette.grey[800],
        color: theme.palette.grey[50],
        cursor: 'not-allowed !important',
        pointerEvents: 'auto',
        opacity: color ? 0.8 : 1,
      },
      '& .MuiButton-endIcon .MuiSvgIcon-root': {
        fontSize: '15px',
      },
    },
  };
});

export function DataGridBottomBarButton(props: DataGridBottomBarButtonProps) {
  return <StyledDataGridBottomBarButton size="small" {...props} />;
}

DataGridTable.displayName = 'DataGridTable';

export default DataGridTable;
