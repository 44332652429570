import {
  Company_Read,
  IamRole_Read as UserRoleProto,
  NotificationMethod,
  StytchMemberStatus,
  TimeZone,
  User_Create,
  User_Me_Read as UserProto,
  User_Read_Nested,
  User_Update,
  UserPreference_Notification_Read,
  UserPreferences_Read,
} from '@treadinc/horizon-api-spec';
import parsePhoneNumber from 'libphonenumber-js';

import {
  UserNotificationsPreferenceDTO,
  UserPreferenceNotificationKey,
} from '~components/UserForm/UserNotificationsPreferenceForm';
import { data as enums } from '~constants/enums';
import { CompanyBasic } from '~hooks/useCompany';
import { Nullable } from '~types/Nullable';

export const getSelectedNotificationMethodsByNotification = (
  dto: UserNotificationsPreferenceDTO,
) => {
  return Object.entries(dto).reduce(
    (obj, [notification, methods]) => {
      const availableMethods = Object.keys(methods) as NotificationMethod[];
      const selectedMethods = availableMethods.filter((method) => methods[method]);

      obj[notification as UserPreferenceNotificationKey] = selectedMethods;

      return obj;
    },
    {} as Record<UserPreferenceNotificationKey, NotificationMethod[]>,
  );
};

export class RoleItem {
  public static parse(proto: UserRoleProto): RoleItem {
    return new RoleItem(
      proto?.id ?? '',
      proto.name ?? '',
      proto?.created_at ?? '',
      proto?.updated_at ?? '',
    );
  }

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get createdAt(): Nullable<string> {
    return this._createdAt;
  }

  public get updatedAt(): Nullable<string> {
    return this._updatedAt;
  }

  constructor(
    private _id: string,
    private _name: string,
    private _createdAt: Nullable<string>,
    private _updatedAt: Nullable<string>,
  ) {}
}

export class UserPreferenceNotification {
  constructor(
    private _pending_job_reminder: Nullable<NotificationMethod[]>,
    private _new_job_request: Nullable<NotificationMethod[]>,
    private _start_job_reminder: Nullable<NotificationMethod[]>,
    private _no_location_alert: Nullable<NotificationMethod[]>,
  ) {}

  public get pendingJobReminder() {
    return this._pending_job_reminder;
  }

  public get newJobRequest() {
    return this._new_job_request;
  }

  public get startJobReminder() {
    return this._start_job_reminder;
  }

  public get noLocationAlert() {
    return this._no_location_alert;
  }

  public static parse(proto: UserPreference_Notification_Read) {
    return new UserPreferenceNotification(
      proto.pending_job_reminder ?? null,
      proto.new_job_request ?? null,
      proto.start_job_reminder ?? null,
      proto.no_location_alert ?? null,
    );
  }
}

export class UserPreferences {
  constructor(private _notifications: Nullable<UserPreferenceNotification>) {}

  public get notifications() {
    return this._notifications;
  }

  public static parse(proto: UserPreferences_Read) {
    return new UserPreferences(
      proto.notifications ? UserPreferenceNotification.parse(proto.notifications) : null,
    );
  }
}

// TODO: this should really extend UserBasic, see Driver/DriverBasic for example
export class User {
  public static parse(proto: UserProto): User {
    return new User(
      proto.id ?? '',
      proto?.first_name ?? '',
      proto.last_name ?? '',
      proto?.company ? CompanyBasic.parse(proto?.company as Company_Read) : null,
      proto?.iam_roles?.length
        ? proto?.iam_roles.map((userSingleRole) => {
            return RoleItem.parse(userSingleRole as UserRoleProto);
          })
        : [],
      proto.email,
      proto.phone,
      proto?.stytch_member_id ?? null,
      proto?.stytch_member_status ?? undefined,
      proto?.stytch_user_id ?? null,
      proto?.stytch_user_status ?? undefined,
      proto?.created_at ?? '',
      proto?.updated_at ?? '',
      proto?.time_zone ?? enums.time_zone.default,
      proto?.intercom_hash ?? '',
      proto?.preferences ? UserPreferences.parse(proto.preferences) : null,
      proto?.text_to_accept_enabled,
      proto?.external_id,
    );
  }

  public static deparse(
    proto: User,
    invitesToSend?: User_Create['send_invite'],
    userNotificationsPreferenceData?: UserNotificationsPreferenceDTO,
  ): User_Update | User_Create {
    const data: Partial<User_Update | User_Create> = {
      iam_role_ids: proto.userRoles?.map((role: RoleItem) => role.id) ?? [],
      first_name: proto.firstName,
      last_name: proto.lastName,
      ...(proto.email ? { email: proto.email } : {}),
      ...(proto.phone ? { phone: proto.phone.replace(/\s/g, '') } : {}),
      time_zone: proto.timeZone as TimeZone,
      external_id: proto.externalId,
    };

    if (proto.id) {
      const updateUserData = data as User_Update;
      updateUserData.id = proto.id;

      if (userNotificationsPreferenceData) {
        updateUserData.preferences = {
          notifications: getSelectedNotificationMethodsByNotification(
            userNotificationsPreferenceData,
          ),
        };
      }

      return updateUserData;
    }

    const createUserData = data as User_Create;
    createUserData.company_id = String(proto.company?.id);
    createUserData.send_invite = invitesToSend;

    return createUserData;
  }

  public get id(): string {
    return this._id;
  }

  public get firstName(): string {
    return this._firstName;
  }

  public get lastName(): string {
    return this._lastName;
  }
  public get fullName(): string {
    return `${this._firstName} ${this._lastName}`;
  }
  public get company(): Nullable<CompanyBasic> {
    return this._company;
  }

  public get userRoles(): Nullable<RoleItem[]> {
    return this._userRoles;
  }

  public get email(): Nullable<string> {
    return this._email;
  }

  public get phone(): Nullable<string> {
    if (this._phone) {
      const phone = this._phone.replace(/^\+/g, '');
      return parsePhoneNumber(`+${phone}`)?.formatInternational() as string;
    }
  }

  public get createdAt(): string {
    return this._createdAt;
  }

  public get updatedAt(): string {
    return this._updatedAt;
  }

  public get status(): StytchMemberStatus {
    return this._stytch_member_status;
  }

  /**
   * An active member is someone that is an active Stytch Member, meaning they have been connected by email
   */
  public get isActiveMember(): boolean {
    return this._stytch_member_status === StytchMemberStatus.ACTIVE;
  }

  /**
   * An active user is someone that is an active Stytch User, meaning they have been connected by phone
   */
  public get isActiveUser(): boolean {
    return this._stytch_user_status === StytchMemberStatus.ACTIVE;
  }

  public get timeZone(): string {
    return this._time_zone;
  }

  public get intercomHash(): string {
    return this._intercom_hash ?? '';
  }

  public get preferences() {
    return this._preferences;
  }

  public get textToAcceptEnabled() {
    return this._text_to_accept_enabled;
  }

  public get externalId() {
    return this._external_id;
  }

  constructor(
    private _id: string,
    private _firstName: string,
    private _lastName: string,
    private _company: Nullable<CompanyBasic>,
    private _userRoles: Nullable<RoleItem[]>,
    private _email: Nullable<string>,
    private _phone: Nullable<string>,
    private _stytch_member_id: string | null,
    private _stytch_member_status: StytchMemberStatus,
    private _stytch_user_id: string | null,
    private _stytch_user_status: StytchMemberStatus,
    private _createdAt: string,
    private _updatedAt: string,
    private _time_zone: string,
    private _intercom_hash: string | null,
    private _preferences: Nullable<UserPreferences>,
    private _text_to_accept_enabled: boolean,
    private _external_id: string | null,
  ) {}
}

export class UserBasic {
  constructor(
    private _id: Nullable<string>,
    private _first_name: string,
    private _last_name: string,
    private _email: Nullable<string>,
    private _phone: Nullable<string>,
  ) {}

  public get id() {
    return this._id;
  }

  public get firstName() {
    return this._first_name;
  }

  public get lastName() {
    return this._last_name;
  }

  public get email(): Nullable<string> {
    return this._email;
  }

  public get phone(): Nullable<string> {
    if (this._phone) {
      const phone = this._phone.replace(/^\+/g, '');
      return parsePhoneNumber(`+${phone}`)?.formatInternational() as string;
    }
  }

  public get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  public static parse(proto: User_Read_Nested) {
    return new UserBasic(
      proto.id,
      proto.first_name,
      proto.last_name,
      proto.email,
      proto.phone,
    );
  }
}
