import ExpandCircleDownOutlined from '@mui/icons-material/ExpandCircleDownOutlined';
import Box from '@mui/material/Box';
import { t } from 'i18next';

import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import theme from '~theme/AppTheme';

interface CreateOrderButtonProps {
  isForResumeOrderCreation?: boolean;
  onClick: () => void;
}

export default function CreateOrderButton({
  isForResumeOrderCreation,
  onClick,
}: CreateOrderButtonProps) {
  return (
    <Box>
      {isForResumeOrderCreation ? (
        <SmallButton
          color="brandV2OrangeDark"
          onClick={onClick}
          startIcon={<ExpandCircleDownOutlined />}
          variant="text"
          sx={{
            '&.MuiButtonBase-root': {
              height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
              mr: -1,
              pl: 0.5,
              pr: 1,
              py: 0,
              '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
              '.MuiButton-startIcon': { ml: 0, mr: 0.5 },
              '.MuiSvgIcon-root': { fontSize: '24px', transform: 'rotate(90deg)' },
            },
          }}
        >
          {t('dispatch.dispatch_v2.resume_new_order')}
        </SmallButton>
      ) : (
        <SmallButton
          color="brandV2Yellow"
          onClick={onClick}
          sx={{
            '&.MuiButtonBase-root': {
              height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
            },
          }}
        >
          {t('dispatch.dispatch_v2.new_order')}
        </SmallButton>
      )}
    </Box>
  );
}
