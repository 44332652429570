import { ServiceClass_Read, ServiceClass_Read_Nested } from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { CompanyBasic } from '~hooks/useCompany';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { Nullable } from '~types/Nullable';

export class BasicServiceClass extends ItemNameAndId {
  public static parse(proto: ServiceClass_Read_Nested): BasicServiceClass {
    return new BasicServiceClass(proto.id ?? '', proto.name ?? '');
  }
  constructor(_id: string, _name: string) {
    super(_name, _id);
  }
}

export class ServiceClass {
  public static parse(proto: ServiceClass_Read): ServiceClass {
    return new ServiceClass(
      proto.id ?? '',
      proto.name ?? '',
      proto.company ? CompanyBasic.parse(proto.company) : null,
      dayjs(proto.created_at),
      dayjs(proto.updated_at),
    );
  }

  public static deparseCreate(data: any) {
    return {
      name: data.name,
      company_id: data.company?.id,
    };
  }

  public static deparseUpdate(data: any) {
    return { id: data.id, name: data.name };
  }

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get company(): Nullable<CompanyBasic> {
    return this._company;
  }

  public get createdAt(): Dayjs {
    return this._createdAt;
  }

  public get updatedAt(): Dayjs {
    return this._updatedAt;
  }

  constructor(
    private _id: string,
    private _name: string,
    private _company: Nullable<CompanyBasic>,
    private _createdAt: Dayjs,
    private _updatedAt: Dayjs,
  ) {}
}
