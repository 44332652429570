import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CompanyType } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { capitalize, startCase } from 'lodash';
import React, { useEffect } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { FormFieldLabel } from '~components/FormFields/FormFieldLabel';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { AccountTypes, data as enums } from '~constants/enums';
import { CompanyShareableBasic } from '~hooks/useCompanyShares';
import { DriverBasic, useDrivers } from '~hooks/useDrivers';
import { Equipment, useEquipment } from '~hooks/useEquipment';
import {
  autoAcceptOrdersOptions,
  connectionTypeOptions,
} from '~pages/Settings/Accounts/AccountFormParts/constants';

interface AccountConfigurationProps {
  control: Control<FieldValues | any>;
  errors: FieldErrors;
  disableAccountType?: boolean;
  isConnectedAccount: boolean;
  isConnectedAccountToggled: boolean;
  isConnectedCustomer: boolean;
  selectedAccountTypes: AccountTypes[];
  drivers: CompanyShareableBasic[];
  equipment: CompanyShareableBasic[];
  vendorDrivers: CompanyShareableBasic[];
  vendorEquipment: CompanyShareableBasic[];
  companyType: CompanyType;
  selectedAccountCompanyId: string;
}

const selectableCompanyTypes = enums.company_type.values.filter(
  (item) => item !== CompanyType.SUPER,
);

export const AccountConfiguration = ({
  control,
  errors,
  disableAccountType,
  isConnectedAccount,
  isConnectedAccountToggled,
  isConnectedCustomer,
  selectedAccountTypes,
  drivers,
  equipment,
  companyType,
  selectedAccountCompanyId,
  vendorDrivers,
  vendorEquipment,
}: AccountConfigurationProps) => {
  const { getDriversByCompanyIdTypeahead } = useDrivers();
  const { getEquipmentByCompanyIdTypeahead } = useEquipment();
  const [selectedDrivers, setSelectedDrivers] = React.useState<
    DriverBasic[] | CompanyShareableBasic[]
  >(drivers);
  const [sharedEquipment, setSharedEquipment] = React.useState<
    Equipment[] | CompanyShareableBasic[]
  >(equipment);
  const isCustomerAccount = selectedAccountTypes.includes(AccountTypes.CUSTOMER);
  const isVendorAccount = selectedAccountTypes.includes(AccountTypes.VENDOR);
  const isOwnerOperator = companyType === CompanyType.OWNER_OPERATOR;
  const isCustomerAndVendorAccount = isCustomerAccount && isVendorAccount;

  useEffect(() => {
    setSelectedDrivers(drivers);
  }, [drivers]);

  useEffect(() => {
    setSharedEquipment(equipment);
  }, [equipment]);

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormSectionTitle
            title={$t('account.form.account_configuration')}
            sx={{ mb: 1 }}
          />
        </Grid>

        <Grid item xs={6}>
          <AutocompleteFormField
            control={control}
            name="connected"
            errors={errors}
            list={connectionTypeOptions}
            label={`${$t('account.form.connection_type')}`}
            isRequired={true}
            getValue={(item) => item.value}
            getLabel={(item) => item.label}
            disabled={isConnectedAccount}
            inputProps={{
              'data-test-id': 'connection-type',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'body2'} mt={2}>
            {$t('account.form.select_connection_type')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <AutocompleteFormField
            control={control}
            multiple={true}
            name="accountTypes"
            errors={errors}
            list={enums.account_type.values}
            label={`${$t('account.form.account_type')}`}
            isRequired={true}
            getValue={(item) => item}
            getLabel={(item) => capitalize(item)}
            defaultValue={[]}
            disabled={isConnectedAccount || disableAccountType}
            inputProps={{
              'data-test-id': 'account-type',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteFormField
            control={control}
            name="companyType"
            errors={errors}
            list={selectableCompanyTypes}
            label={`${$t('form_fields.company_type')}`}
            isRequired={isConnectedAccountToggled}
            getValue={(item) => item}
            getLabel={(item) => startCase(item)}
            disabled={isConnectedAccount || !isConnectedAccountToggled}
            inputProps={{
              'data-test-id': 'company-type',
            }}
          />
          <Typography variant={'body2'}>
            {$t('account.form.select_owner_operator')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <AutocompleteAsyncFormField
            control={control}
            errors={errors}
            name={'drivers'}
            getValue={(item) => item?.id || ''}
            getLabel={(item) => item?.fullName || item?.name || ''}
            asyncCallback={getDriversByCompanyIdTypeahead}
            extraRequestOptions={{
              companyId: selectedAccountCompanyId,
            }}
            label={
              !isCustomerAndVendorAccount
                ? `${$t('account.form.direct_dispatch_drivers')}`
                : `${$t('account.form.customer_direct_dispatch_drivers')}`
            }
            multiple
            limitTags={1}
            hideChipOverflow
            onMultipleOptionsChange={(values) => {
              setSelectedDrivers(values as CompanyShareableBasic[]);
            }}
            disabled={!isCustomerAndVendorAccount && !isConnectedCustomer}
            filterSelectedOptions={false}
            inputProps={{
              'data-test-id': 'direct-dispatch-drivers',
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <FormFieldLabel
            label={
              !isCustomerAndVendorAccount
                ? $t('account.form.direct_dispatch_drivers_list')
                : $t('account.form.customer_direct_dispatch_drivers_list')
            }
          />
          <Typography variant={'body2'}>
            {selectedDrivers.length
              ? selectedDrivers
                  .map((driver) => {
                    if ('fullName' in driver) {
                      return driver.fullName;
                    }

                    return driver.name;
                  })
                  .join(', ')
              : $t('common.none_selected')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <AutocompleteAsyncFormField
            control={control}
            errors={errors}
            name={'equipment'}
            getValue={(item) => item?.id || ''}
            getLabel={(item) => item?.name || ''}
            asyncCallback={getEquipmentByCompanyIdTypeahead}
            extraRequestOptions={{
              companyId: selectedAccountCompanyId,
            }}
            label={
              !isCustomerAndVendorAccount
                ? `${$t('account.form.share_equipment')}`
                : `${$t('account.form.customer_share_equipment')}`
            }
            multiple
            limitTags={1}
            hideChipOverflow
            onMultipleOptionsChange={(values) => {
              setSharedEquipment(values as CompanyShareableBasic[]);
            }}
            filterSelectedOptions={false}
            disabled={!isCustomerAndVendorAccount && !isConnectedCustomer}
          />
        </Grid>

        <Grid item xs={6}>
          <FormFieldLabel
            label={
              !isCustomerAndVendorAccount
                ? $t('account.form.shared_equipment_list')
                : $t('account.form.customer_shared_equipment_list')
            }
          />
          <Typography variant={'body2'}>
            {sharedEquipment.length
              ? sharedEquipment.map((equipment) => equipment.name).join(', ')
              : $t('common.none_selected')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextFormField
            control={control}
            errors={errors}
            name="truckCount"
            type={'number'}
            inputProps={{ min: 0, step: 1, 'data-test-id': 'truck-count' }}
            defaultValue={isVendorAccount && isOwnerOperator ? 1 : ''}
            label={
              !isCustomerAndVendorAccount
                ? `${$t('account.form.committed_truck_capacity')}`
                : `${$t('account.form.customer_committed_truck_capacity')}`
            }
            disabled={!isCustomerAndVendorAccount && !isConnectedCustomer}
          />
        </Grid>

        <Grid item xs={6}>
          <AutocompleteFormField
            control={control}
            errors={errors}
            name={'autoAcceptOrders'}
            label={
              !isCustomerAndVendorAccount
                ? `${$t('account.form.auto_accept_jobs')}`
                : `${$t('account.form.customer_auto_accept_jobs')}`
            }
            list={autoAcceptOrdersOptions}
            getValue={(item) => item.value}
            getLabel={(item) => item.label}
            disabled={!isCustomerAndVendorAccount && !isConnectedCustomer}
          />
        </Grid>

        {isCustomerAndVendorAccount && (
          <>
            <Grid item xs={6}>
              <AutocompleteAsyncFormField
                control={control}
                errors={errors}
                name={'vendorDrivers'}
                getValue={(item) => item?.id || ''}
                getLabel={(item) => item?.fullName || item?.name || ''}
                asyncCallback={getDriversByCompanyIdTypeahead}
                extraRequestOptions={{
                  companyId: selectedAccountCompanyId,
                }}
                label={`${$t('account.form.vendor_direct_dispatch_drivers')}`}
                multiple
                limitTags={1}
                hideChipOverflow
                onMultipleOptionsChange={(values) => {
                  setSelectedDrivers(values as CompanyShareableBasic[]);
                }}
                disabled
                filterSelectedOptions={false}
                inputProps={{
                  'data-test-id': 'direct-dispatch-drivers',
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormFieldLabel
                label={$t('account.form.vendor_direct_dispatch_drivers_list')}
              />
              <Typography variant={'body2'}>
                {vendorDrivers.length
                  ? vendorDrivers
                      .map((driver) => {
                        if ('fullName' in driver) {
                          return driver.fullName;
                        }

                        return driver.name;
                      })
                      .join(', ')
                  : $t('common.none_selected')}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <AutocompleteAsyncFormField
                control={control}
                errors={errors}
                name={'vendorEquipment'}
                getValue={(item) => item?.id || ''}
                getLabel={(item) => item?.name || ''}
                asyncCallback={getEquipmentByCompanyIdTypeahead}
                extraRequestOptions={{
                  companyId: selectedAccountCompanyId,
                }}
                label={`${$t('account.form.vendor_share_equipment')}`}
                multiple
                limitTags={1}
                hideChipOverflow
                onMultipleOptionsChange={(values) => {
                  setSharedEquipment(values as CompanyShareableBasic[]);
                }}
                filterSelectedOptions={false}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <FormFieldLabel label={$t('account.form.vendor_shared_equipment_list')} />
              <Typography variant={'body2'}>
                {vendorEquipment.length
                  ? vendorEquipment.map((equipment) => equipment.name).join(', ')
                  : $t('common.none_selected')}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextFormField
                control={control}
                errors={errors}
                name="vendorTruckCount"
                type={'number'}
                inputProps={{ min: 0, step: 1, 'data-test-id': 'truck-count' }}
                defaultValue={isVendorAccount && isOwnerOperator ? 1 : ''}
                label={`${$t('account.form.vendor_committed_truck_capacity')}`}
                disabled
              />
            </Grid>

            <Grid item xs={6}>
              <AutocompleteFormField
                control={control}
                errors={errors}
                name={'vendorAutoAcceptOrders'}
                label={`${$t('account.form.vendor_auto_accept_jobs')}`}
                list={autoAcceptOrdersOptions}
                getValue={(item) => item.value}
                getLabel={(item) => item.label}
                disabled
              />
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};
