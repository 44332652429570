import { yupResolver } from '@hookform/resolvers/yup';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import WarningAmber from '@mui/icons-material/WarningAmber';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { TicketState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t as $t } from 'i18next';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Header } from '~components/Drawer/src/ui/Header';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { SingleDateTimeFormField } from '~components/FormFields/SingleDateTimeFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { unitOfMeasureOptions } from '~constants/enums';
import { useMaterials } from '~hooks/useMaterials';
import { Ticket } from '~hooks/useTickets';
import { useStores } from '~store';
import { dateFormat } from '~utils/dateTime';
import { canEditTicket } from '~utils/tickets/ticket-utils';

import {
  setDefaultTicketValues,
  TicketFormDTO,
  ticketFormSchema,
} from '../ApprovalsComponents/ticketFormSchema';
import { Field } from './Field';

interface TicketDetailFormImperativeApi {
  setEditMode: (isEditing: boolean) => void;
}

interface TicketDetailFormProps {
  jobId: string;
  onChange: (ticket: TicketFormDTO) => Promise<Ticket>;
  onEditModeChange?: (isEditing: boolean) => void;
  onDelete?: () => void;
  ticket?: Ticket;
}
export const TicketDetailForm = forwardRef<
  TicketDetailFormImperativeApi,
  TicketDetailFormProps
>(({ jobId, onChange, onEditModeChange, onDelete, ticket }, ref) => {
  const { companyAssetsStore } = useStores();
  const { getAllMaterials } = useMaterials();

  const [isEditing, setIsEditing] = useState(!ticket);
  const [isSaving, setIsSaving] = useState(false);

  const formMethods = useForm<TicketFormDTO>({
    resolver: yupResolver(ticketFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: setDefaultTicketValues(ticket, jobId),
  });
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
  } = formMethods;

  useImperativeHandle(
    ref,
    () => ({
      setEditMode: (isEditing: boolean) => {
        setIsEditing(isEditing);
      },
    }),
    [],
  );

  useEffect(() => {
    if (ticket && ticket.ocrProcessed) {
      setValue('ticketNumber', ticket?.ticketNumber || ticket?.ocrTicketNumber || '');
      setValue('quantity', ticket?.quantity || ticket?.ocrNetQuantityTons || '');

      // because of quick updates, serviceDate gets overwritten by createdAt
      // Update serviceDate with a delay to avoid flip flopping.
      setTimeout(() => {
        if (ticket.ocrServiceDate && !ticket.serviceDate) {
          setValue('serviceDate', new Date(ticket.ocrServiceDate));
        }
      }, 1000);
    }
  }, [ticket]);

  const onSubmit = useCallback(() => {
    handleSubmit((data) => {
      setIsSaving(true);

      onChange(data)
        .then((ticket) => {
          reset(setDefaultTicketValues(ticket, jobId));
        })
        .finally(() => {
          setIsSaving(false);
          setIsEditing(false);
        });
    })();
  }, [handleSubmit, reset, jobId]);

  useEffect(() => {
    if (!companyAssetsStore.materials.length) {
      getAllMaterials();
    }
  }, [companyAssetsStore.materials.length]);

  useEffect(() => {
    if (!isEditing) {
      reset(setDefaultTicketValues(ticket, jobId));
    }
  }, [isEditing, ticket, jobId, reset]);

  useEffect(() => {
    onEditModeChange?.(isEditing);
  }, [onEditModeChange, isEditing]);

  const ticketDate = ticket?.serviceDate || ticket?.ocrServiceDate || ticket?.createdAt;

  return (
    <FormProvider {...formMethods}>
      <Box>
        <Header
          title={
            <Typography variant="body2" fontWeight="bold">
              {$t('approvals.ticket_details.ticket_details')}
            </Typography>
          }
          actions={
            <>
              {!isEditing && ticket && canEditTicket(ticket.state as TicketState) && (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    color="warning"
                    disabled={isSaving}
                    onClick={onDelete}
                    startIcon={<Delete />}
                    variant="outlined"
                  >
                    {$t('approvals.ticket_details.delete')}
                  </Button>
                  <Button
                    color="inherit"
                    disabled={isSaving}
                    onClick={() => setIsEditing(true)}
                    startIcon={<Edit />}
                    variant="outlined"
                  >
                    {$t('approvals.ticket_details.edit')}
                  </Button>
                </Box>
              )}

              {isEditing && (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {ticket && (
                    <Button
                      color="inherit"
                      disabled={isSaving}
                      onClick={() => setIsEditing(false)}
                      startIcon={<Close />}
                      variant="outlined"
                    >
                      {$t('actions.cancel')}
                    </Button>
                  )}

                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={isSaving}
                    startIcon={<Check />}
                    onClick={onSubmit}
                  >
                    {$t('actions.save')}
                  </LoadingButton>
                </Box>
              )}
            </>
          }
        />

        <Field
          label={$t('approvals.ticket_details.ticket_number')}
          value={ticket?.ticketNumber || ticket?.ocrTicketNumber || ''}
          icon={ticket?.ticketNumber || ticket?.ocrTicketNumber ? null : <AlertIcon />}
          editing={isEditing}
          editField={
            <TextFormField
              control={control}
              errors={errors}
              disabled={false}
              hint=""
              name="ticketNumber"
              isRequired={false}
              margin="none"
              inputProps={{ maxLength: 15 }}
            />
          }
        />

        <Field
          label={$t('approvals.ticket_details.material')}
          value={ticket?.material?.name || ''}
          icon={ticket?.material?.name ? null : <AlertIcon />}
          editing={isEditing}
          editField={
            <AutocompleteFormField
              sx={{ width: '100%' }}
              list={companyAssetsStore.allMaterials}
              getLabel={(option) => option.name}
              getValue={(option) => option.id}
              control={control}
              errors={errors}
              name={`material`}
              isDenseText={false}
            />
          }
        />

        <Field
          label={$t('approvals.ticket_details.quantity')}
          value={ticket?.quantity || ticket?.ocrNetQuantityTons || ''}
          icon={ticket?.quantity || ticket?.ocrNetQuantityTons ? null : <AlertIcon />}
          editing={isEditing}
          editField={
            <TextFormField
              control={control}
              errors={errors}
              disabled={false}
              hint=""
              name="quantity"
              isRequired={false}
              margin="none"
            />
          }
        />

        <Field
          label={$t('approvals.ticket_details.unit')}
          value={ticket?.unitOfMeasure?.name || ''}
          icon={ticket?.unitOfMeasure?.name ? null : <AlertIcon />}
          editing={isEditing}
          editField={
            <AutocompleteFormField
              key={'ticket-material-key'}
              control={control}
              name="unitOfMeasure"
              isDenseText={false}
              errors={errors}
              list={unitOfMeasureOptions}
              isRequired={true}
              getValue={(item) => item.id}
              getLabel={(item) => item.name || ''}
            />
          }
        />

        <Field
          label={$t('approvals.ticket_details.ticket_date')}
          value={
            ticket &&
            `${dateFormat(dayjs(ticketDate))} ${dateFormat(dayjs(ticketDate), 'hh:mm A')}`
          }
          editing={isEditing}
          editField={
            <SingleDateTimeFormField
              control={control}
              errors={errors}
              name={'serviceDate'}
              isRequired={true}
            />
          }
        />

        <Field
          label={$t('approvals.ticket_details.image_url')}
          value={
            <Link
              href={ticket?.imageUrl}
              target="_blank"
              rel="noreferrer"
              sx={{ '&:hover': { textDecoration: 'underline' } }}
            >
              {ticket?.imageUrl ? $t('approvals.ticket_details.view_image') : ''}
            </Link>
          }
          icon={ticket?.imageUrl ? null : <AlertIcon />}
        />
      </Box>
    </FormProvider>
  );
});

TicketDetailForm.displayName = 'TicketDetailForm';

const AlertIcon = () => {
  return <WarningAmber color="primary" />;
};
