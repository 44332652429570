import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useMemo } from 'react';

import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { useStores } from '~store';

export const SettlementPricing = ({ settlementId }: { settlementId: string }) => {
  const { currencyFormatter } = useCompanyCurrency();
  const { settlementsStore } = useStores();

  const settlement = settlementsStore.settlements.find(
    (settlement) => settlement.id === settlementId,
  );

  const total = settlement?.totalAmount;

  const invoicesTotal = settlement?.invoicesTotalAmount;

  const addOnsTotal = settlement?.addOnChargesTotalAmount;

  return (
    <Box
      sx={{
        gridColumn: '2 / 3',
        justifySelf: 'end',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        border: '1px solid #E2E2E2',
        borderRadius: '6px',
        width: '200px',
        backgroundColor: (theme) => theme.palette.secondary.light,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="body2">
          {t('approvals.driver_pay.settlements.subtotal')}
        </Typography>
        <Typography variant="body2" fontWeight={'600'}>
          {' '}
          {currencyFormatter(invoicesTotal?.toFixed(2) ?? '0.00')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="body2">
          {t('approvals.driver_pay.settlements.add_ons')}
        </Typography>
        <Typography variant="body2" fontWeight={'600'}>
          {addOnsTotal
            ? addOnsTotal > 0
              ? currencyFormatter(addOnsTotal.toFixed(2))
              : `(${currencyFormatter(-addOnsTotal.toFixed(2))})`
            : '0.00'}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '1px',
          backgroundColor: (theme) => theme.brandV2.colors.treadGray7,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="body2">
          {t('approvals.driver_pay.settlements.driver_pay_total')}
        </Typography>
        <Typography variant="body2" fontWeight={'600'}>
          {currencyFormatter(total?.toFixed(2) ?? '0.00')}
        </Typography>
      </Box>
    </Box>
  );
};
