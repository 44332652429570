import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { useCallback } from 'react';

import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import theme from '~theme/AppTheme';

const OUTLINE_WIDTH_IN_PX = 1;

export type StatusTab<T> = {
  label: string;
  value: T;
};

interface StatusTabsProps<T> {
  onChange: (value: T) => void;
  selectedTab: T;
  sx?: SxProps;
  tabs: StatusTab<T>[];
}

export default function StatusTabs<T>({
  onChange,
  selectedTab,
  sx,
  tabs,
}: StatusTabsProps<T>) {
  const handleTabChange = useCallback(
    (value: T) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <Box
      alignItems="center"
      display="flex"
      height={NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX - OUTLINE_WIDTH_IN_PX * 2}
      position="relative"
      sx={sx}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={String(tab.value)}
          {...tab}
          isFirstTab={index === 0}
          isLastTab={index === tabs.length - 1}
          isSelected={selectedTab === tab.value}
          onClick={handleTabChange}
        />
      ))}
    </Box>
  );
}

interface TabProps<T> extends StatusTab<T> {
  isFirstTab?: boolean;
  isLastTab?: boolean;
  isSelected?: boolean;
  onClick: StatusTabsProps<T>['onChange'];
}

function Tab<T>({
  isFirstTab,
  isLastTab,
  isSelected,
  label,
  onClick,
  value,
}: TabProps<T>) {
  return (
    <Box
      alignItems="center"
      bgcolor={isSelected ? theme.brandV2.colors.treadGray1 : 'white'}
      display="flex"
      height="100%"
      justifyContent="center"
      onClick={() => onClick(value)}
      p={theme.spacing(0.5, 1)}
      position="relative"
      sx={{
        cursor: 'pointer',
        mr: isSelected ? '-1px' : 0,
        outline: `solid ${OUTLINE_WIDTH_IN_PX}px ${isSelected ? theme.brandV2.colors.treadGray1 : theme.brandV2.colors.treadGray7}`,
        zIndex: isSelected ? 1 : 0,
        ...(isFirstTab
          ? {
              borderBottomLeftRadius: theme.brandV2.borderRadius,
              borderTopLeftRadius: theme.brandV2.borderRadius,
              pl: theme.spacing(1.5),
            }
          : { ml: '1px' }),
        ...(isLastTab
          ? {
              borderBottomRightRadius: theme.brandV2.borderRadius,
              borderTopRightRadius: theme.brandV2.borderRadius,
              pr: theme.spacing(1.5),
            }
          : {}),
      }}
    >
      <Typography
        color={isSelected ? 'white' : theme.brandV2.colors.treadBlack}
        variant="subtitle2"
        whiteSpace="nowrap"
      >
        {label}
      </Typography>
    </Box>
  );
}
