import { yupResolver } from '@hookform/resolvers/yup';
import {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw';
import Refresh from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Feature } from '@nbai/nbmap-gl';
import { ModelError_Item } from '@treadinc/horizon-api-spec';
import circle from '@turf/circle';
import { convertLength, Polygon, polygon } from '@turf/helpers';
import { t as $t } from 'i18next';
import { get } from 'lodash';
import { LngLat } from 'maplibre-gl';
import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { v4 as UUID } from 'uuid';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import HybridBaseMap from '~components/Maps/HybridBaseMap';
import { AddressOptionForNewSite } from '~components/Order/SiteSelection/types';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import {
  data as enums,
  geoFenceCircleOption,
  geofenceNoneOption,
  geofenceOptions,
  GeoFenceTypes,
} from '~constants/enums';
import { FormStateChangeProps } from '~formsShared';
import { AddressItem } from '~hooks/useAddress';
import { SupportedAddressProvider } from '~hooks/useAddress/models/AddressProvider';
import { Company, CompanyBasic, useCompany } from '~hooks/useCompany';
import { useGeocode } from '~hooks/useGeocode';
import { Site } from '~hooks/useSites';
import { LatLng } from '~interfaces/map';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';
import { validateLatLon } from '~utils/utilFunctions';

import {
  formSchema,
  setDefaultSiteFormValuesFromAddressOption,
  setDefaultSiteValues,
} from './formSchemas';
import {
  AdditionalGeoFenceField,
  AdditionalGeoFencePolygon,
  GeofenceForm,
} from './GeoFenceForm';

interface SiteFormProps {
  defaultSite?: Nullable<Site>;
  addressOption?: Nullable<AddressOptionForNewSite>;
  company?: Nullable<CompanyBasic>;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
  isInline?: boolean;
  errors?: ModelError_Item[];
}

export const radius = 50;
export const options = {
  steps: 50,
};
export const geofenceOptionsWithNone = [...geofenceOptions, geofenceNoneOption];

interface SiteFormRef {
  submit: (callback: () => void) => void;
  resetForm: (callback?: () => void) => void;
}

const SiteForm = forwardRef(function SiteForm(
  {
    addressOption,
    defaultSite,
    onFormStateChange,
    company,
    isInline = false,
    errors: errorsProp,
  }: SiteFormProps,
  ref: Ref<SiteFormRef>,
) {
  const { getCompanyById } = useCompany();
  const [mapId, setMapId] = useState<string>(UUID());
  const [siteGeoFence, setSiteGeoFence] = useState<Nullable<Feature[]>>([]);
  const [defaultSiteGeoFence, setDefaultSiteGeoFence] = useState<
    AdditionalGeoFencePolygon[] | null
  >([]);
  const [activeAdditionalPolygonKey, setActiveAdditionalPolygonKey] = useState<
    string | null
  >(null);
  const [additionalGeoFences, setAdditionalGeoFences] = useState<
    AdditionalGeoFencePolygon[]
  >([]);
  const [isDrawPolygonControlEnabled, setIsDrawPolygonControlEnabled] = useState<boolean>(
    defaultSite ? Boolean(!defaultSite?.nextBillionGeofence?.geojson) : false,
  );
  const [startSearchPosition, setStartSearchPosition] = useState<LatLng>({
    lat: 43.648888,
    lng: -79.388186,
  });
  const [markers, setMarkers] = useState<any>([]);
  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState<Company>();

  const addressProvider = SupportedAddressProvider.GOOGLE_MAPS_PLACES_AUTOCOMPLETE;
  const { getPlaces, getAddressItemByGoogleMapsPlaceId, updaStartSearchPosition } =
    useGeocode({
      addressProvider,
    });
  const { userStore, companyAssetsStore } = useStores();
  const companyValue = (selectedCompanyDetails || userStore.userCompany) as Company;
  const equipmentOptions = companyAssetsStore.equipment;
  const defaultAdditionalGeofences = companyAssetsStore.activeSiteAdditionalGeoFences;
  const constructAdditionalGeoFence = () => {
    const additionalGeoFencesFieldsWithGeoData = additionalGeoFences.map((geo: any) => {
      const index = watchedAdditionalGeoFences.findIndex(
        (f: AdditionalGeoFenceField) => f.id === geo.id,
      );
      return {
        ...geo,
        name: watchedName,
        type: (watchedAdditionalGeoFences[index] as AdditionalGeoFenceField)?.type,
        tag: (watchedAdditionalGeoFences[index] as AdditionalGeoFenceField)?.tag,
        radius: (watchedAdditionalGeoFences[index] as AdditionalGeoFenceField)?.radius,
        isFeet,
      };
    });

    //@ts-ignore
    setValue('additionalGeoFencesDetails', additionalGeoFencesFieldsWithGeoData);
  };

  useImperativeHandle(ref, () => ({
    submit(callBack: any) {
      constructAdditionalGeoFence();
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  useEffect(() => {
    if (errorsProp) {
      for (const error of errorsProp) {
        const newErrorField = getErrorMapping(error.field, error.message);
        if (newErrorField.field && newErrorField.message) {
          setError(newErrorField.field as keyof ReturnType<typeof setError>, {
            type: 'manual',
            message: newErrorField.message,
          });
        }
      }
    }
  }, [errorsProp]);

  // Main office coordinates as default
  const defaultPosition = useMemo(() => {
    return {
      lng: defaultSite?.lng || addressOption?.lng || -79.389193,
      lat: defaultSite?.lat || addressOption?.lat || 43.648947,
    };
  }, [addressOption?.lng, addressOption?.lat, defaultSite?.lng, defaultSite?.lat]);

  const layers = useMemo(() => [], []);
  const sources = useMemo(() => [], []);
  const controlsToAdd = useMemo(() => ['navigation'], []);
  const defaultFormValues = useMemo(() => {
    if (addressOption) {
      return setDefaultSiteFormValuesFromAddressOption(addressOption, companyValue);
    }

    return setDefaultSiteValues(defaultSite, equipmentOptions, companyValue);
  }, [defaultSite?.id, companyValue, defaultAdditionalGeofences]);

  const formMethods = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // Passing in company first in case this is a platform admin/parent company creating a site for a child company
    defaultValues: defaultFormValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    getValues,
    setError,
    formState: { errors, isValid, isDirty },
  } = formMethods;

  const geoCodedAddress = watch('address');
  const coords = watch('latLon');
  const watchedCompany = watch('company');
  const watchedLat = coords.split(',')?.[0]?.trim() || '';
  const watchedLon = coords?.split(',')?.[1]?.trim() || '';
  const watchedRadius = watch('radius');
  const watchedGeofenceType = watch('geofenceType');
  const watchedGeofence = watch('geoFence');
  const watchedGeoFenceInitialId = watch('geoFenceInitialId');
  const watchedName = watch('name');
  const watchedAdditionalGeoFences = useWatch({ name: 'additionalGeoFences', control });
  const isFeet = selectedCompanyDetails?.isFeet || false;

  useEffect(() => {
    if (watchedCompany) {
      getCompanyById({ id: watchedCompany.id, callBack: setSelectedCompanyDetails });
    }
  }, [watchedCompany]);

  // This effect will set the startSearchPosition to the company address lat/lng
  useEffect(() => {
    const lng = get(selectedCompanyDetails, 'address.lng');
    const lat = get(selectedCompanyDetails, 'address.lat');

    // If we have a company address with lat/lng, we can center the api search on it
    if (lat && lng) {
      setStartSearchPosition({
        lat: lat!,
        lng: lng!,
      });
    } else {
      const address = get(selectedCompanyDetails, 'address.streetAddress');

      if (address !== undefined) {
        // If we don't have a company address with lat/lng, we will need to call getPlaces on the company address
        getPlaces({ query: address })?.then((res) => {
          if (res?.length) {
            if (
              addressProvider === SupportedAddressProvider.GOOGLE_MAPS_PLACES_AUTOCOMPLETE
            ) {
              getAddressItemByGoogleMapsPlaceId(res[0].placeId).then((addressItem) => {
                const coordinates = { lat: addressItem.lat, lng: addressItem.lng };

                setStartSearchPosition(coordinates);
                updaStartSearchPosition(coordinates);
              });
            } else {
              const coordinates = { lat: res[0].lat, lng: res[0].lng };

              setStartSearchPosition(coordinates);
              updaStartSearchPosition(coordinates);
            }
          }
        });
      }
    }
  }, [selectedCompanyDetails]);

  useEffect(() => {
    if (company?.id) {
      setValue('company', company);
    }
  }, [company?.id]);

  useEffect(() => {
    if (defaultSite) {
      reset(defaultFormValues);
    }
  }, [defaultSite, defaultAdditionalGeofences]);

  useEffect(() => {
    if (defaultSite?.nextBillionGeofence?.geojson) {
      const polygonFromFenceCoords = polygon(
        defaultSite?.nextBillionGeofence?.geojson?.coordinates,
        {},
        //Set id, in case there is already a fence, we need to update it,
        // Otherwise turf and mapbox will generate a new id, that will result an error in API
        { id: defaultSite?.nextBillionGeofence?.id },
      );
      setDefaultSiteGeoFence([
        polygonFromFenceCoords,
      ] as unknown as AdditionalGeoFencePolygon[]);
    }
  }, [defaultSite?.nextBillionGeofence?.geojson]);

  const createDefaultCircleGeofence = (radiusToApply: number) => {
    const center = [
      Number(watchedLon) || defaultPosition.lng,
      Number(watchedLat) || defaultPosition.lat,
    ];
    //Do we need to support circle ?
    const geofenceDefault = circle(center, radiusToApply, {
      ...options,
      units: 'meters',
    });
    setDefaultSiteGeoFence(
      [geofenceDefault as unknown as AdditionalGeoFencePolygon] || null,
    );

    setValue('geoFence', {
      ...geofenceDefault,
      id: watchedGeofence?.id || '',
      name: getValues('name'),
      type: GeoFenceTypes.CIRCLE.toLowerCase(),
    } as any);
    setValue('circleCenter', {
      lat: center[1],
      lon: center[0],
    });
  };

  const refreshMarker = () => {
    if (watchedLon && watchedLat && !errors?.latLon?.message?.length) {
      setMarkers([
        {
          id: `marker__${mapId}`,
          lng: watchedLon || defaultPosition.lng,
          lat: watchedLat || defaultPosition.lat,
          draggable: true,
          onDragMarker: (e: LngLat) => {
            setValue('latLon', `${e.lat}, ${e.lng}`);
          },
        },
      ]);
      setMapId(UUID());
    }
  };

  const mapCenter = useMemo(() => {
    if (validateLatLon({ latitude: watchedLat, longitude: watchedLon })) {
      return {
        lng: watchedLon,
        lat: watchedLat,
      };
    } else {
      return {
        lng: defaultPosition.lng,
        lat: defaultPosition.lat,
      };
    }
  }, [watchedLon, watchedLat, defaultPosition]);

  const refreshPosition = () => {
    // Set new position
    setValue(
      'latLon',
      `${get(geoCodedAddress, 'lat', '')}, ${get(geoCodedAddress, 'lng', '')}` || '',
    );
    // Refresh mapID to force map rerender
    setMapId(UUID());
    refreshMarker();
  };

  useEffect(() => {
    const placeId = get(geoCodedAddress, 'placeId', '');
    if (get(defaultSite, 'address.placeId', '') !== placeId) {
      const lat = `${get(geoCodedAddress, 'lat', '')}`;
      const lng = `${get(geoCodedAddress, 'lng', '')}`;
      if (lat && lng) {
        setValue('latLon', `${lat}, ${lng}` || '');
        trigger('latLon');
      }
      refreshMarker();
      const radiusConverted = isFeet
        ? convertLength(Number(watchedRadius), 'feet', 'meters')
        : radius;
      createDefaultCircleGeofence(radiusConverted);
    }
    if (placeId) {
      refreshMarker();
    }
  }, [geoCodedAddress]);

  useEffect(() => {
    if (watchedLat && watchedLon) {
      refreshMarker();

      if (
        watchedGeofenceType?.id === GeoFenceTypes.CIRCLE ||
        watchedGeofenceType?.id === GeoFenceTypes.EQUIPMENT
      ) {
        const radiusConverted = isFeet
          ? convertLength(Number(watchedRadius), 'feet', 'meters')
          : radius;
        createDefaultCircleGeofence(radiusConverted);
      } else {
        if (defaultSiteGeoFence?.length) {
          setDefaultSiteGeoFence(null);
          setValue('geoFence', null);
        }
      }
    }
  }, [watchedGeofenceType, coords, watchedRadius, errors?.latLon]);

  useEffect(() => {
    // If we are updating the lat/lon which was previously null, we need to update the geofence to circle if the company has a siteRadius
    // This results from integrations that couldn't find the address and the user had to manually update the lat/lon which we still want to default the geofence to circle
    if (
      watchedLat &&
      watchedLon &&
      !defaultSite?.lat &&
      !defaultSite?.lng &&
      companyValue.siteRadius
    ) {
      const radiusConverted = isFeet
        ? convertLength(Number(companyValue.siteRadius), 'meters', 'feet')
        : companyValue.siteRadius;

      setValue('geofenceType', geoFenceCircleOption);
      setValue('radius', parseFloat(radiusConverted.toFixed(2)));
      createDefaultCircleGeofence(radiusConverted);
    }
  }, [watchedLat, watchedLon]);

  // Currently the Geofence name is set as the site name, so we need to update the geofence name when the site name changes
  useEffect(() => {
    if (watchedGeofence) {
      setValue('geoFence', {
        ...watchedGeofence,
        name: watchedName,
      });
    }
  }, [watchedName]);

  useEffect(() => {
    onFormStateChange({ isDirty, isValid });
  }, [isValid, isDirty]);

  useEffect(() => {
    refreshMarker();
  }, [activeAdditionalPolygonKey]);

  useEffect(() => {
    if (watchedGeofenceType?.id === geofenceNoneOption.id) {
      setDefaultSiteGeoFence(null);
      setSiteGeoFence(null);
      setValue('geoFence', null);
    }
  }, [watchedGeofenceType?.id]);

  useEffect(() => {
    if (siteGeoFence?.length) {
      //Grab the first one only and set to form data to enable/ isDirty state changes
      setValue('geoFence', {
        id: siteGeoFence?.[0]?.id || '',
        geojson: {
          type: 'Polygon',
          coordinates: (siteGeoFence[0]?.geometry as unknown as Polygon)?.coordinates,
        },
        name: getValues('name'),
        type: GeoFenceTypes.POLYGON.toLowerCase(),
      } as any);
      //Re-trigger validation, to refresh the form state
      trigger('geoFence');
    } else if (watchedGeofence?.id && watchedGeofence?.id !== watchedGeoFenceInitialId) {
      setValue('geoFence', null);
    }
  }, [siteGeoFence, watchedGeofence?.id, watchedGeoFenceInitialId]);

  // Enable the draw control if the geofence type is set to polygon or if we have an active additional polygon
  useEffect(() => {
    (watchedGeofenceType?.id === GeoFenceTypes.POLYGON?.toLowerCase() ||
      activeAdditionalPolygonKey) &&
      setIsDrawPolygonControlEnabled(true);
  }, [watchedGeofenceType, activeAdditionalPolygonKey]);

  // Reset the siteGeoFence if the geofence type is set to none
  useEffect(() => {
    if (watchedGeofenceType?.id === geofenceNoneOption.id) {
      setSiteGeoFence([]);
      setDefaultSiteGeoFence([]);
      setAdditionalGeoFences([]);
      setActiveAdditionalPolygonKey(null);
    }
  }, [watchedGeofenceType.id]);

  const showDrawControl = useMemo(
    () =>
      isDrawPolygonControlEnabled &&
      (watchedGeofenceType?.id === GeoFenceTypes.POLYGON?.toLowerCase() ||
        activeAdditionalPolygonKey !== null),
    [
      isDrawPolygonControlEnabled,
      watchedGeofenceType?.id,
      defaultSiteGeoFence,
      activeAdditionalPolygonKey,
    ],
  );

  const showTrashControl = useMemo(
    () =>
      watchedGeofenceType?.id === GeoFenceTypes.POLYGON?.toLowerCase() ||
      activeAdditionalPolygonKey !== null,
    [watchedGeofenceType?.id, activeAdditionalPolygonKey],
  );

  // Add/edit a polygon from the additional geoFences
  const addAdditionalPolygon = (
    e: DrawCreateEvent | DrawUpdateEvent,
    edit: boolean = false,
  ) => {
    const newGeoFences = [...(additionalGeoFences || [])];

    if (edit) {
      const id = newGeoFences.filter((f: any) => f.id === e.features[0].id)[0]?.id;
      const index = newGeoFences.findIndex((f: any) => f.id === id);
      newGeoFences[index] = polygon(
        (e.features[0].geometry as Polygon).coordinates,

        {},
        { id: id },
      ) as unknown as AdditionalGeoFencePolygon;
    } else {
      const newGeoFence = polygon(
        (e.features[0].geometry as Polygon).coordinates,
        {},

        { id: activeAdditionalPolygonKey || '' },
      );
      newGeoFences.push(newGeoFence as unknown as AdditionalGeoFencePolygon);
    }
    const circles = newGeoFences.filter((f: any) => f.circle_center);
    const all = [...circles, ...newGeoFences];
    setAdditionalGeoFences(all);
    refreshMarker();
  };

  // Delete a polygon from the additional geoFences
  const deleteAdditionalPolygon = (id: string) => {
    const newGeoFences = additionalGeoFences.filter((f: any) => f.id !== id);

    setAdditionalGeoFences([...newGeoFences]);
    refreshMarker();
  };

  // Update the default site geofence
  const updateDefaultSiteGeoFence = (e: DrawCreateEvent | DrawUpdateEvent) => {
    const id = defaultSiteGeoFence?.[0]?.id || '';
    setSiteGeoFence([{ ...e.features[0], id: id }] as unknown as Feature[]);
    setDefaultSiteGeoFence([
      polygon(
        (e.features[0].geometry as Polygon).coordinates,
        {},
        {
          id: e.features[0].id,
        },
      ) as unknown as AdditionalGeoFencePolygon,
    ]);
  };

  // Map draw events
  const onCreatePolygon = (e: DrawCreateEvent) => {
    if (activeAdditionalPolygonKey) {
      addAdditionalPolygon(e);
    } else {
      updateDefaultSiteGeoFence(e);
    }
  };

  const onDeletePolygon = (e: DrawDeleteEvent) => {
    const id = e.features[0].id ?? '';
    const isAdditionalPolygon = additionalGeoFences.some((f) => f.id === id);
    if (isAdditionalPolygon) {
      deleteAdditionalPolygon(id.toString());
    } else {
      setSiteGeoFence([]);
      setDefaultSiteGeoFence([]);
      setIsDrawPolygonControlEnabled(true);
    }
  };

  const onEditPolygon = (e: DrawUpdateEvent) => {
    const id = e.features[0].id ?? '';
    const isAdditionalPolygon = additionalGeoFences.some((f) => f.id === id);
    if (isAdditionalPolygon) {
      addAdditionalPolygon(e, true);
    } else {
      updateDefaultSiteGeoFence(e);
    }
  };

  const handleSelectAddress = async (item: AddressItem) => {
    if (addressProvider === SupportedAddressProvider.GOOGLE_MAPS_PLACES_AUTOCOMPLETE) {
      const addressItem = await getAddressItemByGoogleMapsPlaceId(item.placeId);

      return addressItem;
    }

    return item;
  };

  return (
    <FormProvider {...formMethods}>
      <Box component="form" data-test-id="site-creation-form">
        {/*If inline compay will be passed as a prop from other forms(parents selector)*/}
        {/*If not inline user will have to select company*!/*/}
        {!isInline && (
          <Box display={'flex'} flexDirection={'column'} sx={{ mb: 2, mt: 0.5 }}>
            <CompanySelectorFormField
              name={'company'}
              disabled={!!defaultSite?.company?.id}
              errors={errors}
              control={control}
            />
          </Box>
        )}
        <Grid container spacing={2} sx={{ my: 1 }} alignItems="stretch">
          {/* Left side */}
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="name"
                  label={`${$t('form_fields.name')}`}
                  sx={{ mr: 2 }}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={6}>
                <AutocompleteFormField
                  control={control}
                  name="siteType"
                  errors={errors}
                  isRequired={true}
                  list={enums.site_type.values}
                  label={`${$t('form_fields.site_type')}`}
                  getValue={(item) => item}
                  getLabel={(item) => item}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextFormField
                control={control}
                errors={errors}
                name="externalId"
                label={`${$t('form_fields.id')}`}
                isRequired={false}
              />
            </Grid>
            <GeofenceForm
              companyId={companyValue.id}
              activePolygonKey={activeAdditionalPolygonKey}
              setActivePolygonKey={setActiveAdditionalPolygonKey}
              deleteGeofence={deleteAdditionalPolygon}
              additionalGeoFencesPolygons={additionalGeoFences}
              setAdditionalGeoFencesPolygons={setAdditionalGeoFences}
              defaultSiteId={defaultSite?.id}
              defaultPosition={defaultPosition}
              refreshMarker={refreshMarker}
            />

            <Grid item xs={12}>
              <TextFormField
                control={control}
                errors={errors}
                name="notes"
                multiline={true}
                rows={4}
                label={`${$t('form_fields.notes')}`}
              />
            </Grid>
          </Grid>

          {/* Right side */}
          <Grid item xs={8}>
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={8}>
                <AutocompleteAsyncFormField
                  control={control}
                  errors={errors}
                  name="address"
                  getLabel={(item) => get(item, 'streetAddress', '')}
                  getValue={(item) => get(item, 'placeId', '')}
                  label={`${$t('form_fields.address')}`}
                  placeholder={`${$t('common.address_autocomplete_hint')}`}
                  asyncCallback={getPlaces}
                  onSelect={handleSelectAddress}
                  // StartSearchPosition is used by getPlaces hook to center NB search on its lat/lon values
                  extraRequestOptions={{ startSearchPosition }}
                  clearable={true}
                  debounceTime={500}
                />
              </Grid>

              <Grid item xs={4}>
                <Box display="flex">
                  <TextFormField
                    control={control}
                    errors={errors}
                    name="latLon"
                    type="text"
                    disabled={false}
                    isRequired={true}
                    placeholder={`${$t('form_fields.lat_lon_placeholder')}`}
                    label={`${$t('form_fields.coordinates')}`}
                    sx={{ mr: 2 }}
                  ></TextFormField>

                  <Box sx={{ pt: 0.5 }}>
                    <BasicTooltip
                      title={$t('administration.site.set_position_from_address')}
                    >
                      <Button
                        color="inherit"
                        size="small"
                        onClick={refreshPosition}
                        sx={{ p: 0, mt: 2.75, height: 40 }}
                      >
                        <Refresh sx={{ width: 14, height: 14 }} />
                      </Button>
                    </BasicTooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={12}>
                <HybridBaseMap
                  center={mapCenter as LngLat}
                  containerId={mapId}
                  controlsToAdd={controlsToAdd}
                  defaultGeoFences={[
                    ...(defaultSiteGeoFence || []),
                    ...additionalGeoFences,
                  ]}
                  isDrawAllowed={true}
                  isDrawPolygonControlEnabled={showDrawControl}
                  isTrashDrawControlEnabled={showTrashControl}
                  layers={layers}
                  markers={markers}
                  onDrawCreateCallBack={(e: DrawCreateEvent) => {
                    onCreatePolygon(e);
                  }}
                  onDrawDeleteCallBack={(e: DrawDeleteEvent) => {
                    onDeletePolygon(e);
                  }}
                  onDrawUpdateCallBack={(e: DrawUpdateEvent) => {
                    onEditPolygon(e);
                  }}
                  overlay={markers.length ? null : $t('navigation.choose_address')}
                  sources={sources}
                  sx={{ height: '500px' }}
                  zoom={15}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
});

const getErrorMapping = (fieldName: string, defaultMessage: string) => {
  if (fieldName?.includes('address')) {
    fieldName = 'address';
  }
  switch (fieldName) {
    case 'address':
      return {
        field: 'address',
        message: defaultMessage,
      };
    default:
      return {
        field: fieldName,
        message: defaultMessage,
      };
  }
};

export { SiteForm };
