import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-premium';
import {
  AgaveApi_Customer_Read,
  AgaveApi_Item_Read,
  AgaveApi_LedgerAccount_Read,
  AgaveApi_Vendor_Read,
  AgaveItemizableType,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';

import { AccountTypes } from '~constants/enums';
import {
  AgaveCustomer,
  AgaveItem,
  AgaveLedgerAccount,
  AgaveVendor,
} from '~hooks/useAgave/models';
import useAgave from '~hooks/useAgave/useAgave';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

import { DataGridSelector } from './DataGridSelector';
import LedgerAccountSelector from './LedgerAccountSelector';
import { TreadNameSelector } from './TreadNameSelector';

const statusColorMap: Record<
  | AgaveApi_Customer_Read.status
  | AgaveApi_Vendor_Read.status
  | AgaveApi_Item_Read.status
  | AgaveApi_LedgerAccount_Read.status,
  'success' | 'warning' | 'error' | 'default'
> = {
  Active: 'success',
  Archived: 'default',
  Inactive: 'default',
  Pending: 'default',
  Unknown: 'default',
};

export type QuickBooksRowDef = {
  row: {
    id: string;
    account: AgaveCustomer;
    vendor: AgaveVendor;
    item: AgaveItem;
    ledgerAccount: AgaveLedgerAccount;
    linkedAccountId: string;
  };
};

/** CUSTOMERS **/
export const customerNameColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'customerName',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.name')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.account.agaveApiCustomer?.name,
};

export const customerAddressColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'customerAddress',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.address')}`,
  valueGetter: ({ row }: QuickBooksRowDef) =>
    row.account.agaveApiCustomer?.addresses[0]?.street_1,
  renderCell: ({ row }: QuickBooksRowDef) => {
    const address = row.account.agaveApiCustomer?.addresses[0];
    if (!address) return null;

    const formattedAddress = [
      address.street_1,
      address.street_2,
      address.city,
      address.state,
      address.postal_code,
      address.country,
    ]
      .filter(Boolean)
      .join(', ');

    return formattedAddress || null;
  },
};

export const customerStatusColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'customerStatus',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.source_status')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.account.agaveApiCustomer?.status,
  renderCell: ({ row }: QuickBooksRowDef) => {
    return (
      <Chip
        size="small"
        color={statusColorMap[row.account.agaveApiCustomer?.status]}
        label={row.account.agaveApiCustomer?.status}
        variant="outlined"
      />
    );
  },
};

export const customerTreadNameColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'customerTreadName',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.tread_name')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.account.agaveApiCustomer?.name,
  renderCell: ({ row }: QuickBooksRowDef) => {
    return (
      <TreadNameSelector
        type={AccountTypes.CUSTOMER}
        agaveCustomer={row.account}
        agaveVendor={null}
        linkedAccountId={row.linkedAccountId}
      />
    );
  },
};

/** VENDORS **/
export const vendorAddressColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'vendorAddress',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.address')}`,
  valueGetter: ({ row }: QuickBooksRowDef) =>
    row.vendor.agaveApiVendor?.address?.street_1,
  renderCell: ({ row }: QuickBooksRowDef) => {
    const address = row.vendor.agaveApiVendor?.address;
    if (!address) return null;

    const formattedAddress = [
      address.street_1,
      address.street_2,
      address.city,
      address.state,
      address.postal_code,
      address.country,
    ]
      .filter(Boolean)
      .join(', ');

    return formattedAddress || null;
  },
};

export const vendorNameColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'vendorName',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.name')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.vendor.agaveApiVendor?.name,
};

export const vendorStatusColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'vendorStatus',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.source_status')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.vendor.agaveApiVendor?.status,
  renderCell: ({ row }: QuickBooksRowDef) => {
    return (
      <Chip
        size="small"
        color={statusColorMap[row.vendor.agaveApiVendor.status]}
        label={row.vendor.agaveApiVendor.status}
        variant="outlined"
      />
    );
  },
};

export const vendorTreadNameColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'vendorTreadName',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.tread_name')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.vendor.agaveApiVendor?.name,
  renderCell: ({ row }: QuickBooksRowDef) => {
    return (
      <TreadNameSelector
        type={AccountTypes.VENDOR}
        agaveCustomer={null}
        agaveVendor={row.vendor}
        linkedAccountId={row.linkedAccountId}
      />
    );
  },
};

/** ITEMS **/
export const itemNameColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'itemName',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.name')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.item.agaveApiItem?.name,
};

export const itemTypeColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'itemType',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.type')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.item.agaveApiItem?.type,
};

export const itemStatusColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'itemStatus',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.source_status')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.item.agaveApiItem?.status,
  renderCell: ({ row }: QuickBooksRowDef) => {
    return (
      <Chip
        size="small"
        color={statusColorMap[row.item.agaveApiItem?.status]}
        label={row.item.agaveApiItem?.status}
        variant="outlined"
      />
    );
  },
};

export const itemUnitPriceColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'itemUnitPrice',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.unit_price')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.item.agaveApiItem?.unit_price,
  renderCell: ({ row }: QuickBooksRowDef) => {
    const { currencyFormatter } = useCompanyCurrency();
    return (
      <Box>
        <Typography>
          {row.item.agaveApiItem?.unit_price
            ? currencyFormatter(row.item.agaveApiItem.unit_price)
            : '-'}
          {row.item.agaveApiItem?.price_unit_of_measure
            ? `, ${row.item.agaveApiItem?.price_unit_of_measure}`
            : ',-'}
        </Typography>
      </Box>
    );
  },
};

export const itemUnitCostColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'itemUnitCost',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.unit_cost')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.item.agaveApiItem?.unit_cost,
  renderCell: ({ row }: QuickBooksRowDef) => {
    const { currencyFormatter } = useCompanyCurrency();
    return (
      <Box>
        <Typography>
          {row.item.agaveApiItem?.unit_cost
            ? currencyFormatter(row.item.agaveApiItem.unit_cost)
            : '-'}
          {row.item.agaveApiItem?.cost_unit_of_measure
            ? `, ${row.item.agaveApiItem?.cost_unit_of_measure}`
            : ',-'}
        </Typography>
      </Box>
    );
  },
};

export const itemServiceColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'itemService',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.service')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.item.itemizable?.id,
  renderCell: ({ row }: QuickBooksRowDef) => {
    const { getItemsServicesTypeahead, updateItem } = useAgave();
    const { toasterStore } = useStores();

    const disabled = row.item.itemizable?.type === AgaveItemizableType.ADD_ON;
    return (
      <DataGridSelector
        disabled={disabled}
        defaultValue={disabled ? null : row.item.itemizable}
        linkedAccountId={row.linkedAccountId}
        asyncTypeahead={getItemsServicesTypeahead}
        onChange={async (data) => {
          const updatedItem = await updateItem(
            row.item.id,
            data?.id ?? null,
            data?.id ? AgaveItemizableType.SERVICE : null,
          );
          if (!updatedItem) return;

          toasterStore.push(
            alert(
              t(
                'administration.integration.agave.quick_books_online.grid.tread_name_updated',
                {
                  name: updatedItem.agaveApiItem.name,
                },
              ),
              AlertTypes.success,
            ),
          );
        }}
      />
    );
  },
};

export const itemAddOnColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'itemAddOn',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.add_on')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.item.itemizable?.id,
  renderCell: ({ row }: QuickBooksRowDef) => {
    const { getItemsAddOnsTypeahead, updateItem } = useAgave();
    const { toasterStore } = useStores();

    const disabled = row.item.itemizable?.type === AgaveItemizableType.SERVICE;
    return (
      <DataGridSelector
        disabled={disabled}
        defaultValue={disabled ? null : row.item.itemizable}
        linkedAccountId={row.linkedAccountId}
        asyncTypeahead={getItemsAddOnsTypeahead}
        onChange={async (data) => {
          const updatedItem = await updateItem(
            row.item.id,
            data?.id ?? null,
            data?.id ? AgaveItemizableType.ADD_ON : null,
          );
          if (!updatedItem) return;

          toasterStore.push(
            alert(
              t(
                'administration.integration.agave.quick_books_online.grid.tread_name_updated',
                {
                  name: updatedItem.agaveApiItem.name,
                },
              ),
              AlertTypes.success,
            ),
          );
        }}
      />
    );
  },
};

/** LEDGER **/
export const ledgerAccountNameColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'ledgerAccountName',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.name')}`,
  valueGetter: ({ row }: QuickBooksRowDef) =>
    row.ledgerAccount.agaveApiLedgerAccount?.name,
};

export const ledgerAccountTypeColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'ledgerAccountType',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.type')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.ledgerAccount.type,
};

export const ledgerAccountStatusColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'ledgerAccountStatus',
  headerName: `${t('administration.integration.agave.quick_books_online.grid.source_status')}`,
  valueGetter: ({ row }: QuickBooksRowDef) =>
    row.ledgerAccount.agaveApiLedgerAccount?.status,
  renderCell: ({ row }: QuickBooksRowDef) => {
    return (
      <Chip
        size="small"
        color={statusColorMap[row.ledgerAccount.agaveApiLedgerAccount?.status]}
        label={row.ledgerAccount.agaveApiLedgerAccount?.status}
        variant="outlined"
      />
    );
  },
};

export const ledgerAccountColDef: GridColDef = {
  disableReorder: true,
  flex: 1,
  field: 'ledgerAccount',
  minWidth: 460,
  headerName: `${t('administration.integration.agave.quick_books_online.grid.payable_receivable')}`,
  valueGetter: ({ row }: QuickBooksRowDef) => row.ledgerAccount.id,
  renderCell: ({ row }: QuickBooksRowDef) => {
    return <LedgerAccountSelector ledgerAccount={row.ledgerAccount} />;
  },
};
