export const COMMON_GRID_CONFIG = {
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gridTemplateRows: 'repeat(auto-fill, 52px)',
  gridTemplateAreas: `". . . . . . . . qty rate total action"`,
  alignItems: 'center',
  gap: 2,
  bgcolor: 'common.white',
  px: 1,
};

export const EM_DASH = '—';
