import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

import { CallbackErrorState } from '~components/Dialog/ModalDialog';

export const DeleteAccountErrorComponent = (error: CallbackErrorState) => {
  if (error.code === 422) {
    return (
      <Alert severity="warning" sx={{ mt: 4 }}>
        <AlertTitle sx={{ fontSize: '16px', fontWeight: 600 }}>
          {t('account.delete_error_title')}
        </AlertTitle>
        <Typography variant={'body1'}>{t('account.delete_error_text')}</Typography>
      </Alert>
    );
  }
  return null;
};
