import Box from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/system';
import { DateRange, StaticDatePicker } from '@mui/x-date-pickers-pro';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import theme from '~theme/AppTheme';

export type NullableDate = Dayjs | null;

enum DateRangePickerVariant {
  DATE_RANGE = 'date_range',
  SINGLE_DATE = 'single_date',
}

const DATE_FORMAT_WITHOUT_YEAR = 'ddd MMM-DD';
const DATE_FORMAT_WITH_YEAR = 'ddd MMM-DD ‘YY';

type DateRangePickerShortcut = {
  getValue: () => DateRange<Dayjs>;
  key: string;
  label: string;
};

export type DateRangePickerDateSelectFunction = (
  startDate: NullableDate,
  endDate: NullableDate,
  selectedShortcut?: string,
) => void;

const dateRangeVariantShortcuts: DateRangePickerShortcut[] = [
  {
    key: 'today',
    label: t('date.today'),
    getValue: () => {
      const today = dayjs.tz();
      return [today.startOf('day'), today.endOf('day')];
    },
  },
  {
    key: 'tomorrow',
    label: t('date.tomorrow'),
    getValue: () => {
      const today = dayjs.tz().add(1, 'days');
      return [today.startOf('day'), today.endOf('day')];
    },
  },
  {
    key: 'this_week',
    label: t('date.this_week'),
    getValue: () => {
      const today = dayjs.tz();
      return [today.weekday(0).startOf('day'), today.weekday(6).endOf('day')];
    },
  },
  {
    key: 'last_7_days',
    label: t('date.last_seven_days'),
    getValue: () => {
      const today = dayjs.tz();
      return [today.subtract(7, 'days').startOf('day'), today.endOf('day')];
    },
  },
  {
    key: 'next_7_days',
    label: t('date.next_seven_days'),
    getValue: () => {
      const today = dayjs.tz();
      return [today.startOf('day'), today.add(7, 'days').endOf('day')];
    },
  },
];

const singleDateVariantShortcuts: DateRangePickerShortcut[] = [
  {
    key: 'today',
    label: t('date.today'),
    getValue: () => [dayjs.tz(), null],
  },
  {
    key: 'tomorrow',
    label: t('date.tomorrow'),
    getValue: () => [dayjs.tz().add(1, 'days'), null],
  },
];

const getDateFormat = (date: Dayjs) => {
  const isDateIsWithinCurrentYear = dayjs.tz().year() === date.year();

  return isDateIsWithinCurrentYear ? DATE_FORMAT_WITHOUT_YEAR : DATE_FORMAT_WITH_YEAR;
};

const getDateRangeVariantDisplayDate = (
  startDate: NullableDate,
  endDate: NullableDate,
) => {
  if (!startDate && !endDate) {
    return t('dispatch.dispatch_v2.filters.all_dates');
  }

  if (!startDate) {
    const date = endDate as Dayjs;
    const format = getDateFormat(date);

    return date.format(format);
  }

  const start = startDate as Dayjs;
  const startFormat = getDateFormat(start);
  const formattedStartDate = start.format(startFormat);

  if (!endDate) {
    return formattedStartDate;
  }

  const end = endDate as Dayjs;
  const endFormat = getDateFormat(end);
  const formattedEndDate = end.format(endFormat);

  const singleDateLabel = startDate.isToday() ? t('date.today') : formattedStartDate;

  return formattedStartDate !== formattedEndDate
    ? `${formattedStartDate} - ${formattedEndDate}`
    : singleDateLabel;
};

const getSingleDateVariantDisplayDate = (date: NullableDate) => {
  if (!date) {
    return t('dispatch.dispatch_v2.filters.all_dates');
  }

  const singleDate = date as Dayjs;
  const format = getDateFormat(singleDate);
  const label = singleDate.isToday() ? t('date.today') : singleDate.format(format);

  return label;
};

const getDisplayDate = (
  startDate: NullableDate,
  endDate: NullableDate,
  isDateRangeVariant: boolean,
) => {
  if (isDateRangeVariant) {
    return getDateRangeVariantDisplayDate(startDate, endDate);
  }

  return getSingleDateVariantDisplayDate(startDate);
};

const throwIfCustomShortcutsKeysAreNotSafe = (
  defaultShortcuts: DateRangePickerShortcut[],
  customShortcuts?: DateRangePickerShortcut[],
) => {
  const alreadyTakenShortcutKey = customShortcuts?.find((customShortcut) => {
    return defaultShortcuts.find((shortcut) => shortcut.key === customShortcut.key);
  })?.key;

  if (alreadyTakenShortcutKey) {
    throw new Error(
      `DateRangePicker error: custom shortcut key "${alreadyTakenShortcutKey}" has already been taken.`,
    );
  }
};

type Props = {
  allowAllDates?: boolean;
  customShortcuts?: DateRangePickerShortcut[];
  endDate: NullableDate;
  onAnchorClose?: () => void;
  onDateSelect: DateRangePickerDateSelectFunction;
  showShortcuts?: boolean;
  startDate: NullableDate;
  variant?: `${DateRangePickerVariant}`;
};

export default function DateRangePicker({
  allowAllDates,
  customShortcuts,
  endDate,
  onAnchorClose,
  onDateSelect,
  showShortcuts,
  startDate,
  variant = 'date_range',
}: Props) {
  const isDateRangeVariant = variant === 'date_range';

  throwIfCustomShortcutsKeysAreNotSafe(
    isDateRangeVariant ? dateRangeVariantShortcuts : singleDateVariantShortcuts,
    customShortcuts,
  );

  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [selectedShortcut, setSelectedShortcut] = useState<string>();
  const isOpen = Boolean(anchor);

  const hasShortcuts = customShortcuts ? true : showShortcuts;
  const shouldRenderFooter = Boolean(allowAllDates || hasShortcuts);

  const displayDate = useMemo(
    () => getDisplayDate(startDate, endDate, isDateRangeVariant),
    [startDate, endDate, isDateRangeVariant],
  );

  const availableShortcuts = useMemo(() => {
    if (isDateRangeVariant) {
      return dateRangeVariantShortcuts.concat(customShortcuts ?? []);
    }

    return singleDateVariantShortcuts.concat(customShortcuts ?? []);
  }, [isDateRangeVariant, customShortcuts]);

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchor(null);
    onAnchorClose?.();
  }, [onAnchorClose]);

  const handleDateRangeChange = useCallback(
    (newValue: DateRange<Dayjs>, selectedShortcut: string | undefined = undefined) => {
      onDateSelect(newValue[0], newValue[1], selectedShortcut);
      setSelectedShortcut(selectedShortcut);
    },
    [onDateSelect],
  );

  const handleClearButtonClick = useCallback(() => {
    onDateSelect(null, null);
  }, [onDateSelect]);

  useEffect(() => {
    if (selectedShortcut) {
      handleClose();
    }

    return () => {
      setSelectedShortcut(undefined);
    };
  }, [selectedShortcut, handleClose]);

  return (
    <>
      <StyledSmallButton onClick={handleOpen}>{displayDate}</StyledSmallButton>

      <Popover
        open={isOpen}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {isDateRangeVariant ? (
          <DateRangeCalendar
            value={[startDate, endDate]}
            onChange={(value) => handleDateRangeChange(value)}
            sx={(theme) => ({
              '& .MuiDateRangePickerDay-day.Mui-selected': {
                bgcolor: theme.brandV2.colors.treadYellow,
                '&:hover': { bgcolor: theme.brandV2.colors.treadYellowDark },
              },
              '& .MuiDateRangePickerDay-day:focus.Mui-selected': {
                bgcolor: theme.brandV2.colors.treadYellow,
              },
            })}
          />
        ) : (
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={startDate}
            onChange={(value: Dayjs | null) => handleDateRangeChange([value, null])}
            views={['day']}
            sx={(theme) => ({
              '& .MuiPickersDay-root.Mui-selected': {
                bgcolor: theme.brandV2.colors.treadYellow,
                '&:hover': { bgcolor: theme.brandV2.colors.treadYellowDark },
              },
              '& .MuiPickersDay-root:hover:not(.Mui-selected)': {
                border: 'solid 1px #9e9e9e',
              },
              '& .MuiPickersCalendarHeader-root': {
                justifyContent: 'center',
                position: 'relative',
              },
              '& .MuiPickersCalendarHeader-labelContainer': {
                mr: 0,
                fontSize: '14px',
              },
              '& .MuiPickersArrowSwitcher-root .MuiPickersArrowSwitcher-button': {
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                '&:first-child': { left: '12px' },
                '&:last-child': { right: '12px' },
              },
              '& .MuiPickersArrowSwitcher-spacer': { display: 'none' },
            })}
          />
        )}

        {shouldRenderFooter && (
          <Box
            sx={(theme) => ({
              alignItems: 'center',
              borderTop: `solid 1px ${theme.palette.divider}`,
              display: 'flex',
              justifyContent: 'space-between',
              p: 1,
            })}
          >
            {hasShortcuts && (
              <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
                {availableShortcuts.map((shortcut) => (
                  <StyledSmallButton
                    key={shortcut.label}
                    onClick={() => {
                      handleDateRangeChange(shortcut.getValue(), shortcut.key);
                    }}
                  >
                    {shortcut.label}
                  </StyledSmallButton>
                ))}
              </Box>
            )}

            {allowAllDates && (
              <Box sx={{ ml: 'auto' }}>
                <SmallButton
                  color="brandV2OrangeDark"
                  onClick={handleClearButtonClick}
                  variant="text"
                >
                  {t('dispatch.dispatch_v2.filters.all_dates')}
                </SmallButton>
              </Box>
            )}
          </Box>
        )}
      </Popover>
    </>
  );
}

const StyledSmallButton = styled(SmallButton)<ButtonProps>(() => ({
  alignItems: 'center',
  backgroundColor: 'white',
  border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
  borderRadius: theme.brandV2.borderRadius,
  boxShadow: 'none',
  display: 'flex',
  height: '30px',
  padidng: theme.spacing(),
  '&:hover': { backgroundColor: 'white' },
}));
