import _ from 'lodash';
import { useCallback, useState } from 'react';

import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { useCompany } from '~hooks/useCompany';
import { useFetchMore } from '~utils/hooks/useFetchMore';

export const useCompanySelectorMenu = () => {
  const { getAllUserAvailableCompaniesTypeahead } = useCompany();

  const [searchFieldValue, setSearchFieldValue] = useState('');
  const applyDebouncedFilter = _.debounce((callback: () => void) => {
    callback();
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  const fetchCompanies = useCallback(
    async (nextPageCursor?: string) => {
      const response = await getAllUserAvailableCompaniesTypeahead({
        limit: 10,
        query: searchFieldValue,
        cursor: nextPageCursor || '',
      });

      return {
        data: response?.data || [],
        hasMore: response?.pagination.after || '',
      };
    },
    [searchFieldValue],
  );

  const { data, loadingReason, hasMore, fetchMore } = useFetchMore({
    dataFetcher: fetchCompanies,
  });
  return {
    searchFieldValue,
    setSearchFieldValue,
    applyDebouncedFilter,
    data,
    loadingReason,
    hasMore,
    fetchMore,
  };
};
