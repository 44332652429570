import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { BulkSms_Create } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { DialogHeader } from '~components/Dialog/DialogHeader';
import { TextFormField } from '~components/FormFields/TextFormField';
import { Job, useJob } from '~hooks/useJob';
import { Order, useOrders } from '~hooks/useOrders';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

enum SendTextDialogMode {
  BULK_DRIVERS = 'bulk_drivers',
  JOB_DRIVER = 'job_driver',
  ORDER_DRIVERS = 'order_drivers',
}

type FunctionSendSms = (jobId: string, message: string) => Promise<void>;
type FunctionBulkSendSms = (args: BulkSms_Create) => Promise<void>;

type PropsByMode = {
  canSendSms: boolean;
  sendSms: FunctionSendSms | FunctionBulkSendSms;
  sourceFriendlyId?: string;
  textFieldPlaceholder: string;
};

interface SendTextDialogProps {
  isOpen: boolean;
  mode: `${SendTextDialogMode}`;
  onClose: () => void;
  source?: Job | Order | string[];
}

const sendTextSchema = yup.object().shape({
  message: yup
    .string()
    .trim()
    .min(
      1,
      `${t('form_validation_errors.required', { field: t('form_fields.message') })}`,
    )
    .required(),
});

type SendTextDTO = yup.InferType<typeof sendTextSchema>;

export default function SendTextDialog({
  isOpen,
  mode,
  onClose,
  source,
}: SendTextDialogProps) {
  const {
    bulkSendSmsToDrivers,
    sendSmsToDriver,
    isSendingSms: isSendingSmsToJobDriver,
  } = useJob();
  const { sendSmsToAllDrivers, isSendingSms: isSendingSmsToOrderDrivers } = useOrders();
  const { toasterStore } = useStores();

  const isSendingSms = isSendingSmsToJobDriver || isSendingSmsToOrderDrivers;
  const propsByMode = useMemo(() => {
    if (!source) {
      return null;
    }

    if (mode === SendTextDialogMode.JOB_DRIVER) {
      const job = source as Job;

      const propsByMode: PropsByMode = {
        canSendSms: Boolean(job.driver?.phone),
        sendSms: sendSmsToDriver,
        sourceFriendlyId: job.jobId,
        textFieldPlaceholder: t('dispatch.job.send_text.send_message_to', {
          name: String(job.driver?.fullName),
        }),
      };

      return propsByMode;
    }

    if (mode === SendTextDialogMode.ORDER_DRIVERS) {
      const order = source as Order;

      const propsByMode: PropsByMode = {
        canSendSms: Boolean(order.orderSummary?.jobsCount),
        sendSms: sendSmsToAllDrivers,
        sourceFriendlyId: order.orderId,
        textFieldPlaceholder: t('dispatch.job.send_text.send_message_to', {
          name: t('dispatch.job.send_text.all_drivers'),
        }),
      };

      return propsByMode;
    }

    const propsByMode: PropsByMode = {
      canSendSms: true,
      sendSms: bulkSendSmsToDrivers,
      textFieldPlaceholder: t('dispatch.job.send_text.send_message_to', {
        name: t('dispatch.job.send_text.all_drivers'),
      }),
    };

    return propsByMode;
  }, [mode, source]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<SendTextDTO>({
    resolver: yupResolver(sendTextSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { message: '' },
  });

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      event.stopPropagation();

      handleSubmit(async ({ message }) => {
        if (!source) {
          return;
        }

        if (Array.isArray(source)) {
          const args: BulkSms_Create = { job_ids: source, message };

          await (propsByMode?.sendSms as FunctionBulkSendSms)(args);
        } else {
          await (propsByMode?.sendSms as FunctionSendSms)(source.id, message);
        }

        toasterStore.push(
          alert(t('dispatch.job.send_text.text_sent'), AlertTypes.success),
        );
        onClose();
      })();
    },
    [source, onClose, propsByMode],
  );

  useEffect(() => {
    if (isOpen) {
      setValue('message', '');
    }
  }, [isOpen, setValue]);

  if (!propsByMode?.canSendSms) {
    return <></>;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={(__: never, reason: DialogCloseReasonType) => {
        if (isActionClicked(reason)) {
          onClose();
        }
      }}
    >
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <DialogHeader
          closeCallBack={onClose}
          title={
            <Typography component="span" variant="h5">
              {`${propsByMode.sourceFriendlyId ? `${propsByMode.sourceFriendlyId}: ` : ''}${t('actions.send_text')}`}
            </Typography>
          }
        />

        <DialogContent>
          <TextFormField
            control={control}
            errors={errors}
            isRequired
            multiline
            name="message"
            placeholder={propsByMode.textFieldPlaceholder}
            rows={5}
          />
        </DialogContent>

        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <LoadingButton
            color="primary"
            disabled={isSendingSms}
            loading={isSendingSms}
            loadingPosition="start"
            startIcon={<></>}
            sx={isSendingSms ? { pl: 5, pr: 2 } : { pr: 2 }}
            type="submit"
            variant="contained"
          >
            {t('actions.send')}
          </LoadingButton>

          <Button
            color="secondary"
            disabled={isSendingSms}
            onClick={onClose}
            sx={{ mr: 2, px: 2 }}
            variant="outlined"
          >
            {t('actions.cancel')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
