import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { RateOwnerType } from '@treadinc/horizon-api-spec';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { RateCard } from '~components/Cards/RateCard/RateCard';
import { NoDataFound } from '~components/DataGrid/NoDataFound';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog } from '~components/Dialog/ModalDialog';
import { FuelSurchargeInlineForm } from '~components/FuelSurchargeInlineForm/FuelSurchargeInlineForm';
import { ActiveItemsVirtualLazyLoadList } from '~components/Lists/ActiveItemsVirtualLazyLoadList';
import { QuickFilterButtonGroup } from '~components/QuickFilterButtonGroup/QuickFilterButtonGroup';
import { PageTitle } from '~components/typographyTitles/PageTitle';
import { RateTypes } from '~constants/enums';
import { FormStateChangeProps } from '~formsShared';
import { Account, useAccount } from '~hooks/useAccount';
import { Company, useCompany } from '~hooks/useCompany';
import { CopyRateParams, Rate, useRates } from '~hooks/useRates';
import { User, useUserRoles, useUsers } from '~hooks/useUsers';
import { LoaderTread } from '~icons';
import { Paginated } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { isActionClicked } from '~utils/utilFunctions';

import InternalDriversList from './InternalDriversList';
import { RateCopyToForm } from './RateCopyToForm';
import { RateForm } from './RateForm';
import { DEFAULT_COMPANY_RATE_FAKE_ID } from './rateUtils';

interface CurrentSelectionState {
  type: RateOwnerType;
  id: string;
}

interface DriversListState {
  isLoading: boolean;
  list: User[];
}

const LEFT_COLUMN_WIDTH = '35%';
const RIGHT_COLUMN_WIDTH = '65%';
const RatesManagement = observer(() => {
  const theme = useTheme();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isCopyDialogOpen, setCopyDialogOpen] = useState(false);
  const [currentSelection, setCurrentSelection] = useState<CurrentSelectionState>({
    type: RateOwnerType.VENDOR,
    id: DEFAULT_COMPANY_RATE_FAKE_ID,
  });
  const [selectedRateId, setSelectedRateId] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isCopyDirty, setIsCopyDirty] = useState<boolean>(false);
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const [isPrimaryDefined, setIsPrimaryDefined] = useState<boolean>(false);
  const [driversList, setDriversList] = useState<DriversListState>({
    isLoading: true,
    list: [],
  });
  const { userStore, toasterStore, companyAssetsStore } = useStores();
  const {
    getAccountRatesUsingOwnerType,
    deleteRate,
    updateRate,
    createRate,
    copyRate,
    isLoadingRates,
    isUpdatingRate,
    isCopyingRate,
  } = useRates();
  const { getUserRoles } = useUserRoles();
  const { getAllCompanyAccounts } = useAccount();
  const { getFilteredCompanyUsers } = useUsers();
  const { getCompanyById } = useCompany();

  const companyId = userStore.currentCompanies[0]?.id || userStore.userCompany?.id;
  const [companyDetails, setCompanyDetails] = useState<Company>();

  useEffect(() => {
    if (companyId) {
      getCompanyById({
        id: companyId,
        callBack: setCompanyDetails,
      });
    }
  }, [companyId]);

  const rateItems = useMemo(() => {
    return companyAssetsStore.rates
      ? companyAssetsStore.rates?.slice().sort((a) => (a.isPrimary ? -1 : 1))
      : [];
  }, [companyAssetsStore.rates, currentSelection]);

  const selectedRate: Nullable<Rate> = useMemo(() => {
    return companyAssetsStore.rates.find((rate) => rate.id === selectedRateId) || null;
  }, [selectedRateId, rateItems]);

  const [accountItems, setAccountItems] = useState<Account[]>([]);

  const accountItemsFilteredByRateAccountType = useMemo(() => {
    return accountItems.filter((acc) => {
      return (
        acc.accountTypes.includes(`${selectedRate?.accountType}`) &&
        acc.id !== currentSelection.id
      );
    });
  }, [accountItems, selectedRateId]);

  const buttons = [
    {
      title: $t('administration.rates.vendor'),
      value: RateOwnerType.VENDOR,
      action: () => {
        setCurrentSelection({
          id: DEFAULT_COMPANY_RATE_FAKE_ID,
          type: RateOwnerType.VENDOR,
        });
      },
    },
    {
      title: $t('administration.rates.customer'),
      value: RateOwnerType.CUSTOMER,
      action: () => {
        setCurrentSelection({
          id: DEFAULT_COMPANY_RATE_FAKE_ID,
          type: RateOwnerType.CUSTOMER,
        });
      },
    },
    {
      title: $t('administration.rates.internal_drivers'),
      value: RateOwnerType.DRIVER,
      action: () => {
        setCurrentSelection({
          id: DEFAULT_COMPANY_RATE_FAKE_ID,
          type: RateOwnerType.DRIVER,
        });
      },
    },
  ];

  const selectedAccount = useMemo(() => {
    return (
      companyAssetsStore.userCompanyAccounts.find(
        (account) => account.id === currentSelection.id,
      ) || null
    );
  }, [currentSelection.id, accountItems]);

  const rateFormDialogTitle = useMemo(() => {
    if (currentSelection.id === DEFAULT_COMPANY_RATE_FAKE_ID) {
      return $t('administration.rates.account_default');
    }

    if (currentSelection.type === RateOwnerType.DRIVER) {
      const driver = driversList.list.find((driver) => driver.id === currentSelection.id);

      return String(driver?.fullName);
    }

    return String(selectedAccount?.name);
  }, [selectedAccount?.name, currentSelection, driversList.list]);

  const userPermissions = userStore.getPermissions();

  useEffect(() => {
    if (companyId) {
      setDriversList({ isLoading: true, list: [] });

      getFilteredCompanyUsers(companyId, { access: 'execute_jobs' }).then((response) => {
        setDriversList({ isLoading: false, list: response });
      });
    }
  }, [companyId]);

  useEffect(() => {
    getUserRoles();
  }, []);

  useEffect(() => {
    if (currentSelection.type !== RateOwnerType.DRIVER) {
      getAllCompanyAccounts({
        companyId: companyId,
        accountTypes: [currentSelection.type],
        callback: setAccountItems,
      });
    }
  }, [currentSelection.type, companyId]);

  useEffect(() => {
    if (rateItems?.length) {
      setIsPrimaryDefined(rateItems.some((rate) => rate.isPrimary));
    } else {
      setIsPrimaryDefined(false);
    }
  }, [rateItems, selectedAccount]);

  useEffect(() => {
    if (currentSelection.id !== DEFAULT_COMPANY_RATE_FAKE_ID) {
      const params: Parameters<typeof getAccountRatesUsingOwnerType>[0] = {
        ownerType: currentSelection.type,
      };

      if (currentSelection.type === RateOwnerType.DRIVER) {
        params.driverId = currentSelection.id;
      } else {
        params.accountId = currentSelection.id;
      }

      getAccountRatesUsingOwnerType(params).then((response) => {
        updateRatesInCompanyAssetsStore(response);
      });
    }
  }, [currentSelection]);

  useEffect(() => {
    companyAssetsStore.setRates([]);
    setSelectedRateId(DEFAULT_COMPANY_RATE_FAKE_ID);
    getCompanyDefaultRate();
  }, [currentSelection.type]);

  useEffect(() => {
    const disabled = [];
    selectedRateId?.length && disabled.push('type');
    isPrimaryDefined && disabled.push('isPrimary');
    setDisabledFields(disabled);
  }, [selectedRateId, isPrimaryDefined]);

  const [isLoading, setIsLoading] = useState(false);

  const modalDialogRef = useRef<any>(null);
  const rateDialogRef = useRef<any>(null);
  const rateCopyDialogRef = useRef<any>(null);

  const loadMoreAccounts = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      await getAllCompanyAccounts({
        companyId: companyId,
        accountTypes: [currentSelection.type],
        link: 'after',
        callback: (data) => {
          setAccountItems((prevItems) => [...prevItems, ...data]);
        },
      });
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, currentSelection.type, companyId]);

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleCloseCopyDialog = () => {
    setCopyDialogOpen(false);
  };
  const editRate = (id: string) => {
    setSelectedRateId(id);
    setDialogOpen(true);
  };

  const createNew = () => {
    setSelectedRateId(null);
    setDialogOpen(true);
  };

  const deleteRateHandler = (id: string) => {
    setSelectedRateId(id);
    modalDialogRef.current?.open();
  };

  const deleteRateCallback = () => {
    if (selectedRateId) {
      deleteRate({
        id: selectedRateId,
        callBack: () => {
          modalDialogRef.current?.close();
          toasterStore.push(
            alert($t('administration.rates.rate_deleted'), AlertTypes.success),
          );
        },
      });
    }
  };

  const onSuccessCopy = (rate?: Rate) => {
    handleCloseCopyDialog();
    const name = `${rate?.name}`;
    toasterStore.push(
      alert($t('administration.rates.rate_copied', { name }), AlertTypes.success),
    );
  };
  const onSuccess = (rate?: Rate) => {
    handleClose();
    const name = `${rate?.name}`;
    toasterStore.push(
      alert(
        selectedRateId
          ? $t('administration.rates.rate_updated', { name })
          : $t('administration.rates.rate_created', { name }),
        AlertTypes.success,
      ),
    );
  };
  const onSubmitCallback = (data: any) => {
    if (selectedRateId) {
      updateRate({
        rate: data,
        callBack: onSuccess,
        ownerType: currentSelection.type,
      });
    } else {
      createRate({
        rate: data,
        callBack: onSuccess,
        ownerType: currentSelection.type,
      });
    }
  };
  const onSubmitCopyCallback = (data: any) => {
    copyRate({
      rateId: selectedRateId,
      rateName: data.name,
      accountId: data.account?.id,
      projects: data.projects,
      services: data.services,
      equipment: data.equipment,
      callBack: onSuccessCopy,
    } as CopyRateParams);
  };
  const onSubmitForm = () => {
    rateDialogRef.current?.submit(onSubmitCallback);
  };
  const onSubmitCopyForm = () => {
    rateCopyDialogRef.current?.submit(onSubmitCopyCallback);
  };
  const updateRatesInCompanyAssetsStore = ({ data, pagination }: Paginated<Rate>) => {
    companyAssetsStore.setRates(data);
    companyAssetsStore.setRatesPagination(pagination);
  };
  const getCompanyDefaultRate = async () => {
    setCurrentSelection({ ...currentSelection, id: DEFAULT_COMPANY_RATE_FAKE_ID });

    const response = await getAccountRatesUsingOwnerType({
      ownerType: currentSelection.type,
    });
    updateRatesInCompanyAssetsStore(response);
  };
  const onFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setIsDirty(isDirty);
  };
  const onCopyFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setIsCopyDirty(isDirty);
  };

  return (
    <>
      {userPermissions.canEditCompany && (
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} alignItems={'start'} justifyContent={'start'}>
            <PageTitle text={`${t('administration.rates.title')}`}></PageTitle>
          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} sx={{ mb: 2, alignItems: 'center' }}>
              <Typography variant={'body1'}>
                {t('administration.rates.fuel_title')}
              </Typography>
            </Box>
            {companyDetails && (
              <FuelSurchargeInlineForm
                buttonOrder={2}
                inputOrder={1}
                defaultValue={7}
                company={companyDetails}
              />
            )}
            <Box display={'flex'} sx={{ mb: 2, mt: 1 }}>
              <Typography variant={'caption'} color={'secondary'}>
                {t('administration.rates.fuel_surcharge_explanation')}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          alignItems={'center'}
          sx={{
            minHeight: '82px',
          }}
        >
          <Box
            width={LEFT_COLUMN_WIDTH}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <QuickFilterButtonGroup
              buttons={buttons}
              color={theme.palette.action.selected}
              currentActiveFilter={currentSelection.type}
            />
          </Box>
          <Divider orientation={'vertical'} variant={'fullWidth'} flexItem></Divider>
          <Box
            width={RIGHT_COLUMN_WIDTH}
            display={'flex'}
            alignItems={'flex-end'}
            justifyContent={'end'}
          >
            {userPermissions?.canCreateRate && (
              <Button sx={{ mr: 2 }} onClick={createNew}>
                {t('actions.create_rate_card')}
              </Button>
            )}
          </Box>
        </Box>
        <Divider variant={'fullWidth'} flexItem></Divider>
        <Box display={'flex'} flexDirection={'row'}>
          <Box
            display={'flex'}
            width={LEFT_COLUMN_WIDTH}
            sx={{ borderRight: `1px solid ${theme.palette.divider}` }}
          >
            <Box
              sx={{ height: `calc(100vh - 220px)` }}
              width={'100%'}
              overflow={'hidden'}
            >
              <Box>
                {/*Keep, for default company rate we have to mock it as account item, that fetches rates without accountId*/}
                <ListItemButton
                  selected={currentSelection.id === DEFAULT_COMPANY_RATE_FAKE_ID}
                  onClick={getCompanyDefaultRate}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    borderRight:
                      currentSelection.id === DEFAULT_COMPANY_RATE_FAKE_ID
                        ? `2px solid ${theme.palette.primary.main}`
                        : '',
                  }}
                >
                  <ListItemText
                    sx={{ fontWeight: 600 }}
                    primary={t('administration.rates.default_rate')}
                  />
                </ListItemButton>
              </Box>

              {currentSelection.type === RateOwnerType.DRIVER ? (
                <InternalDriversList
                  drivers={driversList.list}
                  isLoading={driversList.isLoading}
                  selectedDriverId={currentSelection.id}
                  onSelectedDriverChange={(driverId: string) => {
                    setCurrentSelection({ ...currentSelection, id: driverId });
                  }}
                />
              ) : (
                <ActiveItemsVirtualLazyLoadList
                  titleProperty={'name'}
                  hasNextPage={Boolean(
                    companyAssetsStore.accountsPagination.after?.length,
                  )}
                  items={accountItems}
                  loadNextPage={loadMoreAccounts}
                  activeItemId={selectedAccount?.id || ''}
                  itemHeight={48}
                  onClickCallBack={(data) => {
                    setCurrentSelection({ ...currentSelection, id: data.id });
                  }}
                />
              )}
            </Box>
          </Box>
          <Box
            width={RIGHT_COLUMN_WIDTH}
            display={'flex'}
            flexDirection={'column'}
            alignItems={rateItems?.length ? 'center' : ''}
            sx={{ maxHeight: 'calc(100vh - 220px)', overflowY: 'auto', pt: 2 }}
          >
            {isLoadingRates && (
              <Box
                sx={{ height: '100%', width: '100%' }}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <LoaderTread />
              </Box>
            )}
            {!isLoadingRates && !rateItems?.length && (
              <NoDataFound noDataText={`${t('administration.rates.empty_state_text')}`} />
            )}

            {!isLoadingRates &&
              Boolean(rateItems?.length) &&
              rateItems.map((r) => {
                return (
                  <Box
                    key={r.id}
                    display={'inline-flex'}
                    sx={{ marginLeft: 2, marginBottom: 2 }}
                  >
                    <RateCard
                      rate={r as Rate}
                      onEdit={editRate}
                      deleteClickCallBack={deleteRateHandler}
                      copyClickCallBack={
                        currentSelection.type === RateOwnerType.DRIVER
                          ? undefined
                          : (id: string) => {
                              setSelectedRateId(id);
                              setCopyDialogOpen(true);
                            }
                      }
                    />
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={
            <>
              <Typography component={'span'} variant={'h5'}>
                {t(
                  selectedRateId?.length
                    ? `administration.rates.update_rate`
                    : `administration.rates.create_rate`,
                )}
                {` - `}
                {rateFormDialogTitle}
              </Typography>
            </>
          }
        />

        <DialogContent sx={{ my: 2 }}>
          <RateForm
            subFormsToHide={
              currentSelection.type === RateOwnerType.CUSTOMER
                ? [RateTypes.RATE_COMMISSION]
                : []
            }
            account={selectedAccount}
            disabledFields={disabledFields}
            ref={rateDialogRef}
            defaultRate={(selectedRate as unknown as Rate) || {}}
            onFormStateChange={onFormStateChange}
            driverId={
              currentSelection.type === RateOwnerType.DRIVER &&
              currentSelection.id !== DEFAULT_COMPANY_RATE_FAKE_ID
                ? currentSelection.id
                : undefined
            }
          ></RateForm>
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={!isDirty || isUpdatingRate}
              loading={isUpdatingRate}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdatingRate ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t(selectedRateId ? 'actions.update' : 'actions.create')}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdatingRate}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isCopyDialogOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleCloseCopyDialog();
        }}
      >
        <DialogHeader
          closeCallBack={handleCloseCopyDialog}
          title={
            <>
              <Typography component={'span'} variant={'h5'}>
                {t(`administration.rates.copy_rate`)}
              </Typography>
              <Box display={'flex'} alignItems={'start'}>
                <Typography sx={{ mt: 1 }} variant={'body1'}>
                  {$t('administration.rates.rate_where_to_copy')}
                </Typography>
              </Box>
            </>
          }
        />
        <DialogContent sx={{ my: 2 }}>
          <RateCopyToForm
            accountOptions={accountItemsFilteredByRateAccountType}
            ref={rateCopyDialogRef}
            rate={(selectedRate as unknown as Rate) || {}}
            onCopyFormStateChange={onCopyFormStateChange}
          ></RateCopyToForm>
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={!isCopyDirty || isCopyingRate}
              loading={isCopyingRate}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitCopyForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isCopyingRate ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t('actions.copy')}
            </LoadingButton>
            <Button
              onClick={handleCloseCopyDialog}
              sx={{ mr: 2, px: 2 }}
              disabled={isCopyingRate}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <ModalDialog
        ref={modalDialogRef}
        title={$t('administration.rates.delete_rate_title')}
        content={$t('administration.rates.delete_rate_text')}
        confirmButtonText={`${$t('actions.confirm') || ''}`}
        callBack={deleteRateCallback}
        loading={isUpdatingRate}
      />
    </>
  );
});

export { RatesManagement };
