import {
  Address_Create,
  Contact_Read_Nested,
  GeofenceType,
  NextBillionGeofence_Read,
  Site_Create,
  Site_Read,
  Site_Read_Nested,
  Site_Update,
  SiteState,
  SiteType,
  Waypoint_Read_Nested as WayPointProto,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import { convertLength, Geometry } from '@turf/helpers';
import _, { capitalize, cloneDeep, every, isEmpty } from 'lodash';

import { geofenceNoneOption, GeoFenceTypes } from '~constants/enums';
import { AddressItem } from '~hooks/useAddress';
import { CompanyBasic } from '~hooks/useCompany';
import { ContactItem } from '~hooks/useContact';
import { LatLongItem } from '~hooks/useMaps/models';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { Nullable } from '~types/Nullable';
import { splitStringBySentenceChange } from '~utils/utilFunctions';

export interface WayPointFormProps {
  id: string;
  type: WaypointType;
  site: SiteBasic;
  contact: ContactItem;
  ordinality: number;
}
const checkAddress = (proto: any) => {
  if (proto?.address?.streetAddress?.length) {
    return AddressItem.deparse(proto.address);
  } else if (!proto?.address?.streetAddress?.length && proto?.placeId?.length) {
    return {
      id: `${proto?.placeId}`,
      _destroy: 1, //BE stuff needed if no address is provided in order to delete it
    };
  }
};
export class WayPoint {
  public static parse(proto: WayPointProto): WayPoint {
    return new WayPoint(
      proto.id ?? '',
      proto.type ?? '',
      proto.ordinality ?? 10,
      proto.contact ? ContactItem.parse(proto.contact as Contact_Read_Nested) : null,
      proto.site ? SiteBasic.parse(proto.site) : null,
      proto.site ? Site.parseNested(proto.site) : null,
    );
  }
  public static deparse(proto: WayPointFormProps, cleanupIds?: boolean): any {
    const data = {
      type: proto.type,
      ...(proto.id && proto.id !== 'undefined' ? { id: proto.id } : {}),
      ordinality: proto.ordinality, // Temp until multiple waypoint will be handled in UI proto.ordinality,
      contact: every(proto.contact, isEmpty)
        ? { name: '', phone: '' }
        : ContactItem.deparse(proto.contact as unknown as ContactItem),
      site_id: proto.site.id || '',
    } as any;

    if (cleanupIds) {
      delete data.id;
    }

    return data;
  }
  public get isPickUp(): boolean {
    return this._type === WaypointType.PICKUP;
  }
  public get isDropOff(): boolean {
    return this._type === WaypointType.DROP_OFF;
  }
  public get isWeighPoint(): boolean {
    return this._type === WaypointType.WEIGH_POINT;
  }
  public get id(): string {
    return this._id;
  }
  public get type(): WaypointType {
    return this._type;
  }
  public get ordinality(): number {
    return this._ordinality;
  }
  public get contact(): Nullable<ContactItem> {
    return this._contact;
  }
  public get site(): Nullable<SiteBasic> {
    return this._site;
  }
  public set site(site: Nullable<SiteBasic>) {
    this.site = cloneDeep(site);
  }

  public get siteNested(): Nullable<Site> {
    return this._site_nested;
  }

  constructor(
    private _id: string,
    private _type: WaypointType,
    private _ordinality: number,
    private _contact: Nullable<ContactItem>,
    private _site: Nullable<SiteBasic>,
    private _site_nested: Nullable<Site>,
  ) {}
}

export class SiteBasic extends ItemNameAndId {
  public static parse(proto: Site_Read_Nested): SiteBasic {
    return new SiteBasic(
      proto.id ?? '',
      proto.name ?? '',
      proto?.lat ?? '',
      proto?.lon ?? '',
      proto.lat && proto.lon ? `${proto.lat},${proto.lon}` : '',
      //@ts-ignore
      proto?.next_billion_geofence ? Geofence.parse(proto.next_billion_geofence) : null,
    );
  }
  public get latLon(): string {
    return this._latLon;
  }
  public get lat(): Nullable<number> {
    return this._lat ? parseFloat(this._lat) : null;
  }
  public get lon(): Nullable<number> {
    return this._lon ? parseFloat(this._lon) : null;
  }
  public get nextBillionGeofence(): Nullable<Geofence> {
    return this._next_billion_geofence;
  }
  constructor(
    _id: string,
    _name: string,
    private _lat: Nullable<string>,
    private _lon: Nullable<string>,
    private _latLon: string,
    private _next_billion_geofence: Nullable<Geofence>,
  ) {
    super(_name, _id);
  }
}
class GeoJsonItem {
  public static parse(proto: any): GeoJsonItem {
    return new GeoJsonItem(proto.type ?? '', proto.coordinates ?? []);
  }
  public get type(): string {
    return this._type;
  }
  public get coordinates(): [] {
    return this._coordinates;
  }
  public get geometry(): Geometry {
    return {
      type: this._type,
      coordinates: this._coordinates,
    };
  }
  constructor(
    private _type: string,
    private _coordinates: [],
  ) {}
}
export class Geofence {
  private static _id: string;
  public static parse(proto: NextBillionGeofence_Read): Geofence {
    return new Geofence(
      proto.next_billion_geofence_id ?? '',
      proto?.id ?? '',
      proto?.geofence?.name ? proto.geofence.name : '',
      proto?.geofence?.type ? proto.geofence.type.toLowerCase() : '',
      proto.geofence?.geojson?.coordinates
        ? GeoJsonItem.parse(proto.geofence.geojson)
        : null,
      proto?.geofence?.circle_center
        ? LatLongItem.parse(proto.geofence.circle_center)
        : null,
      proto?.geofence?.circle_radius ?? 0,
      proto?.moving_geofence ?? false,
      proto?.tag ?? '',
      proto.geofence_type ?? null,
    );
  }

  public static deparseCreate(proto: any): any {
    const geoType =
      proto?.geofenceType?.id?.toLowerCase() || GeoFenceTypes.POLYGON.toLowerCase();
    switch (geoType) {
      case GeoFenceTypes.CIRCLE.toLowerCase():
        return {
          geofence: {
            name: proto.name,
            type: geoType,
            circle_center: {
              lat: proto.circleCenter?.lat,
              lon: proto.circleCenter?.lon,
            },
            // If our company is using feet, the value in the form will be in feet, so we need to convert it to meters
            circle_radius: proto.isFeet
              ? convertLength(proto.radius, 'feet', 'meters')
              : proto.radius,
          },

          moving_geofence: false,
        };
      case GeoFenceTypes.EQUIPMENT.toLowerCase():
        return {
          geofence: {
            name: proto.name,
            type: GeoFenceTypes.CIRCLE.toLowerCase(),
            circle_center: {
              lat: proto.circleCenter?.lat,
              lon: proto.circleCenter?.lon,
            },
            // If our company is using feet, the value in the form will be in feet, so we need to convert it to meters
            circle_radius: proto.isFeet
              ? convertLength(proto.radius, 'feet', 'meters')
              : proto.radius,
          },

          moving_geofence: true,
        };
      case GeoFenceTypes.POLYGON.toLowerCase():
        return {
          geofence: {
            name: proto.name,
            type: geoType,
            geojson: {
              type: capitalize(GeoFenceTypes.POLYGON),
              coordinates:
                proto.geoFence?.geojson?.coordinates ||
                proto.geoFence?.geometry?.coordinates,
            },
          },

          moving_geofence: false,
        };
      case geofenceNoneOption.id:
        return proto?.geoFenceInitialId
          ? {
              id: proto.geoFenceInitialId,
              _destroy: 1,
            }
          : undefined;
    }
  }
  public static deparseUpdate(proto: any): any {
    const geoType = proto?.geofenceType?.id.toLowerCase();

    if (!geoType) return undefined;

    const commonFields = {
      id: proto?.geoFenceInitialId?.length ? proto.geoFenceInitialId : undefined,
      name: proto.name,
      type: geoType,
      tag: proto.tag ? proto.tag : undefined,
    };

    switch (geoType) {
      case GeoFenceTypes.CIRCLE.toLowerCase():
        return {
          geofence: {
            ...commonFields,
            circle_center: {
              lat: proto.circleCenter?.lat,
              lon: proto.circleCenter?.lon,
            },
            // If our company is using feet, the value in the form will be in feet, so we need to convert it to meters
            circle_radius: proto.isFeet
              ? convertLength(proto.radius, 'feet', 'meters')
              : proto.radius,
          },
          moving_geofence: false,
        };

      case GeoFenceTypes.EQUIPMENT.toLowerCase():
        return {
          geofence: {
            ...commonFields,
            circle_center: {
              lat: proto.circleCenter?.lat,
              lon: proto.circleCenter?.lon,
            },
            // If our company is using feet, the value in the form will be in feet, so we need to convert it to meters
            circle_radius: proto.isFeet
              ? convertLength(proto.radius, 'feet', 'meters')
              : proto.radius,
            type: GeoFenceTypes.CIRCLE.toLowerCase(),
          },
          moving_geofence: true,
        };

      case GeoFenceTypes.POLYGON.toLowerCase():
        return {
          geofence: {
            ...commonFields,
            geojson: {
              type: capitalize(GeoFenceTypes.POLYGON),
              coordinates:
                proto.geoFence?.geojson?.coordinates ||
                proto.geoFence?.geometry?.coordinates,
            },
          },
          moving_geofence: false,
        };

      case geofenceNoneOption.id:
        return proto?.geoFenceInitialId
          ? {
              id: proto.geoFenceInitialId,
              _destroy: 1,
            }
          : undefined;

      default:
        return undefined;
    }
  }
  public static deparseAdditionalGeoFence(proto: any): any {
    const geoType = proto?.type?.id.toLowerCase();

    const commonFields = {
      name: proto.name,
      type: geoType,
    };

    switch (geoType) {
      case GeoFenceTypes.CIRCLE.toLowerCase():
        return {
          geofence: {
            ...commonFields,
            circle_center: {
              lat: proto.circleCenter?.lat,
              lon: proto.circleCenter?.lon,
            },
            // If our company is using feet, the value in the form will be in feet, so we need to convert it to meters
            circle_radius: proto.isFeet
              ? convertLength(proto.radius, 'feet', 'meters')
              : proto.radius,
          },
          tag: proto.tag.id,

          moving_geofence: false,
        };

      case GeoFenceTypes.EQUIPMENT.toLowerCase():
        return {
          geofence: {
            ...commonFields,
            circle_center: {
              lat: proto.circleCenter?.lat,
              lon: proto.circleCenter?.lon,
            },
            // If our company is using feet, the value in the form will be in feet, so we need to convert it to meters
            circle_radius: proto.isFeet
              ? convertLength(proto.radius, 'feet', 'meters')
              : proto.radius,
            type: GeoFenceTypes.CIRCLE.toLowerCase(),
          },
          tag: proto.tag.id,

          moving_geofence: true,
        };

      case GeoFenceTypes.POLYGON.toLowerCase():
        return {
          geofence: {
            ...commonFields,
            geojson: {
              type: capitalize(GeoFenceTypes.POLYGON),
              coordinates: proto.geojson?.coordinates || proto.geometry?.coordinates,
            },
          },
          tag: proto.tag.id,
          moving_geofence: false,
        };

      default:
        return undefined;
    }
  }

  public get id(): string {
    return this._id;
  }
  public get nbId(): string {
    return this._nbId;
  }
  public get name(): string {
    return this._name;
  }
  public get type(): string {
    return this._type.toLowerCase();
  }
  public get geojson(): Nullable<GeoJsonItem> {
    return this._geojson;
  }
  public get circleCenter(): Nullable<LatLongItem> {
    return this._circle_center;
  }
  public get circleRadius(): number {
    return this._circle_radius;
  }
  public get movingGeofence(): boolean {
    return this._moving_geofence;
  }
  public get tag(): string {
    return this._tag;
  }
  public get geofenceType(): Nullable<GeofenceType> {
    return this._geofenceType;
  }
  constructor(
    private _nbId: string,
    private _id: string,
    private _name: string,
    private _type: string,
    private _geojson: Nullable<GeoJsonItem>,
    private _circle_center: Nullable<LatLongItem>,
    private _circle_radius: number,
    private _moving_geofence: boolean,
    private _tag: string,
    private _geofenceType: Nullable<GeofenceType>,
  ) {}
}
// @ts-ignore
export class Site extends SiteBasic {
  public static parse(proto: Site_Read): Site {
    return new Site(
      proto.company ? CompanyBasic.parse(proto.company) : null,
      proto.address?.country?.length ? AddressItem.parse(proto.address) : null,
      proto.id ?? '',
      proto.name ?? '',
      proto.lat ?? '',
      proto.lon ?? '',
      proto.site_type ?? null,
      proto.state ?? SiteState.INACTIVE,
      proto.notes ?? '',
      //@ts-ignore
      proto?.next_billion_geofence ? Geofence.parse(proto.next_billion_geofence) : null,
      proto.external_id ?? '',
      proto.lat && proto.lon ? `${proto.lat},${proto.lon}` : '',
      proto.routable ?? false,
      proto.moving_site ?? null,
    );
  }

  public static parseNested(proto: Site_Read_Nested): Site {
    return new Site(
      null,
      proto.address ? AddressItem.parse(proto.address) : null,
      proto.id ?? '',
      proto.name ?? '',
      proto.lat ?? '',
      proto.lon ?? '',
      null,
      SiteState.ACTIVE,
      '',
      proto?.next_billion_geofence ? Geofence.parse(proto.next_billion_geofence) : null,
      '',
      proto.lat && proto.lon ? `${proto.lat},${proto.lon}` : '',
      proto.routable ?? false,
      //@ts-ignore
      proto.moving_site ?? null,
    );
  }

  public static deparse(proto: any): Site_Create | Site_Update {
    const lat = proto.latLon?.split(',')?.[0] ? proto.latLon.split(',')[0] : '';
    const lon = proto.latLon?.split(',')?.[1] ? proto.latLon.split(',')[1] : '';

    const isValidAddress = Boolean(
      proto?.address?.streetAddress?.length && proto?.address?.postalCode?.length,
    );
    const address = isValidAddress
      ? (AddressItem.deparse(proto.address) as Address_Create)
      : proto?.address?.streetAddress || undefined;

    const data = {
      name: proto.name,
      company_id: proto.company?.id || undefined,
      //If on of values is empty set to undefined,as its all or none required rule
      ...(isValidAddress ? { address } : { full_address: address }),
      lat,
      lon,
      notes: proto.notes ?? '',
      external_id: proto.externalId,
      next_billion_geofence: proto?.geoFence?.name?.length
        ? Geofence.deparseCreate(proto)
        : undefined,
    } as any;

    if (proto.siteType) {
      data.site_type = proto.siteType.replace(/\s/g, '');
    }

    if (proto.geofenceType?.id === GeoFenceTypes.EQUIPMENT.toLowerCase()) {
      data.moving_site = {
        equipment_id: proto.equipment.id,
      };
    }

    return data;
  }

  public static deparseUpdate(proto: any): Site_Create | Site_Update {
    const lat = proto.latLon?.split(',')?.[0] ? proto.latLon.split(',')[0] : '';
    const lon = proto.latLon?.split(',')?.[1] ? proto.latLon.split(',')[1] : '';
    const data = {
      name: proto.name,
      address: checkAddress(proto),
      lat,
      lon,
      notes: proto.notes,
      external_id: proto.externalId,
      next_billion_geofence: Geofence.deparseUpdate(proto),
    } as any;

    if (proto.siteType) {
      data.site_type = proto.siteType.replace(/\s/g, '');
    }
    if (proto.geofenceType?.id === GeoFenceTypes.EQUIPMENT.toLowerCase()) {
      data.moving_site = {
        equipment_id: proto.equipment.id,
      };
    }
    return data;
  }

  public get company(): Nullable<CompanyBasic> {
    return this._company;
  }
  public get companyName(): string {
    return this._company?.legalName || '';
  }

  public get siteType(): string {
    return this._site_type ? splitStringBySentenceChange(this._site_type) : '';
  }

  public get address(): Nullable<AddressItem> {
    return this._address;
  }

  public get isActive(): boolean {
    return this._state === SiteState.ACTIVE;
  }

  public get lng(): Nullable<number> {
    return this._lon ? parseFloat(this._lon) : null;
  }

  public get notes(): string {
    return this._notes;
  }
  public get externalId(): string {
    return this._external_id;
  }
  public get routable(): boolean {
    return this._routable;
  }

  public get movingSite(): Nullable<{
    equipment_id: string;
  }> {
    return this._moving_site;
  }
  constructor(
    private _company: Nullable<CompanyBasic>,
    private _address: Nullable<AddressItem>,
    _id: string,
    _name: string,
    private _lat: Nullable<string>,
    private _lon: Nullable<string>,
    private _site_type: Nullable<SiteType>,
    private _state: SiteState,
    private _notes: string,
    private _next_billion_geofence: Nullable<Geofence>,
    private _external_id: string,
    private _latLon: string,
    private _routable: boolean,
    private _moving_site: Nullable<{
      equipment_id: string;
    }>,
  ) {
    super(_id, _name, _lat, _lon, _latLon, _next_billion_geofence);
  }
}
