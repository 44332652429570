import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import {
  CompanyShareableType,
  ModelError_Item,
  ModelError_Response,
} from '@treadinc/horizon-api-spec';
import { AxiosError } from 'axios';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DialogHeader } from '~components/Dialog/DialogHeader';
import { AccountTypesFilter } from '~constants/enums';
import { companyConnectAccountByTreadId, FeatureFlags } from '~constants/featureFlags';
import { Account, useAccount } from '~hooks/useAccount';
import { useCompanyShares } from '~hooks/useCompanyShares/useCompanyShares';
import { DriverBasic } from '~hooks/useDrivers';
import { BasicEquipment } from '~hooks/useEquipment';
import { ConnectedAccountForm as AccountForm } from '~pages/Settings/Accounts/ConnectedAccountForm';
import { useStores } from '~store/RootStore';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { isActionClicked } from '~utils/utilFunctions';

import { AccountFormStateChangeProps } from './AccountsDataGrid';
import { useCompanySharesDetails } from './useCompanyShareDetails';

interface AccountsDialogProps {
  open?: boolean;
  disableAccountType?: boolean;
  defaultAccount?: Account;
  onClose?: () => void;
}

const AccountsDialog = observer(
  ({ open, disableAccountType, defaultAccount, onClose }: AccountsDialogProps) => {
    const theme = useTheme();
    const [selectedAccountId, setSelectedAccountId] = useState<Nullable<string>>(null);
    const { toasterStore, userStore, companyAssetsStore } = useStores();

    const selectedAccount = useMemo(() => {
      return (
        companyAssetsStore.userCompanyAccounts.find(
          (account) => account.id === selectedAccountId,
        ) ||
        defaultAccount ||
        null
      );
    }, [defaultAccount, selectedAccountId, companyAssetsStore.userCompanyAccounts]);
    const [currentFormDirty, setCurrentFormDirty] = useState<boolean>(false);
    const [asyncErrors, setAsyncErrors] = useState<ModelError_Item[]>([]);
    const {
      drivers,
      equipment,
      sharedDriversCompanyShares,
      sharedEquipmentCompanyShares,
      setEquipment,
      setDrivers,
      setSharedDriversCompanyShares,
      setSharedEquipmentCompanyShares,
    } = useCompanySharesDetails({
      isAccountDialogOpen: open || false,
      selectedAccountId,
      selectedAccount,
    });
    const accountActionsRef = useRef<any>(null);

    const {
      getAllCompanyAccounts,
      deleteAccount,
      updateAccount,
      createAccount,
      createConnectedAccount,
      createConnectedAccountByCompanyId,
      isLoading,
    } = useAccount();
    const { createCompanyShareByCompanyId, deleteCompanyShare } = useCompanyShares();
    const onFormStateChange = ({ isDirty }: AccountFormStateChangeProps) => {
      setCurrentFormDirty(isDirty);
    };
    const companyId =
      userStore.currentCompanies[0]?.id || userStore.userCompany?.id || '';

    const { treatments, isReady } = useSplitTreatments({
      names: [companyConnectAccountByTreadId],
    });
    const isCustomerPage = location.pathname.includes('customers');

    const queriedAccountType = isCustomerPage
      ? AccountTypesFilter.CUSTOMER
      : AccountTypesFilter.VENDOR;
    const simplifiedSettingsTabsFeatureFlag =
      treatments[FeatureFlags.simplifiedSettingsTabs];
    const getAccountType = useCallback(() => {
      return [
        queriedAccountType as AccountTypesFilter.CUSTOMER | AccountTypesFilter.VENDOR,
      ];
    }, []);

    const handleClose = () => {
      onClose?.();
      setEquipment([]);
      setDrivers([]);
      setSharedDriversCompanyShares([]);
      setSharedEquipmentCompanyShares([]);
      setAsyncErrors([]);
    };
    const onSuccess = (account?: Account) => {
      setSelectedAccountId(null);

      handleClose();
      toasterStore.push(
        alert(
          selectedAccountId
            ? $t('account.account_updated', { name: account?.name })
            : $t('account.account_created', { name: account?.name }),
          AlertTypes.success,
        ),
      );
    };

    const onSubmitAccountForm = () => {
      accountActionsRef.current?.submit(onSubmitAccountFormCallBack);
    };
    const onSubmitAccountFormCallBack = async (data: Account | any) => {
      if (selectedAccountId) {
        try {
          const driversPresentOnModalOpen = drivers;
          const driversPresentOnSubmit = data.drivers;

          const equipmentPresentOnModalOpen = equipment;
          const equipmentPresentOnSubmit = data.equipment;

          const updatedAccount = await updateAccount({
            account: data,
          });

          const driversToCreate = driversPresentOnSubmit?.filter(
            (submittedDrivers: { id: string }) =>
              !driversPresentOnModalOpen.some(
                (existingDrivers) => existingDrivers.id === submittedDrivers.id,
              ),
          );

          const driversToDelete = driversPresentOnModalOpen.filter(
            (existingDriver) =>
              driversPresentOnSubmit &&
              !driversPresentOnSubmit.some(
                (submittedDriver: { id: string }) =>
                  existingDriver.id === submittedDriver.id,
              ),
          );

          // Get the company shares for the drivers that we need to remove
          const driverCompanySharesToDelete = sharedDriversCompanyShares.filter(
            (companyShare) =>
              driversToDelete.some(
                (driver) => driver.id === companyShare.companyShareable.id,
              ),
          );

          const equipmentToCreate = equipmentPresentOnSubmit?.filter(
            (submittedEquipment: { id: string }) =>
              !equipmentPresentOnModalOpen.some(
                (existingEquipment) => existingEquipment.id === submittedEquipment.id,
              ),
          );

          const equipmentToDelete = equipmentPresentOnModalOpen.filter(
            (existingEquipment) =>
              equipmentPresentOnSubmit &&
              !equipmentPresentOnSubmit.some(
                (submittedEquipment: { id: string }) =>
                  existingEquipment.id === submittedEquipment.id,
              ),
          );

          // Get the company shares for the equipment that we need to remove
          const equipmentCompanySharesToDelete = sharedEquipmentCompanyShares.filter(
            (companyShare) =>
              equipmentToDelete.some(
                (equip) => equip.id === companyShare.companyShareable.id,
              ),
          );

          const companySharePromises: Promise<void>[] = [];
          if (updatedAccount) {
            if (
              driversToCreate?.length &&
              updatedAccount?.company?.id &&
              updatedAccount?.connectedCompany?.id
            ) {
              driversToCreate.forEach((driver: DriverBasic) => {
                companySharePromises.push(
                  createCompanyShareByCompanyId({
                    companyId: updatedAccount.company?.id || '',
                    receiver_company_id: updatedAccount.connectedCompany?.id || '',
                    company_shareable_id: driver.id,
                    company_shareable_type: 'User' as CompanyShareableType,
                  }),
                );
              });
            }

            if (driverCompanySharesToDelete?.length) {
              driverCompanySharesToDelete.forEach((driverCompanyShare) => {
                companySharePromises.push(
                  deleteCompanyShare(driverCompanyShare.id as string),
                );
              });
            }

            if (
              equipmentToCreate?.length &&
              updatedAccount?.company?.id &&
              updatedAccount?.connectedCompany?.id
            ) {
              equipmentToCreate.forEach((equip: BasicEquipment) => {
                companySharePromises.push(
                  createCompanyShareByCompanyId({
                    companyId: updatedAccount.company?.id || '',
                    receiver_company_id: updatedAccount.connectedCompany?.id || '',
                    company_shareable_id: equip.id,
                    company_shareable_type: 'Equipment' as CompanyShareableType,
                  }),
                );
              });
            }

            if (equipmentCompanySharesToDelete?.length) {
              equipmentCompanySharesToDelete.forEach((equipCompanyShare) => {
                companySharePromises.push(
                  deleteCompanyShare(equipCompanyShare.id as string),
                );
              });
            }
          }

          Promise.all(companySharePromises).then(() => {
            onSuccess(data);
          });
        } catch (error: AxiosError<ModelError_Response> | any) {
          setAsyncErrors(error.response?.data.error.errors || []);
        }
      } else if (data?.connected?.value) {
        try {
          const isCreatingAccountForCurrentCompany = data?.company?.id === companyId;
          const newConnectedAccount =
            userStore.isCurrentCompanyActive && isCreatingAccountForCurrentCompany
              ? await createConnectedAccount({
                  account: data,
                })
              : await createConnectedAccountByCompanyId({ account: data });

          const drivers = data.drivers;
          const equipment = data.equipment;

          const companySharePromises: Promise<void>[] = [];

          if (newConnectedAccount) {
            if (
              drivers?.length &&
              newConnectedAccount?.company?.id &&
              newConnectedAccount?.connectedCompany?.id
            ) {
              drivers.forEach((driver: DriverBasic) => {
                companySharePromises.push(
                  createCompanyShareByCompanyId({
                    companyId: newConnectedAccount.company?.id || '',
                    receiver_company_id: newConnectedAccount.connectedCompany?.id || '',
                    company_shareable_id: driver.id,
                    company_shareable_type: 'User' as CompanyShareableType,
                  }),
                );
              });
            }

            if (
              equipment?.length &&
              newConnectedAccount?.company?.id &&
              newConnectedAccount?.connectedCompany?.id
            ) {
              equipment.forEach((equip: BasicEquipment) => {
                companySharePromises.push(
                  createCompanyShareByCompanyId({
                    companyId: newConnectedAccount.company?.id || '',
                    receiver_company_id: newConnectedAccount.connectedCompany?.id || '',
                    company_shareable_id: equip.id,
                    company_shareable_type: 'Equipment' as CompanyShareableType,
                  }),
                );
              });
            }
          }

          Promise.all(companySharePromises).then(() => {
            handleClose();
            // Re-fetch all data since there is two accounts is created
            getAllCompanyAccounts({
              accountTypes: getAccountType(),
              ...{ companyId: !userStore.isCurrentCompanyActive ? companyId : undefined },
            });
            const message = `Connected Account '${data?.name}' has been created successfully`;
            toasterStore.push(alert(message, AlertTypes.success));
          });
        } catch (error: AxiosError<ModelError_Response> | any) {
          setAsyncErrors(error.response?.data.error.errors || []);
        }
      } else {
        try {
          await createAccount({ account: data, callBack: onSuccess });
        } catch (error: AxiosError<ModelError_Response> | any) {
          setAsyncErrors(error.response?.data.error.errors || []);
        }
      }
    };

    useEffect(() => {
      if (defaultAccount?.id) {
        setSelectedAccountId(defaultAccount.id);
      } else {
        setSelectedAccountId(null);
      }
    }, [defaultAccount]);

    return (
      <>
        <Dialog
          open={open || false}
          onClose={(_: never, reason: DialogCloseReasonType) => {
            isActionClicked(reason) && handleClose();
          }}
          maxWidth={'lg'}
        >
          <DialogHeader
            closeCallBack={handleClose}
            title={t(
              `account.${selectedAccountId ? 'update_account' : 'create_account'}`,
            )}
          />
          <DialogContent sx={{ backgroundColor: theme.palette.grey[100] }}>
            <AccountForm
              ref={accountActionsRef}
              disableAccountType={disableAccountType}
              onFormStateChange={onFormStateChange}
              defaultAccount={selectedAccount}
              sharedDrivers={drivers}
              sharedEquipment={equipment}
              sharedDriversCompanyShares={sharedDriversCompanyShares}
              sharedEquipmentCompanyShares={sharedEquipmentCompanyShares}
              errors={asyncErrors}
            ></AccountForm>
          </DialogContent>
          <DialogActions
            sx={{
              m: 0,
              p: 2,
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{ mr: 1, px: 2 }}
              disabled={isLoading}
              color="secondary"
              variant="outlined"
            >
              {t('actions.cancel')}
            </Button>

            <LoadingButton
              disabled={isLoading || !currentFormDirty}
              loading={isLoading}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitAccountForm}
              data-test-id={'create-account-btn'}
              type="button"
              variant="contained"
              color="primary"
              sx={isLoading ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {t(`actions.${selectedAccountId ? 'submit' : 'create'}`)}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);
export { AccountsDialog };
