import { SettlementLineItem_Group_Read } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { SettlementLineItemGroup } from '~hooks/useSettlementLineItems';
import connection from '~services/connectionModule';
import { useStores } from '~store';

export default function useSettlementLineItems() {
  const [isLoading, setIsLoading] = useState(false);
  const { settlementLineItemsStore } = useStores();

  const getAllSettlementLineItems = async (settlementId: string) => {
    setIsLoading(true);

    try {
      const response = await connection.get<SettlementLineItem_Group_Read[]>(
        `${API_VERSION}/settlements/${settlementId}/settlement_line_items`,
        {},
        t('error_messages.settlements.failed_to_fetch_line_items') as string,
      );

      const parsedResponse = response.map((lineItem) => {
        return SettlementLineItemGroup.parse(lineItem);
      });

      settlementLineItemsStore.setSettlementLineItemGroupsBySettlementId(
        settlementId,
        parsedResponse,
      );

      return parsedResponse;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to retrieve settlement line items');
    } finally {
      setIsLoading(false);
    }
  };

  return { getAllSettlementLineItems, isLoading };
}
