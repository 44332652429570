import Close from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Portal from '@mui/material/Portal';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { observer } from 'mobx-react-lite';

import { useStores } from '~store';
import { Alert as AlertType } from '~types/AlertTypes';

const Toasters = () => {
  const { toasterStore } = useStores();

  const SlideTransition = (props: SlideProps) => {
    return (
      <Slide
        {...props}
        direction="up"
        mountOnEnter
        unmountOnExit
        onExited={toasterStore.process}
      />
    );
  };

  return toasterStore.current.cata({
    Just: (alert: AlertType) => (
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={alert.display}
          onClose={() => {
            toasterStore.hide();
            toasterStore.process();
          }}
          autoHideDuration={6000}
          TransitionComponent={SlideTransition}
        >
          <Box display={'flex'} data-test-id={'toaster-test-id'}>
            <Alert
              severity={alert.kind as AlertColor}
              sx={{ width: '100%' }}
              action={
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => {
                    toasterStore.hide();
                  }}
                >
                  <Close sx={{ width: 14, height: 14 }}></Close>
                </IconButton>
              }
            >
              {alert?.message}
            </Alert>
          </Box>
        </Snackbar>
      </Portal>
    ),
    Nothing: () => <></>,
  });
};
export default observer(Toasters);
