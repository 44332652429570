// @ts-nocheck has to be fixed @veni

import {
  Account_Read_Nested as AccountNestedProto,
  Contact_Update,
  Order_Create,
  Order_Estimate_Create,
  Order_Estimate_Read,
  Order_Read,
  Order_Read_Nested,
  Order_Update,
  OrderSummary_Read_Nested,
  RateType,
  RequestedQuantityType,
  User_Me_Read,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs'; // Import other necessary types
import _ from 'lodash';

import { ContactTypes, OrderState, OrderUnitOfMeasure } from '~constants/enums';
import { AccountBasic } from '~hooks/useAccount';
import { CompanyBasic } from '~hooks/useCompany';
import { BasicDepartment } from '~hooks/useDepartment';
import { EquipmentTypeItem } from '~hooks/useEquipment';
import { Material } from '~hooks/useMaterials';
import {
  contactTypeMap,
  ExtendedContactItem,
  filterContact,
  Phase,
  ProjectBasic,
  transformContact,
} from '~hooks/useProjects/models';
import { RateBasicWithValue } from '~hooks/useRates';
import { BasicServiceClass } from '~hooks/useServiceClass';
import { Service } from '~hooks/useServices';
import { WayPoint, WayPointFormProps } from '~hooks/useSites';
import { User, UserBasic } from '~hooks/useUsers';
import {
  deparseRateDetailsDTO,
  deparseUpdateRateDetailsDTO,
} from '~pages/Sales/Orders/NewOrderForm/schema';
import {
  NewOrderFormSchemaInterface,
  OrderSchemaVersion,
  QuantityType,
} from '~pages/Sales/Orders/newOrderFormSchema';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { Nullable } from '~types/Nullable';

export class Order {
  public static parse(proto: Order_Read): Order {
    return new Order(
      proto.id ?? '',
      proto.editable ?? false,
      proto.created_at?.length ? dayjs(proto.created_at) : null,
      proto.company ? CompanyBasic.parse(proto.company) : null,
      proto.job_start_at?.length ? dayjs(proto.job_start_at) : null,
      proto.load_at?.length ? dayjs(proto.load_at) : null,
      proto.load_at_date ?? null,
      proto.load_at_time ?? null,
      proto.notes ?? null,
      proto.job_notes ?? null,
      proto.job_time ?? null,
      proto.internal_notes ?? null,
      proto.state ?? null,
      proto.truck_count ?? null,
      proto.truck_delay ?? null,
      proto.trucks_per_delay ?? null,
      proto.loads_per_truck ?? '',
      proto.load_cycle_avg ?? 0,
      proto.unit_of_measure
        ? ItemNameAndId.parse({
            name: proto.unit_of_measure,
            id: proto.unit_of_measure,
          })
        : null,
      proto.account ? AccountBasic.parse(proto.account) : null, // Account is another type that needs to be defined
      proto.order_id ?? '',
      proto.project ? ProjectBasic.parse(proto.project) : null,
      proto.material ? Material.parse(proto.material) : null,
      proto?.equipment_type ? EquipmentTypeItem.parse(proto.equipment_type) : null,
      proto.quantity ?? null,
      proto.service ? Service.parse(proto.service) : null,
      proto?.waypoints?.length ? proto.waypoints.map(WayPoint.parse) : null,
      proto?.waypoints?.length
        ? proto.waypoints
            .filter((point) => {
              return point.type === WaypointType.PICKUP;
            })
            .map(WayPoint.parse)
        : null,
      proto?.waypoints?.length
        ? proto.waypoints
            .filter((point) => {
              return point.type === WaypointType.DROP_OFF;
            })
            .map(WayPoint.parse)
        : null,

      proto?.vendor_rate ? RateBasicWithValue.parse(proto.vendor_rate) : null,
      proto?.customer_rate ? RateBasicWithValue.parse(proto.customer_rate) : null,
      proto.phase ? Phase.parse(proto.phase) : null,
      proto.phases ? proto.phases.map(Phase.parse) : [],
      proto.sales_contact
        ? ExtendedContactItem.parse({ ...proto.sales_contact, type: ContactTypes.SALES })
        : null,
      proto.foreman_contact
        ? ExtendedContactItem.parse({
            ...proto.foreman_contact,
            type: ContactTypes.FOREMAN,
          })
        : null,
      proto.collaborator_contact
        ? ExtendedContactItem.parse({
            ...proto.collaborator_contact,
            type: ContactTypes.COLLABORATOR,
          })
        : null,
      proto.supervisor_contact
        ? ExtendedContactItem.parse({
            ...proto.supervisor_contact,
            type: ContactTypes.SUPERVISOR,
          })
        : null,
      proto.department ? BasicDepartment.parse(proto.department) : null,
      proto.service_class ? BasicServiceClass.parse(proto.service_class) : null,
      proto.action_required ?? false,
      proto.foremen?.length
        ? proto?.foremen.map((item) => User.parse(item as User_Me_Read))
        : [],
      proto.name,
      proto.po_job_number,
      proto.zone,
      proto.external_id,
      proto.hauler_rate_type ? (proto.hauler_rate_type as RateTypes) : null,
      proto.units_per_hour ?? null,
      proto.job_quantity ?? null,
      proto.sending_accounts ?? [],
      proto.dispatch_number ?? null,
      proto.customer_rate_value ? Number(proto.customer_rate_value) : null,
      proto.customer_rate_type ?? null,
      proto.vendor_rate_value ? Number(proto.vendor_rate_value) : null,
      proto.vendor_rate_type ?? null,
      proto.driver_rate ? RateBasicWithValue.parse(proto.driver_rate) : null,
      proto.driver_rate_value ? Number(proto.driver_rate_value) : null,
      proto.driver_rate_type ?? null,
      proto.loads_pickup_time_minutes_avg,
      proto.requester ? UserBasic.parse(proto.requester) : null,
      proto.order_summary ? OrderSummaryReadNested.parse(proto.order_summary) : null,
      proto.equipment_type_gross_capacity
        ? Number(proto.equipment_type_gross_capacity)
        : null,
      proto.pickup_onsite_minutes,
      proto.dropoff_onsite_minutes,
      proto.pickup_to_dropoff_time_minutes,
      _.isNil(proto.pickup_to_dropoff_distance_meters)
        ? null
        : Number(proto.pickup_to_dropoff_distance_meters),
      proto.expected_total_loads,
      proto.cycle_time_minutes,
      proto.requested_quantity_type,
    );
  }
  public static getOrderContacts(project: Project | any) {
    const contacts: Record<string, any> = {};
    Object.keys(contactTypeMap).forEach((key) => {
      const contactType = contactTypeMap[key];
      const contact = filterContact(project, contactType);
      contacts[key] = transformContact(contact);
    });
    return contacts;
  }
  public static clone(order: Order, impersonate: boolean): Order {
    return new Order(
      impersonate ? null : order.id,
      impersonate ? null : order.createdAt,
      order.company,
      order.billingUnitOfMeasure,
      order.jobStartAt,
      order.loadAt,
      order.loadAtDate,
      order.loadAtTime,
      order.notes,
      order.jobNotes,
      order.jobTime,
      order.internalNotes,
      impersonate ? null : order.state,
      order.truckCount,
      order.truckDelay,
      order.unitOfMeasure,
      order.account ? AccountBasic.parse(order.account) : null,
      impersonate ? '' : order.orderId,
      order.project ? ProjectBasic.parse(order.project) : null,
      order.material,
      order.equipmentType,
      order.quantity,
      order.service,
      order.waypoints,
      order.pickUpWayPoints,
      order.dropOffWayPoints,
      order.vendorRate,
      order.Rate,
      order.phase,
      order.salesContact,
      order.foremanContact,
      order.collaboratorContact,
      order.supervisorContact,
      order.foremen,
      order.name,
      order.poJobNumber,
      order.zone,
      order.externalId,
      order.unitsPerHour,
      order.jobQuantity,
      order.sendingAccounts,
      order.dispatchNumber,
      order.requester,
      order.orderSummary,
    );
  }
  public static deparseUpdate(order: NewOrderFormSchemaInterface): Order_Update {
    const { formenContact, salesContact, collaboratorContact, supervisorContact } =
      this.getOrderContacts(order);

    // Note: the below code is commented out because the loadAtDate and loadAtTime are not being
    // Used in the form, but will be in the future
    // Let loadAt = undefined;

    // // if loadAtDate and loadAtTime are set, then we need to combine them into loadAt
    // If (order.loadAtDate && order.loadAtTime) {
    //   Const date = new Date(order.loadAtDate);
    //   Const time = new Date(order.loadAtTime);
    //   Const parsedDate = new Date(
    //     Date.getFullYear(),
    //     Date.getMonth(),
    //     Date.getDate(),
    //     Time.getHours(),
    //     Time.getMinutes(),
    //     Time.getSeconds(),
    //   );
    //   LoadAt = dayjs(parsedDate).toISOString();
    //   Order.loadAt = loadAt;
    // } else if (order.loadAt) {
    //   LoadAt = dayjs(order.loadAt);
    //   Order.loadAt = loadAt.toISOString();
    // }

    const customerRate = deparseUpdateRateDetailsDTO('customer', {
      targetRate: order.customerRate,
      targetRateType: order.customerRateType,
      targetRateValue: order.customerRateValue,
    });
    const vendorRate = deparseUpdateRateDetailsDTO('vendor', {
      targetRate: order.vendorRate,
      targetRateType: order.vendorRateType,
      targetRateValue: order.vendorRateValue,
    });
    const driverRate = deparseUpdateRateDetailsDTO('driver', {
      targetRate: order.driverRate,
      targetRateType: order.driverRateType,
      targetRateValue: order.driverRateValue,
    });

    const body = {
      ...(order?.project?.id?.length ? { project_id: order.project.id } : {}),
      equipment_type_id: order?.equipmentType?.id as string,
      material_id: order?.material?.id as string,
      quantity: (order.quantity as number) || undefined,
      unit_of_measure: order.unitOfMeasure.id as OrderUnitOfMeasure,
      job_start_at: order.jobStartAt ? dayjs(order.jobStartAt).toISOString() : undefined,
      load_at: order.loadAt ? dayjs(order.loadAt).toISOString() : undefined,
      notes: order.orderNotes?.length ? order.orderNotes : '',
      job_notes: order.jobNotes?.length ? order.jobNotes : '',
      job_time: order.jobTime || null,
      internal_notes: order.internalNotes?.length ? order.internalNotes : '',
      truck_delay: Number(order.truckDelay || 0),
      trucks_per_delay: Number(order.trucksPerDelay || 1),
      service_id: order?.service?.id || '',
      waypoints: [
        WayPoint.deparse(order.dropOffWayPoint as WayPointFormProps),
        WayPoint.deparse(order.pickUpWayPoint as WayPointFormProps),
      ],
      phase: order?.phase?.name?.length ? Phase.deparse(order?.phase) : undefined,
      phases: order?.phases?.length
        ? order?.phases.map((phase) => Phase.deparseUpdate(phase))
        : [],
      ...customerRate,
      ...vendorRate,
      ...driverRate,
      sales_contact: salesContact ? (salesContact as Contact_Update) : undefined,
      foreman_contact: formenContact ? formenContact : undefined,
      collaborator_contact: collaboratorContact
        ? (collaboratorContact as Contact_Update)
        : undefined,
      supervisor_contact: supervisorContact
        ? (supervisorContact as Contact_Update)
        : undefined,
      department_id: order?.department?.id || null,
      service_class_id: order?.serviceClass?.id || null,
      foreman_ids: order.foremen?.map((foreman) => foreman.id) || [],
      name: order.name || '',
      po_job_number: order.poJobNumber || '',
      zone: order.zone || '',
      external_id: order.externalId || '',
      units_per_hour: order.unitsPerHour || null,
      job_quantity: order.jobQuantity || undefined,
      dispatch_number: order.dispatchNumber || null,
      requester_id: order?.requester?.id || null,
    };

    if (order._schemaVersion === OrderSchemaVersion.V2) {
      body.job_time =
        _.isNil(order.jobTime) || `${order.jobTime}`.length === 0
          ? null
          : Number(order.jobTime) * 60;

      let quantityType = RequestedQuantityType.UNIT;

      if (order.quantityType?.id === QuantityType.LOAD) {
        quantityType = RequestedQuantityType.LOAD;
      } else if (order.quantityType?.id === QuantityType.TRUCKS) {
        quantityType = RequestedQuantityType.TRUCK;
      }

      body.requested_quantity_type = quantityType;
      body.cycle_time_minutes = _.isNil(order.cycleTime)
        ? undefined
        : Number(order.cycleTime);
      body.pickup_onsite_minutes = _.isNil(order.pickupOnsite)
        ? undefined
        : Number(order.pickupOnsite);
      body.dropoff_onsite_minutes = _.isNil(order.dropoffOnsite)
        ? undefined
        : Number(order.dropoffOnsite);
      body.pickup_to_dropoff_time_minutes = _.isNil(order.pickupToDropoff)
        ? undefined
        : Number(order.pickupToDropoff);
      body.pickup_to_dropoff_distance_meters = _.isNil(order.cycleDistance)
        ? undefined
        : Number(order.cycleDistance);
      body.expected_total_loads = _.isNil(order.totalLoads)
        ? undefined
        : Number(order.totalLoads);
      body.equipment_type_gross_capacity = _.isNil(order.grossCapacity)
        ? undefined
        : Number(order.grossCapacity);
    }

    return body;
  }

  public static deparse(order: NewOrderFormSchemaInterface): Order_Create {
    const { formenContact, salesContact, collaboratorContact, supervisorContact } =
      this.getOrderContacts(order);

    const customerRate = deparseRateDetailsDTO('customer', {
      targetRate: order.customerRate,
      targetRateType: order.customerRateType,
      targetRateValue: order.customerRateValue,
    });
    const vendorRate = deparseRateDetailsDTO('vendor', {
      targetRate: order.vendorRate,
      targetRateType: order.vendorRateType,
      targetRateValue: order.vendorRateValue,
    });
    const driverRate = deparseRateDetailsDTO('driver', {
      targetRate: order.driverRate,
      targetRateType: order.driverRateType,
      targetRateValue: order.driverRateValue,
    });

    const body = {
      company_id: order?.company?.id as string,
      project_id: order?.project?.id?.length ? order?.project?.id : undefined,
      equipment_type_id: order?.equipmentType?.id as string,
      material_id: order?.material?.id as string,
      quantity: (order.quantity as number) || undefined,
      unit_of_measure: order.unitOfMeasure.id as OrderUnitOfMeasure,
      job_start_at: order.jobStartAt ? dayjs(order.jobStartAt).toISOString() : undefined,
      load_at: order.loadAt ? dayjs(order.loadAt).toISOString() : undefined,
      notes: order.orderNotes?.length ? order.orderNotes : undefined,
      job_notes: order.jobNotes?.length ? order.jobNotes : undefined,
      job_time: order.jobTime || null,
      internal_notes: order.internalNotes?.length ? order.internalNotes : undefined,
      truck_count: Number(order.truckCount || 0),
      truck_delay: Number(order.truckDelay || 0),
      trucks_per_delay: Number(order.trucksPerDelay || 1),
      loads_per_truck: order.loadsPerTruck ? Number(order.loadsPerTruck) : undefined,
      account_id: order?.account?.id || '',
      service_id: order?.service?.id || '',
      waypoints: [
        WayPoint.deparse(order.dropOffWayPoint as WayPointFormProps, true),
        WayPoint.deparse(order.pickUpWayPoint as WayPointFormProps, true),
      ],
      phase: order?.phase?.name?.length ? Phase.deparse(order?.phase) : undefined,
      phases: order?.phases?.length
        ? order?.phases.map((phase) => Phase.deparse(phase))
        : [],
      ...customerRate,
      ...vendorRate,
      sales_contact: salesContact ? (salesContact as Contact_Update) : undefined,
      foreman_contact: formenContact ? formenContact : undefined,

      collaborator_contact: collaboratorContact
        ? (collaboratorContact as Contact_Update)
        : undefined,
      supervisor_contact: supervisorContact
        ? (supervisorContact as Contact_Update)
        : undefined,
      department_id: order?.department?.id || null,
      service_class_id: order?.serviceClass?.id || null,
      foreman_ids: order.foremen?.map((foreman) => foreman.id) || [],
      name: order.name || '',
      po_job_number: order.poJobNumber || '',
      zone: order.zone || '',
      external_id: order.externalId || '',
      units_per_hour: order.unitsPerHour || null,
      job_quantity: order.jobQuantity || null,
      dispatch_number: order.dispatchNumber || null,
      requester_id: order?.requester?.id || null,
      ...driverRate,
    };

    if (order._schemaVersion === OrderSchemaVersion.V2) {
      body.job_time =
        _.isNil(order.jobTime) || `${order.jobTime}`.length === 0
          ? null
          : Number(order.jobTime) * 60;

      let quantityType = RequestedQuantityType.UNIT;

      if (order.quantityType?.id === QuantityType.LOAD) {
        quantityType = RequestedQuantityType.LOAD;
      } else if (order.quantityType?.id === QuantityType.TRUCKS) {
        quantityType = RequestedQuantityType.TRUCK;
      }

      body.requested_quantity_type = quantityType;
      body.cycle_time_minutes = _.isNil(order.cycleTime)
        ? undefined
        : Number(order.cycleTime);
      body.pickup_onsite_minutes = _.isNil(order.pickupOnsite)
        ? undefined
        : Number(order.pickupOnsite);
      body.dropoff_onsite_minutes = _.isNil(order.dropoffOnsite)
        ? undefined
        : Number(order.dropoffOnsite);
      body.pickup_to_dropoff_time_minutes = _.isNil(order.pickupToDropoff)
        ? undefined
        : Number(order.pickupToDropoff);
      body.pickup_to_dropoff_distance_meters = _.isNil(order.cycleDistance)
        ? undefined
        : Number(order.cycleDistance);
      body.expected_total_loads = _.isNil(order.totalLoads)
        ? undefined
        : Number(order.totalLoads);
      body.equipment_type_gross_capacity = _.isNil(order.grossCapacity)
        ? undefined
        : Number(order.grossCapacity);
    }

    return body;
  }
  public get id(): string {
    return this._id;
  }
  public get editable(): boolean {
    return this._editable;
  }
  public get createdAt(): Nullable<Dayjs> {
    return this._created_at;
  }
  public get company(): Nullable<CompanyBasic> {
    return this._company;
  }

  public get jobStartAt(): Nullable<Dayjs> {
    return this._job_start_at;
  }

  public get loadAt(): Nullable<Dayjs> {
    return this._load_at;
  }

  public get loadAtDate(): Nullable<string> {
    return this._load_at?.format('YYYY-MM-DD');
  }

  public get loadAtTime(): Nullable<string> {
    return this._load_at?.format('HH:mm a');
  }

  public get name() {
    return this._name;
  }

  public get notes(): string | null {
    return this._notes;
  }

  public get jobNotes(): string | null {
    return this._job_notes;
  }

  public get internalNotes(): string | null {
    return this._internal_notes;
  }

  public get state(): OrderState {
    return this._state;
  }

  public get truckCount(): number {
    return this._truck_count;
  }

  public get truckDelay(): number {
    return this._truck_delay;
  }

  public get trucksPerDelay(): number {
    return this._trucks_per_delay;
  }

  public get loadsPerTruck(): number {
    return this._loads_per_truck;
  }

  public get loadCycleAvg(): number {
    return this._load_cycle_avg || 0;
  }

  public get unitOfMeasure(): Nullable<ItemNameAndId> {
    return this._unit_of_measure;
  }

  public get account(): Nullable<AccountBasic> {
    return this._account;
  }

  public get orderId(): string {
    return this._order_id;
  }

  public get project(): Nullable<ProjectBasic> {
    return this._project;
  }
  public get projectName(): Nullable<string> {
    return this._project?.name;
  }

  public get material(): Nullable<Material> {
    return this._material;
  }

  public get equipmentType(): Nullable<EquipmentTypeItem> {
    return this._equipment_type;
  }

  public get quantity(): number {
    return this._quantity;
  }

  public get service(): Nullable<Service> {
    return this._service;
  }
  public get waypoints(): Nullable<WayPoint[]> {
    return this._waypoints;
  }
  public set pickUpWayPoints(waypoints: Nullable<WayPoint[]>) {
    this._waypoints = _.cloneDeep(waypoints);
  }

  public get pickUpWayPoints(): Nullable<WayPoint[]> {
    return this._pickUpWayPoints;
  }
  public get dropOffWayPoints(): Nullable<WayPoint[]> {
    return this._dropOffWayPoints;
  }
  public set dropOffWayPoints(waypoints: Nullable<WayPoint[]>) {
    this._waypoints = _.cloneDeep(waypoints);
  }
  public get weightWayPoints(): Nullable<WayPoint> {
    return this._waypoints?.find((waypoint) => waypoint.isWeighPoint);
  }
  public get vendorRate(): Nullable<RateBasicWithValue> {
    return this._vendor_rate;
  }
  public get customerRate(): Nullable<RateBasicWithValue> {
    return this._customer_rate;
  }
  public get driverRate(): Nullable<RateBasicWithValue> {
    return this._driver_rate;
  }
  public get phase(): Nullable<Phase> {
    return this._phase;
  }
  public set phase(phase: Nullable<Phase>) {
    this._phase = phase;
  }
  public get phases(): Nullable<Phase[]> {
    return this._phases;
  }
  public set phases(phases: Nullable<Phase[]>) {
    this._phases = phases;
  }
  public get isSalesContact(): boolean {
    return Boolean(this._sales_contact?.id.length);
  }
  public get isSupervisorContact(): boolean {
    return Boolean(this._supervisor_contact?.id.length);
  }
  public get isForemanContact(): boolean {
    return Boolean(this._foreman_contact?.id.length);
  }
  public get isCollaboratorContact(): boolean {
    return Boolean(this._collaborator_contact?.id.length);
  }
  public get salesContact(): Nullable<ExtendedContactItem> {
    return this._sales_contact;
  }
  public set salesContact(value: Nullable<ExtendedContactItem>) {
    this._sales_contact = value;
  }
  public get foremanContact(): Nullable<ExtendedContactItem> {
    return this._foreman_contact;
  }
  public set foremanContact(value: Nullable<ExtendedContactItem>) {
    this._foreman_contact = value;
  }
  public get collaboratorContact(): Nullable<ExtendedContactItem> {
    return this._collaborator_contact;
  }
  public set collaboratorContact(value: Nullable<ExtendedContactItem>) {
    this._collaborator_contact = value;
  }
  public get supervisorContact(): Nullable<ExtendedContactItem> {
    return this._supervisor_contact;
  }
  public set supervisorContact(value: Nullable<ExtendedContactItem>) {
    this._supervisor_contact = value;
  }
  public get allContacts(): ExtendedContactItem[] {
    const allContacts = [];
    if (this._sales_contact) {
      allContacts.push(this._sales_contact);
    }
    if (this._foreman_contact) {
      allContacts.push(this._foreman_contact);
    }
    if (this._collaborator_contact) {
      allContacts.push(this._collaborator_contact);
    }
    if (this._supervisor_contact) {
      allContacts.push(this._supervisor_contact);
    }
    return allContacts;
  }
  public get department(): Nullable<BasicDepartment> {
    return this._department;
  }

  public get serviceClass(): Nullable<BasicServiceClass> {
    return this._service_class;
  }

  public get actionRequired(): boolean {
    return this._action_required;
  }

  public get foremen(): Array<User> {
    return this._foremen || [];
  }

  public get poJobNumber(): Nullable<string> {
    return this._po_job_number;
  }

  public get zone(): Nullable<string> {
    return this._zone;
  }

  public get externalId(): Nullable<string> {
    return this._external_id;
  }

  public get haulerRateType(): Nullable<RateTypes> {
    return this._hauler_rate_type;
  }
  public get jobTime(): Nullable<RateTypes> {
    return this._job_time;
  }
  public get unitsPerHour(): Nullable<RateTypes> {
    return this._units_per_hour;
  }
  public get jobQuantity(): Nullable<number> {
    return this._job_quantity;
  }
  public get orderNotes(): Nullable<string> {
    return this.notes;
  }

  public get sendingAccounts(): Array<AccountNestedProto> {
    return this._sending_accounts;
  }

  public get dispatchNumber(): Nullable<string> {
    return this._dispatch_number;
  }

  public get customerRateValue() {
    return this._customer_rate_value;
  }

  public get customerRateType() {
    return this._customer_rate_type;
  }

  public get vendorRateValue() {
    return this._vendor_rate_value;
  }

  public get vendorRateType() {
    return this._vendor_rate_type;
  }

  public get driverRateValue() {
    return this._driver_rate_value;
  }

  public get driverRateType() {
    return this._driver_rate_type;
  }

  public get loadsPickupTimeMinutesAvg() {
    return this._loads_pickup_time_minutes_avg;
  }

  public get requester(): Nullable<UserBasic> {
    return this._requester;
  }

  public get orderSummary(): Nullable<OrderSummaryReadNested> {
    return this._order_summary;
  }

  public get equipmentTypeGrossCapacity() {
    return this._equipment_type_gross_capacity;
  }

  public get pickupOnsiteMinutes() {
    return this._pickup_onsite_minutes;
  }

  public get dropoffOnsiteMinutes() {
    return this._dropoff_onsite_minutes;
  }

  public get pickupToDropoffTimeMinutes() {
    return this._pickup_to_dropoff_time_minutes;
  }

  public get pickupToDropoffDistanceMeters() {
    return this._pickup_to_dropoff_distance_meters;
  }

  public get expectedTotalLoads() {
    return this._expected_total_loads;
  }

  public get cycleTimeMinutes() {
    return this._cycle_time_minutes;
  }

  public get requestedQuantityType() {
    return this._requested_quantity_type;
  }

  constructor(
    private _id: string,
    private _editable: boolean,
    private _created_at: Nullable<Dayjs>,
    private _company: Nullable<CompanyBasic>,
    private _job_start_at: Nullable<Dayjs>,
    private _load_at: Nullable<Dayjs>,
    private _load_at_date: Nullable<string>,
    private _load_at_time: Nullable<string>,
    private _notes: string | null,
    private _job_notes: string | null,
    private _job_time: Nullable<number>,
    private _internal_notes: string | null,
    private _state: OrderState,
    private _truck_count: number,
    private _truck_delay: number,
    private _trucks_per_delay: number,
    private _loads_per_truck: number,
    private _load_cycle_avg: number,
    private _unit_of_measure: Nullable<ItemNameAndId>,
    private _account: Nullable<AccountBasic>,
    private _order_id: string,
    private _project: Nullable<ProjectBasic>,
    private _material: Nullable<Material>,
    private _equipment_type: Nullable<EquipmentTypeItem>,
    private _quantity: number,
    private _service: Nullable<Service>,
    private _waypoints: Nullable<WayPoint[]>,
    private _pickUpWayPoints: Nullable<WayPoint[]>,
    private _dropOffWayPoints: Nullable<WayPoint[]>,
    private _vendor_rate: Nullable<RateBasicWithValue>,
    private _customer_rate: Nullable<RateBasicWithValue>,
    private _phase: Nullable<Phase>,
    private _phases: Nullable<Phase[]>,
    private _sales_contact: Nullable<ExtendedContactItem>,
    private _foreman_contact: Nullable<ExtendedContactItem>,
    private _collaborator_contact: Nullable<ExtendedContactItem>,
    private _supervisor_contact: Nullable<ExtendedContactItem>,
    private _department: Nullable<BasicDepartment>,
    private _service_class: Nullable<BasicServiceClass>,
    private _action_required: boolean,
    private _foremen: Array<User> | null,
    private _name: Nullable<string>,
    private _po_job_number: Nullable<string>,
    private _zone: Nullable<string>,
    private _external_id: Nullable<string>,
    private _hauler_rate_type: Nullable<RateTypes>,
    private _units_per_hour: Nullable<number>,
    private _job_quantity: Nullable<number>,
    private _sending_accounts: Array<AccountNestedProto>,
    private _dispatch_number: Nullable<string>,
    private _customer_rate_value: Nullable<number>,
    private _customer_rate_type: Nullable<RateType>,
    private _vendor_rate_value: Nullable<number>,
    private _vendor_rate_type: Nullable<RateType>,
    private _driver_rate: Nullable<RateBasicWithValue>,
    private _driver_rate_value: Nullable<number>,
    private _driver_rate_type: Nullable<RateType>,
    private _loads_pickup_time_minutes_avg: number,
    private _requester: Nullable<UserBasic>,
    private _order_summary: Nullable<OrderSummaryReadNested>,
    private _equipment_type_gross_capacity: number | null,
    private _pickup_onsite_minutes: number | null,
    private _dropoff_onsite_minutes: number | null,
    private _pickup_to_dropoff_time_minutes: number | null,
    private _pickup_to_dropoff_distance_meters: number | null,
    private _expected_total_loads: number | null,
    private _cycle_time_minutes: number | null,
    private _requested_quantity_type: RequestedQuantityType | null,
  ) {}
}

export class OrderBasic {
  constructor(
    private _id: Nullable<string>,
    private _order_id: Nullable<string>,
    private _dispatch_number: Nullable<string>,
  ) {}

  public get id() {
    return this._id;
  }

  public get orderId() {
    return this._order_id;
  }

  public get dispatchNumber() {
    return this._dispatch_number;
  }
  public static parse(proto: Order_Read_Nested) {
    return new OrderBasic(proto.id, proto.order_id, proto.dispatch_number);
  }
}

export class DeliveredQuantity {
  constructor(
    private _delivered: string,
    private _unit_of_measure: OrderUnitOfMeasure,
    private _material_name: string,
    private _total: string,
  ) {}

  public get delivered(): string {
    return this._delivered;
  }

  public get unitOfMeasure(): OrderUnitOfMeasure {
    return this._unit_of_measure;
  }

  public get materialName(): string {
    return this._material_name;
  }

  public get total(): string {
    return this._total;
  }

  public static parse(proto: DeliveredQuantity_Read): DeliveredQuantity {
    return new DeliveredQuantity(
      proto.delivered,
      proto.unit_of_measure,
      proto.material_name,
      proto.total,
    );
  }
}

export class OrderSummaryReadNested {
  constructor(
    private _accepted_jobs_count: number,
    private _assigned_jobs_count: number,
    private _delivered_loads_count: number,
    private _jobs_count: number,
    private _loads_count: number,
    private _sent_jobs_count: number,
    private _started_jobs_count: number,
    private _delivered_quantities: Array<DeliveredQuantity>,
  ) {}

  public get acceptedJobsCount(): number {
    return this._accepted_jobs_count;
  }

  public get assignedJobsCount(): number {
    return this._assigned_jobs_count;
  }

  public get deliveredLoadsCount(): number {
    return this._delivered_loads_count;
  }

  public get jobsCount(): number {
    return this._jobs_count;
  }

  public get loadsCount(): number {
    return this._loads_count;
  }

  public get sentJobsCount(): number {
    return this._sent_jobs_count;
  }

  public get startedJobsCount(): number {
    return this._started_jobs_count;
  }

  public get deliveredQuantities(): Array<DeliveredQuantity> {
    return this._delivered_quantities;
  }

  public static parse(proto: OrderSummary_Read_Nested): OrderSummaryReadNested {
    return new OrderSummaryReadNested(
      proto.accepted_jobs_count,
      proto.assigned_jobs_count,
      proto.delivered_loads_count,
      proto.jobs_count,
      proto.loads_count,
      proto.sent_jobs_count,
      proto.started_jobs_count,
      proto.delivered_quantities.map(DeliveredQuantity.parse),
    );
  }
}

export type DeparsedOrderEstimate = {
  quantityType: QuantityType;
  quantity: number;
  equipmentCapacity: number;
  jobDurationMinutes: number;
  cycleTimeMinutes: number;
};

export class OrderEstimate {
  constructor(
    private _truck_quantity: number | null,
    private _loads_per_truck: number | null,
    private _total_loads: number | null,
    private _delivered_per_truck: number | null,
    private _total_units: number | null,
    private _units_per_hour: number | null,
  ) {}

  public get truckQuantity() {
    return this._truck_quantity;
  }

  public get loadsPerTruck() {
    return this._loads_per_truck;
  }

  public get totalLoads() {
    return this._total_loads;
  }

  public get deliveredPerTruck() {
    return this._delivered_per_truck;
  }

  public get totalUnits() {
    return this._total_units;
  }

  public get unitsPerHour() {
    return this._units_per_hour;
  }

  public static parse(proto: Order_Estimate_Read) {
    return new OrderEstimate(
      proto.truck_quantity ?? null,
      proto.loads_per_truck ?? null,
      proto.total_loads ?? null,
      proto.delivered_per_truck ?? null,
      proto.total_units ?? null,
      proto.units_per_hour ?? null,
    );
  }

  public static deparse(proto: DeparseOrderEstimate) {
    const isBasedInLoads = proto.quantityType === QuantityType.LOAD;
    const isBasedInTrucks = proto.quantityType === QuantityType.TRUCKS;
    const isBasedInUnits = !isBasedInLoads && !isBasedInTrucks;

    const body: Order_Estimate_Create = {
      total_loads: isBasedInLoads ? proto.quantity : undefined,
      truck_quantity: isBasedInTrucks ? proto.quantity : undefined,
      total_units: isBasedInUnits ? proto.quantity : undefined,
      equipment_capacity: proto.equipmentCapacity,
      job_duration_minutes: proto.jobDurationMinutes,
      cycle_time_minutes: proto.cycleTimeMinutes,
    };

    return body;
  }
}
