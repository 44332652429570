import AddIcon from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { AddOnRateType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { AddOn, AddOnCharge } from '~hooks/useAddOns';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { usePrevious } from '~utils/hooks/usePrevious';

import { AddOnChargesDTO, AddOnChargesFormHandler } from '../../AddOnChargesTableRows';
import { appendAddOnRateTypeToName, invoiceHelpers, schemaHelpers } from '../../helpers';
import { COMMON_GRID_CONFIG } from './constants';

type AddOnSelectOption = {
  id: string;
  name: string;
};
type SettlementAddOnChargesProps = {
  settlementId: string;
  settlementAddOnCharges: AddOnCharge[];
  currencyFormatter: (value: number) => string;
  isEditing: boolean;
};

const SettlementAddOnCharges = forwardRef<
  AddOnChargesFormHandler,
  SettlementAddOnChargesProps
>(function SettlementAddOnCharges(
  { settlementId, settlementAddOnCharges, currencyFormatter, isEditing },
  ref,
) {
  const { settlementsStore, addOnsStore } = useStores();

  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useFormContext<AddOnChargesDTO>();

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'addOnCharges',
  });

  const watchedAddOnCharges = useWatch({ control, name: 'addOnCharges' });
  const previousWatchedAddOnCharges = usePrevious(watchedAddOnCharges);

  const handleAddAddOnClick = useCallback(() => {
    const addOnCharge = new AddOnCharge(
      null,
      '',
      0,
      0,
      0,
      0,
      AddOnRateType.RATE_FOR_EACH,
      null,
      { id: '' },
    );

    append(schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge));
  }, [append]);

  const addOnSelectOptions = useMemo(() => {
    const options = addOnsStore.addOns.reduce((options, addOn) => {
      options.push({
        id: String(addOn.id),
        name: appendAddOnRateTypeToName(addOn.name, addOn.addOnRateType),
      });

      return options;
    }, [] as AddOnSelectOption[]);

    return options;
  }, [settlementId, addOnsStore.addOns]);

  const handleRemoveAddOnClick = useCallback(
    (index: number) => {
      const addOnChargeToDelete = watchedAddOnCharges?.[index];

      if (addOnChargeToDelete?.id) {
        update(index, { ...addOnChargeToDelete, _destroy: 1 });
      } else {
        remove(index);
      }
    },
    [update, watchedAddOnCharges],
  );

  const addOnByAddOnId = useMemo(() => {
    const addOnByAddOnId = addOnsStore.addOns.reduce(
      (acc, addOn) => {
        acc[addOn.id ?? ''] = addOn;
        return acc;
      },
      {} as Record<string, AddOn>,
    );
    return addOnByAddOnId;
  }, [settlementId, addOnsStore.addOns, settlementsStore.setAddOnChargesBySettlementId]);

  useEffect(() => {
    if (!watchedAddOnCharges || !previousWatchedAddOnCharges) {
      return;
    }

    watchedAddOnCharges.forEach((current, index) => {
      const currentAddOnId = current.addOn?.id;
      const previousAddOnIn = previousWatchedAddOnCharges[index]?.addOn?.id;
      const selectedAddOnDidChange = currentAddOnId && currentAddOnId !== previousAddOnIn;
      // If the selected add-on did change then use the newly selected add-on values
      // As the default values for the add-on charge DTO
      if (selectedAddOnDidChange && addOnByAddOnId[currentAddOnId]) {
        const newlySelectedAddOn = addOnByAddOnId[currentAddOnId];
        const isRateForEach =
          newlySelectedAddOn.addOnRateType === AddOnRateType.RATE_FOR_EACH;
        const addOnCharge = new AddOnCharge(
          current.id,
          newlySelectedAddOn.name,
          newlySelectedAddOn.percentage,
          isRateForEach ? newlySelectedAddOn.quantity : 0,
          newlySelectedAddOn.rate,
          0,
          newlySelectedAddOn.addOnRateType,
          newlySelectedAddOn.externalId,
          { id: currentAddOnId },
        );
        update(index, schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge));
        clearErrors([`addOnCharges.${index}.quantity`, `addOnCharges.${index}.value`]);
      }
    });
  }, [
    watchedAddOnCharges,
    previousWatchedAddOnCharges,
    update,
    clearErrors,
    addOnByAddOnId,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: () => {
        return new Promise((resolve) => {
          handleSubmit((data) => {
            resolve(data.addOnCharges ?? []);
          })();
        });
      },
    }),
    [],
  );

  if (isEditing) {
    return (
      <>
        {fields.map((field, index) => {
          const isDeleted = watchedAddOnCharges?.[index]?._destroy === 1;

          if (isDeleted) {
            return null;
          }

          const editedAddOnCharge = watchedAddOnCharges?.[index];
          const isAddOnSelected = Boolean(editedAddOnCharge?.addOn?.id);
          const isRateForEach =
            editedAddOnCharge?.addOnRateType === AddOnRateType.RATE_FOR_EACH;

          const quantity = editedAddOnCharge?.quantity;
          const rate = editedAddOnCharge?.value;

          const total = invoiceHelpers.calculateAddOnChargeTotal(
            editedAddOnCharge?.addOnRateType
              ? (editedAddOnCharge.addOnRateType as AddOnRateType)
              : null,
            editedAddOnCharge?.quantity,
            editedAddOnCharge?.value,
            editedAddOnCharge?.value,
            0,
          );

          return (
            <Box
              key={field.id}
              sx={{
                ...COMMON_GRID_CONFIG,
                borderBottom: '1px solid #E2E2E2',
              }}
            >
              <AutocompleteFormField
                clearable
                control={control}
                errors={errors}
                getLabel={(item) => item.name}
                getValue={(item) => item.id}
                isRequired
                list={addOnSelectOptions}
                name={`addOnCharges[${index}].addOn`}
                sx={{
                  gridColumn: '1 / 4',
                  fontSize: '12px',
                  '& .MuiAutocomplete-inputRoot': { fontSize: '12px', width: '100%' },
                  '& .MuiAutocomplete-endAdornment': {
                    right: '5px !important',
                    top: 'calc(50% - 13px)',
                    width: 'fit-content',
                  },
                }}
              />

              {isAddOnSelected && isRateForEach && (
                <TextFormField
                  control={control}
                  errors={errors}
                  type="number"
                  name={`addOnCharges[${index}].quantity`}
                  sx={{
                    ml: 'auto',
                    '& .MuiFormControl-root': { mb: 0 },
                    '& .MuiOutlinedInput-root': { pr: 0 },
                    '& .MuiOutlinedInput-input': { p: '5px 7px', fontSize: '12px' },
                    gridArea: 'qty',
                    '& .MuiInputBase-input': {
                      fontSize: '12px',
                    },
                  }}
                  inputProps={{ sx: { textAlign: 'right' } }}
                />
              )}

              {isAddOnSelected ? (
                <TextFormField
                  control={control}
                  errors={errors}
                  type="number"
                  name={`addOnCharges[${index}].value`}
                  sx={{
                    ml: 'auto',
                    '& .MuiFormControl-root': { mb: 0 },
                    '& .MuiOutlinedInput-root': { pr: 0, pl: '7px' },
                    '& .MuiOutlinedInput-input': {
                      p: '5px 7px 5px 0px',
                      fontSize: '12px',
                    },
                    gridArea: 'rate',
                    '& .MuiInputBase-input': {
                      fontSize: '12px',
                    },
                  }}
                  inputProps={{ sx: { textAlign: 'right' } }}
                  InputProps={{
                    startAdornment: (
                      <Typography mr="5px" fontSize="12px">
                        {addOnByAddOnId[editedAddOnCharge?.addOn?.id || ' ']?.percentage
                          ? '%'
                          : '$'}
                      </Typography>
                    ),
                  }}
                />
              ) : null}

              <Box sx={{ gridArea: 'total', fontSize: '12px' }}>
                {quantity && rate && isRateForEach ? currencyFormatter(total) : '$-'}
              </Box>
              <IconButton
                size="small"
                onClick={() => handleRemoveAddOnClick(index)}
                sx={{ gridArea: 'action', justifySelf: 'end' }}
              >
                <Close fontSize="small" sx={{ color: theme.brandV2.colors.treadGray3 }} />
              </IconButton>
            </Box>
          );
        })}

        <Button
          type="button"
          startIcon={<AddIcon />}
          sx={{ fontWeight: 500, mt: 2 }}
          onClick={() => {
            handleAddAddOnClick();
          }}
        >
          {t('approvals.driver_pay.settlements.add_on')}
        </Button>
      </>
    );
  }
  return settlementAddOnCharges.map((addOnCharge) => (
    <Box
      key={addOnCharge.id}
      sx={{
        ...COMMON_GRID_CONFIG,
        borderBottom: '1px solid #E2E2E2',
      }}
    >
      <Typography variant="body2" sx={{ gridColumn: '1 / 4' }}>
        {`${t('approvals.driver_pay.settlements.add_on_name_prefixed', { name: addOnCharge.name })}`}
      </Typography>
      {!!addOnCharge.quantity && (
        <Typography variant="body2" sx={{ gridArea: 'qty' }}>
          {addOnCharge.quantity}
        </Typography>
      )}
      {addOnByAddOnId[addOnCharge.addOnId]?.percentage ? (
        <Typography variant="body2" sx={{ gridArea: 'rate' }}>
          {addOnCharge.percentage >= 0
            ? `%${addOnCharge.percentage}`
            : `(%${-addOnCharge.percentage})`}
        </Typography>
      ) : (
        <Typography variant="body2" sx={{ gridArea: 'rate' }}>
          {addOnCharge.rate >= 0
            ? currencyFormatter(addOnCharge.rate)
            : `(${currencyFormatter(-addOnCharge.rate)})`}
        </Typography>
      )}
      <Typography variant="body2" sx={{ gridArea: 'total' }}>
        {addOnCharge.totalAmount}
      </Typography>

      {isEditing && (
        <IconButton
          type="button"
          onClick={() => {}}
          sx={{ gridArea: 'action', justifySelf: 'end' }}
        >
          <CloseIcon fontSize={'small'} />
        </IconButton>
      )}
      {}
    </Box>
  ));
});

export default observer(SettlementAddOnCharges);
