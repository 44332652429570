import { RequestedQuantityType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import * as yup from 'yup';

import {
  nameIdNonRequiredSchema,
  nameIdSchemaRequired,
  nameIdTypeSchema,
  notesSchema,
  personNameSchema,
  phoneSchema,
  waypointSchema,
} from '~constants/regexConst';
import { CompanyBasic } from '~hooks/useCompany';
import { Order } from '~hooks/useOrders';
import { Service } from '~hooks/useServices';
import { EmptyContactItem } from '~pages/Sales/Projects/projectFormSchema';
import { Nullable } from '~types/Nullable';

import { fileAttachmentSchema } from '../../../formsShared/components/FileAttachments';

const DEFAULT_JOB_DURATION_IN_HOURS = 10.5;
const DEFAULT_CYCLE_ONSITE_DURATION_IN_MINUTES = 20;

export const serviceIsStandingHaul = (service: Service | null) => {
  return service?.name === 'Standing Haul';
};

export enum QuantityType {
  TRUCKS = 'trucks',
  LOAD = 'load',
  TONNE = 'tonne',
  TON = 'ton',
  YARD = 'yard',
  METER = 'meter',
  FOOT = 'foot',
  LITER = 'liter',
  HOUR = 'hour',
  BUSHEL = 'bushel',
  GALLON = 'gallon',
  CUBIC_METER = 'cubic_meter',
  MILE = 'mile',
  KILOMETER = 'kilometer',
  BARREL = 'barrel',
}

export const quantityTypeOptions = Object.values(QuantityType).map((type) => ({
  id: type,
  name: t(`dispatch.order.order_details_v2.quantity_type_enum.${type}`),
}));

export enum OrderSchemaVersion {
  V1 = 'v1',
  V2 = 'v2',
}

const emptyStringOrNumberNotRequiredSchema = yup.lazy((val) => {
  return String(val).trim() === ''
    ? yup.string().notRequired()
    : yup.number().notRequired();
});

export const newOrderFormSchema = yup.object().shape({
  _schemaVersion: yup
    .mixed<OrderSchemaVersion>()
    .oneOf(Object.values(OrderSchemaVersion)),
  id: yup.string(),
  company: yup.object().when('id', {
    // @ts-ignore
    is: '',
    then: () => {
      yup.object().shape({
        legalName: yup.string().required(),
        id: yup.string().required(),
      });
    },
    otherwise: () => {
      yup.object().notRequired();
    },
  }),
  jobStartAt: yup.string().required(),
  loadAt: yup.string().required(),
  loadAtTime: yup.string().nullable(),
  loadAtDate: yup.string().nullable(),
  orderId: yup.string(),
  notes: notesSchema,
  jobNotes: notesSchema,
  projectNotes: notesSchema,
  orderNotes: notesSchema,
  internalNotes: notesSchema,
  jobTime: emptyStringOrNumberNotRequiredSchema,
  truckCount: yup.number().when('id', {
    is: (id: string) => id,
    then: (schema) => {
      return schema.notRequired();
    },
    otherwise: (schema) => schema.typeError('Numbers only.').required(),
  }),
  truckDelay: yup.number().nullable(),
  trucksPerDelay: yup
    .number()
    .min(1, () => t('form_fields.trucks_per_delay_min'))
    .nullable()
    .test(
      'is-not-greater-than-truckCount',
      `${t('form_validation_errors.trucks_per_delay_greater_than_truck_count')}`,
      (value, context) => {
        if (_.isNil(value)) return true;
        if (!context.parent.truckCount) return true;
        return value <= context.parent.truckCount;
      },
    ),
  loadsPerTruck: yup
    .number()
    .nullable()
    .min(0)
    .transform((_, val) => (String(val).trim() !== '' ? Number(val) : null)),
  unitOfMeasure: nameIdSchemaRequired.required(),
  account: nameIdSchemaRequired.required(),
  project: nameIdNonRequiredSchema.nullable(),
  material: yup.object().required(),
  equipmentType: yup.object().notRequired(),
  service: nameIdTypeSchema.required(),
  quantity: yup.number().typeError('Numbers only.').required(),
  state: yup.string(),
  pickUpWayPoint: waypointSchema.required(),
  dropOffWayPoint: waypointSchema.required(),
  phase: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
      code: yup.string(),
    })
    .notRequired(),
  phaseName: yup.string().notRequired(),
  foremen: yup.array().of(yup.object().shape({ id: yup.string().required() }).required()),
  allContacts: yup.array().of(
    yup.object().shape({
      name: personNameSchema(`${t('form_fields.name')}`).notRequired(),
      phone: phoneSchema,
      type: nameIdNonRequiredSchema,
      isDeleted: yup.boolean().notRequired(),
    }),
  ),
  projectMaterialTotals: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          material: nameIdNonRequiredSchema,
          unitOfMeasure: nameIdNonRequiredSchema,
          totalQuantity: yup.string().notRequired(),
          id: yup.string(),
        })
        .notRequired(),
    )
    .notRequired(),
  allPhaseCodes: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          id: yup.string(),
          name: yup.string(),
          code: yup.string(),
        })
        .notRequired(),
    )
    .notRequired(),
  department: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .notRequired(),
  name: yup.string().notRequired(),
  poJobNumber: yup.string().notRequired(),
  zone: yup.string().notRequired(),
  externalId: yup.string().notRequired(),
  serviceClass: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .notRequired(),
  grossCapacity: yup.string().notRequired(),
  unitsPerHour: yup.number().nullable(),
  jobQuantity: yup.number().when('service', {
    is: (service: Service | null) => serviceIsStandingHaul(service),
    then: (schema) => {
      return schema
        .nullable()
        .min(0)
        .transform((_, val) => (String(val).trim() !== '' ? Number(val) : null));
    },
    otherwise: (schema) => schema.min(0).required(),
  }),
  dispatchNumber: yup.string().notRequired(),
  fileAttachments: fileAttachmentSchema,
  requester: yup.object().shape({ id: yup.string() }).notRequired(),
  quantityType: yup.object().when('_schemaVersion', {
    is: OrderSchemaVersion.V2,
    then: () => nameIdSchemaRequired.required(),
    otherwise: () => nameIdNonRequiredSchema.notRequired(),
  }),
  serviceQuantity: yup.string().when('_schemaVersion', {
    is: OrderSchemaVersion.V2,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  jobStartAtEndTime: yup.string().notRequired(),
  cycleTime: emptyStringOrNumberNotRequiredSchema,
  cycleDistance: emptyStringOrNumberNotRequiredSchema,
  pickupOnsite: emptyStringOrNumberNotRequiredSchema,
  dropoffOnsite: emptyStringOrNumberNotRequiredSchema,
  pickupToDropoff: emptyStringOrNumberNotRequiredSchema,
  totalLoads: emptyStringOrNumberNotRequiredSchema,
});

export const setDefaultOrderValues = (
  defaultOrder: Order,
  company?: Nullable<CompanyBasic>,
  schemaVersion?: OrderSchemaVersion,
) => {
  const pickUpWayPoint = defaultOrder?.pickUpWayPoints?.[0];
  const dropOffWayPoint = defaultOrder?.dropOffWayPoints?.[0];
  const isSchemaV2 = schemaVersion === OrderSchemaVersion.V2;

  let quantityType = null;
  let serviceQuantity = null;

  if (defaultOrder?.requestedQuantityType) {
    if (defaultOrder.requestedQuantityType === RequestedQuantityType.TRUCK) {
      quantityType = quantityTypeOptions.find(({ id }) => id === QuantityType.TRUCKS);
      serviceQuantity = defaultOrder?.truckCount;
    } else if (defaultOrder.requestedQuantityType === RequestedQuantityType.LOAD) {
      quantityType = quantityTypeOptions.find(({ id }) => id === QuantityType.LOAD);
      serviceQuantity = defaultOrder?.expectedTotalLoads;
    } else {
      quantityType = quantityTypeOptions.find(({ id }) => {
        return id === _.snakeCase(defaultOrder?.unitOfMeasure?.id);
      });
      serviceQuantity = defaultOrder?.quantity;
    }
  }

  return {
    _schemaVersion: schemaVersion ?? OrderSchemaVersion.V1,
    id: defaultOrder?.id || '',
    company: defaultOrder?.company || company || null,
    jobStartAt: defaultOrder?.jobStartAt || '',
    loadAt: defaultOrder?.loadAt || '',
    loadAtDate: defaultOrder?.loadAt || '',
    loadAtTime: defaultOrder?.loadAt || '',
    notes: defaultOrder?.notes || '',
    jobNotes: defaultOrder?.jobNotes || '',
    projectNotes: '',
    orderNotes: defaultOrder?.notes || '',
    internalNotes: defaultOrder?.internalNotes || '',
    jobTime: isSchemaV2
      ? defaultOrder
        ? _.isNil(defaultOrder?.jobTime)
          ? null
          : Number(defaultOrder.jobTime / 60)
        : DEFAULT_JOB_DURATION_IN_HOURS
      : defaultOrder?.jobTime || null,
    jobQuantity: defaultOrder?.jobQuantity || '',
    state: defaultOrder?.state || '',
    truckCount: isSchemaV2
      ? defaultOrder?.truckCount ?? ''
      : defaultOrder?.orderId
        ? defaultOrder?.orderSummary?.jobsCount
        : 1,
    truckDelay: defaultOrder?.truckDelay || 0,
    trucksPerDelay: defaultOrder?.trucksPerDelay || 1,
    loadsPerTruck: isSchemaV2
      ? defaultOrder?.loadsPerTruck ?? null
      : defaultOrder?.loadsPerTruck || 1,
    unitOfMeasure: {
      name: defaultOrder?.unitOfMeasure?.name || '',
      id: defaultOrder?.unitOfMeasure?.id || '',
    },
    account: {
      name: defaultOrder?.account?.name || '',
      id: defaultOrder?.account?.id || '',
    },
    orderId: defaultOrder?.orderId || '',
    project: {
      name: defaultOrder?.project?.name || '',
      id: defaultOrder?.project?.id || '',
      externalId: defaultOrder?.project?.externalId || '',
    },
    projectExternalId: defaultOrder?.project?.externalId || null,
    material: defaultOrder?.material || null,
    equipmentType: defaultOrder?.equipmentType || null,
    service: defaultOrder?.service || null,
    quantity: defaultOrder?.quantity,
    pickUpWayPoint: pickUpWayPoint?.id
      ? {
          id: pickUpWayPoint?.id || '',
          type: pickUpWayPoint?.type || '',
          ordinality: pickUpWayPoint?.ordinality ?? 10,
          contact: {
            name: pickUpWayPoint?.contact?.name || '',
            phone: pickUpWayPoint?.contact?.phone || '',
          },
          site: {
            id: pickUpWayPoint?.site?.id || '',
            name: pickUpWayPoint?.site?.name || '',
          },
        }
      : null,
    dropOffWayPoint: dropOffWayPoint?.id
      ? {
          id: dropOffWayPoint?.id || '',
          type: dropOffWayPoint?.type || '',
          ordinality: dropOffWayPoint?.ordinality ?? 10,
          contact: {
            name: dropOffWayPoint?.contact?.name || '',
            phone: dropOffWayPoint?.contact?.phone || '',
          },
          site: {
            id: dropOffWayPoint?.site?.id || '',
            name: dropOffWayPoint?.site?.name || '',
          },
        }
      : null,
    allContacts: defaultOrder?.allContacts?.length
      ? defaultOrder.allContacts.map((single) => {
          return {
            id: single.id || undefined,
            name: single.name || '',
            phone: single.phone || '',
            type: single.type || undefined,
            isDeleted: false,
          };
        })
      : [EmptyContactItem],
    phase: {
      id: defaultOrder?.phase?.id || '',
      name: defaultOrder?.phase?.name || '',
      code: defaultOrder?.phase?.code || '',
    },
    phaseCode: defaultOrder?.phase?.code || '',
    phases: defaultOrder?.phases || [],
    department: defaultOrder?.department || {},
    foremen: defaultOrder?.foremen || [],
    name: defaultOrder?.name || '',
    poJobNumber: defaultOrder?.poJobNumber || '',
    zone: defaultOrder?.zone || '',
    externalId: defaultOrder?.externalId || '',
    serviceClass: defaultOrder?.serviceClass || {},
    grossCapacity: defaultOrder?.equipmentType?.grossCapacity || '',
    unitsPerHour: defaultOrder?.unitsPerHour || null,
    dispatchNumber: defaultOrder?.dispatchNumber || '',
    requester: defaultOrder?.requester || {},
    quantityType: quantityType ?? null,
    serviceQuantity: serviceQuantity ?? null,
    jobStartAtEndTime: null,
    cycleTime: defaultOrder?.cycleTimeMinutes ?? null,
    cycleDistance: defaultOrder?.pickupToDropoffDistanceMeters ?? null,
    pickupOnsite:
      defaultOrder?.pickupOnsiteMinutes ?? DEFAULT_CYCLE_ONSITE_DURATION_IN_MINUTES,
    dropoffOnsite:
      defaultOrder?.dropoffOnsiteMinutes ?? DEFAULT_CYCLE_ONSITE_DURATION_IN_MINUTES,
    pickupToDropoff: defaultOrder?.pickupToDropoffTimeMinutes ?? null,
    totalLoads: defaultOrder?.expectedTotalLoads ?? null,
  };
};
export type NewOrderFormSchemaInterface = yup.InferType<typeof newOrderFormSchema>;
