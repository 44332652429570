import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { CSSObject, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { getNavItems } from '~pages/Settings/NavItems';
import { routes } from '~router';
import theme from '~theme/AppTheme';

import { APPBAR_HEIGHT_IN_PX } from '../Dispatch/components/drivers/constants';

interface ListItemLinkProps {
  title: string;
}

const ListTitleItem = ({ title }: ListItemLinkProps) => {
  return (
    <ListItemText
      sx={{ color: theme.palette.grey[700] }}
      disableTypography={true}
      primary={<Typography variant={'h6'}>{title}</Typography>}
    ></ListItemText>
  );
};
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const drawerWidth = 240;

const activeBorderWith = '2px';
const sxSubnavItem = { paddingLeft: '52px' };
const sxSubnavItemActive = {
  ...sxSubnavItem,
  borderRight: `${activeBorderWith} solid ${theme.palette.primary.main}`,
};
const sxNestedSubnavItem = {
  paddingLeft: '65px',
};
const sxNestedSubnavItemActive = {
  ...sxSubnavItemActive,
  ...sxNestedSubnavItem,
};

const arrowBaseOffset = '-25px';
const sxSubnavItemArrow = {
  transform: `translate(calc(${arrowBaseOffset} - ${activeBorderWith}), -50%)`,
};
const sxSubnavItemArrowActive = {
  transform: `translate(${arrowBaseOffset}, -50%) rotate(90deg)`,
};
const sxSubnavItemArrowWithChildActive = {
  transform: `translate(calc(${arrowBaseOffset} - ${activeBorderWith}), -50%) rotate(90deg)`,
};

export const Settings = observer(() => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const [activeParentName, setActiveParentName] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const navItems = getNavItems();
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const settingsIndex = routes.settings.base;

  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen((open) => !open);
  }, []);

  const navigateToSelectedPage = (link: string, parentTitle: string) => () => {
    navigate(`/${settingsIndex}/${link}`);
    setActiveParentName(parentTitle);
  };

  useEffect(() => {
    navItems.map((item) => {
      const { items } = item;
      items.map((subItem) => {
        if (location.pathname.includes(subItem.link)) {
          setActiveParentName(item.title);
        }
      });
    });
    return () => {
      setActiveParentName('');
    };
  }, []);

  return (
    <Box
      display={'flex'}
      sx={{
        marginLeft: -2,
        marginRight: -5,
        marginTop: 0,
        flexGrow: 1,
        flexDirection: 'row',
        height: `calc(100vh - ${APPBAR_HEIGHT_IN_PX}px)`,
        overflow: 'hidden',
      }}
    >
      <Drawer
        variant="permanent"
        hideBackdrop={true}
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        sx={{
          width: drawerWidth,
          borderRight: `1px solid ${theme.palette.grey[200]}`,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            position: 'relative',
            height: '100%',
            justifyContent: 'space-between',
          },
        }}
      >
        <List sx={{ overflow: 'auto' }}>
          {navItems.map((navItem, index) => {
            return (
              <React.Fragment key={index}>
                <ListItemButton onClick={toggleDrawer}>
                  <ListItemIcon
                    sx={{
                      minWidth: 36,
                      '.MuiSvgIcon-root': {
                        fill:
                          activeParentName === navItem.title
                            ? theme.palette.primary.main
                            : theme.palette.grey[700],
                      },
                    }}
                  >
                    {/*<ArrowRight sx={{ width: 20, height: 20 }} />*/}
                    {navItem.icon}
                  </ListItemIcon>
                  <ListTitleItem title={navItem.title} />
                </ListItemButton>
                <Collapse in={isDrawerOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {navItem.items.map((subnavItem, subIndex) => {
                      const isActive = location.pathname.endsWith(subnavItem.link);
                      const isAnyChildActive = (subnavItem.items || []).some((nested) => {
                        const fullyQualifiedRoute = `/${settingsIndex}/${nested.link}`;

                        return location.pathname === fullyQualifiedRoute;
                      });

                      return (
                        <React.Fragment key={subIndex}>
                          <ListItemButton
                            selected={isActive}
                            onClick={navigateToSelectedPage(
                              subnavItem.link,
                              navItem.title,
                            )}
                            sx={{
                              ...(isActive ? sxSubnavItemActive : sxSubnavItem),
                            }}
                          >
                            <ListItemText primary={subnavItem.title} />

                            {Boolean(subnavItem.items?.length) && (
                              <Box
                                position="absolute"
                                top="50%"
                                left="100%"
                                sx={{
                                  ...(isAnyChildActive
                                    ? sxSubnavItemArrowWithChildActive
                                    : isActive
                                      ? sxSubnavItemArrowActive
                                      : sxSubnavItemArrow),
                                }}
                              >
                                <ChevronRight
                                  sx={{ color: 'grey.500', fontSize: '20px' }}
                                />
                              </Box>
                            )}
                          </ListItemButton>

                          {isActive || isAnyChildActive
                            ? subnavItem.items?.map((nestedSubnavItem) => {
                                const fullyQualifiedRoute = `/${settingsIndex}/${nestedSubnavItem.link}`;
                                const isActive =
                                  location.pathname === fullyQualifiedRoute;

                                return (
                                  <ListItemButton
                                    key={`${subnavItem.title}-${nestedSubnavItem.title}`}
                                    selected={isActive}
                                    onClick={navigateToSelectedPage(
                                      nestedSubnavItem.link,
                                      nestedSubnavItem.title,
                                    )}
                                    sx={{
                                      ...(isActive
                                        ? sxNestedSubnavItemActive
                                        : sxNestedSubnavItem),
                                    }}
                                  >
                                    <ListItemText
                                      disableTypography
                                      primary={
                                        <Typography fontSize="12px">
                                          {nestedSubnavItem.title}
                                        </Typography>
                                      }
                                    ></ListItemText>
                                  </ListItemButton>
                                );
                              })
                            : null}
                        </React.Fragment>
                      );
                    })}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          })}
        </List>
        <Box
          display={'flex'}
          onClick={toggleDrawer}
          sx={{
            flex: 0,
            width: isDrawerOpen ? drawerWidth : 48,
            alignItems: 'center',
            justifyContent: isDrawerOpen ? 'start' : 'center',
            textAlign: 'center',
            borderTop: `1px solid ${theme.palette.grey[200]}`,
          }}
        >
          <Button
            sx={{
              minWidth: 32,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.palette.grey.A100,
              m: 2,
            }}
          >
            {isDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
          </Button>
          {isDrawerOpen && (
            <Typography variant={'body1'}>{t('actions.collapse')}</Typography>
          )}
        </Box>
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          width: `calc(100vw - ${isDrawerOpen ? drawerWidth : theme.spacing(6)}px)`,
          overflow: 'auto',
        }}
      >
        <Box py={4} pl={3} pr={2}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
});
