import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { AddOn } from '~hooks/useAddOns';
import useAddOns from '~hooks/useAddOns/useAddOns';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import {
  SettlementLineItem,
  useSettlementLineItems,
} from '~hooks/useSettlementLineItems';
import { useSettlements } from '~hooks/useSettlements';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';

import { AddOnChargesDTO, AddOnChargesFormHandler } from '../../AddOnChargesTableRows';
import { schemaHelpers } from '../../helpers';
import { COMMON_GRID_CONFIG } from './constants';
import SettlementAddOnCharges from './SettlementAddOnCharges';
import { SettlementLineItems } from './SettlementLineItems';
import { SettlementPricing } from './SettlementPricing';

function LoadingPlaceholder() {
  return (
    <Box
      sx={{
        gridColumn: '1 / -1',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100px',
        bgcolor: 'common.white',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

type AddOnSelectOption = {
  id: string;
  label: string;
};

export const SettlementDetails = observer(
  ({ settlementId }: { settlementId: string }) => {
    const [lineItems, setLineItems] = useState<SettlementLineItem[]>([]);
    const [isEditing, setIsEditing] = useState(false);
    const { addOnsStore, settlementsStore, userStore, toasterStore } = useStores();
    const addOnChargesRef = useRef<AddOnChargesFormHandler>({});
    const companyId = userStore.userCompany.id;
    const settlement = useMemo(() => {
      return settlementsStore.settlements.find(
        (settlement) => settlement.settlementId === settlementId,
      );
    }, [settlementId, settlementsStore.settlements]);
    const settlementAddOnCharges = useMemo(() => {
      return settlementsStore.addOnChargesBySettlementId[settlementId] ?? [];
    }, [settlementId, settlementsStore.addOnChargesBySettlementId]);

    const formMethods = useForm<AddOnChargesDTO>({
      defaultValues: {
        addOnCharges: settlementAddOnCharges.map((addOnCharge) => {
          return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
        }),
      },
      mode: 'all',
      delayError: 500,
    });
    const {
      control,
      handleSubmit,
      formState: { isValid, isDirty },
      reset,
      setValue,
    } = formMethods;

    useEffect(() => {
      reset({
        addOnCharges: settlementAddOnCharges.map((addOnCharge) => {
          return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
        }),
      });
    }, [settlementAddOnCharges]);
    const watchedAddOnCharges = useWatch({ control, name: 'addOnCharges' });

    const {
      getAllAddOns,
      isLoading: isLoadingAllAdOns,
      createSettlementAddOnCharges,
      isCreating: isCreatingSettlementAddOnCharges,
      batchUpdateAddOnChargesOfSettlements,
    } = useAddOns();
    const {
      getAllSettlementAddOnCharges,
      isLoading: isLoadingAllSettlementAddOnCharges,
    } = useSettlements();
    const { getAllSettlementLineItems, isLoading: isLoadingAllSettlementLineItems } =
      useSettlementLineItems();
    const { currencyFormatter } = useCompanyCurrency();

    // Hydrate line items and add on charges on mount
    useEffect(() => {
      const getSettlementLineItems = async () => {
        const lineItemsRequest = await getAllSettlementLineItems(String(settlementId));
        const lineItems = lineItemsRequest.map((group) => {
          return group.lineItems;
        });
        setLineItems(lineItems.flat());
      };

      getSettlementLineItems();
      getAllSettlementAddOnCharges(String(settlementId));
    }, [settlementId]);

    // Hydrate select options on mount
    useEffect(() => {
      if (!addOnsStore.addOns.length) {
        getAllAddOns(companyId);
      }
    }, [companyId, addOnsStore.addOns]);

    const isLoadingAddOnArea = useMemo(
      () =>
        isLoadingAllAdOns ||
        isLoadingAllSettlementAddOnCharges ||
        isLoadingAllSettlementLineItems,
      [
        isLoadingAllAdOns,
        isLoadingAllSettlementAddOnCharges,
        isLoadingAllSettlementLineItems,
      ],
    );

    const addOnByAddOnId = useMemo(() => {
      const addOnByAddOnId = addOnsStore.addOns.reduce(
        (acc, addOn) => {
          acc[addOn.id ?? ''] = addOn;
          return acc;
        },
        {} as Record<string, AddOn>,
      );
      return addOnByAddOnId;
    }, [settlementId, addOnsStore.addOns]);

    const isReadyForSubmission = useMemo(
      () => (watchedAddOnCharges?.length ?? 0) > 0 && isDirty && isValid,
      [isValid, isDirty, watchedAddOnCharges],
    );

    const onSubmit = useCallback(
      handleSubmit(async () => {
        const addOnCharges = (await addOnChargesRef.current.onSubmit?.()) ?? [];
        await batchUpdateAddOnChargesOfSettlements(settlementId, addOnCharges);
        setIsEditing(false);
        toasterStore.push(
          alert(
            t('approvals.driver_pay.settlements.add_ons_updated_successfully'),
            AlertTypes.success,
          ),
        );
        reset();
      }),
      [setIsEditing, reset, createSettlementAddOnCharges, settlementId, addOnByAddOnId],
    );

    return (
      <FormProvider {...formMethods}>
        <Box
          sx={{
            border: 'none',
            px: 1,
            py: 2,
            backgroundColor: theme.brandV2.colors.treadGray8,
            gap: 2,
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
          }}
        >
          {isEditing ? (
            <Box
              component="form"
              onSubmit={onSubmit}
              sx={{ gridColumn: '1 / -1', justifySelf: 'end', display: 'flex', gap: 2 }}
            >
              <Button
                type="button"
                color="inherit"
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={() => {
                  setIsEditing(false);
                  reset();
                }}
                sx={{ fontWeight: 500, bgcolor: 'common.white' }}
              >
                {t('approvals.driver_pay.settlements.cancel')}
              </Button>
              <LoadingButton
                type="submit"
                color="success"
                loading={isCreatingSettlementAddOnCharges}
                disabled={!isReadyForSubmission}
                startIcon={<CheckIcon />}
                sx={{ fontWeight: 500 }}
              >
                {t('approvals.driver_pay.settlements.save')}
              </LoadingButton>
            </Box>
          ) : (
            <Button
              type="button"
              startIcon={<EditOutlinedIcon />}
              sx={{ gridColumn: '1 / -1', justifySelf: 'end', fontWeight: 500 }}
              onClick={() => setIsEditing(true)}
            >
              {t('approvals.driver_pay.settlements.edit_add_ons')}
            </Button>
          )}
          <Box sx={{ gridColumn: '1 / -1' }}>
            <Box
              sx={{
                ...COMMON_GRID_CONFIG,
                gridTemplateRows: '36px',
                bgcolor: 'secondary.light',
              }}
            >
              {[
                t('approvals.driver_pay.settlements.pdf.date'),
                t('approvals.driver_pay.settlements.pdf.truck_num'),
                t('approvals.driver_pay.settlements.pdf.equipment_type'),
                t('approvals.driver_pay.settlements.pdf.material'),
                t('approvals.driver_pay.settlements.pdf.ids_jobs'),
                t('approvals.driver_pay.settlements.pdf.qty'),
                t('approvals.driver_pay.settlements.pdf.description'),
                t('approvals.driver_pay.settlements.adjustments'),
                t('approvals.driver_pay.settlements.pdf.billing_qty'),
                t('approvals.driver_pay.settlements.pay_rate'),
                t('approvals.driver_pay.settlements.pdf.total'),
              ].map((header, index, arr) => (
                <Typography
                  key={header}
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    ...(index === arr.length - 1 ? { gridColumn: 'span 2' } : {}),
                  }}
                >
                  {header}
                </Typography>
              ))}
            </Box>

            {isLoadingAddOnArea ? (
              <LoadingPlaceholder />
            ) : (
              <>
                <SettlementLineItems
                  lineItems={lineItems}
                  settlement={settlement}
                  currencyFormatter={currencyFormatter}
                />
                <SettlementAddOnCharges
                  settlementId={settlementId}
                  settlementAddOnCharges={settlementAddOnCharges}
                  currencyFormatter={currencyFormatter}
                  isEditing={isEditing}
                  ref={addOnChargesRef}
                />
              </>
            )}

            {isCreatingSettlementAddOnCharges && <LoadingPlaceholder />}
          </Box>

          <SettlementPricing settlementId={settlementId} />
        </Box>
      </FormProvider>
    );
  },
);
