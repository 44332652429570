import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { JobState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { ReactElement, useMemo } from 'react';
import React from 'react';

import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { Account } from '~hooks/useAccount';
import { useJob } from '~hooks/useJob';
import { User } from '~hooks/useUsers';

import { OverflowAwareText } from '../../Order/ordersDispatchStyledComponents';
import { shouldShowUnassignCTA } from './AssignJobToView';

interface AssignedViewProp {
  customUnassignCTA?: ReactElement;
  driver?: User;
  editable?: boolean;
  isUnassignable?: boolean;
  status: JobState;
  valueToDisplay?: 'driver' | 'vendor';
  vendor?: Account;
  onUnassignClick: () => void;
}

const AssignedDriverView = ({
  customUnassignCTA,
  driver,
  editable,
  isUnassignable,
  status,
  valueToDisplay = 'vendor',
  vendor,
  onUnassignClick,
}: AssignedViewProp) => {
  const theme = useTheme();
  const { isUpdating } = useJob();
  const displayName = useMemo(() => {
    if (driver && valueToDisplay === 'driver') {
      return `${driver.firstName} ${driver.lastName}`;
    } else if (vendor && valueToDisplay === 'vendor') {
      return vendor?.name || '';
    }
    return '';
  }, [driver, vendor, valueToDisplay]);

  if (editable && isUnassignable && customUnassignCTA) {
    return (
      <BasicTooltip title={t('dispatch.job.assignment_popover.unassign')}>
        {React.cloneElement(customUnassignCTA, {
          onClick(event: React.MouseEvent) {
            event?.stopPropagation();
            onUnassignClick();
          },
        })}
      </BasicTooltip>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center', width: '100%' }}>
        <BasicTooltip
          title={displayName}
          {...(valueToDisplay === 'driver' ? { 'data-sentry-mask': true } : {})}
        >
          <OverflowAwareText>{displayName}</OverflowAwareText>
        </BasicTooltip>

        {isUnassignable ? (
          <BasicTooltip title={t('dispatch.job.assignment_popover.unassign')}>
            <IconButton
              size="small"
              onClick={onUnassignClick}
              sx={{ color: `${theme.palette.grey[500]}` }}
              disabled={isUpdating}
            >
              <Close sx={{ fontSize: 20 }} />
            </IconButton>
          </BasicTooltip>
        ) : null}
      </Box>
    </Box>
  );
};

export { AssignedDriverView };
