import { t } from 'i18next';

import { geofenceOptions, GeoFenceTypes } from '~constants/enums';
import { Geofence } from '~hooks/useSites';
import { ItemNameAndId } from '~types/ItemNameAndId';

export const geofenceToItemNameAndId = (geofence: Geofence) => {
  const { geofenceType, movingGeofence } = geofence;
  geofence?.movingGeofence;
  if (movingGeofence) {
    return ItemNameAndId.parse({
      name: t('geofence.equipment'),
      id: GeoFenceTypes.EQUIPMENT,
    });
  }
  return geofenceOptions.find((item) => item.id === geofenceType);
};

export const getErrorMapping = (fieldName: string, defaultMessage: string) => {
  if (fieldName?.includes('address')) {
    fieldName = 'address';
  }
  switch (fieldName) {
    case 'address':
      return {
        field: 'address',
        message: defaultMessage,
      };
    default:
      return {
        field: fieldName,
        message: defaultMessage,
      };
  }
};
