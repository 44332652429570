import * as React from 'react';
import { SVGProps } from 'react';

interface GeoFenceIconPolygonProps extends SVGProps<SVGSVGElement> {
  textFill?: string;
}

export const GeoFenceIconPolygon = ({
  textFill = '#BBC4C9',
  ...props
}: GeoFenceIconPolygonProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="20" height="20" fill="none" />
    <path
      d="M17.129 11.6101L10 17.0562L2.87102 11.6101L5.60896 2.75L14.391 2.75L17.129 11.6101Z"
      stroke={textFill}
      strokeWidth="1.5"
    />
    <circle cx="10" cy="9" r="2" fill={textFill} />
  </svg>
);
