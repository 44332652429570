import {
  AddOn_Read,
  AddOnCharge_Create,
  AddOnCharge_Read,
  AddOnRateType,
  patchV1DriverDaysDriverDayIdAddOnCharges,
  patchV1JobsJobIdAddOnCharges,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { AddOn, AddOnCharge } from '~hooks/useAddOns';
import { AddOnChargeDTO } from '~pages/Approvals/DriverPay/helpers';
import { AddOnDTO } from '~pages/Settings/RatesManagement/AddOnCharges/components/addOnFormSchema';
import connection from '~services/connectionModule';
import { PaginationLink, PaginationQuery } from '~services/pagination';
import { useStores } from '~store';

type CommonSettlementAddOn = {
  addOnId: string;
  name: string;
};

type PercentageSettlementAddOn = {
  addOnRateType: AddOnRateType.RATE_PERCENT_OF_TOTAL;
  percentage: number;
} & CommonSettlementAddOn;

type RateSettlementAddOn = {
  addOnRateType: AddOnRateType.RATE_FOR_EACH;
  rate: number;
  quantity: number;
} & CommonSettlementAddOn;

type SettlementAddOn = PercentageSettlementAddOn | RateSettlementAddOn;
type GetAddOnChargesByJobQueryParams = { 'page[after]'?: string };

const useAddOns = () => {
  const { addOnsStore, settlementsStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const createSettlementAddOnCharges = async (
    settlementId: string,
    addOns: SettlementAddOn[],
  ) => {
    try {
      setIsCreating(true);
      const updates = [];

      for (const addOn of addOns) {
        const payload: AddOnCharge_Create =
          addOn.addOnRateType === AddOnRateType.RATE_FOR_EACH
            ? {
                add_on_id: addOn.addOnId,
                add_on_rate_type: addOn.addOnRateType,
                name: addOn.name,
                quantity: addOn.quantity,
                rate: addOn.rate,
              }
            : {
                add_on_id: addOn.addOnId,
                add_on_rate_type: addOn.addOnRateType,
                name: addOn.name,
                percentage: addOn.percentage,
              };
        const post = connection.post<AddOnCharge_Read>(
          `${API_VERSION}/settlements/${settlementId}/add_on_charges`,
          payload,
          {},
          $t('error_messages.add_ons.failed_to_create') as string,
        );
        updates.push(post);
      }
      const responses = await Promise.all(updates);
      responses.forEach((charge) => {
        const addOnCharge = AddOnCharge.parse(charge);
        settlementsStore.updateAddOnChargeBySettlementId(settlementId, addOnCharge);
      });
    } finally {
      setIsCreating(false);
    }
  };

  const batchUpdateAddOnCharges = async (invoiceId: string, addOns: AddOnChargeDTO[]) => {
    try {
      setIsUpdating(true);

      const payload = {
        add_on_charges: addOns.map((addOn) => {
          return AddOnCharge.deparse(addOn);
        }),
      };

      const response = await connection.patch<AddOnCharge_Read[]>(
        `${API_VERSION}/invoices/${invoiceId}/add_on_charges`,
        payload,
        {},
        $t('error_messages.add_ons.failed_to_update') as string,
      );

      const updatedAddOnCharges = response.map((addOnCharge) => {
        return AddOnCharge.parse(addOnCharge);
      });
      addOnsStore.setAddOnChargesByInvoiceId(invoiceId, updatedAddOnCharges);

      return updatedAddOnCharges;
    } finally {
      setIsUpdating(false);
    }
  };

  const createAddOn = async (data: AddOnDTO) => {
    try {
      setIsCreating(true);

      const response = await connection.post<AddOn_Read>(
        `${API_VERSION}/add_ons`,
        AddOn.deparse(data),
        {},
        $t('error_messages.add_ons.failed_to_create') as string,
      );

      const newAddOn = AddOn.parse(response);
      addOnsStore.addAddOn(newAddOn);

      return newAddOn;
    } finally {
      setIsCreating(false);
    }
  };

  const getAllAddOns = async (companyId: string) => {
    setIsLoading(true);

    const fetchAddOnsPage = (params: PaginationQuery) => {
      return connection.getPaginated<AddOn_Read>(
        `${API_VERSION}/companies/${companyId}/add_ons`,
        { params },
        $t('error_messages.add_ons.failed_to_fetch') as string,
      );
    };

    let shouldFetchNextPage = true;
    let allAddOns: AddOn_Read[] = [];

    const params: PaginationQuery = {
      'page[limit]': addOnsStore.pagination.limit,
    };

    while (shouldFetchNextPage) {
      const { data, pagination } = await fetchAddOnsPage(params);

      allAddOns = [...allAddOns, ...data];
      shouldFetchNextPage = Boolean(pagination.after);

      if (shouldFetchNextPage) {
        params['page[after]'] = pagination.after;
      }
    }

    const parsedAddOns = allAddOns.map((addOn) => AddOn.parse(addOn));
    addOnsStore.setAddOns(parsedAddOns);

    setIsLoading(false);

    return parsedAddOns;
  };

  const getAddOns = async (companyId: string, link?: PaginationLink) => {
    try {
      setIsLoading(true);

      const params: PaginationQuery = { 'page[limit]': addOnsStore.pagination.limit };

      if (link && addOnsStore.pagination[link]) {
        params[`page[${link}]`] = addOnsStore.pagination[link];
      }

      const response = await connection.getPaginated<AddOn_Read>(
        `${API_VERSION}/companies/${companyId}/add_ons`,
        { params },
        $t('error_messages.add_ons.failed_to_fetch') as string,
      );

      const { data, pagination } = response;
      const addOns = data.map((addOn) => AddOn.parse(addOn));

      addOnsStore.setAddOns(addOns);
      addOnsStore.setPagination(pagination);
      addOnsStore.updatePageNumber(link);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllInvoiceAddOnCharges = async (invoiceId: string) => {
    setIsLoading(true);

    const fetchAddOnChargesPage = (params: PaginationQuery) => {
      return connection.getPaginated<AddOnCharge_Read>(
        `${API_VERSION}/invoices/${invoiceId}/add_on_charges`,
        { params },
        $t('error_messages.add_ons.failed_to_fetch_charges') as string,
      );
    };

    let shouldFetchNextPage = true;
    let allAddOnCharges: AddOnCharge_Read[] = [];

    const params: PaginationQuery = {
      'page[limit]': addOnsStore.pagination.limit,
    };

    while (shouldFetchNextPage) {
      const { data, pagination } = await fetchAddOnChargesPage(params);

      allAddOnCharges = [...allAddOnCharges, ...data];
      shouldFetchNextPage = Boolean(pagination.after);

      if (shouldFetchNextPage) {
        params['page[after]'] = pagination.after;
      }
    }

    const parsedAddOnCharges = allAddOnCharges.map((addOnCharge) =>
      AddOnCharge.parse(addOnCharge),
    );
    addOnsStore.setAddOnChargesByInvoiceId(invoiceId, parsedAddOnCharges);

    setIsLoading(false);

    return parsedAddOnCharges;
  };

  const updateAddOn = async (addOnId: string, data: AddOnDTO) => {
    try {
      setIsUpdating(true);

      const response = await connection.patch<AddOn_Read>(
        `${API_VERSION}/add_ons/${addOnId}`,
        AddOn.deparseUpdate(data),
        {},
        $t('error_messages.add_ons.failed_to_update') as string,
      );

      const updatedAddOn = AddOn.parse(response);
      addOnsStore.updateAddOn(updatedAddOn);

      return updatedAddOn;
    } finally {
      setIsUpdating(false);
    }
  };

  const batchUpdateAddOnChargesOfSettlements = async (
    settlementId: string,
    addOns: AddOnChargeDTO[],
  ) => {
    try {
      setIsUpdating(true);

      const payload = {
        add_on_charges: addOns.map((addOn) => {
          return AddOnCharge.deparse(addOn);
        }),
      };

      const response = await connection.patch<AddOnCharge_Read[]>(
        `${API_VERSION}/settlements/${settlementId}/add_on_charges`,
        payload,
        {},
        $t('error_messages.add_ons.failed_to_update') as string,
      );
      const updatedAddOnCharges = response.map((addOnCharge) => {
        return AddOnCharge.parse(addOnCharge);
      });
      settlementsStore.setAddOnChargesBySettlementId(settlementId, updatedAddOnCharges);

      return updatedAddOnCharges;
    } finally {
      setIsUpdating(false);
    }
  };
  const getAllAddOnChargesByDriverDay = async (driverDayId: string) => {
    setIsLoading(true);

    const getPage = (params: GetAddOnChargesByJobQueryParams) => {
      return connection.getPaginated<AddOnCharge_Read>(
        `${API_VERSION}/driver_days/${driverDayId}/add_on_charges`,
        { params: { ...params, 'page[limit]': 25 } },
        $t('error_messages.add_ons.failed_to_fetch_charges') as string,
      );
    };

    let addOnCharges: AddOnCharge[] = [];
    let shouldFetchNextPage = true;
    const params: GetAddOnChargesByJobQueryParams = {};

    try {
      while (shouldFetchNextPage) {
        const response = await getPage(params);

        const parsedAddOnCharges = response.data.map((addOnCharge) => {
          return AddOnCharge.parse(addOnCharge);
        });
        addOnCharges = addOnCharges.concat(parsedAddOnCharges);

        shouldFetchNextPage = Boolean(response.pagination.after);

        if (shouldFetchNextPage) {
          params['page[after]'] = response.pagination.after;
        }
      }
      addOnsStore.setAddOnChargesByDriverDayId(driverDayId, addOnCharges);
    } finally {
      setIsLoading(false);
    }
  };
  const batchUpdateAddOnChargesByDriverDay = async (
    driverDayId: string,
    addOns: AddOnChargeDTO[],
  ) => {
    setIsUpdating(true);
    try {
      const response = await patchV1DriverDaysDriverDayIdAddOnCharges({
        path: { 'driver-day-id': driverDayId },
        body: {
          add_on_charges: addOns.map((addOn) => {
            return AddOnCharge.deparse(addOn);
          }),
        },
      });

      const updatedAddOnCharges = response.data.data.map((addOnCharge) => {
        return AddOnCharge.parse(addOnCharge);
      });
      addOnsStore.setAddOnChargesByDriverDayId(driverDayId, updatedAddOnCharges);
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.add_ons.failed_to_update') as string,
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const batchUpdateAddOnChargesByJob = async (
    jobId: string,
    addOns: AddOnChargeDTO[],
  ) => {
    setIsUpdating(true);
    try {
      const response = await patchV1JobsJobIdAddOnCharges({
        path: { 'job-id': jobId },
        body: {
          add_on_charges: addOns.map((addOn) => {
            return AddOnCharge.deparse(addOn);
          }),
        },
      });

      const updatedAddOnCharges = response.data.data.map((addOnCharge) => {
        return AddOnCharge.parse(addOnCharge);
      });

      addOnsStore.setAddOnChargesByJobId(jobId, updatedAddOnCharges);
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.add_ons.failed_to_update') as string,
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const getAllAddOnChargesByJob = async (jobId: string) => {
    setIsLoading(true);

    const getPage = (params: GetAddOnChargesByJobQueryParams) => {
      return connection.getPaginated<AddOnCharge_Read>(
        `${API_VERSION}/jobs/${jobId}/add_on_charges`,
        { params: { ...params, 'page[limit]': 25 } },
        $t('error_messages.add_ons.failed_to_fetch_charges') as string,
      );
    };

    let addOnCharges: AddOnCharge[] = [];
    let shouldFetchNextPage = true;
    const params: GetAddOnChargesByJobQueryParams = {};

    try {
      while (shouldFetchNextPage) {
        const response = await getPage(params);

        const parsedAddOnCharges = response.data.map((addOnCharge) => {
          return AddOnCharge.parse(addOnCharge);
        });
        addOnCharges = addOnCharges.concat(parsedAddOnCharges);

        shouldFetchNextPage = Boolean(response.pagination.after);

        if (shouldFetchNextPage) {
          params['page[after]'] = response.pagination.after;
        }
      }

      addOnsStore.setAddOnChargesByJobId(jobId, addOnCharges);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createSettlementAddOnCharges,
    batchUpdateAddOnCharges,
    batchUpdateAddOnChargesByDriverDay,
    batchUpdateAddOnChargesByJob,
    batchUpdateAddOnChargesOfSettlements,
    createAddOn,
    getAddOns,
    getAllAddOnChargesByJob,
    getAllAddOns,
    getAllInvoiceAddOnCharges,
    getAllAddOnChargesByDriverDay,
    isCreating,
    isLoading,
    isUpdating,
    updateAddOn,
  };
};

export default useAddOns;
