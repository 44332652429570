import { Subscription } from '@rails/actioncable';
import {
  BulkSms_Create,
  getV1JobsJobIdJobEvents,
  GetV1JobsJobIdJobEventsData,
  getV1JobsJobIdTimeline,
  Job_Bulk_Update,
  Job_Read,
  JobAssignment_Read,
  JobEvent_Read,
  JobState,
  Load_LoadSummary_Read,
  Load_Read,
  patchV1JobsBulkUpdate,
  patchV1LoadsId,
  putV1JobsBulkDriverSms,
  UserBulkAssignJobsChannel_Read,
  UserBulkSendJobsChannel_Read,
} from '@treadinc/horizon-api-spec';
import { LoadCycle_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { chunk, get } from 'lodash';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import connection from '~services/connectionModule';
import { extractPagination, PaginationLink, PaginationQuery } from '~services/pagination';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { subscribeToChannel } from '~utils/rtu/rtu-utils';

import {
  Job,
  JobAssignment,
  JobLoad,
  JobLoadCycle,
  JobLoadSummary,
  JobTimeline,
  JobTripEvent,
} from './models';

export type JobEventType =
  | 'request'
  | 'accept'
  | 'reject'
  | 'enroute'
  | 'arrive'
  | 'load_material'
  | 'unload_material'
  | 'complete'
  | 'unassign'
  | 'cancel'
  | 'sign_off';

export interface LoadOptionsProps {
  quantity: number | string;
  unitOfMeasure: ItemNameAndId;
  id?: string;
}

interface LoadCycleDetails {
  load_cycles: LoadCycle_Read[];
  load_cycle_time_minutes_avg: number;
}

interface JobTripEventsParams {
  id: string;
  link?: PaginationLink;
  limit?: number;
}

interface JobLoadProps {
  loadId: string;
  callback?: (load: JobLoad) => void;
}

type BulkAssignJobsChannelReceivedCallback = (
  responsse: UserBulkAssignJobsChannel_Read,
) => void;

type BulkSendJobsChannelReceivedCallback = (resp: UserBulkSendJobsChannel_Read) => void;

type GetJobsLoadsQueryParams = { 'page[after]'?: string };

export const onlyTimeLineOptions = [
  JobState.TO_PICKUP,
  JobState.ARRIVED_PICKUP,
  JobState.LOADED,
  JobState.TO_DROPOFF,
  JobState.ARRIVED_DROPOFF,
  JobState.UNLOADED,
  JobState.COMPLETED,
];

export const useJob = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isSendingSms, setIsSendingSms] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();

  const { jobStore, userStore, toasterStore } = useStores();
  const { addSearchHeaderParam } = useDataGridSearch();

  const subscribeToJobUpdates = () => {
    const companyId = userStore.userCompany?.id;
    subscribeToChannel(
      realTimeChannels.CompanyJobUpdateChannel,
      companyId,
      setSubscription,
      (resp: { data: Job_Read }) => {
        //This message is broadcast to all client including the one who sent the request, will trigger 2*render
        const job = Job.parse(resp?.data);

        // If not last page
        if (!jobStore.pagination.after?.length) {
          jobStore.addJob(job);
        } else {
          jobStore.updateJob(job);
        }
      },
    );
  };

  const subscribeToBulkAssignJobsUpdates = (
    updateReceived: BulkAssignJobsChannelReceivedCallback,
  ) => {
    const companyId = userStore.userCompany?.id;
    subscribeToChannel(
      realTimeChannels.UserBulkAssignJobsChannel,
      companyId,
      setSubscription,
      (response: UserBulkAssignJobsChannel_Read) => {
        updateReceived(response);
      },
    );
  };

  const subscribeToBulkSendJobsUpdates = (
    updateReceived: BulkSendJobsChannelReceivedCallback,
  ) => {
    const companyId = userStore.userCompany?.id;
    subscribeToChannel(
      realTimeChannels.UserBulkSendJobsChannel,
      companyId,
      setSubscription,
      (resp: UserBulkSendJobsChannel_Read) => {
        updateReceived(resp);
      },
    );
  };

  const getAllJobs = (
    link?: PaginationLink,
    searchQuery?: string,
    filterParams?: Record<string, any>,
    callBack?: (jobs: Job[]) => void,
  ): Promise<Job[]> => {
    setIsLoading(true);
    let params: PaginationQuery = {
      'page[limit]': jobStore.pagination.limit,
    };

    if (link && jobStore.pagination[link]) {
      params[`page[${link}]`] = jobStore.pagination[link];
    }

    params = addSearchHeaderParam({
      searchValue: searchQuery,
      filterParams,
      params,
    });

    // Status keeps track of the current order or job filter status, but we don't want to send it to api
    delete params['filter[status]'];

    return connection
      .getPaginated<Job_Read>(
        `${API_VERSION}/jobs`,
        { params },
        $t('error_messages.jobs.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted = data.map(Job.parse);
        jobStore.setJobs(formatted);
        jobStore.setPagination(pagination);
        jobStore.updatePageNumber(link);
        callBack?.(formatted);
        return formatted; // Return the formatted jobs
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getJobById = (id: string) => {
    setIsLoading(true);
    return connection
      .get<Job_Read>(
        `${API_VERSION}/jobs/${id}`,
        {},
        $t('error_messages.jobs.failed_to_fetch_individual_job') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        jobStore.addJob(formatted);
        return formatted;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Fetches a full list of jobs
   *
   * @param jobsIds - The IDs ot the jobs to fetch.
   * @returns An array with the fetched jobs.
   */
  const getJobsByJobIds = async (jobIds: string[]): Promise<Job[]> => {
    // Capped to 25 jobs, need to be aware of the GET url length
    const batchSize = 25;
    const batches = chunk(jobIds, batchSize);

    const fetchJobsPage = (params: PaginationQuery) => {
      return connection.getPaginated<Job_Read>(
        `${API_VERSION}/jobs`,
        {
          params,
        },
        $t('error_messages.jobs.failed_to_fetch') as string,
      );
    };

    const fetchJobsBatch = async (ids: string[]) => {
      const params: PaginationQuery = { ids, 'page[limit]': batchSize };

      let shouldFetchNextPage = true;
      let allJobsInPage: Job_Read[] = [];

      while (shouldFetchNextPage) {
        const { data, pagination } = await fetchJobsPage(params);

        allJobsInPage = [...allJobsInPage, ...data];
        shouldFetchNextPage = Boolean(pagination.after);

        if (shouldFetchNextPage) {
          params['page[after]'] = pagination.after;
        }
      }

      return allJobsInPage;
    };

    let currentBatchIndex = 0;
    let currentJobsBatch = batches[currentBatchIndex];
    let allJobs: Job_Read[] = [];
    let shouldFetchNextBatch = true;

    while (shouldFetchNextBatch) {
      const jobsInBatch = await fetchJobsBatch(currentJobsBatch);

      allJobs = [...allJobs, ...jobsInBatch];
      currentBatchIndex += 1;
      currentJobsBatch = batches[currentBatchIndex];
      shouldFetchNextBatch = Boolean(currentJobsBatch);
    }

    const parsedJobs = allJobs.map((job) => Job.parse(job));
    jobStore.setBatchedJobs(parsedJobs);

    return parsedJobs;
  };

  const getJobsByOrder = (
    orderId: string,
    searchQuery?: string,
    filterParams?: Record<string, any>,
  ) => {
    setIsLoading(true);
    let params: PaginationQuery = {
      'page[limit]': jobStore.pagination.limit,
    };

    // Add search and filter params
    params = addSearchHeaderParam({
      searchValue: searchQuery,
      filterParams,
      params,
    });

    // Status keeps track of the current order or job filter status, but we don't want to send it to api
    delete params['filter[status]'];

    const url = `${API_VERSION}/orders/${orderId}/jobs`;
    return connection
      .get<Job_Read[]>(
        url,
        { params },
        $t('error_messages.jobs.failed_to_fetch') as string,
      )
      .then((data) => {
        const formatted = data.map(Job.parse);
        jobStore.setJobsByOrder(orderId, formatted);
        return formatted; // Return the formatted jobs
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cloneJob = (id: string) => {
    setIsUpdating(true);
    return connection
      .post<Job_Read>(
        `${API_VERSION}/jobs/${id}/copy`,
        {},
        {},
        $t('error_messages.jobs.failed_to_duplicate') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);

        // If not last page
        if (!jobStore.pagination.after?.length) {
          jobStore.addJob(formatted);
        }
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const updateJob = (id: string, data: any, inline?: boolean) => {
    setIsUpdating(true);
    const deparsedModel = inline
      ? Job.deparseUpdateFromInlineMode(data)
      : Job.deparseUpdate(data);
    return connection
      .patch<Job_Read>(
        `${API_VERSION}/jobs/${id}`,
        deparsedModel,
        {},
        $t('error_messages.jobs.failed_to_update') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        jobStore.updateJob(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const removeJob = (id: string) => {
    setIsUpdating(true);

    return connection
      .delete(
        `${API_VERSION}/jobs/${id}`,
        {},
        $t('error_messages.jobs.failed_to_delete') as string,
      )
      .then(() => {
        jobStore.removeJob(id);
        return id;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const doEvent = (id: string, event: JobEventType, data?: any) => {
    setIsUpdating(true);

    return connection
      .put<Job_Read>(
        `${API_VERSION}/jobs/${id}/${event}`,
        data || undefined,
        {},
        $t('error_messages.jobs.failed_to_do_event', {
          event,
        }) as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        jobStore.updateJob(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const assignDriver = (jobId: string, driverId: string) => {
    setIsUpdating(true);
    return connection
      .patch<Job_Read>(
        `${API_VERSION}/jobs/${jobId}`,
        {
          driver_id: driverId,
        },
        {},
        $t('error_messages.jobs.failed_to_assign_driver') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        jobStore.updateJob(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const unassignDriver = (jobId: string) => {
    setIsUpdating(true);
    return connection
      .patch<Job_Read>(
        `${API_VERSION}/jobs/${jobId}`,
        {
          driver_id: null,
        },
        {},
        $t('error_messages.jobs.failed_to_unassign_driver') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        jobStore.updateJob(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const assignVendor = (id: string, vendorId: string) => {
    setIsUpdating(true);
    return connection
      .put<Job_Read>(
        `${API_VERSION}/jobs/${id}/assign_vendor`,
        {
          vendor_id: vendorId,
        },
        {},
        $t('error_messages.jobs.failed_to_assign_vendor') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        jobStore.updateJob(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const getJobTimeline = async (jobId: string) => {
    try {
      setIsLoading(true);
      const response = await getV1JobsJobIdTimeline({ path: { 'job-id': jobId } });
      const timeline = response.data.data.map((item) => JobTimeline.parse(item));

      jobStore.setTimeline(jobId, timeline);

      return timeline;
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.jobs.failed_to_fetch_timeline') as string,
      );

      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const getJobTripEvents = async ({ id, link, limit }: JobTripEventsParams) => {
    const params: PaginationQuery = {
      'page[limit]': limit ?? jobStore.jobEventsPagination.limit,
    };

    if (link && jobStore.jobEventsPagination[link]) {
      params[`page[${link}]`] = jobStore.jobEventsPagination[link];
    }

    try {
      setIsLoading(true);
      const { data, pagination } = await connection.getPaginated<JobEvent_Read>(
        `${API_VERSION}/jobs/${id}/job_events`,
        { params },
        $t('error_messages.jobs.failed_to_fetch_job_events') as string,
      );
      const events = data.map((item) => JobTripEvent.parse(item));
      jobStore.setJobTripEvents(id, events);
      jobStore.setJobsEventsPagination(pagination);
      jobStore.updateJobEventsPageNumber(link);
      return events;
    } catch (error) {
      console.error(error);
      throw new Error($t('error_messages.jobs.failed_to_fetch_job_events') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllJobEvents = async (
    jobId: GetV1JobsJobIdJobEventsData['path']['job-id'],
  ) => {
    setIsLoading(true);

    const fetchJobEventsPage = (params: GetV1JobsJobIdJobEventsData) => {
      return getV1JobsJobIdJobEvents(params);
    };

    let shouldFetchNextPage = true;
    let allJobEvents: JobEvent_Read[] = [];

    const params: Required<Pick<GetV1JobsJobIdJobEventsData, 'query'>> = {
      query: { 'page[limit]': 100 },
    };

    while (shouldFetchNextPage) {
      try {
        const response = await fetchJobEventsPage({
          path: { 'job-id': jobId },
          ...params,
        });

        allJobEvents = [...allJobEvents, ...response.data.data];

        const pagination = extractPagination(response);
        shouldFetchNextPage = Boolean(pagination.after);

        if (shouldFetchNextPage) {
          params.query['page[after]'] = pagination.after;
        }
      } catch (error) {
        shouldFetchNextPage = false;

        console.error(error);
        connection.handleRequestError(
          error,
          $t('error_messages.jobs.failed_to_fetch_job_events') as string,
        );
      }
    }

    const parsedJobEvents = allJobEvents.map((event) => JobTripEvent.parse(event));

    setIsLoading(false);

    return parsedJobEvents;
  };

  const getJobAssignments = (jobId: string) => {
    const url = `${API_VERSION}/jobs/${jobId}/job_assignments`;

    return connection
      .get<
        JobAssignment_Read[]
      >(url, {}, $t('error_messages.jobs.failed_to_fetch_assignments') as string)
      .then((resp) => {
        const jobAssignments = resp.map((item) => JobAssignment.parse(item));
        jobStore.setJobAssignments(jobId, jobAssignments);

        return jobAssignments;
      });
  };
  const approveJob = (jobId: string) => {
    setIsUpdating(true);
    return connection
      .put<Job_Read>(
        `${API_VERSION}/jobs/${jobId}/approve`,
        {},
        {},
        $t('error_messages.jobs.failed_to_approve_job') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        jobStore.updateJob(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const unapproveJob = (jobId: string) => {
    setIsUpdating(true);
    return connection
      .put<Job_Read>(
        `${API_VERSION}/jobs/${jobId}/unapprove`,
        {},
        {},
        $t('error_messages.jobs.failed_to_unapprove_job') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        jobStore.updateJob(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  //Loads
  const getLoadByLoadId = async ({ loadId, callback }: JobLoadProps) => {
    try {
      setIsLoading(true);
      const response = await connection.get<Load_Read>(
        `${API_VERSION}/loads/${loadId}`,
        {},
        $t('error_messages.jobs.failed_to_fetch_load') as string,
      );
      const parsedData = JobLoad.parse(response);
      callback?.(parsedData);
      return parsedData;
    } catch (error) {
      console.error(error);
      throw new Error($t('error_messages.jobs.failed_to_fetch_load') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const addLoad = (jobId: string, options: LoadOptionsProps) => {
    setIsUpdating(true);
    return connection
      .post<Load_Read>(
        `${API_VERSION}/jobs/${jobId}/loads`,
        JobLoad.deparse(options),
        {},
        $t('error_messages.jobs.failed_to_add_load') as string,
      )
      .then((resp) => {
        return JobLoad.parse(resp);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const updateLoad = async (load: JobLoad) => {
    try {
      setIsUpdating(true);

      const response = await patchV1LoadsId({
        path: { id: load.id },
        body: JobLoad.deparseUpdate(load),
      });

      const updatedLoad = JobLoad.parse(response.data.data);

      return updatedLoad;
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.jobs.failed_to_update_job_load') as string,
      );

      return null;
    } finally {
      setIsUpdating(false);
    }
  };

  const removeLoad = (loadId: string) => {
    setIsUpdating(true);

    return connection
      .delete(
        `${API_VERSION}/loads/${loadId}`,
        {},
        $t('error_messages.jobs.failed_to_remove_load') as string,
      )
      .then(() => {})
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const getJobLoads = async (jobId: string) => {
    setIsLoading(true);

    const getPage = (params: GetJobsLoadsQueryParams) => {
      return connection.getPaginated<Load_LoadSummary_Read>(
        `${API_VERSION}/jobs/${jobId}/loads`,
        { params: { ...params, 'page[limit]': 25 } },
        $t('error_messages.jobs.failed_to_fetch_job_loads') as string,
      );
    };

    let loads: JobLoadSummary[] = [];
    let shouldFetchNextPage = true;
    const params: GetJobsLoadsQueryParams = {};

    try {
      while (shouldFetchNextPage) {
        const response = await getPage(params);

        const parsedLoads = response.data.map((load) => JobLoadSummary.parse(load));
        loads = loads.concat(parsedLoads);

        shouldFetchNextPage = Boolean(response.pagination.after);

        if (shouldFetchNextPage) {
          params['page[after]'] = response.pagination.after;
        }
      }

      return loads;
    } finally {
      setIsLoading(false);
    }
  };

  const getJobLoadCycles = (jobId: string) => {
    return connection
      .get<LoadCycleDetails>(
        `${API_VERSION}/jobs/${jobId}/loads/cycles`,
        {},
        $t('error_messages.jobs.failed_to_fetch_load_cycles') as string,
      )
      .then((resp) => {
        const loadCycles = get(resp, 'load_cycles', []).map((item) =>
          JobLoadCycle.parse(item),
        );
        return loadCycles;
      });
  };

  const bulkSendJobs = (jobIds: string[], orderIds: string[]) => {
    setIsUpdating(true);
    return connection
      .put<Job_Read>(
        `${API_VERSION}/jobs/bulk_send`,
        {
          job_ids: jobIds,
          order_ids: orderIds,
        },
        {},
        $t('error_messages.jobs.failed_to_bulk_send_jobs') as string,
      )
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const bulkAssignJobs = async (jobIds: string[], vendorAccountId: string) => {
    try {
      setIsUpdating(true);

      await connection.put<Job_Read>(
        `${API_VERSION}/jobs/bulk_assign`,
        {
          job_ids: jobIds,
          vendor_account_id: vendorAccountId,
        },
        {},
        $t('error_messages.jobs.failed_to_bulk_assign_jobs') as string,
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const approveLoad = (loadId: string) => {
    setIsUpdating(true);
    return connection
      .put<Load_Read>(
        `${API_VERSION}/loads/${loadId}/approve`,
        {},
        {},
        $t('error_messages.jobs.failed_to_approve_load') as string,
      )
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const unapproveLoad = (loadId: string) => {
    setIsUpdating(true);
    return connection
      .put<Load_Read>(
        `${API_VERSION}/loads/${loadId}/unapprove`,
        {},
        {},
        $t('error_messages.jobs.failed_to_unapprove_load') as string,
      )
      .finally(() => {
        setIsUpdating(false);
      });
  };

  /**
   * Creates a job from an order.
   *
   * @param orderId - The ID of the order.
   * @returns A promise that resolves to the formatted job object.
   */
  const createJobFromOrder = (orderId: string) => {
    setIsUpdating(true);
    return connection
      .post<Job_Read>(
        `${API_VERSION}/orders/${orderId}/jobs`,
        {},
        {},
        $t('error_messages.jobs.failed_to_create') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const sendSmsToDriver = async (jobId: string, message: string) => {
    try {
      setIsSendingSms(true);

      await connection.put(
        `${API_VERSION}/jobs/${jobId}/driver_sms`,
        { message },
        {},
        $t('error_messages.jobs.failed_to_send_text') as string,
      );
    } finally {
      setIsSendingSms(false);
    }
  };

  const bulkSendSmsToDrivers = async (args: BulkSms_Create) => {
    try {
      setIsSendingSms(true);

      await putV1JobsBulkDriverSms({ body: args });
    } catch {
      toasterStore.push(
        alert(
          $t('error_messages.jobs.failed_to_bulk_send_text_to_drivers'),
          AlertTypes.error,
        ),
      );
    } finally {
      setIsSendingSms(false);
    }
  };

  const bulkEditJobNotes = async (args: Job_Bulk_Update) => {
    try {
      setIsUpdating(true);

      await patchV1JobsBulkUpdate({ body: args });
    } catch {
      toasterStore.push(
        alert($t('error_messages.jobs.failed_to_bulk_edit_job_notes'), AlertTypes.error),
      );
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    addLoad,
    approveJob,
    approveLoad,
    assignDriver,
    assignVendor,
    bulkAssignJobs,
    bulkEditJobNotes,
    bulkSendJobs,
    bulkSendSmsToDrivers,
    cloneJob,
    createJobFromOrder,
    doEvent,
    getAllJobEvents,
    getAllJobs,
    getJobAssignments,
    getJobById,
    getJobLoadCycles,
    getJobLoads,
    getJobTimeline,
    getJobTripEvents,
    getJobsByJobIds,
    getJobsByOrder,
    getLoadByLoadId,
    isLoading,
    isSendingSms,
    isUpdating,
    removeJob,
    removeLoad,
    sendSmsToDriver,
    subscribeToBulkAssignJobsUpdates,
    subscribeToBulkSendJobsUpdates,
    subscribeToJobUpdates,
    subscription,
    unapproveJob,
    unapproveLoad,
    unassignDriver,
    updateJob,
    updateLoad,
  } as const;
};
