import { RouteEstimate_Create, RouteEstimate_Read } from '@treadinc/horizon-api-spec';

export type DeparsedRouteEstimate = {
  pickupSiteLat: number;
  pickupSiteLon: number;
  dropoffSiteLat: number;
  dropoffSiteLon: number;
};

export class RouteEstimate {
  constructor(
    private _pickup_to_drop_off_time_minutes: number,
    private _pickup_to_drop_off_distance_meters: number,
  ) {}

  public get pickupToDropOffTimeMinutes() {
    return this._pickup_to_drop_off_time_minutes;
  }

  public get pickupToDropOffDistanceMeters() {
    return this._pickup_to_drop_off_distance_meters;
  }

  public static parse(proto: RouteEstimate_Read) {
    return new RouteEstimate(
      proto.pickup_to_drop_off_time_minutes,
      proto.pickup_to_drop_off_distance_meters,
    );
  }

  public static deparse(proto: DeparsedRouteEstimate) {
    const body: RouteEstimate_Create = {
      pickup_site_lat: proto.pickupSiteLat,
      pickup_site_lon: proto.pickupSiteLon,
      drop_off_site_lat: proto.dropoffSiteLat,
      drop_off_site_lon: proto.dropoffSiteLon,
    };

    return body;
  }
}
