import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography, { TypographyOwnProps } from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-premium';
import { AddOnRateType, AddOnType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';

import { AddOn } from '~hooks/useAddOns';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';

const CellText = styled(Typography)<TypographyOwnProps>(() => ({
  '&.MuiTypography-root': {
    fontSize: '12px',
  },
}));

const rateTypeTextByAddOnRateType: Record<AddOnRateType, string> = {
  [AddOnRateType.RATE_FOR_EACH]: t('administration.rates.add_ons.rate_for_each'),
  [AddOnRateType.RATE_PERCENT_OF_TOTAL]: t(
    'administration.rates.add_ons.rate_percent_of_total',
  ),
};

const addOnTypeTextByAddOnType: Record<AddOnType, string> = {
  [AddOnType.DRIVER_PAY]: t('administration.rates.add_ons.add_on_type_driver_pay'),
  [AddOnType.INVOICING]: t('administration.rates.add_ons.add_on_type_invoicing'),
  [AddOnType.JOB]: t('administration.rates.add_ons.add_on_type_job'),
  [AddOnType.SETTLEMENT]: t('administration.rates.add_ons.add_on_type_settlement'),
  [AddOnType.DRIVER_DAY]: t('administration.rates.add_ons.add_on_type_driver_day'),
};

const formatAddOnTypes = (addOn: AddOn, translate?: boolean) => {
  const addOnTypes = translate
    ? addOn.addOnTypes.map((type) => addOnTypeTextByAddOnType[type])
    : addOn.addOnTypes.slice();

  return addOnTypes.sort().join(', ');
};

type CurrencyFormatterFn = ReturnType<typeof useCompanyCurrency>['currencyFormatter'];

type AddOnChargesRowDefUtils = {
  currencyFormatter: CurrencyFormatterFn;
  onEditButtonClick: (addOn: AddOn) => void;
};

export type AddOnChargesRowDef = {
  row: {
    id: string;
    addOn: AddOn;
    utils: AddOnChargesRowDefUtils;
  };
};

export const descriptionColDef: GridColDef = {
  flex: 1,
  field: 'name',
  headerName: `${t('common.name')}`,
  valueGetter: ({ row }: AddOnChargesRowDef) => row.addOn.name,
  renderCell: ({ row }: AddOnChargesRowDef) => {
    return <CellText>{row.addOn.name}</CellText>;
  },
};

export const defaultRateColDef: GridColDef = {
  flex: 1,
  field: 'rate',
  headerName: `${t('administration.rates.default_rate')}`,
  valueGetter: ({ row }: AddOnChargesRowDef) => row.addOn.rate,
  renderCell: ({ row }: AddOnChargesRowDef) => {
    return <CellText>{row.utils.currencyFormatter(row.addOn.rate)}</CellText>;
  },
};

export const rateTypeColDef: GridColDef = {
  flex: 1,
  field: 'addOnRateType',
  headerName: `${t('administration.rates.rate_type')}`,
  valueGetter: ({ row }: AddOnChargesRowDef) => row.addOn.addOnRateType,
  renderCell: ({ row }: AddOnChargesRowDef) => {
    return <CellText>{rateTypeTextByAddOnRateType[row.addOn.addOnRateType]}</CellText>;
  },
};

export const addOnTypesColDef: GridColDef = {
  flex: 1,
  field: 'addOnTypes',
  headerName: `${t('administration.rates.add_ons.add_on_types')}`,
  valueGetter: ({ row }: AddOnChargesRowDef) => formatAddOnTypes(row.addOn),
  renderCell: ({ row }: AddOnChargesRowDef) => {
    return <CellText>{formatAddOnTypes(row.addOn, true)}</CellText>;
  },
};

export const actionsColDef = (renderHeader: () => React.ReactNode): GridColDef => ({
  width: 96,
  field: 'actions',
  headerName: `${t('actions.actions')}`,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  hideable: false,
  renderHeader,
  renderCell: ({ row }: AddOnChargesRowDef) => {
    return (
      <Box width="100%" display="flex" justifyContent="center" alignItems="center">
        <IconButton size="small" onClick={() => row.utils.onEditButtonClick(row.addOn)}>
          <Edit />
        </IconButton>
      </Box>
    );
  },
});
