import { AccountType, Rate_Read, RateOwnerType } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import _ from 'lodash';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { Equipment } from '~hooks/useEquipment';
import { ProjectBasic } from '~hooks/useProjects/models';
import { Rate } from '~hooks/useRates/models';
import { Service } from '~hooks/useServices';
import { RatesFormSchemaInterface } from '~pages/Settings/RatesManagement/rateFormValidationSchema';
import {
  rateFilterOptions,
  rateFilterUsingOwnerTypeOptions,
} from '~pages/Settings/RatesManagement/rateUtils';
import connection from '~services/connectionModule';
import { Paginated, PaginationLink, PaginationQuery } from '~services/pagination';
import { useStores } from '~store';

interface CommonRateParams {
  id: string;
  callBack?: (rate?: Rate) => void;
}

interface UpdateRateParams {
  rate: RatesFormSchemaInterface;
  callBack?: (rate: Rate) => void;
  ownerType: RateOwnerType;
}
interface CreateRateParams {
  rate: RatesFormSchemaInterface;
  callBack?: (rate: Rate) => void;
  ownerType: RateOwnerType;
}
export interface CopyRateParams {
  accountId?: string;
  callBack?: (rate: Rate) => void;
  driverId?: string;
  equipment: Equipment[];
  projects: ProjectBasic[];
  rateId: string;
  rateName: string;
  services: Service[];
}
interface GetAccountRatesParams {
  link?: PaginationLink;
  accountId?: string;
  accountType?: AccountType;
  serviceId?: string;
  projectId?: string;
  equipmentId?: string;
  includeDefaultRates?: boolean;
  callBack: ({ data, pagination }: Paginated<Rate>) => void;
}

// @todo move from 'AccountType' to 'RateOwnerType' when querying rates, as it is deprecated
interface GetAccountRatesUsingOwnerTypeParams {
  accountId?: string;
  driverId?: string;
  equipmentId?: string;
  includeDefaultRates?: boolean;
  link?: PaginationLink;
  ownerType?: RateOwnerType;
  projectId?: string;
  serviceId?: string;
  cursor?: string;
  callback?: ({ data, pagination }: Paginated<Rate>) => void;
  query?: string;
  limit?: number;
}

export const useRates = () => {
  const [isLoadingRates, setIsLoadingRates] = useState<boolean>(false);
  const [isUpdatingRate, setIsUpdatingRate] = useState<boolean>(false);
  const [isCopyingRate, setIsCopyingRate] = useState<boolean>(false);
  const { companyAssetsStore, userStore } = useStores();

  /**
   * @deprecated Please use "getAccountRatesUsingOwnerType"
   */
  const getAccountRates = async ({
    link,
    accountId,
    accountType,
    equipmentId,
    projectId,
    serviceId,
    includeDefaultRates,
    callBack,
  }: GetAccountRatesParams) => {
    const params: PaginationQuery = {
      'page[limit]': companyAssetsStore.ratesPagination.limit,
      [rateFilterOptions.account]: accountId,
      [rateFilterOptions.accountType]: accountType || '',
      [rateFilterOptions.equipment]: equipmentId || '',
      [rateFilterOptions.project]: projectId || '',
      [rateFilterOptions.service]: serviceId || '',
      [rateFilterOptions.includeDefaultRates]: includeDefaultRates || false,
    };

    if (link && companyAssetsStore.ratesPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.ratesPagination[link];
    }
    setIsLoadingRates(true);
    await connection
      .getPaginated<Rate_Read>(
        `${API_VERSION}/rates`,
        { params },
        $t('error_messages.rates.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted = data.map(Rate.parse);
        callBack?.({ data: formatted, pagination });
      })
      .finally(() => {
        setIsLoadingRates(false);
      });
  };

  // @todo move from 'AccountType' to 'RateOwnerType' when querying rates, as it is deprecated
  const getAccountRatesUsingOwnerType = async ({
    accountId,
    driverId,
    equipmentId,
    includeDefaultRates,
    link,
    ownerType,
    projectId,
    serviceId,
    cursor,
    callback,
    query,
    limit,
  }: GetAccountRatesUsingOwnerTypeParams) => {
    const params: PaginationQuery = {
      'page[limit]': limit || companyAssetsStore.ratesPagination.limit,
      [rateFilterUsingOwnerTypeOptions.account]: accountId,
      [rateFilterUsingOwnerTypeOptions.driverId]: driverId || '',
      [rateFilterUsingOwnerTypeOptions.equipment]: equipmentId || '',
      [rateFilterUsingOwnerTypeOptions.includeDefaultRates]: includeDefaultRates || false,
      [rateFilterUsingOwnerTypeOptions.ownerType]: ownerType || '',
      [rateFilterUsingOwnerTypeOptions.project]: projectId || '',
      [rateFilterUsingOwnerTypeOptions.service]: serviceId || '',
    };

    if (cursor) {
      params['page[after]'] = cursor;
    } else if (link && companyAssetsStore.ratesPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.ratesPagination[link];
    }

    let url = `${API_VERSION}/rates`;
    const isTypeahead = _.isString(query) && query.length > 0;

    if (isTypeahead) {
      url = `${API_VERSION}/rates/typeahead`;
      params[rateFilterUsingOwnerTypeOptions.query] = query;
    }

    setIsLoadingRates(true);

    try {
      const response = await connection.getPaginated<Rate_Read>(
        url,
        {
          params,
        },
        $t('error_messages.rates.failed_to_fetch') as string,
      );
      const { data, pagination } = response;
      const formatted = data.map(Rate.parse);

      callback?.({ data: formatted, pagination });
      return { data: formatted, pagination };
    } finally {
      setIsLoadingRates(false);
    }
  };

  const getOneRate = async ({ id, callBack }: CommonRateParams) => {
    setIsLoadingRates(true);
    try {
      await connection
        .get<Rate_Read>(
          `${API_VERSION}/rates/${id}`,
          {},
          $t('error_messages.rates.failed_to_fetch_rate') as string,
        )
        .then((resp) => {
          const rate = Rate.parse(resp);
          companyAssetsStore.addRate(rate);
          callBack?.(rate);
        });
    } finally {
      setIsLoadingRates(false);
    }
  };

  const deleteRate = async ({ id, callBack }: CommonRateParams) => {
    setIsLoadingRates(true);
    try {
      await connection
        .delete(
          `${API_VERSION}/rates/${id}`,
          {},
          $t('error_messages.rates.failed_to_delete') as string,
        )
        .then(() => {
          companyAssetsStore.deleteRate(id);
          callBack?.();
        });
    } finally {
      setIsLoadingRates(false);
    }
  };
  const copyRate = async ({
    accountId,
    callBack,
    driverId,
    equipment,
    projects,
    rateId,
    rateName,
    services,
  }: CopyRateParams) => {
    setIsCopyingRate(true);
    try {
      await connection
        .post<Rate_Read>(
          `${API_VERSION}/rates/${rateId}/copy`,
          {
            account_id: accountId,
            driver_id: driverId,
            equipment_ids: equipment.map((item) => item.id),
            name: rateName,
            project_ids: projects.map((item) => item.id),
            service_ids: services.map((item) => item.id),
          },
          {},
          $t('error_messages.rates.failed_to_copy') as string,
        )
        .then((resp) => {
          const rate = Rate.parse(resp);
          callBack?.(rate);
        });
    } finally {
      setIsCopyingRate(false);
    }
  };
  const updateRate = async ({ rate, callBack, ownerType }: UpdateRateParams) => {
    setIsUpdatingRate(true);
    try {
      await connection
        .patch<Rate_Read>(
          `${API_VERSION}/rates/${rate?.id}`,
          Rate.deparseUpdate(rate, ownerType),
          {},
          $t('error_messages.rates.failed_to_update') as string,
        )
        .then((resp) => {
          const rate = Rate.parse(resp);
          companyAssetsStore.updateRate(rate);
          callBack?.(rate);
        });
    } finally {
      setIsUpdatingRate(false);
    }
  };
  const createRate = async ({ rate, callBack, ownerType }: CreateRateParams) => {
    setIsUpdatingRate(true);
    const rateToCreate = Rate.deparseCreate(
      rate,
      rate.company.id || userStore.userCompany.id,
      ownerType,
    );
    try {
      await connection
        .post<Rate_Read>(
          `${API_VERSION}/rates`,
          rateToCreate,
          {},
          $t('error_messages.rates.failed_to_create') as string,
          // Set default user company id for rate, until we have other ask from Product
          // If non account, but company id, it will be a default company rate
        )
        .then((resp) => {
          const rate = Rate.parse(resp);
          companyAssetsStore.addRate(rate);
          callBack?.(rate);
        });
    } finally {
      setIsUpdatingRate(false);
    }
  };

  return {
    getAccountRates,
    getAccountRatesUsingOwnerType,
    getOneRate,
    deleteRate,
    updateRate,
    createRate,
    copyRate,
    isCopyingRate,
    isLoadingRates,
    isUpdatingRate,
  };
};
