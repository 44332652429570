import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

import { Job } from '~hooks/useJob';
import { Order } from '~hooks/useOrders';
import theme from '~theme/AppTheme';

import OrderList from './SideBarComponents/OrderList';
import TreadLiveTopBar from './TreadLiveTopBar';

interface TreadLiveSideBarProps {
  onSelectJob: (job: Job) => void;
  onSelectOrder: (order: Order | undefined) => void;
  selectedJob?: Job;
  selectedOrder?: Order;
  sx?: SxProps;
  onViewJobRoute: (job: Job) => void;
  isSidebarCollapsed?: boolean;
}

const TreadLiveSideBar = observer(
  ({
    onSelectJob,
    onSelectOrder,
    selectedJob,
    selectedOrder,
    onViewJobRoute,
    isSidebarCollapsed,
  }: TreadLiveSideBarProps) => {
    return (
      <Box height={'100%'} borderRight={`solid 1px ${theme.brandV2.colors.treadGray7}`}>
        <TreadLiveTopBar isSidebarCollapsed={isSidebarCollapsed} />

        <OrderList
          onSelectJob={onSelectJob}
          onSelectOrder={onSelectOrder}
          selectedJob={selectedJob}
          selectedOrder={selectedOrder}
          onViewJobRoute={onViewJobRoute}
          isSidebarCollapsed={isSidebarCollapsed}
        />
      </Box>
    );
  },
);

export default TreadLiveSideBar;
