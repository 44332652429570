import Grid from '@mui/material/Grid';
import { AccountType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { AccountTypeahead, useAccount } from '~hooks/useAccount';
import { ProjectState, useProjects } from '~hooks/useProjects';

interface OrderCustomerDetailsV2Props {
  companyId?: string;
}

export default function OrderCustomerDetailsV2({
  companyId,
}: OrderCustomerDetailsV2Props) {
  const form = useFormContext();

  const { getAccountsByCompanyIdTypeahead } = useAccount();
  const { getProjectsByCompanyIdTypeahead } = useProjects();

  const watchedAccount = form.watch('account') as AccountTypeahead | null;

  const fetchAccountsByCompanyId = useCallback(
    async (additionalProps = {}) => {
      if (!companyId) {
        return [];
      }

      return await getAccountsByCompanyIdTypeahead({
        companyId,
        accountTypes: [AccountType.CUSTOMER],
        ...additionalProps,
      });
    },
    [companyId],
  );

  const fetchProjectsByCompanyId = useCallback(
    async (additionalProps = {}) => {
      if (!companyId) {
        return [];
      }

      return await getProjectsByCompanyIdTypeahead({
        companyId: companyId,
        states: [ProjectState.ACTIVE],
        accountId: watchedAccount?.id,
        ...additionalProps,
      });
    },
    [companyId, watchedAccount?.id],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <AutocompleteAsyncFormField
          asyncCallback={fetchAccountsByCompanyId}
          control={form.control}
          disabled={!companyId}
          errors={form.formState.errors}
          getLabel={(item) => item.name || ''}
          getValue={(item) => item.id}
          isRequired
          label={`${t('dispatch.order.customer_account')}`}
          name="account"
          onSelect={(option) => {
            form.setValue('project', null);

            return option;
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteAsyncFormField
          asyncCallback={fetchProjectsByCompanyId}
          clearable
          control={form.control}
          disabled={!companyId}
          errors={form.formState.errors}
          getLabel={(item) => item.name || ''}
          getValue={(item) => item.id}
          label={`${t('order.form.associated_project_name')}`}
          name="project"
          onClear={() => form.setValue('project', null)}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('order.form.order_name')}`}
          name="name"
        />
      </Grid>

      <Grid item xs={6}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('order.form.dispatch_number_full')}`}
          name="dispatchNumber"
        />
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('order.form.order_po')}`}
          name="poJobNumber"
        />
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('order.form.order_zone')}`}
          name="zone"
        />
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('form_fields.external_id')}`}
          name="externalId"
        />
      </Grid>
    </Grid>
  );
}
