import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AccountTypeahead } from '~hooks/useAccount';
import { TypeaheadOptions } from '~hooks/useAgave/useAgave';
import { Nullable } from '~types/Nullable';

const dataGridSelectorSchema = yup.object().shape({
  item: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable(),
});

type DataGridSelectorDTO = yup.InferType<typeof dataGridSelectorSchema>;

interface DataGridSelectorProps {
  defaultValue: Nullable<{
    id: string;
    name: string;
  }>;
  linkedAccountId: string;
  disabled?: boolean;
  asyncTypeahead: (options: TypeaheadOptions) => void;
  onChange: (data: Nullable<AccountTypeahead>) => Promise<void>;
}

export const DataGridSelector = observer(
  ({
    defaultValue,
    linkedAccountId,
    asyncTypeahead,
    onChange,
    disabled,
  }: DataGridSelectorProps) => {
    const {
      control,
      setValue,
      formState: { errors },
    } = useForm<DataGridSelectorDTO>({
      resolver: yupResolver(dataGridSelectorSchema),
      reValidateMode: 'onChange',
      defaultValues: {
        item: defaultValue,
      },
    });

    const onChangeHandler = async (data: Nullable<AccountTypeahead>) => {
      await onChange(data ?? null);
      setValue('item', data ?? null);
      return data ? data : null;
    };

    return (
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
        <AutocompleteAsyncFormField
          control={control}
          errors={errors}
          placeholder={t('select') as string}
          disabled={disabled}
          name={'item'}
          getValue={(item) => item?.id || ''}
          getLabel={(item) => item?.name || ''}
          asyncCallback={asyncTypeahead}
          extraRequestOptions={{
            linkedAccountId,
            pagination: {
              limit: 10,
            },
          }}
          filterSelectedOptions={false}
          clearable
          hideLabel
          disablePortal={false}
          onSelect={onChangeHandler}
          onClear={() => onChangeHandler(null)}
        />
      </Box>
    );
  },
);
