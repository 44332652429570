import { outlinedInputClasses } from '@mui/material';
import Grid from '@mui/material/Grid';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { CheckBoxFormField } from '~components/FormFields/CheckBoxFormField';
import { SingleDateTimeFormField } from '~components/FormFields/SingleDateTimeFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { minutesToFormattedDuration } from '~utils/dateTime';
import { nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

import { Hint, requiredForEstimatesInputAdornment } from './shared-components';

export default function DateAndTime() {
  const form = useFormContext();

  const watchedJobStartSameAsOrderStart = form.watch('jobStartSameAsOrderStart');
  const watchedJobStartAt = form.watch('jobStartAt') as string | Dayjs;
  const watchedJobTime = form.watch('jobTime') as string | number | null;

  const formattedJobTime = useMemo(() => {
    if (nullableStringOrNumberIsValidNumber(watchedJobTime)) {
      const duration = parseFloat(String(watchedJobTime));

      if (duration >= 0) {
        return minutesToFormattedDuration(duration * 60);
      }
    }

    return null;
  }, [watchedJobTime]);

  useEffect(() => {
    const isValid = nullableStringOrNumberIsValidNumber(watchedJobTime);

    if (isValid && dayjs.isDayjs(watchedJobStartAt)) {
      const duration = parseFloat(String(watchedJobTime));

      if (duration >= 0) {
        const jobEndTime = watchedJobStartAt.add(duration * 60, 'minutes');

        form.setValue('jobStartAtEndTime', jobEndTime.format('hh:mm A'));
      }
    } else {
      form.setValue('jobStartAtEndTime', null);
    }
  }, [watchedJobTime, watchedJobStartAt]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SingleDateTimeFormField
          control={form.control}
          dateLabel={`${t('order.form.start_date')}`}
          errors={form.formState.errors}
          isRequired
          name="loadAt"
          timeLabel={`${t('order.form.start_time')}`}
        />
      </Grid>

      <Grid item xs={6}>
        <CheckBoxFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('dispatch.order.order_details_v2.same_start_time')}`}
          name="jobStartSameAsOrderStart"
          value={true}
          sx={{ pt: '18px' }}
        />
      </Grid>

      <Grid item xs={6}>
        <SingleDateTimeFormField
          control={form.control}
          dateLabel={`${t('order.form.job_start_date')}`}
          disabled={watchedJobStartSameAsOrderStart}
          errors={form.formState.errors}
          isRequired={!watchedJobStartSameAsOrderStart}
          name="jobStartAt"
          timeLabel={`${t('order.form.job_start_time')}`}
        />
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          control={form.control}
          disabled
          errors={form.formState.errors}
          label={`${t('order.form.job_end_time')}`}
          name="jobStartAtEndTime"
          sx={{
            [`& .${outlinedInputClasses.input}`]: { fontSize: '12px', height: '23px' },
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          InputProps={{ endAdornment: requiredForEstimatesInputAdornment.component }}
          control={form.control}
          errors={form.formState.errors}
          label={`${t('dispatch.order.order_details_v2.truck_duration')}`}
          name="jobTime"
          type="number"
          sx={{
            [`& .${outlinedInputClasses.input}`]: { fontSize: '12px', height: '23px' },
            ...requiredForEstimatesInputAdornment.textFormFieldSx,
          }}
        />
        <Hint isLarge>
          {formattedJobTime
            ? t('dispatch.order.hrs_minutes', { value: formattedJobTime })
            : null}
        </Hint>
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('form_fields.truck_stagger')}`}
          name="truckDelay"
          type="number"
        />
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('form_fields.trucks_per_stagger')}`}
          name="trucksPerDelay"
          type="number"
        />
      </Grid>
    </Grid>
  );
}
