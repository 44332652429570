import { FileAttachment_Read, Principal_Read_Nested } from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { Nullable } from '~types/Nullable';

export class FileAttachment {
  public static parse(proto: FileAttachment_Read): FileAttachment {
    return new FileAttachment(
      dayjs(proto.created_at) ?? null,
      dayjs(proto.updated_at) ?? null,
      proto.category ?? null,
      proto.description ?? null,
      proto.file_attachable_id ?? null,
      (proto.file_attachable_type as FileAttachment_Read.file_attachable_type) ?? null,
      proto.file_url ?? null,
      proto.file_name ?? null,
      proto.id ?? null,
      proto.mime_type ?? null,
      proto.uploaded_by ?? null,
      proto.editable,
      proto.deletable,
    );
  }

  public get createdAt(): Dayjs {
    return this._created_at;
  }

  public get updatedAt(): Dayjs {
    return this._updated_at;
  }

  public get category(): Nullable<string> {
    return this._category;
  }

  public get description(): Nullable<string> {
    return this._description;
  }

  public get fileAttachableId(): string {
    return this._file_attachable_id;
  }

  public get fileAttachableType(): FileAttachment_Read.file_attachable_type {
    return this._file_attachable_type;
  }

  public get fileUrl(): string {
    return this._file_url;
  }

  public get fileName(): string {
    return this._file_name;
  }

  public get id(): string {
    return this._id;
  }

  public get mimeType(): string {
    return this._mime_type;
  }

  public get uploadedBy(): Principal_Read_Nested {
    return this._uploaded_by;
  }

  public get editable(): boolean {
    return this._editable;
  }

  public get deletable(): boolean {
    return this._deletable;
  }

  constructor(
    public _created_at: Dayjs,
    public _updated_at: Dayjs,
    public _category: Nullable<string>,
    public _description: Nullable<string>,
    public _file_attachable_id: string,
    public _file_attachable_type: FileAttachment_Read.file_attachable_type,
    public _file_url: string,
    public _file_name: string,
    public _id: string,
    public _mime_type: string,
    public _uploaded_by: Principal_Read_Nested,
    public _editable: boolean,
    public _deletable: boolean,
  ) {}
}
