import Box, { BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography, { TypographyProps } from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';

import { GridCardWrapper } from '~components/Cards/GridCardWrapper';
import { SimpleCard } from '~components/Cards/SimpleCard';
import { JobAuditLog } from '~components/Job/AuditDetails/JobAuditLog';
import { JobLoadTimeline } from '~components/Job/AuditDetails/JobLoadTimeline';
import JobTimeline from '~components/Job/JobTimeline';
import Loads from '~components/Job/Loads';
import { ROUTE_MAP_HEIGHT_IN_PX, RouteMap } from '~components/Job/RouteMap';
import { SmallTabs } from '~components/Tabs/SmallTabs';
import { FeatureFlags } from '~constants/featureFlags';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { Job } from '~hooks/useJob';
import theme from '~theme/AppTheme';
import { useAwaitableFeatureFlag } from '~utils/hooks/useFeatureFlag';

interface Props {
  details: Job;
  reload: () => void;
}

const LoadDetails = ({ details, reload }: Props) => {
  const unifiedEventTimelineFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.unifiedEventTimeline,
  });
  const shoudShowNewUnifiedEventTimeline =
    unifiedEventTimelineFeatureFlag.isReady &&
    unifiedEventTimelineFeatureFlag.isFeatureFlagEnabled;

  const loadsTableNodeRef = useRef<HTMLDivElement>();
  const [timelineContainerHeight, setTimelineContainerHeight] =
    useState(ROUTE_MAP_HEIGHT_IN_PX);

  useEffect(() => {
    if (!loadsTableNodeRef.current) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      const [loadsContainer] = entries;

      if (loadsContainer) {
        const height = loadsContainer.borderBoxSize[0].blockSize;

        if (height > ROUTE_MAP_HEIGHT_IN_PX) {
          setTimelineContainerHeight(height);
        }
      }
    });

    observer.observe(loadsTableNodeRef.current);

    return () => {
      observer.disconnect();
    };
  }, [loadsTableNodeRef.current]);

  if (shoudShowNewUnifiedEventTimeline) {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '12fr 7fr 8fr',
          gap: 2.5,
        }}
      >
        <Box>
          <Loads
            loadsTableNodeRef={loadsTableNodeRef}
            details={details}
            reload={reload}
          />
        </Box>

        <Container>
          <Title>{t('dispatch.timeline.timeline')}</Title>

          <Box
            sx={{
              flex: 1,
              minHeight: `${ROUTE_MAP_HEIGHT_IN_PX}px`,
              maxHeight: `${timelineContainerHeight}px`,
            }}
          >
            <JobTimeline
              job={details}
              sx={{
                border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                borderRadius: '4px',
                height: '100%',
                overflow: 'auto',
                pl: 1.5,
              }}
            />
          </Box>
        </Container>

        <Container>
          <Title>{t('dispatch.timeline.map_card_title')}</Title>

          <RouteMap job={details} />
        </Container>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <GridCardWrapper sx={{ border: 'none', pt: 1.5 }}>
            <Paper
              elevation={0}
              sx={{ flexGrow: 1, background: 'transparent', borderRadius: 0 }}
            >
              <Box sx={{ pb: 2, pr: 2 }}>
                <Loads details={details} reload={reload} />
              </Box>
            </Paper>
          </GridCardWrapper>
        </Grid>

        <Grid item xs={3.5}>
          <GridCardWrapper sx={{ border: 'none', m: -2, width: '100%' }}>
            <SimpleCard
              key={`loads__${details.id}}`}
              sx={{ flexGrow: 1, borderRadius: 0, pr: 0, mr: -2 }}
            >
              <Box>
                <RouteMap job={details} />
              </Box>
            </SimpleCard>
          </GridCardWrapper>
        </Grid>

        <Grid item xs={3.5}>
          <GridCardWrapper sx={{ border: 'none', m: -2, width: '100%' }}>
            <SimpleCard
              key={`jobEvents__${details.id}`}
              sx={{ flexGrow: 1, borderRadius: 0, pr: 0, mr: -2 }}
            >
              <SmallTabs
                sx={{ mr: -2 }}
                panelHeight={400}
                tabs={[
                  {
                    label: `${t('dispatch.timeline.job_audit_log')}`,
                    id: `details_${details.id}_events`,
                    content: (
                      <JobAuditLog jobId={details.id} orderId={details.order?.id} />
                    ),
                  },
                  {
                    label: `${t('dispatch.timeline.job_load_timeline')}`,
                    id: `details_${details.id}_timeline`,
                    content: <JobLoadTimeline id={details.id} sx={{ mx: -2, mt: -1 }} />,
                  },
                ]}
              />
            </SimpleCard>
          </GridCardWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

const Container = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: ROUTE_MAP_HEIGHT_IN_PX,
}));

const Title = styled(Typography)<TypographyProps>(() => ({
  color: theme.brandV2.colors.treadBlack,
  fontSize: '14px',
  fontWeight: 600,
  minHeight: `calc(${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px + ${theme.spacing(0.5)})`,
}));

export { LoadDetails };
