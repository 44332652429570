import { makeObservable, observable, runInAction } from 'mobx';

import { Project } from '~hooks/useProjects';
import { Pagination, PaginationLink } from '~services/pagination';
import { RootStore } from '~store/RootStore';
import { getPageNumber } from '~utils/pagination';

class ProjectStore {
  projects: Project[] = [];
  projectsPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      projects: observable,
      projectsPagination: observable,
    });
    this.rootStore = rootStore;
  }

  setProjectsPagination(pagination: Pagination) {
    this.projectsPagination = {
      ...this.projectsPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }

  updateProjectsPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.projectsPagination = {
        ...this.projectsPagination,
        page: getPageNumber(this.projectsPagination.page, link),
      };
    });
  }

  // Public get userInitials(): string {
  //   Return `${this.user?.firstName?.charAt(0)}${this.user?.lastName?.charAt(0)}` || '';
  // }

  setProjects(projects: Project[]) {
    this.projects = [...projects];
  }

  updateProject(project: Project) {
    this.projects = this.projects.map((p) => (p.id === project.id ? project : p));
  }

  deleteProject(id: string) {
    this.projects = this.projects.filter((pr) => pr.id !== id);
  }
  addAProject(project: Project) {
    this.projects = [...this.projects, project];
  }
}

export default ProjectStore;
