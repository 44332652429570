import { t } from 'i18next';
import * as yup from 'yup';

import {
  userEmailSchema,
  userPhoneSchema,
} from '~components/UserForm/userFormValidationSchema';
import { personNameSchema } from '~constants/regexConst';

export const driverFormValidationSchema = yup.object().shape({
  company: yup
    .object()
    .typeError(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    )
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    ),
  firstName: personNameSchema(`${t('form_fields.first_name')}`).required(
    `${t('form_validation_errors.required', { field: t('form_fields.first_name') })}`,
  ),
  lastName: personNameSchema(`${t('form_fields.last_name')}`).required(
    `${t('form_validation_errors.required', { field: t('form_fields.last_name') })}`,
  ),
  email: userEmailSchema
    .transform((val) => {
      if (!val?.length) {
        return undefined;
      }

      return val;
    })
    .notRequired()
    .when('phone', {
      is: (phone: string | undefined) => {
        return Boolean(!phone || phone.length === 0);
      },
      then: () => {
        return userEmailSchema.required(
          `${t('form_validation_errors.either_one_or_other', { one: t('form_fields.email'), other: t('form_fields.phone') })}`,
        );
      },
    }),
  phone: userPhoneSchema
    .transform((val) => {
      if (!val?.length) {
        return undefined;
      }

      return val;
    })
    .notRequired(),
  address: yup
    .object()
    .shape({
      streetAddress: yup.string().notRequired(),
    })
    .notRequired(),
  timeZone: yup.string().required(),
  notes: yup.string().max(
    350,
    `${t('form_validation_errors.max_length', {
      field: t('form_fields.notes'),
      max: 350,
    })}`,
  ),
  createEquipment: yup.boolean(),
  equipmentName: yup.string().when('createEquipment', {
    is: true,
    then: (schema) =>
      schema.required(
        `${t('form_validation_errors.required', { field: t('form_fields.equipment_name') })}`,
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
  equipmentType: yup.string().when('createEquipment', {
    is: true,
    then: (schema) =>
      schema.required(
        `${t('form_validation_errors.required', { field: t('form_fields.equipment_type') })}`,
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
  externalId: yup.string(),
  sharedId: yup.string(),
  licenseNumber: yup.string(),
});
