import {
  InvoiceCategoryFilter,
  InvoiceState,
  RateType,
} from '@treadinc/horizon-api-spec';
import { makeObservable, observable, runInAction } from 'mobx';

import { Invoice } from '~hooks/useInvoices';
import { Pagination, PaginationLink } from '~services/pagination';
import { getPageNumber } from '~utils/pagination';

export enum InvoiceSearchParamName {
  END_DATE = 'filter[end_date]',
  START_DATE = 'filter[start_date]',
  INVOICE_STATES = 'filter[states]',
  INVOICE_CATEGORY = 'filter[invoice_category]',
  INVOICEABLE_TYPE = 'filter[invoiceable_type]',
  GROUPED = 'filter[grouped]',
  PROJECTS = 'filter[project_ids]',
  CUSTOMERS = 'filter[customer_account_ids]',
  VENDORS = 'filter[vendor_account_ids]',
  TRUCKS = 'filter[truck_ids]',
  DRIVERS = 'filter[driver_ids]',
  RATE_TYPES = 'filter[rate_types]',
  PICKUP_SITE = 'filter[pickup_site_ids]',
  DROPOFF_SITE = 'filter[dropoff_site_ids]',
}

export interface InvoiceSearchParams {
  [InvoiceSearchParamName.END_DATE]?: string;
  [InvoiceSearchParamName.START_DATE]?: string;
  [InvoiceSearchParamName.INVOICE_STATES]?: Array<InvoiceState>;
  [InvoiceSearchParamName.INVOICE_CATEGORY]?: InvoiceCategoryFilter;
  [InvoiceSearchParamName.GROUPED]?: boolean;
  [InvoiceSearchParamName.PROJECTS]?: string[];
  [InvoiceSearchParamName.CUSTOMERS]?: string[];
  [InvoiceSearchParamName.VENDORS]?: string[];
  [InvoiceSearchParamName.TRUCKS]?: string[];
  [InvoiceSearchParamName.DRIVERS]?: string[];
  [InvoiceSearchParamName.DROPOFF_SITE]?: string[];
  [InvoiceSearchParamName.PICKUP_SITE]?: string[];
  [InvoiceSearchParamName.RATE_TYPES]?: RateType[];
}

export default class InvoicesStore {
  invoices: Invoice[] = [];

  pagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };

  searchParams: InvoiceSearchParams = {};

  constructor() {
    makeObservable(this, {
      invoices: observable,
      pagination: observable,
      searchParams: observable,
    });
  }

  addInvoice(invoice: Invoice) {
    runInAction(() => {
      this.invoices = [...this.invoices, invoice];
    });
  }

  setPagination(pagination: Pagination) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        before: pagination.before || '',
        after: pagination.after || '',
      };
    });
  }

  updatePageNumber(link: PaginationLink) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        page: getPageNumber(this.pagination.page, link),
      };
    });
  }

  setSearchParams(searchParams: InvoiceSearchParams) {
    runInAction(() => {
      this.searchParams = searchParams;
    });
  }

  setInvoices(invoices: Invoice[]) {
    runInAction(() => {
      this.invoices = [...invoices];
    });
  }

  updateInvoice(invoice: Invoice, upsert?: boolean) {
    runInAction(() => {
      const invoiceIndex = this.invoices.findIndex(({ id }) => id === invoice.id);

      if (invoiceIndex > -1) {
        const newInvoicesList = this.invoices.slice();

        newInvoicesList.splice(invoiceIndex, 1, invoice);
        this.setInvoices(newInvoicesList);
      } else if (upsert) {
        this.addInvoice(invoice);
      }
    });
  }
}
