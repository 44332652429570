import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { JobState } from '@treadinc/horizon-api-spec';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import TreadLiveMapV2 from '~components/Maps/v2/TreadLiveMapV2';
import { FeatureFlags } from '~constants/featureFlags';
import { Job } from '~hooks/useJob';
import { Order } from '~hooks/useOrders';
import { useStores } from '~store';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

import { APPBAR_HEIGHT_IN_PX } from '../Dispatch/components/drivers/constants';
import { BackToLiveBar } from './BackToLiveBar';
import TreadLiveMap from './TreadLiveMap';
import TreadLiveSideBar from './TreadLiveSideBar';

export const ACTIVE_JOB_STATES = [
  JobState.TO_PICKUP,
  JobState.ARRIVED_PICKUP,
  JobState.LOADED,
  JobState.TO_DROPOFF,
  JobState.ARRIVED_DROPOFF,
  JobState.UNLOADED,
];

/*
 * TreadLivePage
 *
 * The TreadLivePage component is responsible for rendering the live map view using only location data pulled from Tread APIs.
 */
const TreadLivePage = () => {
  const theme = useTheme();
  const { liveMapStoreNew } = useStores();
  const [selectedJob, setSelectedJob] = useState<Job>();
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [isViewingRoute, setIsViewingRoute] = useState<boolean>(false);

  const showLiveMapV2 = useFeatureFlag({
    featureFlagKey: FeatureFlags.googleMapsLiveMapV2,
  });

  const viewRouteForSelectedJob = (selectedJob: Job) => {
    if (selectedJob?.id) {
      setIsViewingRoute(true);
    }
  };

  const handleBackOnClick = () => {
    setSelectedJob(undefined);
    setSelectedOrder(undefined);
    setIsViewingRoute(false);
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={'auto 1fr'}
      height={`calc(100vh - ${APPBAR_HEIGHT_IN_PX}px)`}
      mx={-5}
      overflow="hidden"
    >
      {/* Top bar with filters */}
      <Box
        sx={{
          width: liveMapStoreNew.isSidebarCollapsed ? '50px' : '500px',
          [theme.breakpoints.down('lg')]: {
            width: liveMapStoreNew.isSidebarCollapsed ? '50px' : '450px',
          },
          height: `calc(100vh - ${APPBAR_HEIGHT_IN_PX}px)`,
          transition: 'width 0.3s',
        }}
      >
        <TreadLiveSideBar
          onSelectJob={(job) => {
            setSelectedJob(job);
            setSelectedOrder(undefined);
            setIsViewingRoute(false);
          }}
          onSelectOrder={(order: Order | undefined) => {
            setSelectedOrder(order);
            setSelectedJob(undefined);
            setIsViewingRoute(false);
          }}
          onViewJobRoute={(job) => {
            setSelectedJob(job);
            setSelectedOrder(undefined);
            viewRouteForSelectedJob(job);
          }}
          selectedJob={selectedJob}
          selectedOrder={selectedOrder}
          isSidebarCollapsed={liveMapStoreNew.isSidebarCollapsed}
        />
      </Box>
      {/* Live Map */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        className={'live-map'}
        position={'relative'}
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          height: '100%',
          width: '100%',
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          {((!!selectedJob && isViewingRoute) || !!selectedOrder) && (
            <BackToLiveBar
              handleBackOnClick={handleBackOnClick}
              selectedJob={selectedJob}
              selectedOrder={selectedOrder}
            />
          )}
          {showLiveMapV2 ? (
            <TreadLiveMapV2 job={selectedJob} order={selectedOrder} />
          ) : (
            <TreadLiveMap
              job={selectedJob}
              order={selectedOrder}
              isSidebarCollapsed={liveMapStoreNew.isSidebarCollapsed}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default observer(TreadLivePage);
