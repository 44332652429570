import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import nextbillion, { LngLat } from '@nbai/nbmap-gl';
import {
  ModelError_Item,
  ModelError_Response,
  Site_Read_Nested,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import circle from '@turf/circle';
import { Polygon, polygon } from '@turf/helpers';
import { AxiosError } from 'axios';
import { t as $t } from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as UUID } from 'uuid';

import { ModalDialog } from '~components/Dialog/ModalDialog';
import { FormFieldLabel } from '~components/FormFields/FormFieldLabel';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import HybridBaseMap from '~components/Maps/HybridBaseMap';
import { MarkerProps } from '~components/Maps/interfaces';
import { AddressType } from '~constants/enums';
import { AddressItem } from '~hooks/useAddress';
import { CompanyBasic, useCompany } from '~hooks/useCompany';
import {
  LIVE_MAP_GEOFENCE_FILL_LAYER_ID,
  LIVE_MAP_PINS_LAYER_ID,
  LIVE_MAP_SITES_LAYER_ID,
  LIVE_MAP_TRUCK_LAYER_ID,
} from '~hooks/useLiveMap/constants';
import { Site, SiteBasic, useSites } from '~hooks/useSites';
import { SiteWithAdditionalGeoFences } from '~pages/Settings/Administration/Sites/GeoFenceForm';
import { SiteForm } from '~pages/Settings/Administration/Sites/SiteForm';
import { Nullable } from '~types/Nullable';
import { usePrevious } from '~utils/hooks/usePrevious';
import { formatAddressOptionForSiteForm, getFormattedLatLng } from '~utils/sites';

import { SiteSelectorWithDefaultSites } from './SiteSelectorWithDefaultSites';
import { AddressOption, AddressOptionForNewSite } from './types';
import { Form } from './types';
import { AddressType as AddressTypeSection } from './types';

interface SiteLocationFormProps {
  companyId?: Nullable<string>;
  orderId?: Nullable<string>;
  pickUpSite?: Nullable<SiteBasic>;
  dropOffSite?: Nullable<SiteBasic>;
  onPickUpSiteChange: (value?: Site) => void;
  onDropOffSiteChange: (value?: Site) => void;
  errors?: { pickUpWayPoint?: object; dropOffWayPoint?: object };
  allowSuggestions?: boolean;
  isRequired?: boolean;
  projectId?: Nullable<string>;
  onDefaultSiteAdded?: ({
    waypointType,
    siteId,
  }: {
    waypointType: WaypointType;
    siteId: string;
  }) => void;
  hideAddDefaultSiteAction?: boolean;
}

interface SiteSelectionLabelProps {
  label: string;
  isRequired: boolean;
  editCta: {
    disabled?: boolean;
    label: string;
    onClick: () => void;
  };
}

const checkAndAddDroppedPin = (str: string): string => {
  const droppedPin = '(Dropped pin)';
  if (!str.includes(droppedPin)) {
    return `${droppedPin} ${str}`;
  }
  return str;
};

const LabelWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RequiredLabel = styled('sup')`
  top: 0;
`;

const SiteSelectionLabel = ({ label, editCta, isRequired }: SiteSelectionLabelProps) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {isRequired ? (
        <LabelWrapper>
          <FormFieldLabel label={label} />
          <RequiredLabel>*</RequiredLabel>
        </LabelWrapper>
      ) : (
        <FormFieldLabel label={label} />
      )}

      <PseudoLink
        disabled={editCta.disabled}
        title={editCta.label}
        action={editCta.onClick}
        sx={{ lineHeight: '10px' }}
      />
    </Box>
  );
};

interface SelectedSite {
  site: Nullable<Site>;
  type: WaypointType;
}
interface SelectedAddressOption {
  addressOption: Nullable<AddressOptionForNewSite>;
  type: AddressType;
}

type AddressOptionFormValue = Omit<AddressOption, 'type'>;

const transformSiteToAddressOption = (site: SiteBasic | Site): AddressOptionFormValue => {
  return {
    name: site.name,
    lat: site.lat || '',
    lng: site.lon || '',
    id: site.id,
    siteId: site.id,
    geofence: site.nextBillionGeofence,
  };
};

export const SiteSelection = ({
  companyId,
  orderId,
  pickUpSite,
  dropOffSite,
  onPickUpSiteChange,
  onDropOffSiteChange,
  errors: errorsProp,
  isRequired,
  allowSuggestions,
  projectId,
  onDefaultSiteAdded,
  hideAddDefaultSiteAction,
}: SiteLocationFormProps) => {
  const { getCompanyById, getUserCompanyDefaultMapCenter } = useCompany();
  const {
    createNewSite,
    getSiteById,
    isUpdating: isUpdatingSites,
    updateSite,
  } = useSites();

  const [company, setCompany] = useState<Nullable<CompanyBasic>>(null);
  const [editSiteFormDirty, setEditSiteFormDirty] = useState<boolean>(false);
  const [selectedAddressOption, setSelectedAddressOption] =
    useState<Nullable<SelectedAddressOption>>(null);
  const [selectedSiteToEdit, setSelectedSiteToEdit] =
    useState<Nullable<SelectedSite>>(null);
  const [selectedPickUpSite, setSelectedPickUpSite] =
    useState<Nullable<SiteBasic>>(pickUpSite);
  const [selectedDropOffSite, setSelectedDropOffSite] =
    useState<Nullable<SiteBasic>>(dropOffSite);
  const [mapId, setMapId] = useState<string>(UUID());
  const [markers, setMarkers] = useState<MarkerProps[]>([]);
  const previousPickUpSite = usePrevious(pickUpSite);
  const previousDropOffSite = usePrevious(dropOffSite);
  const [asyncErrors, setAsyncErrors] = useState<ModelError_Item[]>([]);

  const siteFormRef = useRef<any>(null);
  const siteModalDialogRef = useRef<any>(null);
  const isOrder = !!orderId;
  const isPickupSiteDuplicatedFromExistingOrder =
    isOrder && pickUpSite === undefined && previousPickUpSite;
  const isDropOffSiteDuplicatedFromExistingOrder =
    isOrder && dropOffSite === undefined && previousDropOffSite;
  const defaultPosition = getUserCompanyDefaultMapCenter();
  const layers = useMemo(() => [], []);
  const sources = useMemo(() => [], []);
  const controlsToAdd = useMemo(() => ['navigation'], []);

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      pickUpAddress: null as Nullable<AddressOptionFormValue>,
      dropOffAddress: null as Nullable<AddressOptionFormValue>,
      pickUpLatLng: '',
      dropOffLatLng: '',
    },
  });

  const siteSelectorProps = useMemo(
    () => ({
      onDefaultSiteAdded,
      control,
      latLngLabel: `${$t('form_fields.coordinates')}`,
      isRequired,
      allowSuggestions,
    }),
    [allowSuggestions, onDefaultSiteAdded, control, isRequired, selectedPickUpSite],
  );
  const pickUpSelectorProps = useMemo(
    () => ({
      ...siteSelectorProps,
      waypointType: WaypointType.PICKUP,
      addressName: 'pickUpAddress',
      latLngName: 'pickUpLatLng',
      addressLabel: '',
      renderCustomLabel: ({ isRequired = false }) => (
        <SiteSelectionLabel
          label={$t('form_fields.pick_up_site')}
          isRequired={isRequired}
          editCta={{
            disabled: !selectedPickUpSite,
            label: selectedPickUpSite
              ? $t('administration.site.edit_selected_pick_up_site')
              : $t('administration.site.create_new_site'),
            onClick: () =>
              onCreateEditSiteClick({
                siteId: selectedPickUpSite?.id,
                addressType: WaypointType.PICKUP,
              }),
          }}
        />
      ),
    }),
    [selectedPickUpSite],
  );

  const dropOffSelectorProps = useMemo(
    () => ({
      ...siteSelectorProps,
      waypointType: WaypointType.DROP_OFF,
      addressName: 'dropOffAddress',
      latLngName: 'dropOffLatLng',
      addressLabel: '',
      renderCustomLabel: ({ isRequired = false }) => (
        <SiteSelectionLabel
          label={$t('form_fields.drop_off_site')}
          isRequired={isRequired}
          editCta={{
            disabled: !selectedDropOffSite,
            label: selectedDropOffSite
              ? $t('administration.site.edit_selected_drop_off_site')
              : $t('administration.site.create_new_site'),
            onClick: () =>
              onCreateEditSiteClick({
                siteId: selectedDropOffSite?.id,
                addressType: WaypointType.DROP_OFF,
              }),
          }}
        />
      ),
    }),
    [selectedDropOffSite],
  );
  const watchPickUpAddress = watch('pickUpAddress');
  const watchDropOffAddress = watch('dropOffAddress');

  useEffect(() => {
    handleAddressChange(
      'pickUpAddress',
      'pickUpLatLng',
      onPickUpSiteChange,
      AddressType.PickUp,
    );
  }, [watchPickUpAddress]);

  useEffect(() => {
    handleAddressChange(
      'dropOffAddress',
      'dropOffLatLng',
      onDropOffSiteChange,
      AddressType.DropOff,
    );
  }, [watchDropOffAddress]);

  useEffect(() => {
    const fetchCompany = async () => {
      if (companyId) {
        const company = await getCompanyById({ id: companyId });
        setCompany(company as unknown as CompanyBasic);
      }
    };
    fetchCompany();
  }, [companyId]);

  useEffect(() => {
    if (errorsProp?.pickUpWayPoint) {
      setError('pickUpAddress', {
        message:
          'message' in errorsProp.pickUpWayPoint
            ? (errorsProp.pickUpWayPoint.message as string)
            : `${$t('form_validation_errors.required', { field: $t('form_fields.pick_up_site') })}`,
      });
    }

    if (errorsProp?.dropOffWayPoint) {
      setError('dropOffAddress', {
        message:
          'message' in errorsProp.dropOffWayPoint
            ? (errorsProp.dropOffWayPoint.message as string)
            : `${$t('form_validation_errors.required', { field: $t('form_fields.drop_off_site') })}`,
      });
    }

    return () => {
      clearErrors(['pickUpAddress', 'dropOffAddress']);
    };
  }, [errorsProp?.pickUpWayPoint, errorsProp?.dropOffWayPoint]);

  // Set the initial site values on load
  useEffect(() => {
    if (pickUpSite) {
      const initialPickUpSite = transformSiteToAddressOption(pickUpSite);
      setValue('pickUpAddress', initialPickUpSite);
    }
    if (dropOffSite) {
      const initialDropOffSite = transformSiteToAddressOption(dropOffSite);
      setValue('dropOffAddress', initialDropOffSite);
    }
  }, []);

  // Handle when orders are duplicated
  useEffect(() => {
    if (isPickupSiteDuplicatedFromExistingOrder && pickUpSite !== undefined) {
      setValue('pickUpAddress', transformSiteToAddressOption(previousPickUpSite));
    }
    if (isDropOffSiteDuplicatedFromExistingOrder && dropOffSite !== undefined) {
      setValue('dropOffAddress', transformSiteToAddressOption(previousDropOffSite));
    }
  }, [
    previousDropOffSite,
    previousPickUpSite,
    isDropOffSiteDuplicatedFromExistingOrder,
    isPickupSiteDuplicatedFromExistingOrder,
    transformSiteToAddressOption,
  ]);

  const mapCenter = useMemo(() => {
    const lastAddition = markers[markers.length - 1];
    if (lastAddition?.lng && lastAddition?.lat) {
      return {
        lng: lastAddition.lng,
        lat: lastAddition.lat,
      };
    } else {
      return {
        lng: defaultPosition.lng,
        lat: defaultPosition.lat,
      };
    }
  }, [markers, defaultPosition]);
  const [defaultSiteGeoFence, setDefaultSiteGeoFence] = useState<Polygon[]>([]);

  const bounds = useMemo(() => {
    const getLatLng = (address: Nullable<AddressOptionFormValue>) => {
      return address?.lat && address?.lng
        ? new nextbillion.maps.LngLat(Number(address.lng), Number(address.lat))
        : null;
    };

    const pickUpSite2 = getValues('pickUpAddress') as Nullable<AddressOptionFormValue>;
    const dropOffSite2 = getValues('dropOffAddress') as Nullable<AddressOptionFormValue>;

    const endPoint = getLatLng(pickUpSite2);
    const startPoint = getLatLng(dropOffSite2);

    if (startPoint && endPoint) {
      return new nextbillion.maps.LngLatBounds(startPoint, endPoint);
    } else if (!endPoint) {
      return new nextbillion.maps.LngLatBounds(startPoint, startPoint);
    } else if (!startPoint) {
      return new nextbillion.maps.LngLatBounds(endPoint, endPoint);
    }

    return null;
  }, [watchPickUpAddress, watchDropOffAddress]);

  const onEditOrderSiteForm = () => {
    siteFormRef.current?.submit(onSubmitEditOrderSiteFormCallBack);
  };

  const onCreateNewSiteForm = () => {
    siteFormRef.current?.submit(onSubmitNewSiteFormCallBack);
  };

  const onSubmitEditOrderSiteFormCallBack = async (data: SiteWithAdditionalGeoFences) => {
    selectedSiteToEdit?.site &&
      (await updateSite({
        ...data,
        id: selectedSiteToEdit.site.id,
      } as SiteWithAdditionalGeoFences)
        .then((site: Site) => {
          updateFormSite(site);
          siteModalDialogRef.current.close();
        })
        .catch((error: AxiosError<ModelError_Response>) => {
          setAsyncErrors(error.response?.data.error.errors || []);
        }));
  };

  const onSubmitNewSiteFormCallBack = async (data: SiteWithAdditionalGeoFences) => {
    await createNewSite(data)
      .then((site: Site | void) => {
        if (site) {
          updateFormSite(site);
          siteModalDialogRef.current.close();
        }
      })
      .catch((error) => {
        setAsyncErrors(error.response.data.error.errors);
      });
  };

  const onCreateEditSiteClick = async ({
    siteId,
    addressType,
  }: {
    siteId?: string;
    addressType: WaypointType;
  }) => {
    const siteData = (siteId && (await getSiteById(siteId))) || null;

    setSelectedSiteToEdit({ site: siteData, type: addressType });
    siteModalDialogRef.current?.open();
  };

  const updateSiteOnAddressChange = ({
    name,
    selectedSite,
  }: {
    name: keyof Form;
    selectedSite: Site;
  }) => {
    setSelectedAddressOption(null);

    if (name === 'pickUpAddress') {
      setSelectedPickUpSite(selectedSite as unknown as SiteBasic);
    } else if (name === 'dropOffAddress') {
      setSelectedDropOffSite(selectedSite as unknown as SiteBasic);
    }
  };

  const updateAddressOptionOnSiteChange = ({
    addressOption,
    waypointType,
  }: {
    addressOption: AddressOption;
    waypointType: AddressType;
  }) => {
    const formattedAddressOption = formatAddressOptionForSiteForm({
      addressOption,
    });
    if (waypointType === AddressType.PickUp) {
      setSelectedPickUpSite(null);
    } else if (waypointType === AddressType.DropOff) {
      setSelectedDropOffSite(null);
    }

    setSelectedAddressOption({
      type: waypointType,
      addressOption: formattedAddressOption,
    });
  };

  const handleAddressChange = (
    name: keyof Form,
    latLngName: keyof Form,
    changeFunc: (site: Site) => void,
    aType: AddressType,
  ) => {
    const address = getValues(name) as Nullable<AddressOption>;
    const isPickUp = aType === AddressType.PickUp;

    if (!address) {
      handleNoAddressChange(name, isPickUp, latLngName);
      return;
    }

    if (address?.geofence?.geojson) {
      const polygonFromFenceCoords = polygon(
        address?.geofence?.geojson?.coordinates,
        {},
        // ID will upsert the polygon on the map
        { id: address?.geofence?.id },
      );

      setDefaultSiteGeoFence((prev) => {
        const filteredFences = prev.filter((fence: any) => fence.siteType !== name);
        return [
          ...filteredFences,
          { ...polygonFromFenceCoords, siteType: name },
        ] as Polygon[];
      });
    } else if (address.geofence?.circleCenter && address.geofence?.circleRadius) {
      const center = [
        address.geofence.circleCenter.lon,
        address.geofence.circleCenter.lat,
      ];

      const circleFence = circle(center, address.geofence.circleRadius, {
        units: 'meters',
      });

      setDefaultSiteGeoFence((prev) => {
        const filteredFences = prev.filter((fence: any) => fence.siteType !== name);
        return [
          ...filteredFences,
          {
            ...circleFence,
            siteType: name,
          },
        ] as Polygon[];
      });
    }

    const { lat, lng } = getFormattedLatLng(address.lat as string, address.lng as string);

    // If the change is to a suggestion,
    // Update the selected address option and null
    // Out the selected site for the address type

    const site: Site_Read_Nested = {
      id: address.siteId || '',
      name: address.name,
      lat: lat,
      lon: lng,
      full_address: '',
      routable: !!(lat && lng),
    };

    if (address.address) {
      site.address = AddressItem.deparse(address.address);
    }

    const selectedSite = Site.parseNested(site);
    changeFunc(selectedSite);

    if (address.type === AddressTypeSection.Suggestions) {
      updateAddressOptionOnSiteChange({
        addressOption: address,
        waypointType: aType,
      });
    } else {
      updateSiteOnAddressChange({ name, selectedSite });
    }

    setValue(latLngName, `${lat}, ${lng}`);

    updateMarkers(isPickUp, lat, lng, name, address);
  };

  const updateMarkers = (
    isPickUp: boolean,
    lat: string,
    lng: string,
    name: keyof Form,
    address: AddressOption,
  ) => {
    const markerId = isPickUp ? String(AddressType.PickUp) : String(AddressType.DropOff);

    setMarkers((prev) => {
      const filteredMarkers = prev.filter((marker) => marker.id !== markerId);
      const newMarker: MarkerProps = {
        id: markerId,
        lng: Number.parseFloat(lng),
        lat: Number.parseFloat(lat),
        draggable: !address.siteId,
        onDragMarker: (e: LngLat) => {
          if (!address.siteId) {
            setValue(name, {
              ...address,
              lat: e.lat.toFixed(6),
              lng: e.lng.toFixed(6),
              name: checkAndAddDroppedPin(address.name),
            });
          }
        },
      };

      return [...filteredMarkers, newMarker];
    });
    setMapId(UUID());
  };

  const handleNoAddressChange = (
    name: keyof Form,
    isPickUp: boolean,
    latLngName: keyof Form,
  ) => {
    setDefaultSiteGeoFence((prev) => {
      return prev.filter((fence: any) => fence.siteType !== name);
    });
    setMarkers((prev) =>
      prev.filter(
        (marker) => marker.id !== (isPickUp ? AddressType.PickUp : AddressType.DropOff),
      ),
    );
    setValue(latLngName, '');
    setMapId(UUID());

    if (name === 'pickUpAddress') {
      onPickUpSiteChange(undefined);
      setSelectedPickUpSite(null);
    } else if (name === 'dropOffAddress') {
      onDropOffSiteChange(undefined);
      setSelectedDropOffSite(null);
    }
  };

  const updateFormSite = (site: Site) => {
    const addressType = selectedSiteToEdit?.type;

    // Update the order/project form site value with the newly
    // Create site
    if (addressType) {
      if (addressType === WaypointType.PICKUP) {
        onPickUpSiteChange(site);
      } else if (addressType === WaypointType.DROP_OFF) {
        onDropOffSiteChange(site);
      }

      // Update the selected site to edit with the newly created site
      setSelectedSiteToEdit({ site, type: addressType });

      if (addressType === WaypointType.PICKUP) {
        setValue('pickUpAddress', transformSiteToAddressOption(site));
      } else if (addressType === WaypointType.DROP_OFF) {
        setValue('dropOffAddress', transformSiteToAddressOption(site));
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <SiteSelectorWithDefaultSites
          projectId={projectId}
          orderId={orderId}
          hideAddDefaultSiteAction={hideAddDefaultSiteAction}
          errors={errors}
          {...pickUpSelectorProps}
          companyId={companyId}
        />
        <SiteSelectorWithDefaultSites
          projectId={projectId}
          orderId={orderId}
          hideAddDefaultSiteAction={hideAddDefaultSiteAction}
          errors={errors}
          {...dropOffSelectorProps}
          companyId={companyId}
        />
      </Box>

      <HybridBaseMap
        containerId={mapId}
        center={mapCenter as LngLat}
        layers={layers}
        sources={sources}
        bounds={bounds}
        controlsToAdd={controlsToAdd}
        defaultGeoFences={defaultSiteGeoFence}
        isDrawPolygonControlEnabled={false}
        isTrashDrawControlEnabled={false}
        markers={markers}
        isDrawAllowed={true}
        clickable={[
          LIVE_MAP_PINS_LAYER_ID,
          LIVE_MAP_TRUCK_LAYER_ID,
          LIVE_MAP_SITES_LAYER_ID,
          LIVE_MAP_GEOFENCE_FILL_LAYER_ID,
        ]}
        zoom={15}
        sx={{ height: '300px' }}
        overlay={markers.length ? null : $t('navigation.choose_address')}
      />

      <ModalDialog
        maxWidth="xl"
        title={
          selectedSiteToEdit?.site
            ? $t('administration.site.update_site')
            : $t('administration.site.add_new')
        }
        ref={siteModalDialogRef}
        content={
          <SiteForm
            ref={siteFormRef}
            defaultSite={selectedSiteToEdit?.site}
            addressOption={selectedAddressOption?.addressOption}
            onFormStateChange={() => setEditSiteFormDirty(true)}
            company={company}
            errors={asyncErrors}
          />
        }
        loading={isUpdatingSites}
        confirmButtonText={$t('actions.submit')}
        confirmButtonColor="primary"
        cancelButtonText={$t('actions.cancel')}
        callBack={selectedSiteToEdit?.site ? onEditOrderSiteForm : onCreateNewSiteForm}
        onClose={() => siteModalDialogRef.current?.close()}
        onCancel={() => siteModalDialogRef.current?.close()}
        confirmedDisabled={!editSiteFormDirty}
      />
    </Box>
  );
};
